import * as types from "../types/assets";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/asset`;

//////////////////////////////////////////////
///////////// Asset Requisition //////////////
//////////////////////////////////////////////
export const createAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.post(`${URL}/requisition`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_ASSET_REQUISITION,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_ASSET_REQUISITION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAssetRequisitions = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_ASSET_REQUISITIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/requisition`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_ASSET_REQUISITIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_ASSET_REQUISITIONS_FAIL,
            payload: error
        });
    }
};

export const getAssetRequisition = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.get(`${URL}/requisition/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ASSET_REQUISITION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_ASSET_REQUISITION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_ASSET_REQUISITION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteAssetRequisition = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_ASSET_REQUISITION_REQUEST,
        });
        await axios.delete(`${URL}/requisition/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_ASSET_REQUISITION,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const disapproveAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DISAPPROVE_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/disapprove/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DISAPPROVE_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DISAPPROVE_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const reviewAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log(data)
        
        dispatch({
            type: types.DECLINE_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const authorizeAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.AUTHORIZE_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/authorize/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.AUTHORIZE_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.AUTHORIZE_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deauthorizeAssetRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DEAUTHORIZE_ASSET_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/deauthorize/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log(data)
        
        dispatch({
            type: types.DEAUTHORIZE_ASSET_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DEAUTHORIZE_ASSET_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
