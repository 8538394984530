import React, { useState, useEffect, useCallback  } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    Hidden,
    Fab,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import {
    getPurchaseRequisitions,
    getPurchaseRequisition,
    reviewPurchaseRequisition,
} from "../../../../actions/purchasingActions";
import { getApprovedVendors } from "../../../../actions/vendorsActions";
// import { getDivisionMonthlyBudgetItems } from "../../../actions/monthlyBudgetAction";
import { getDivisionYearAnnualBudgetLines } from "../../../../actions/annualAction";

import { getProfile } from "../../../../actions/userActions";

import ReviewPurchaseRequisitionModal from "../modals/ReviewPurchaseRequisitionModal";
import { formatAmount, formatCurrency } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        paddingBottom: 0
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    grid2: {
        marginBottom: 0,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 280,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    month: "",
    year: "",
    division: "",
    purpose: "",
    dateNeeded: "",
    requisitionGroup: "",
    items: [],
    totalQuantityNeeded: null,
    requisitionType: "",
    purchaseType:"",
    shippingType:"",
    vendorType: "",
    reqvendors: [],
};

const INIT_VENDOR = {
    id: "",
    companyName: "",
    email: "",
    justification: ""
}

const ReviewPurchaseRequisition = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [vendor, setVendor] = useState(INIT_VENDOR);
    // const { divItems } = useSelector((state) => state.monthlyBudget);
    const { vendors } = useSelector((state) => state.vendors);
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const { profile } = useSelector((state) => state.user);
    const { error, loading, purchase_requisition } = useSelector((state) => state.purchaseRequisition);
    const [openReview, setOpenReview] = useState(false);
    const { annualBudgetLines } = useSelector((state) => state.annualBudget);


    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    // console.log(annualBudgetLines)
    useEffect(() => {
        dispatch(getPurchaseRequisitions());
        dispatch(getApprovedVendors());
        dispatch(getPurchaseRequisition(id));
        dispatch(getProfile)
    }, [dispatch, id]);

    const handleVendorChange = (e) => {
        e.persist();
        setVendor((prev) => ({
            ...prev,
            justification: e.target.value,
        }));
    };

    const handleAddVendorsArray = (vendor) => {
        if (vendor.id !== "" && vendor.companyName !== "" && vendor.email !== "" && vendor.justification !== "") {
            // if(state.reqvendors && state.reqvendors.length < 3){
                setState((prev) => ({
                    ...prev,
                    reqvendors: prev.reqvendors.concat([vendor]),
                }));
                setVendor(INIT_VENDOR);
            // }
        }
    };

    const handleRemoveVendorsArray = (i) => {
        setState((prev) => ({
            ...prev,
            reqvendors: prev.reqvendors.filter(
                (_, index) => index !== i
            ),
        }));
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            purpose: (purchase_requisition && purchase_requisition.purpose) || "",
            employee: (purchase_requisition && purchase_requisition.employee) || "",
            division: (purchase_requisition && purchase_requisition.division) || "",
            dateNeeded: (purchase_requisition && moment(purchase_requisition.dateNeeded).format("l")) || "",
            requisitionGroup: (purchase_requisition && purchase_requisition.requisitionGroup) || "",
            month: (purchase_requisition && purchase_requisition.month) || "",
            year: (purchase_requisition && purchase_requisition.year) || "",

            totalQuantityNeeded: (purchase_requisition && purchase_requisition.totalQuantityNeeded) || "",

            items: (purchase_requisition && purchase_requisition.items) || [],
            status: (purchase_requisition && purchase_requisition.status) || "",
            documentNo: (purchase_requisition && purchase_requisition.documentNo) || "",

            requisitionType: (purchase_requisition && purchase_requisition.requisitionType) || "",
            shippingType: (purchase_requisition && purchase_requisition.shippingType) || "",
            purchaseType: (purchase_requisition && purchase_requisition.purchaseType) || "",
            vendorType: (purchase_requisition && purchase_requisition.vendorType) || "",
            vendors: (purchase_requisition && purchase_requisition.vendors) || [],

            createdBy: (purchase_requisition && purchase_requisition.createdBy) || "",
            createdAt: (purchase_requisition && purchase_requisition.createdAt) || "",

            submittedBy: (purchase_requisition && purchase_requisition.submittedBy) || "",
            submittedAt: (purchase_requisition && purchase_requisition.submittedAt) || "",

            approvedBy: (purchase_requisition && purchase_requisition.approvedBy) || "",
            approvedAt: (purchase_requisition && purchase_requisition.approvedAt) || "",
            approveComment: (purchase_requisition && purchase_requisition.approveComment) || "",
            
            rejectedBy: (purchase_requisition && purchase_requisition.rejectedBy) || "",
            rejectedAt: (purchase_requisition && purchase_requisition.rejectedAt) || "",
            rejectComment: (purchase_requisition && purchase_requisition.rejectComment) || "",

            acceptedBy: (purchase_requisition && purchase_requisition.acceptedBy) || "",
            acceptedAt: (purchase_requisition && purchase_requisition.acceptedAt) || "",
            acceptComment: (purchase_requisition && purchase_requisition.acceptComment) || "",

            declinedBy: (purchase_requisition && purchase_requisition.declinedBy) || "",
            declinedAt: (purchase_requisition && purchase_requisition.declinedAt) || "",
            declineComment: (purchase_requisition && purchase_requisition.declineComment) || "",

            reviewedBy: (purchase_requisition && purchase_requisition.reviewedBy) || "",
            reviewedAt: (purchase_requisition && purchase_requisition.reviewedAt) || "",

        }));
    }, [purchase_requisition]);

    // console.log(purchase_requisition)

    // const getDivisionMonthlyBudgetItemsCallback = useCallback(() => {
    //     let data = {
    //         year: state.year && state.year,
    //         month: state.month && state.month,
    //         serviceDivision: state && state.division._id,
    //     };
    //     dispatch(getDivisionMonthlyBudgetItems(data));
    // }, [dispatch, state]);

    // useEffect(() => {
    //     getDivisionMonthlyBudgetItemsCallback();
    // }, [getDivisionMonthlyBudgetItemsCallback]);

    // use annual budget
    const getDivisionYearAnnualBudgetLinesCallback = useCallback(() => {
        if(state.division !==""){
            let data = {
                year: state.year && state.year,
                division: state && state.division._id,
            };
            getDivisionYearAnnualBudgetLines(data);
        }
    }, [state]);

    // console.log(state.division)

    useEffect(() => {
        if(state.division !==""){
            getDivisionYearAnnualBudgetLinesCallback();
        }
    }, [getDivisionYearAnnualBudgetLinesCallback, state]);


    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };

    const handleReview = async (e) => {
        // e.persist();
        if(state && state.purchaseType === "Vendor" && state.vendorType === "Regular" && state.reqvendors.length >= 3){
            setFeed((prev) => ({ 
                ...prev,
                loading: true, 
            }));
            const data = {
                purchaseType: state.purchaseType,
                shippingType: state.shippingType,
                requisitionType: state.requisitionType,
                vendorType: state.vendorType,
                vendors: state.reqvendors,
                id,
                path: `purchasing/requisition/review/${id}`,
            };
            await dispatch(reviewPurchaseRequisition(data));
            await dispatch(getPurchaseRequisitions);
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Purchase Requisition Reviewed Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push("/purchasing/requisition");
            }, 1500);
        } else if(state && state.purchaseType === "Vendor" && (state.vendorType === "Contract" || state.vendorType === "Sole Source") 
        && state.reqvendors.length === 1){
            setFeed((prev) => ({ 
                ...prev,
                loading: true, 
            }));
            const data = {
                purchaseType: state.purchaseType,
                shippingType: state.shippingType,
                requisitionType: state.requisitionType,
                vendorType: state.vendorType,
                vendors: state.reqvendors,
                id,
                path: `purchasing/requisition/review/${id}`,
            };
            
            await dispatch(reviewPurchaseRequisition(data));
            await dispatch(getPurchaseRequisitions);
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Purchase Requisition Reviewed Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push("/purchasing/requisition");
            }, 1500);
        }else if(state && state.purchaseType === "Open Market"){
            setFeed((prev) => ({ 
                ...prev,
                loading: true, 
            }));
            const data = {
                purchaseType: state.purchaseType,
                shippingType: state.shippingType,
                requisitionType: state.requisitionType,
                vendorType: state.vendorType,
                vendors: state.reqvendors,
                id,
                path: `purchasing/requisition/review/${id}`,
            };
            await dispatch(reviewPurchaseRequisition(data));
            await dispatch(getPurchaseRequisitions);
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Purchase Requisition Reviewed Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push("/purchasing/requisition");
            }, 1500);
        }
    };

    const submitCheck = () => 
        !state.requisitionType ||
        !state.purchaseType ||
        !state.shippingType || 
        !state.vendorType || 
        (state.vendorType === "Regular" && (state.reqvendors.length < 3)) || 
        (state.purchaseType !== "Vendor");

    const isProcurement = profile
        ? profile &&
        profile.division &&
        profile.division.name === "Procurement Management"
        : false;

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;

    const validCheck = () =>
        !state.requisitionType ||
        !state.purchaseType ||
        !state.shippingType;
        
    return (
        <div>
            <ReviewPurchaseRequisitionModal 
                {...{ 
                    openReview, 
                    handleCloseReview, 
                    id, 
                    reviewPurchaseRequisition, 
                    data: {
                        purchaseType: state.purchaseType,
                        shippingType: state.shippingType,
                        requisitionType: state.requisitionType,
                        vendorType: state.vendorType,
                        vendors: state.reqvendors,
                        id,
                        path: `purchasing/requisition/review/${id}`,
                    }
                }} 
            />

            <MuiPickersUtilsProvider utils={MomentUtils}>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            
            <Typography variant="overline" className={classes.title}>
                Review Purchase Requisition / Generate RFQ
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/purchasing/requisition")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft" ? classes.statusYellow
                                : state.status === "submitted" ? classes.statusOrange
                                    : (state.status === "accepted" || state.status === "approved") ? classes.statusGreen
                                        : (state.status === "reviewed" || state.status === "completed") ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Employee</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={(state.createdBy && state.createdBy.userName) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Division/Subdivision</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={(state.division && state.division.name) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Document No</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={state.documentNo || ""}
                        disabled
                        required
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Date Needed</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={state.dateNeeded || ""}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Month</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={(state.month && state.month) || ""}
                        disabled
                        required
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Year</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={(state.year && state.year) || ""}
                        disabled
                        required
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <label style={{ fontWeight: "bold" }}>Requisition Category</label>
                    <FormControl fullWidth variant="outlined" required>
                        <Select
                            inputProps={{
                                id: "requisitionGroup",
                                name: "requisitionGroup",
                            }}
                            value={state.requisitionGroup || ""}
                            disabled
                        >
                            <MenuItem disabled>Select</MenuItem>
                            <MenuItem value="Regular">Regular Requisition</MenuItem>
                            <MenuItem value="Asset Requisition" disabled>Asset Acquisition</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label style={{ fontWeight: "bold" }}>Purpose</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        disabled
                        multiline
                        rows={3}
                        name="purpose"
                        value={state.purpose || ""}
                    />
                </Grid>
            </Grid>

            <Typography variant="overline" className={classes.title2}>
                Requisition Items
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {state.items.map((sitem, i) => (
                    <Grid
                        container
                        spacing={2}
                        className={classes.grid}
                        key={i}
                    >
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="outlined" required>
                                <label style={{ fontWeight: "bold" }}>Budget Item</label>
                                <Select
                                        inputProps={{
                                            id: "budgetItem",
                                            name: "budgetItem",
                                        }}
                                        value={sitem.budgetItem}
                                        disabled
                                    >
                                        <MenuItem disabled>Select</MenuItem>
                                        {annualBudgetLines &&
                                            annualBudgetLines.map((stuff, i) => (
                                                <MenuItem key={i} value={stuff._id}>
                                                    {stuff?.item?.name}
                                                </MenuItem>
                                            ))
                                        }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <label style={{ fontWeight: "bold" }}>Budget Amount</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="budgetAmount"
                                value={`${formatCurrency(sitem.budgetAmount, "en-NG", "NGN") || 0}` || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <label style={{ fontWeight: "bold" }}>Description</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="description"
                                value={sitem.description || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <label style={{ fontWeight: "bold" }}>Quantity Needed</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                type="number"
                                name="quantityNeeded"
                                value={sitem.quantityNeeded || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2} >
                            <FormControl fullWidth variant="outlined" required>
                                <label style={{ fontWeight: "bold" }}>Unit of Measurement</label>
                                <Select
                                    inputProps={{
                                        id: "unit",
                                        name: "unit",
                                    }}
                                    value={sitem.unit}
                                    disabled
                                >
                                    <MenuItem disabled>Select Unit</MenuItem>
                                    <MenuItem value="items">Items</MenuItem>
                                    <MenuItem value="packets">Packets</MenuItem>
                                    <MenuItem value="reams">Reams</MenuItem>
                                    <MenuItem value="kg">Kg</MenuItem>
                                    <MenuItem value="cartons">Cartons</MenuItem>
                                    <MenuItem value="crates">Crates</MenuItem>
                                    <MenuItem value="meters">Meters</MenuItem>
                                    <MenuItem value="kilometers">Kilometers</MenuItem>
                                    <MenuItem value="pieces">Pieces</MenuItem>
                                    <MenuItem value="dozens">Dozens</MenuItem>
                                    <MenuItem value="litres">Litres</MenuItem>
                                    <MenuItem value="gallons">Gallons</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                ))}
            </Paper>
            
            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={9} />
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Total Quantity Needed"
                        name="totalQuantityNeeded"
                        value={`${formatAmount(state.totalQuantityNeeded, "en-NG") || 0}` || ""}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>

            <div>
                {/* accepted purchase requisition */}
                {(isProcurement || isAdmin) && state && (state.status === "accepted") ? ( 
                <>
                    <Typography variant="overline" className={classes.title2}>
                        Review Requisition
                    </Typography>

                    <Paper variant="outlined" square className={classes.paper} style={{paddingBottom: 20}}>
                        <Typography variant="overline" className={classes.title2}>
                            Please select the requisition type, shipping type, and purchase type<br />
                            If the purchase type is Vendor, kindly select the Vendor Type, select a Vendor and enter a 
                            justification for choosing the Vendor.<br />
                            Click the green button to add to Selected Vendors.
                        </Typography>
                    </Paper>

                    <Paper variant="outlined" square className={classes.paper}>
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel id="requisitionType">Requisition Type</InputLabel>
                                    <Select
                                        labelId="requisitionType"
                                        name="requisitionType"
                                        inputProps={{
                                        id: "requisitionType",
                                        name: "requisitionType",
                                        }}
                                        onChange={handleChange}
                                        value={state.requisitionType || ""}
                                    >
                                        <MenuItem value="Service">Service</MenuItem>
                                        <MenuItem value="Product">Product</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel id="shippingType">Ship Via</InputLabel>
                                    <Select
                                        labelId="shippingType"
                                        name="shippingType"
                                        inputProps={{
                                            id: "shippingType",
                                            name: "shippingType",
                                        }}
                                        onChange={handleChange}
                                        value={state.shippingType || ""}
                                    >
                                        <MenuItem value="Digital">Digital (Download)</MenuItem>
                                        <MenuItem value="Vendor">Vendor Delivery</MenuItem>
                                        <MenuItem value="Courier">Courier</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel id="purchaseType">Purchase Type</InputLabel>
                                    <Select
                                        labelId="purchaseType"
                                        name="purchaseType"
                                        inputProps={{
                                            id: "purchaseType",
                                            name: "purchaseType",
                                        }}
                                        onChange={handleChange}
                                        value={state.purchaseType || ""}
                                    >
                                        <MenuItem value="Vendor">Vendor</MenuItem>
                                        <MenuItem value="Open Market">Open Market</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {state && (state.purchaseType === "Vendor" && state.status === "accepted") ? (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel id="vendorType">Vendor Type</InputLabel>
                                            <Select
                                                labelId="vendorType"
                                                name="vendorType"
                                                inputProps={{
                                                    id: "vendorType",
                                                    name: "vendorType",
                                                }}
                                                onChange={handleChange}
                                                value={state.vendorType || ""}
                                            >
                                                <MenuItem value="Contract">Contract</MenuItem>
                                                <MenuItem value="Sole Source">Sole Source</MenuItem>
                                                <MenuItem value="Regular">Regular (3 Quotes)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Autocomplete
                                            style={{marginTop: -15}}
                                            id="vendor"
                                            options={vendors}
                                            value={vendor.id || ""}
                                            onChange={(event, newValue) => {
                                                setVendor((prev) => ({
                                                    ...prev,
                                                    _id: newValue && newValue._id,
                                                    id: newValue && newValue._id,
                                                    email: newValue && newValue.email,
                                                    companyName: newValue && newValue.companyName,
                                                }));
                                            }}
                                            getOptionLabel={(option) => option.companyName || ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderOption={(option) => (
                                                <React.Fragment>{option.companyName}</React.Fragment>
                                            )}
                                            autoComplete
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Vendor"
                                                    margin="normal"
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    value={(vendor.companyName && vendor.companyName) || ""}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "disabled",
                                                        name: "vendor",
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Vendor Company Name"
                                            name="companyName"
                                            value={vendor.companyName || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Vendor Email"
                                            name="email"
                                            value={vendor.email || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Justification"
                                            name="justification"
                                            value={vendor.justification || ""}
                                            onChange={handleVendorChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Fab
                                            color="primary"
                                            style={{ backgroundColor: "#093", color: "#fff", }}
                                            onClick={() => handleAddVendorsArray(vendor)}
                                            // disabled={(state.vendorType === "Regular" && (state.reqvendors.length < 3))  ? true : false}
                                            // disabled
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Grid>
                                </>
                            ) : null}
                        </Grid>
                    </Paper>
                
                    {state && (state.purchaseType === "Vendor") ? (
                        <>
                        <Typography variant="overline" className={classes.title2}>
                            Selected Vendors
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.reqvendors && state.reqvendors ? (
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.grid}
                                >
                                    {state.reqvendors ? state.reqvendors.map((svendor, i) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                                key={i}
                                            >
                                                <Grid item xs={12} md={4}>
                                                    <label style={{ fontWeight: "bold" }}>Vendor Company Name</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="companyName"
                                                        value={svendor.companyName || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <label style={{ fontWeight: "bold" }}>Vendor Email</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="email"
                                                        value={svendor.email || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <label style={{ fontWeight: "bold" }}>Vendor Justification</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="justification"
                                                        value={svendor.justification || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={1}>
                                                    <label>&nbsp;</label>
                                                    <Fab    
                                                        style={{margintop: 30}}
                                                        color="secondary"
                                                        onClick={() => handleRemoveVendorsArray(i)}
                                                    >
                                                        <CloseIcon />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        )) : <>
                                            <Typography variant="overline" className={classes.title2}>
                                                No Requested Items
                                            </Typography>
                                        </>}
                                </Grid>
                            ) : null}
                        </Paper>
                    </>
                    ) : null}
                </>
                ) : null}

                {/* reviewed purchase requisition */}
                {state && (state.status === "reviewed") ? (
                    <>
                        <Typography variant="overline" className={classes.title2}>
                            Reviewed Requisition
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            <Grid container spacing={2} className={classes.grid2}>
                                <Grid item xs={12} md={3}>
                                    <label style={{ fontWeight: "bold" }}>Requisition Type</label>
                                    <FormControl fullWidth variant="outlined" required>
                                        <Select
                                            inputProps={{
                                                id: "requisitionType",
                                                name: "requisitionType",
                                            }}
                                            disabled
                                            value={state.requisitionType || ""}
                                        >
                                            <MenuItem value="Service">Service</MenuItem>
                                            <MenuItem value="Product">Product</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <label style={{ fontWeight: "bold" }}>Shipping Type</label>
                                    <FormControl fullWidth variant="outlined" required>
                                        <Select
                                            labelId="shippingType"
                                            inputProps={{
                                                id: "shippingType",
                                                name: "shippingType",
                                            }}
                                            disabled
                                            value={state.shippingType || ""}
                                        >
                                            <MenuItem value="Digital">Digital (Download)</MenuItem>
                                            <MenuItem value="Vendor">Vendor Delivery</MenuItem>
                                            <MenuItem value="Courier">Courier</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <label style={{ fontWeight: "bold" }}>Purchase Type</label>
                                    <FormControl fullWidth variant="outlined" required>
                                        <Select
                                            labelId="purchaseType"
                                            inputProps={{
                                                id: "purchaseType",
                                                name: "purchaseType",
                                            }}
                                            disabled
                                            value={state.purchaseType || ""}
                                        >
                                            <MenuItem value="Vendor">Vendor</MenuItem>
                                            <MenuItem value="Open Market">Open Market</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                {state && (state.purchaseType === "Vendor") ? 
                                    <Grid item xs={12} md={3}>
                                        <label style={{ fontWeight: "bold" }}>Vendor Type</label>
                                        <FormControl fullWidth variant="outlined" required>
                                            <Select
                                                labelId="vendorType"
                                                inputProps={{
                                                    id: "vendorType",
                                                    name: "vendorType",
                                                }}
                                                disabled
                                                value={state.vendorType || ""}
                                            >
                                                <MenuItem value="Contract">Contract</MenuItem>
                                                <MenuItem value="Sole Source">Sole Source</MenuItem>
                                                <MenuItem value="Regular">Regular (3 Quotes)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                : null}
                            </Grid>
                        </Paper>

                        {(state.vendors && state.vendors.length > 0) ? (
                            <>
                                <Typography variant="overline" className={classes.title2}>
                                    Your Selected Vendors
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            {state.vendors ? state.vendors.map((mvendor, i) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                    key={i}
                                                >
                                                    <Grid item xs={12} md={3}>
                                                        <label style={{ fontWeight: "bold" }}>Vendor Company Name</label>
                                                        <FormControl fullWidth variant="outlined" required>
                                                            <Select
                                                                labelId="companyName"
                                                                value={mvendor || ""}
                                                                disabled
                                                            >
                                                                <MenuItem disabled>Select</MenuItem>
                                                                {vendors &&
                                                                    vendors.map((ovendor) => (
                                                                        <MenuItem key={ovendor._id} value={ovendor._id}>
                                                                            {ovendor.companyName}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <label style={{ fontWeight: "bold" }}>Vendor Company Name</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            value={mvendor.companyName || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <label style={{ fontWeight: "bold" }}>Vendor Email</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            value={mvendor.email || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <label style={{ fontWeight: "bold" }}>Vendor Justification</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            value={mvendor.justification || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )) : <>
                                                <Typography variant="overline" className={classes.title2}>
                                                    No Requested Vendors
                                                </Typography>
                                            </>}
                                        </Grid>
                                </Paper>
                            </>
                        ) : null}
                    </>
                ) : null}

                <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    spacing={1}
                    style={{ marginBottom: 5 }}
                >
                    <Grid item>
                        <Typography variant="overline" className={classes.title2}>
                            Audit Trail
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Icon className="fas fa-clipboard-list" color="disabled" />
                    </Grid>
                </Grid>
                <Paper variant="outlined" square className={classes.paper}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Paper
                                className={classes.auditContainer}
                                component="div"
                                elevation={1}
                                variant="outlined"
                                square
                            >
                                <span className={classes.auditTitle}>Created By</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.createdAt}</span>
                            </Paper>
                        </Grid>
                        
                        <Grid item xs={12} md={12}>
                            <Paper
                                className={classes.auditContainer}
                                component="div"
                                elevation={1}
                                variant="outlined"
                                square
                            >
                                <span className={classes.auditTitle}>Submitted By</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.submittedAt}</span>
                            </Paper>
                        </Grid>

                        {(state.approvedAt && state.approvedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Approved By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approvedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approveComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.rejectedAt && state.rejectedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Rejected By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.acceptedAt && state.acceptedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Accepted By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.declinedAt && state.declinedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Declined By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declinedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declineComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.reviewedAt && state.reviewedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Reviewed By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.reviewedBy && state.reviewedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.reviewedAt}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>

                {/* ACTION BUTTONS */}
                {(isProcurement || isAdmin) && state.status === "accepted" ? (
                    <Grid container justify="center">
                        <Grid item>
                            <div className={classes.centered}>
                                <Paper className={classes.paperBlack}>
                                    <HelpOutlineIcon fontSize="large" />
                                    <Typography placeholder="Info" className={classes.info2}>
                                        This purchase requisition {state.purchaseType === "Vendor" ? "generation of RFQ" : "review and creation of open market"} is to 
                                        be done by the Procurement Officer.
                                    </Typography>
                                </Paper>
                            </div>
                            <div className={classes.btnWrapper}>
                                <Button
                                    type="submit"
                                    onClick={(state.purchaseType === "Vendor") ? handleOpenReview : () => handleReview() }
                                    className={classes.saveBtn2}
                                    disabled={loading || ((state.purchaseType === "Vendor" && state.vendorType === "Regular") ? submitCheck() : validCheck()) }
                                    color="primary"
                                    variant="contained"
                                >
                                    {loading ? "Loading..." : ((state.purchaseType === "Vendor" && state.vendorType === "Regular") ? "Generate RFQ" : "Review")}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                ) : null}

            </div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default ReviewPurchaseRequisition;
