import React from "react";
import { connect, useDispatch } from "react-redux";
import {
  Paper,
  Grid,
  Button,
  IconButton,
  Typography,
  Chip,
  makeStyles,
  darken,
  ButtonGroup,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import FilterListIcon from "@material-ui/icons/FilterList";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@material-ui/core/Checkbox";

import { TablePaginationActions } from "../../TablePaginationActions";
import {
  postponeMonthlyBudget,
  getMonthlyBudgetById,
} from "../../../actions/monthlyBudgetAction";
import Feedback from "../../atoms/Feedback";
import clsx from "clsx";
import MonthlyReviewModal from "./Approvals/MonthlyReviewModal";

const useStyles = makeStyles((theme) => ({
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    // fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  actions: {
    background: "#388e3c",
    color: "#fff",
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  paperBlack: {
    color: "#f5f5f5",
    background: "#3c3737",
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    textAlign: "center",
  },
  info: {
    textAlign: "left",
    fontWeight: 400,
  },
  marginBottom: {
    marginBottom: 30,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const MonthlyBudgetReviewTable = ({
  filteredRows,

  page,
  rowsPerPage,
  id,

  emptyRows,
  handleChangePage,
  handleChangeRowsPerPage,
  theme,
  formatCurrency,
  monthlyBudget,
  profile,
  state,
  postponeMonthlyBudget,
  getMonthlyBudgetById,
  error,
}) => {
  const classes = useStyles();
  const [selectBudgetLines, setSelectBudgetLines] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openReview, setOpenReview] = React.useState(false);

  const dispatch = useDispatch();

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const onHandleSelectBudgetLine = (e, data) => {
    e.persist();

    setSelectBudgetLines((prev) =>
      e.target.checked === false && prev.includes(data._id)
        ? prev.filter((item) => item !== data._id)
        : [...prev, data._id]
    );
  };

  const handlePostponeBudget = () => {
    setLoading((prev) => !prev);
    const data = {
      budgetLineIds: selectBudgetLines,
      path: "budget/monthly/view",
    };
    postponeMonthlyBudget(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          success: true,
        });
        dispatch(getMonthlyBudgetById(id));
      })
      .catch(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          success: false,
        });
      })
      .finally(() => {
        setSelectBudgetLines([]);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleOpenReview = () => {
    setOpenReview(true);
  };
  const handleCloseReview = () => {
    setOpenReview(false);
  };

  let isAdminAndFinance = profile
    ? profile &&
      profile.groups &&
      profile.groups.some(
        (grp) =>
          grp.name === "admin" ||
          (grp.name === "reviewer" && grp.module === "budget")
      )
    : false;

  // let isCEO = profile
  //   ? profile &&
  //     profile.groups &&
  //     profile.groups.some(
  //       (grp) => grp.name === "ceo" && grp.module === "budget"
  //     )
  //   : false;

  // let isCEOAndReviewer = profile
  //   ? profile &&
  //     profile.groups &&
  //     profile.groups.some(
  //       (grp) =>
  //         (grp.name === "ceo" && grp.module === "budget") ||
  //         (grp.name === "reviewer" && grp.module === "budget")
  //     )
  //   : false;

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "Budget Line deferred Successfully"}
        />
      )}
      <MonthlyReviewModal
        {...{
          id: monthlyBudget && monthlyBudget._id,
          openReview,
          handleCloseReview,
        }}
      />
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        // className={classes.marginBottom}
      >
        <Grid item></Grid>

        <Grid item>
          {isAdminAndFinance && state.status === "approved" && (
            <>
              <ButtonGroup>
                <Button
                  variant="contained"
                  className={classes.actions}
                  onClick={handleOpenReview}
                >
                  Accept
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.saveBtn}
                  disabled={
                    selectBudgetLines.length === 0 ||
                    state.status === "completed" ||
                    loading
                  }
                  onClick={handlePostponeBudget}
                >
                  {loading ? "Loading..." : "Defer"}
                </Button>
              </ButtonGroup>
            </>
          )}
        </Grid>
      </Grid>

      {isAdminAndFinance && state.status === "approved" && (
        <Paper className={classes.paperBlack}>
          <HelpOutlineIcon fontSize="large" />
          <Typography component="p" placeholder="Info" className={classes.info}>
            To defer budgetline(s), click on the checkbox below action in the
            data-table, then click on the "DEFER" button, any budgetline not
            checked will be "ACCEPTED" automatically once you click on the
            "Accept" button.
          </Typography>
        </Paper>
      )}

      <Typography variant="overline" style={{ color: "#6F6767" }}>
        BudgetLines
      </Typography>
      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Expense Head</TableCell>
                <TableCell>Expense SubHead</TableCell>
                <TableCell>Budget Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow
                    hover
                    key={row._id}
                    style={{
                      background:
                        row.status === "postponed" ? "#eeeeee" : "none",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Checkbox
                        onChange={(e) => onHandleSelectBudgetLine(e, row)}
                        checked={
                          selectBudgetLines.includes(row._id) ||
                          row.status === "deferred"
                        }
                        disabled={
                          row.status === "deferred" ||
                          state.status === "completed"
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.head && row.head.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.subHead && row.subHead.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.item && row.item.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        className={clsx(
                          row.status === "draft"
                            ? classes.statusOrange
                            : row.status === "deferred"
                            ? classes.statusRed
                            : row.status === "approved"
                            ? classes.statusGreen
                            : row.status === "completed",
                          classes.chip
                        )}
                        variant="outlined"
                        label={row.status}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color:
                          row.amount <= 200000
                            ? theme.palette.warning.main
                            : theme.palette.success.main,
                      }}
                    >
                      {row.amount
                        ? formatCurrency(row.amount, "en-NG", "NGN")
                        : formatCurrency(0, "en-NG", "NGN")}
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.monthlyBudget.error,
});

const mapDispatchToProps = {
  postponeMonthlyBudget,
  getMonthlyBudgetById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyBudgetReviewTable);
