import axios from "axios";
import * as types from "../../types/priceListType";

const token = localStorage.getItem("token:key");

export const createEquipmentPriceList = (data) => async (dispatch) => {
  try {
    dispatch({
      type: types.CREATE_EQUIPMENT_PRICE_LIST_REQUEST,
    });
    const URL = `/api/v1/pricelist/equipment`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: types.CREATE_EQUIPMENT_PRICE_LIST,
      payload: res.data?.data,
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_EQUIPMENT_PRICE_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const getAllEquipmentPriceList = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ALL_EQUIPMENT_PRICE_LIST_REQUEST,
    });
    const URL = `/api/v1/pricelist/equipment`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: types.GET_ALL_EQUIPMENT_PRICE_LIST,
      payload: res.data?.data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_ALL_EQUIPMENT_PRICE_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const updateEquipmentPriceList = (body) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_EQUIPMENT_PRICE_LIST_REQUEST,
    });
    const URL = `/api/v1/pricelist/equipment`;
    const { data } = await axios.put(`${URL}/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: types.UPDATE_EQUIPMENT_PRICE_LIST,
      payload: data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: types.UPDATE_EQUIPMENT_PRICE_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const deleteEquipmentPriceList = (id) => async (dispatch) => {
  try {
    dispatch({
      type: types.DELETE_EQUIPMENT_PRICE_LIST_REQUEST,
    });
    const URL = `/api/v1/pricelist/equipment`;
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: types.DELETE_EQUIPMENT_PRICE_LIST,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: types.DELETE_EQUIPMENT_PRICE_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const getEquipmentPriceListById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_EQUIPMENT_PRICE_LIST_REQUEST,
    });
    const URL = `/api/v1/pricelist/equipment`;
    const { data } = await axios.get(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: types.GET_EQUIPMENT_PRICE_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_EQUIPMENT_PRICE_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};
