import * as types from "../types/quotationType";

import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/quotation`;

export const createQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_QUOTATION_REQUEST,
        });
        const { data } = await axios.post(URL, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_QUOTATION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_QUOTATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_QUOTATION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getQuotations = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_QUOTATIONS_REQUEST,
        });
        const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_QUOTATIONS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_QUOTATIONS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getQuotation = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_QUOTATION_REQUEST,
        });
        const { data } = await axios.get(`${URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_QUOTATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCustomerQuotation = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_CUSTOMER_QUOTATION_REQUEST,
        });
        const res = await axios.get(`${URL}/customer/${id}`);
        dispatch({
            type: types.GET_CUSTOMER_QUOTATION,
            payload: res.data,
        });
        return await res.data;
    } catch (error) {
        if (error.response) {
            dispatch({
                type: types.GET_CUSTOMER_QUOTATION_ERROR,
                error: error.response.data,
            });
        } else if (error.request) {
            dispatch({
                type: types.GET_CUSTOMER_QUOTATION_ERROR,
                error: error.request.data,
            });
        } else {
            dispatch({
                type: types.GET_CUSTOMER_QUOTATION_ERROR,
                error: error.message,
            });
        }
    }
};

export const markCustomerQuotation = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.MARK_CUSTOMER_QUOTATION_REQUEST,
        });
        const res = await axios.post(`${URL}/read/${id}`);
        dispatch({
            type: types.MARK_CUSTOMER_QUOTATION,
            payload: res.data.data,
        });
        return await res.data.data;
    } catch (error) {
        if (error.response) {
            dispatch({
                type: types.MARK_CUSTOMER_QUOTATION_ERROR,
                error: error.response.data,
            });
        } else if (error.request) {
            dispatch({
                type: types.MARK_CUSTOMER_QUOTATION_ERROR,
                error: error.request.data,
            });
        } else {
            dispatch({
                type: types.MARK_CUSTOMER_QUOTATION_ERROR,
                error: error.message,
            });
        }
    }
};

export const updateQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_QUOTATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_QUOTATION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_QUOTATION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteQuotation = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_QUOTATION_REQUEST,
        });
        await axios.delete(`${URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_QUOTATION,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_QUOTATION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_QUOTATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_QUOTATION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_QUOTATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_QUOTATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.REJECT_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

// BD Head reviews the Quotation
export const reviewQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_QUOTATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_QUOTATION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

// BD Head declines the Quotation
export const declineQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_QUOTATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_QUOTATION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

// Commercial sends quotation to customer 
export const sendQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SEND_QUOTATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/send/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SEND_QUOTATION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.SEND_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

// Closing the QUOTATION
export const closeQuotation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CLOSE_QUOTATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/close/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CLOSE_QUOTATION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CLOSE_QUOTATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const uploadQuotationDoc = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPLOAD_QUOTATION_DOCUMENT_REQUEST,
        });
        const { data } = await axios.post(`${URL}/upload/${body.id}`, body.file, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPLOAD_QUOTATION_DOCUMENT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.UPLOAD_QUOTATION_DOCUMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
