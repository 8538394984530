import React, { useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Backdrop,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Box,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TodayIcon from "@material-ui/icons/Today";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAttendance } from "../../../actions/peoplesActions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    // height: "100vh",
    width: "100%",
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    // padding: theme.spacing(1),
    background: "none",
    textAlign: "center",
    width: 120,
  },
  time: {
    fontSize: 13,
  },
  card: {
    marginTop: theme.spacing(6),
  },
  date: {
    fontFamily: "Rubik",
    display: "flex",
    marginBottom: 10,

    alignItems: "center",
  },
  box: {
    textAlign: "center",

    fontWeight: 500,
  },
  icon: {
    marginRight: 5,
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const INIT_STATE = {
  userName: "",
  division: "",
  jobTitle: "",
  date: "",
  week: "",
  timeIn: "",
  timeOut: "",
  location: "",
};

const ViewAttendance = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [state, setState] = useState(INIT_STATE);

  const { attendance, loading } = useSelector((state) => state.peoples);

  React.useEffect(() => {
    dispatch(getAttendance(id));
  }, [dispatch, id]);

  console.log(attendance);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      userName:
        attendance && attendance.employee && attendance.employee.userName,
      division: attendance && attendance.division && attendance.division.name,
      jobTitle: attendance && attendance.jobTitle?.name,
      date: attendance && attendance.date,
      week: attendance && attendance.week,
      location: attendance && attendance.location,
      timeIn: attendance && attendance.timeIn,
      timeOut: attendance && attendance.timeOut,
    }));
  }, [attendance]);

  return (
    <div>
      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ChevronLeftIcon />}
            onClick={() => history.goBack()}
            className={classes.backBtn}
          >
            Back
          </Button>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <Grid item>
          <Typography variant="overline" gutterBottom className={classes.title}>
            Attendance Details
          </Typography>
        </Grid>
        <Grid item>
          <Box className={classes.box}>
            <Typography
              className={classes.date}
              variant="overline"
              gutterBottom
            >
              <TodayIcon className={classes.icon} />
              <time>
                {" "}
                {state.date ? state.date : "Loading..."} / Week{" "}
                {state.week || "Unavailable"}
              </time>
            </Typography>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={3}
            style={{ marginRight: 10 }}
          >
            <div>
              <Paper variant="outlined" square className={classes.paper}>
                <small style={{ display: "block" }}>Time In</small>
                <small className={classes.time}>
                  {state.timeIn ? state.timeIn : "Loading..."}
                </small>
              </Paper>
            </div>

            <ArrowForwardIcon color="disabled" />

            <Paper variant="outlined" square className={classes.paper}>
              <small style={{ display: "block" }}>Time Out</small>
              <small className={classes.time}>
                {state.timeOut ? state.timeOut : "-- • --"}
              </small>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Card className={classes.card}>
        <CardHeader
          title={<Typography variant="overline">Details</Typography>}
        />
        <Divider />
        <CardContent>
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid xs={12} md={6} item>
              <TextField
                variant="outlined"
                fullWidth
                value={state.userName || ""}
                disabled
                label="Fullname"
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                variant="outlined"
                fullWidth
                value={state.division || ""}
                disabled
                label="Division/Subdivision"
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                variant="outlined"
                fullWidth
                value={state.location || ""}
                disabled
                label="Office Location"
              />
            </Grid>
            <Grid xs={12} md={6} item>
              {/* <TextField
                variant="outlined"
                fullWidth
                value={state.jobTitle || ""}
                disabled
                label="Job Title"
              /> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ViewAttendance;
