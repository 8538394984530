import React from "react";
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Backdrop,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect, useDispatch } from "react-redux";
import { getAllBudgetHeads } from "../../../actions/budgetHeadsActions";
import { getBudgetItemBySubheadAndDivision } from "../../../actions/budgetItemActions";
import { getBudgetSubheadByHead } from "../../../actions/budgetSubheadAction";

import Feedback from "../../atoms/Feedback";
import { getCostCodesByType } from "../../../actions/costCodeAction";
import {
  getAllMonthlyBudgetLineByBudgetId,
  createMonthlyBudgetLine,
  getMonthlyBudgetById,
  getAllMonthlyBudgetItems,
  getMonthlyBudgetLineById,
  updateMonthlyBudgetLine,
} from "../../../actions/monthlyBudgetAction";

import { currencyConvert } from "../../../utils/currencyconvert";

import { useHistory, useParams } from "react-router-dom";
import { getCurrencies } from "../../../actions/currencyActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  container: {
    // marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 10,
    marginBottom: 20,
  },
  btn2: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 300,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  floatingBtn: {
    transform: "translateY(0px)",
    animation: "$floating 1.2s infinite alternate",
  },

  "@keyframes floating": {
    "0%": {
      transform: "translateY(0px)",
    },

    "100%": {
      transform: "translateY(-10px)",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperText: {
    fontFamily: "Rubik",
    fontSize: 13,
    fontWeight: 500,
    color: "#fff",

    height: "fit-content",
    padding: theme.spacing(1) + 2,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#0176ff",

    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      right: -5,
      top: "40%",
      width: 10,
      height: 10,

      backgroundColor: "#0176ff",

      transform: "rotate(45deg)",
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 30,
  },
}));

const INIT_STATE = {
  budgetHead: "",
  budgetSubhead: "",
  year: "",
  month: "",
  budgetItem: "",
  description: "",

  amount: 0,

  rate: 1,
};

const EditMonthlyBudgetLineForm = ({
  getAllMonthlyBudgetLineByBudgetId,
  createMonthlyBudgetLine,
  getAllBudgetHeads,
  getAllMonthlyBudgetItems,
  getBudgetSubheadByHead,
  getBudgetItemBySubheadAndDivision,
  error,
  heads,
  subheads,
  items,

  profile,
  updateMonthlyBudgetLine,
  getMonthlyBudgetById,
  getMonthlyBudgetLineById,
  getCostCodesByType,
  costcodes,
  monthlyBudgetLine,
  monthlyBudget,
  getCurrencies,
  currencies,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = React.useState(INIT_STATE);

  const [loading, setLoading] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [convertedAmount, setConvertedAmount] = React.useState(0);
  const [loadPage, setLoadPage] = React.useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const getMonthlyBudgetLineByIdCallback = React.useCallback(() => {
    setLoadPage((prev) => !prev);
    getMonthlyBudgetLineById(id).then(() => {
      setLoadPage(false);
    });
  }, [id, getMonthlyBudgetLineById]);

  React.useEffect(() => {
    getMonthlyBudgetLineByIdCallback();
  }, [getMonthlyBudgetLineByIdCallback]);

  React.useEffect(() => {
    getCurrencies();
  }, [getCurrencies]);

  React.useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getAllBudgetHeads().then(() => {
      setOpenBackdrop(false);
    });
  }, [getAllBudgetHeads]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      budgetHead:
        monthlyBudgetLine &&
        monthlyBudgetLine.head &&
        monthlyBudgetLine.head._id,
      budgetSubhead:
        monthlyBudgetLine &&
        monthlyBudgetLine.subHead &&
        monthlyBudgetLine.subHead._id,
      year: monthlyBudgetLine && monthlyBudgetLine.year,
      month: monthlyBudgetLine && monthlyBudgetLine.month,
      budgetItem:
        monthlyBudgetLine &&
        monthlyBudgetLine.item &&
        monthlyBudgetLine.item._id,
      description: monthlyBudgetLine && monthlyBudgetLine.description,

      amount: monthlyBudgetLine && monthlyBudgetLine.amount,
    }));
  }, [monthlyBudgetLine]);

  const getBudgetSubheadCallback = React.useCallback(() => {
    setLoad((prev) => !prev);
    let head = state.budgetHead && state.budgetHead;
    if (head !== undefined || head !== "") {
      getBudgetSubheadByHead(head).then(() => {
        setLoad(false);
      });
    }
  }, [state.budgetHead, getBudgetSubheadByHead]);

  React.useEffect(() => {
    getBudgetSubheadCallback();
  }, [getBudgetSubheadCallback]);

  const itemCallback = React.useCallback(() => {
    const data = {
      subhead: state.budgetSubhead && state.budgetSubhead,
      division: profile && profile.division && profile.division._id,
    };
    setOpenBackdrop((prev) => !prev);

    if (data.subhead !== undefined) {
      getBudgetItemBySubheadAndDivision(data).then(() => {
        setOpenBackdrop(false);
      });
    }
  }, [state.budgetSubhead, profile, getBudgetItemBySubheadAndDivision]);

  React.useEffect(() => {
    itemCallback();
  }, [itemCallback]);

  const codeTypeCallback = React.useCallback(() => {
    setLoad((prev) => !prev);
    if (state.costCategory !== "") {
      getCostCodesByType(state.costCategory).then(() => {
        setLoad(false);
      });
    }
  }, [getCostCodesByType, state]);

  React.useEffect(() => {
    codeTypeCallback();
  }, [codeTypeCallback]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  React.useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    const convert = currencyConvert(state.amount, state.rate) || 0;
    setConvertedAmount(convert);
    setOpenBackdrop(false);
  }, [state.amount, state.rate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading((prev) => !prev);
    const {
      description,
      budgetHead,
      budgetSubhead,
      budgetItem,
      amount,
      rate,
    } = state;

    let convertedAmount = currencyConvert(amount, rate);
    let data = {};

    data.subHead = budgetSubhead;
    data.description = description;
    data.head = budgetHead;
    data.item = budgetItem;

    data.amount = +convertedAmount;

    data.id = id;

    updateMonthlyBudgetLine(data)
      .then((doc) => {
        if (doc) {
          setFeed({
            loading: false,
            open: !feed.open,
            message: `BudgetLine Created Successfully`,
            success: true,
          });

          dispatch(getAllMonthlyBudgetLineByBudgetId(id));
          setLoading(false);
          setState({
            rate: 1,
          });
        } else {
          setState({
            rate: 1,
          });
          // setState(INIT_STATE);

          setFeed({
            loading: false,
            open: !feed.open,

            success: false,
          });
          setLoading(false);
        }
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,

          success: false,
        });
        setLoading(false);
      })
      .finally(() => {
        setConvertedAmount(0);
        history.goBack();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error.error) || feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "BudgetLine Updated Successfully."}
        />
      )}

      <Typography variant="overline" className={classes.title}>
        Edit Budget Line / Form
      </Typography>
      <Button
        onClick={() => history.goBack()}
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        className={classes.backBtn}
      >
        Back
      </Button>

      <form onSubmit={handleSubmit}>
        <Grid container justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Month"
              id="month"
              name="month"
              // onChange={handleChange}
              value={state.month}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Year"
              id="year"
              name="year"
              // onChange={handleChange}
              value={state.year}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="budget-head">Expense Head</InputLabel>
              <Select
                labelId="budget-head"
                id="budgetHead"
                name="budgetHead"
                inputProps={{
                  id: "budgetHead",
                  name: "budgetHead",
                }}
                value={state.budgetHead || ""}
                onChange={handleChange}
              >
                <MenuItem disabled value="">
                  --- Select Expense Head ---
                </MenuItem>
                {heads &&
                  heads.map((head) => (
                    <MenuItem key={head._id} value={head._id}>
                      {head.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="budget-subhead">Expense SubHead</InputLabel>
              <Select
                labelId="budget-subhead"
                id="budgetSubhead"
                name="budgetSubHead"
                inputProps={{
                  id: "budgetSubhead",
                  name: "budgetSubhead",
                }}
                value={state.budgetSubhead || ""}
                onChange={handleChange}
              >
                <MenuItem disabled value="">
                  --- Select Expense SubHead ---
                </MenuItem>
                {subheads &&
                  subheads.map((subhead) => (
                    <MenuItem key={subhead._id} value={subhead._id}>
                      {subhead.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="budgetItem">Budget Category</InputLabel>
              <Select
                labelId="budgetItem"
                id="budgetItem"
                inputProps={{
                  id: "budgetItem",
                  name: "budgetItem",
                }}
                value={state.budgetItem || ""}
                onChange={handleChange}
                name="budgetItem"
              >
                <MenuItem disabled value="">
                  --- Select Budget Category ---
                </MenuItem>
                {items &&
                  items.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Description"
              id="description"
              name="description"
              onChange={handleChange}
              value={state.description || ""}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              // className={classes.textField}
            >
              <InputLabel id="head-label-id">Currency</InputLabel>
              <Select
                labelId="head-label-id"
                id="rate"
                label="Rate"
                inputProps={{
                  id: "rate",
                  name: "rate",
                }}
                value={state.rate || 1}
                onChange={handleChange}
                name="rate"
              >
                <MenuItem value="" disabled>
                  --- Select Currency ---
                </MenuItem>
                {currencies &&
                  currencies.map((currency) => (
                    <MenuItem key={currency._id} value={currency.rate}>
                      {currency.code}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Amount"
              id="amount"
              name="amount"
              onChange={handleChange}
              value={state.amount || ""}
              fullWidth
              variant="outlined"
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Converted Amount"
              id="convertedAmount"
              name="convertedAmount"
              value={convertedAmount}
              fullWidth
              variant="outlined"
              disabled
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">&#8358;</InputAdornment>
                    <Divider
                      className={classes.divider2}
                      orientation="vertical"
                    />
                  </>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>

        <Grid container justify="center">
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.saveBtn}
              size="large"
              disabled={loading || load || openBackdrop}
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading || load || openBackdrop ? "Loading..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Backdrop className={classes.backdrop} open={loadPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  heads: state.budgetHeads.heads.data,
  subheads: state.budgetSubheads.subheads.data,
  items: state.budgetItems.items.data,
  profile: state.user.profile,
  // error: state.annualBudget.annualBudgetLineError,
  error: state.monthlyBudget.budgetLineError,
  // annualBudget: state.annualBudget.annualBudget.data,
  annualBudgetLines: state.annualBudget.annualBudgetLines,
  monthlyBudgetLines:
    state.monthlyBudget.monthlyBudgetLines &&
    state.monthlyBudget.monthlyBudgetLines.data,
  monthlyBudgetLine:
    state.monthlyBudget.monthlyBudgetLine &&
    state.monthlyBudget.monthlyBudgetLine.data,
  monthlyBudget:
    state.monthlyBudget.monthlyBudget && state.monthlyBudget.monthlyBudget.data,
  currencies: state.currency.currencies,
  costcodes: state.costcode.costcodes && state.costcode.costcodes.data,
});

const mapDispatchToProps = {
  getAllBudgetHeads,
  getBudgetItemBySubheadAndDivision,
  getBudgetSubheadByHead,

  getCostCodesByType,

  getAllMonthlyBudgetLineByBudgetId,
  createMonthlyBudgetLine,
  getMonthlyBudgetById,
  getMonthlyBudgetLineById,
  getAllMonthlyBudgetItems,
  getCurrencies,
  updateMonthlyBudgetLine,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMonthlyBudgetLineForm);
