import * as types from "../types/salesOrder";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/salesorder`;

//////////////////////////////////////////////
////////////// Order Database ////////////////
//////////////////////////////////////////////
export const createOrderDatabase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.post(`${URL}/odb`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_ORDER_DATABASE,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_ORDER_DATABASE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getOrderDatabases = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_ORDER_DATABASES_REQUEST,
        });
        const { data } = await axios.get(`${URL}/odb`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_ORDER_DATABASES,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_ORDER_DATABASES_FAIL,
            payload: error
        });
    }
};

export const getOrderDatabase = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.get(`${URL}/odb/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: types.GET_ORDER_DATABASE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_ORDER_DATABASE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const getCustomerOrders = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_CUSTOMER_ORDERS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/odb/customer/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: types.GET_CUSTOMER_ORDERS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_CUSTOMER_ORDERS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateOrderDatabase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/odb/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_ORDER_DATABASE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_ORDER_DATABASE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteOrderDatabase = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_ORDER_DATABASE_REQUEST,
        });
        await axios.delete(`${URL}/odb/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_ORDER_DATABASE,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_ORDER_DATABASE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitOrderDatabase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/odb/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_ORDER_DATABASE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_ORDER_DATABASE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const approveOrderDatabase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/odb/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_ORDER_DATABASE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_ORDER_DATABASE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectOrderDatabase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/odb/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_ORDER_DATABASE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_ORDER_DATABASE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptOrderDatabase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/odb/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_ORDER_DATABASE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_ORDER_DATABASE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineOrderDatabase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/odb/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_ORDER_DATABASE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_ORDER_DATABASE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const reviewOrderDatabase = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_ORDER_DATABASE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/odb/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_ORDER_DATABASE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_ORDER_DATABASE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
