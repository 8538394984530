import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    Fab,
    DialogContent,
    Backdrop,
    darken,
    Radio,
    RadioGroup,
    FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Feedback from "../../../../atoms/Feedback";
import { getProfile } from "../../../../../actions/userActions";
import { createFIELD, getApprovedCRFs, getFIELDs } from "../../../../../actions/ppmActions/changeMgtActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        marginBottom: 30,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper1: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: darken("#fff", 0.05),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    table: {
        minWidth: 500,
        backgroundColor: "#fff",
        border:"1px solid #ccc",
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },
    btnWrapper: {
        textAlign: "center",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    month: "",
    year: "",
    changeRequest: null,
    changeRequestId: null,
    drivingForces: [],
    restrainingForces: [],
};

const INIT_DRIVING = {
    description: "",
    likelihood: "",
}

const INIT_RESTRAINING = {
    description: "",
    likelihood: "",
}

const CreateFieldForceModal = ({ openCreate, handleCloseCreate, createFIELD }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [requestDate] = useState(moment(new Date()));
    const [month] = useState(moment().format("MMMM"));
    const [year] = useState(moment().format("YYYY"));
    const [state, setState] = useState(INIT_STATE);
    const [driving, setDriving] = useState(INIT_DRIVING);
    const [restraining, setRestraining] = useState(INIT_RESTRAINING);

    const { profile } = useSelector((state) => state.user);
    const { crfs_approved } = useSelector((state) => state.changeMgt);
    const { loading, error} = useSelector((state) => state.fieldForce);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            month: month,
            year: year,
            requestDate: moment(requestDate).toDate(),
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
        }));
    }, [profile, year, month, requestDate]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getApprovedCRFs());
        dispatch(getFIELDs());
    }, [dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    
    const handleDrivingChange = (e) => {
        e.persist();
        setDriving((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddDrivingArray = (item) => {
        if (item.description !== "" && item.likelihood !== "") {
            setState((prev) => ({
                ...prev,
                drivingForces: prev.drivingForces.concat([item]),
            }));
            setDriving(INIT_DRIVING);
        }
    };

    const handleRemoveDrivingArray = (i) => {
        setState((prev) => ({
            ...prev,
            drivingForces: prev.drivingForces.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleRestrainingChange = (e) => {
        e.persist();
        setRestraining((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddRestrainingArray = (item) => {
        if (item.description !== "" && item.likelihood !== "") {
            setState((prev) => ({
                ...prev,
                restrainingForces: prev.restrainingForces.concat([item]),
            }));
            setRestraining(INIT_RESTRAINING);
        }
    };

    const handleRemoveRestrainingArray = (i) => {
        setState((prev) => ({
            ...prev,
            restrainingForces: prev.restrainingForces.filter(
                (_, index) => index !== i
            ),
        }));
    };

    useEffect(() => {
        if (state.drivingForces && state.drivingForces.length > 0) {
            let totalDrivingForces = state.drivingForces.reduce((acc, cur) => { return acc + +cur.likelihood; }, 0);
            setState((prev) => ({
                ...prev,
                totalDrivingForces: parseInt(totalDrivingForces) || 0
            }));
        }
    }, [state.drivingForces]);

    useEffect(() => {
        if (state.restrainingForces && state.restrainingForces.length > 0) {
            let totalRestrainingForces = state.restrainingForces.reduce((acc, cur) => { return acc + +cur.likelihood; }, 0);
            setState((prev) => ({
                ...prev,
                totalRestrainingForces: parseInt(totalRestrainingForces) || 0
            }));
        }
    }, [state.restrainingForces]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            changeRequestId: state.changeRequest?._id,
            drivingForces: state.drivingForces,
            restrainingForces: state.restrainingForces,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(createFIELD(data));
        });
        
        if (res) {
            dispatch(getFIELDs());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Field Force Analysis Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.changeRequest || 
        !state.changeRequest?._id ||  
        (state.drivingForces.length === 0) || 
        (state.restrainingForces.length === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title3}>
                                    Create Field Force Analysis
                                </Typography>

                                <Typography variant="overline" className={classes.title}>
                                    Change Request Details
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper1}>
                                    <Grid container spacing={2} className={classes.grid}>
                                        <Grid item xs={12} md={3}>
                                            <Autocomplete
                                                id="changeRequest"
                                                options={crfs_approved}
                                                label="Search for CRF"
                                                value={state.changeRequest}
                                                debug
                                                onChange={(event, newValue) => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        changeRequest: newValue,
                                                    }));
                                                }}
                                                getOptionLabel={(option) => option.requestDescription || ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>{option.requestDescription} - {option.requestNo}</React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            placeholder="Search for Approved CRF..."
                                                            fullWidth
                                                            required
                                                            variant="outlined"
                                                            value={(state.changeRequest && state.changeRequest) || ""}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "disabled",
                                                                name: "changeRequest",
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                            label="Change Initiator"
                                            name="employeeName"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.employeeName && state.employeeName) || ""}
                                            disabled
                                            required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                label="EID"
                                                name="employeeId"
                                                variant="outlined"
                                                fullWidth
                                                onChange={handleChange}
                                                value={state.employeeId || ""}
                                                disabled
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                label="Division/Subdivision"
                                                name="division"
                                                variant="outlined"
                                                fullWidth
                                                value={(state.division && state.divisionName) || ""}
                                                disabled
                                                required
                                            />
                                        </Grid>
                                        {state.changeRequest !== null ? <>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Request No"
                                                name="changeRequestNo"
                                                value={state.changeRequest?.requestNo || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Initiator"
                                                name="changeInitiator"
                                                value={state.changeRequest?.createdBy?.userName || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Request Coordinator"
                                                name="changeCoordinator"
                                                value={state.changeRequest?.reviewedBy?.userName || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Request Description"
                                                name="changeRequestDescription"
                                                value={state.changeRequest?.requestDescription || ""}
                                                disabled
                                            />
                                        </Grid>
                                        </> : null }
                                    </Grid>
                                </Paper>

                                <Typography variant="overline" className={classes.title}>
                                    Driving Forces  
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper1}>
                                    {driving ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description"
                                                    name="description"
                                                    value={(driving.description && driving.description) || ""}
                                                    onChange={handleDrivingChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    component="fieldset"
                                                >
                                                    <RadioGroup row onChange={handleDrivingChange}>
                                                        <Grid item xs={6} md={3}>
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio checked={(driving.likelihood === "5") ? true : false} />}
                                                                label="Very Likely"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio checked={(driving.likelihood === "4") ? true : false} />}
                                                                label="Likely"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio checked={(driving.likelihood === "3") ? true : false} />}
                                                                label="Undecided"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio checked={(driving.likelihood === "2") ? true : false} />}
                                                                label="Unlikely"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={3}>
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio checked={(driving.likelihood === "1") ? true : false} />}
                                                                label="Very Unlikely"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    style={{ backgroundColor: "#1877F2" }}
                                                    onClick={() => handleAddDrivingArray(driving)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                <Typography variant="overline" className={classes.title}>
                                    Restraining Forces  
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper1}>
                                    {restraining ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description"
                                                    name="description"
                                                    value={(restraining.description && restraining.description) || ""}
                                                    onChange={handleRestrainingChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    component="fieldset"
                                                >
                                                    <RadioGroup row onChange={handleRestrainingChange}>
                                                        <Grid item xs={6} md={3}>
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio checked={(restraining.likelihood === "5") ? true : false} />}
                                                                label="Very Likely"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio checked={(restraining.likelihood === "4") ? true : false} />}
                                                                label="Likely"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio checked={(restraining.likelihood === "3") ? true : false} />}
                                                                label="Undecided"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={2}>
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio checked={(restraining.likelihood === "2") ? true : false} />}
                                                                label="Unlikely"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={3}>
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio checked={(restraining.likelihood === "1") ? true : false} />}
                                                                label="Very Unlikely"
                                                                name="likelihood"
                                                            />
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    style={{ backgroundColor: "#1877F2" }}
                                                    onClick={() => handleAddRestrainingArray(restraining)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                {/* Driving Force Added */}
                                {(state.drivingForces && state.drivingForces.length > 0) ? 
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Driving Forces
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper1}>
                                        <TableContainer>
                                            <Table bordered className={classes.table} aria-label="custom pagination table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>SN</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Very Likely</TableCell>
                                                        <TableCell>Likely</TableCell>
                                                        <TableCell>Undecided</TableCell>
                                                        <TableCell>Unlikely</TableCell>
                                                        <TableCell>Very Unlikely</TableCell>
                                                        <TableCell>Remove</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {state.drivingForces && state.drivingForces.map((sitem, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell component="th" scope="row">{i + 1}</TableCell>
                                                        <TableCell component="th" scope="row">{sitem.description || ""}</TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio checked={(sitem.likelihood === "5") ? true : false} />}
                                                                label="Very Likely"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio checked={(sitem.likelihood === "4") ? true : false} />}
                                                                label="Likely"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio checked={(sitem.likelihood === "3") ? true : false} />}
                                                                label="Undecided"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio checked={(sitem.likelihood === "2") ? true : false} />}
                                                                label="Unlikely"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio checked={(sitem.likelihood === "1") ? true : false} />}
                                                                label="Very Likely"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Fab
                                                                color="secondary"
                                                                onClick={() => handleRemoveDrivingArray(i)}
                                                            >
                                                                <CloseIcon />
                                                            </Fab>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" colSpan={2}>
                                                            Total:
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" colSpan={6}>
                                                            {(state.totalDrivingForces && state.totalDrivingForces) || 0}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </> : null }

                                {/* Restraining Force Added */}
                                {(state.restrainingForces && state.restrainingForces.length > 0) ? 
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Restraining Forces
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper1}>
                                        <TableContainer>
                                            <Table bordered className={classes.table} aria-label="custom pagination table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>SN</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Very Likely</TableCell>
                                                        <TableCell>Likely</TableCell>
                                                        <TableCell>Undecided</TableCell>
                                                        <TableCell>Unlikely</TableCell>
                                                        <TableCell>Very Unlikely</TableCell>
                                                        <TableCell>Remove</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {state.restrainingForces && state.restrainingForces.map((sitem, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell component="th" scope="row">{i + 1}</TableCell>
                                                        <TableCell component="th" scope="row">{sitem.description || ""}</TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio checked={(sitem.likelihood === "5") ? true : false} />}
                                                                label="Very Likely"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio checked={(sitem.likelihood === "4") ? true : false} />}
                                                                label="Likely"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio checked={(sitem.likelihood === "3") ? true : false} />}
                                                                label="Undecided"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio checked={(sitem.likelihood === "2") ? true : false} />}
                                                                label="Unlikely"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio checked={(sitem.likelihood === "1") ? true : false} />}
                                                                label="Very Likely"
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Fab
                                                                color="secondary"
                                                                onClick={() => handleRemoveRestrainingArray(i)}
                                                            >
                                                                <CloseIcon />
                                                            </Fab>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" colSpan={2}>
                                                            Total:
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" colSpan={6}>
                                                            {(state.totalRestrainingForces && 
                                                                state.totalRestrainingForces) || 0}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </> : null }

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createFIELD, getFIELDs })(CreateFieldForceModal);
