import * as types from "../../../types/priceListType";

const initState = {
  personnels: [],
  personnel: null,
  loading: false,
  error: null,
  success: false,
};

const personnelReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CREATE_PERSONNEL_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_PERSONNEL_PRICE_LIST:
      return {
        ...state,
        loading: false,
        success: true,
        personnel: action.payload,
      };
    case types.CREATE_PERSONNEL_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.GET_ALL_PERSONNEL_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_PERSONNEL_PRICE_LIST:
      return {
        ...state,
        loading: false,
        personnels: action.payload,
      };
    case types.GET_ALL_PERSONNEL_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.GET_PERSONNEL_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_PERSONNEL_PRICE_LIST:
      return {
        ...state,
        loading: false,
        personnel: action.payload.data,
      };
    case types.GET_PERSONNEL_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.RESET_PERSONNEL_PRICE_LIST:
      return {
        personnel: null,
        loading: false,
        error: null,
        success: false,
      };

    case types.UPDATE_PERSONNEL_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_PERSONNEL_PRICE_LIST:
      return {
        ...state,
        loading: false,
        personnels: state.personnels.map((item) =>
          item._id === action.payload.data._id
            ? (item = action.payload.data)
            : item
        ),
      };
    case types.UPDATE_PERSONNEL_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.DELETE_PERSONNEL_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_PERSONNEL_PRICE_LIST:
      return {
        ...state,
        loading: false,
        personnels: state.personnels.filter((item) => item._id !== action.payload),
      };
    case types.DELETE_PERSONNEL_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default personnelReducer;
