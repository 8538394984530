import React, { useState } from "react";
import {
  Typography,
  Button,
  makeStyles,
  Grid,
  TextField,
  Divider,
  InputAdornment,
  Tooltip,
  withStyles,
  Badge,
  Icon,
  darken,
  Paper,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import { getMonthlyBudgetById, getAllMonthlyBudgetLineByBudgetId } from "../../../actions/monthlyBudgetAction";

import MonthlyBudetLineTable from "./MonthlyBudetLineTable";
import CreateMonthlyBudgetLineModal from "./modals/CreateMonthlyBudgetLineModal";
import SubmitMonthlyBudgetLineModal from "./modals/SubmitMonthlyBudgetLineModal";
import SubmitMonthlyDeclinedModal from "./modals/SubmitMonthlyDeclinedModal";
import SubmitMonthlyRejectedModal from "./modals/SubmitMonthlyRejectedModal";
import SubmitMonthlyModifyModal from "./modals/SubmitMonthlyModifyModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 300,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  floatingBtn: {
    transform: "translateY(0px)",
    animation: "$floating 1.2s infinite alternate",
  },

  "@keyframes floating": {
    "0%": {
      transform: "translateY(0px)",
    },

    "100%": {
      transform: "translateY(-10px)",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperText: {
    fontFamily: "Rubik",
    fontSize: 13,
    fontWeight: 500,
    color: "#fff",

    height: "fit-content",
    padding: theme.spacing(1) + 2,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#0176ff",

    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      right: -5,
      top: "40%",
      width: 10,
      height: 10,

      backgroundColor: "#0176ff",

      transform: "rotate(45deg)",
    },
  },
  submitBtn: {
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 300,
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
  },
  status: {
    width: 140,
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  textGreen: {
    color: "#388e3c",
    fontWeight: 900,
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const RippleEffect = withStyles((theme) => ({
  root: {
    backgroundColor: "#44b700",
    // backgroundColor: theme.palette.secondary.light,
    color: "#44b700",
    // color: theme.palette.secondary.light,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginLeft: -5,

    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const MonthlyBudgetLine = ({
  getMonthlyBudgetById,
  getAllMonthlyBudgetLineByBudgetId,
  monthlyBudget,
  monthlyBudgetLines,
  profile,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [openCreate, setOpenCreate] = useState(false);
  const { id } = useParams();

  const [openSubmit, setOpenSubmit] = React.useState(false);
  const [openDecline, setOpenDecline] = React.useState(false);
  const [openRejected, setOpenRejected] = React.useState(false);
  const [openModify, setOpenModify] = React.useState(false);

  const getMonthlyBudgetByIdCallback = React.useCallback(() => {
    getMonthlyBudgetById(id);
  }, [id, getMonthlyBudgetById]);

  React.useEffect(() => {
    getMonthlyBudgetByIdCallback();
  }, [getMonthlyBudgetByIdCallback]);

  const getAllMonthlyBudgetLineByBudgetIdCallback = React.useCallback(() => {
    getAllMonthlyBudgetLineByBudgetId(id);
  }, [getAllMonthlyBudgetLineByBudgetId, id]);

  React.useEffect(() => {
    getAllMonthlyBudgetLineByBudgetIdCallback();
  }, [getAllMonthlyBudgetLineByBudgetIdCallback]);

  const handleCreateOpen = () => {
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  const handleOpenSubmit = () => {
    setOpenSubmit(true);
  };

  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  const handleOpenDecline = () => {
    setOpenDecline(true);
  };

  const handleCloseDecline = () => {
    setOpenDecline(false);
  };

  const handleOpenRejected = () => {
    setOpenRejected(true);
  };

  const handleCloseRejected = () => {
    setOpenRejected(false);
  };

  const handleOpenModify = () => {
    setOpenModify(true);
  };

  const handleCloseModify = () => {
    setOpenModify(false);
  };

  let isHead = profile
    ? profile &&
      profile.groups &&
      profile.groups.some(
        (grp) => grp.name === "head" && grp.module === "budget"
      )
    : false;

  let isManager = profile
    ? profile &&
      profile.groups &&
      profile.groups.some(
        (grp) => grp.name === "manager" && grp.module === "budget"
      )
    : false;

  return (
    <div>
      <CreateMonthlyBudgetLineModal
        {...{ openCreate, handleCreateClose, id }}
      />
      <SubmitMonthlyBudgetLineModal
        {...{ openSubmit, handleCloseSubmit, id }}
      />
      <SubmitMonthlyDeclinedModal
        {...{ openDecline, handleCloseDecline, id }}
      />

      <SubmitMonthlyRejectedModal
        {...{ openRejected, handleCloseRejected, id }}
      />

      <SubmitMonthlyModifyModal {...{ openModify, handleCloseModify, id }} />

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
        spacing={3}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title}>
            {monthlyBudget === undefined
              ? "Loading"
              : monthlyBudget && monthlyBudget.type === "monthly"
              ? "Monthly"
              : "Supplementary"}
            / New Budget Line
          </Typography>
          <Button
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            id="scroll-to-top"
            className={classes.backBtn}
          >
            Back
          </Button>
        </Grid>

        <Grid item>
          <TextField
            variant="outlined"
            label="Document Number"
            value={(monthlyBudget && monthlyBudget.documentNo) || ""}
            disabled
          />
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
        spacing={3}
      >
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            variant="outlined"
            value={(monthlyBudget && monthlyBudget.month) || ""}
            disabled
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">Month</InputAdornment>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                </>
              ),
            }}
            style={{
              width: 180,
              // marginBottom: 10,
            }}
          />
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              (monthlyBudget && monthlyBudget.status === "draft") ||
                (monthlyBudget && monthlyBudget.status === "toclarify") ||
                (monthlyBudget && monthlyBudget.status === "tomodify") ||
                (monthlyBudget && monthlyBudget.status === "modified")
                ? classes.statusYellow
                : monthlyBudget && monthlyBudget.status === "submitted"
                ? classes.statusOrange
                : (monthlyBudget && monthlyBudget.status === "approved") ||
                  (monthlyBudget && monthlyBudget.status === "reviewed") ||
                  (monthlyBudget && monthlyBudget.status === "confirmed")
                ? classes.statusGreen
                : monthlyBudget && monthlyBudget.status === "completed"
                ? classes.statusDarkGreen
                : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {monthlyBudget &&
              monthlyBudget.status &&
              monthlyBudget.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <>
        <Tooltip
          arrow
          title="Click button to add a new budget line"
          placement="right"
        >
          <Button
            onClick={handleCreateOpen}
            variant="contained"
            color="primary"
            size="large"
            endIcon={<Icon className="fas fa-folder-plus" />}
          >
            Add Budget Line
          </Button>
        </Tooltip>
        <RippleEffect variant="dot" overlap="circle" />
      </>

      <div style={{ marginBottom: 30 }} />

      <MonthlyBudetLineTable />

      {monthlyBudget && monthlyBudget.status === "draft" && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenSubmit}
              size="large"
              disabled={monthlyBudgetLines && monthlyBudgetLines.length < 1}
              className={classes.submitBtn}
            >
              Submit Budget
            </Button>
          </Grid>
        </Grid>
      )}

      {monthlyBudget && monthlyBudget.status === "declined" && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDecline}
              size="large"
              // disabled={monthlyBudgetLines && monthlyBudgetLines.length < 1}
              className={classes.submitBtn}
            >
              Submit Budget
            </Button>
          </Grid>
        </Grid>
      )}

      {monthlyBudget && monthlyBudget.status === "rejected" && isManager && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenRejected}
              size="large"
              // disabled={monthlyBudgetLines && monthlyBudgetLines.length < 1}
              className={classes.submitBtn}
            >
              Submit Budget
            </Button>
          </Grid>
        </Grid>
      )}

      {monthlyBudget && monthlyBudget.status === "tomodify" && isHead && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModify}
              size="large"
              // disabled={monthlyBudgetLines && monthlyBudgetLines.length < 1}
              className={classes.submitBtn}
            >
              Submit Budget
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  monthlyBudget:
    state.monthlyBudget.monthlyBudget && state.monthlyBudget.monthlyBudget.data,
  monthlyBudgetLines: state.monthlyBudget.monthlyBudgetLines,
  profile: state.user.profile,
});

const mapDispatchToProps = {
  getMonthlyBudgetById,
  getAllMonthlyBudgetLineByBudgetId,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyBudgetLine);
