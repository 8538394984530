import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AdminQuotationGuard = ({ children, profile, ...rest }) => {
    const token = localStorage.getItem("token:key");

    let isAdminAndQuotation = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) =>
                grp.name === "admin" ||
                (grp.name === "reviewer" && grp.module === "sales") ||
                (grp.name === "ceo" && grp.module === "sales") ||
                (grp.name === "user" && grp.module === "sales")
        )
        : false;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token && isAdminAndQuotation ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: {
                                from: location,
                            },
                        }}
                    />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    profile: state.user.profile,
});

export default connect(mapStateToProps, {})(AdminQuotationGuard);
