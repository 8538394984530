import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Backdrop,
  IconButton,
  Chip,
  Icon,
  useTheme,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";
import Fade from "@material-ui/core/Fade";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import SearchComponent from "../../SearchComponent";

import { connect } from "react-redux";
import clsx from "clsx";

import Feedback from "../../atoms/Feedback";

import AnnualModal from "./modals/AnnualModal";
import {
  getAnnualBudgets,
  submitAnnualBudget,
} from "../../../actions/annualAction";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../../utils";
import { TablePaginationActions } from "../../TablePaginationActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const Annual = ({
  getAnnualBudgets,
  annualBudgets,
  profile,
  submitAnnualBudget,
  error,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const [search, setSearch] = useState("");

  useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getAnnualBudgets().then(() => {
      setOpenBackdrop(false);
    });
  }, [getAnnualBudgets]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const handleChangeDate = (date) => {
    // setSelectedDate(moment(date).format("YYYY").toString());
    setSelectedDate(date);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  let filteredRows = () =>
    annualBudgets === undefined
      ? []
      : annualBudgets.filter((row) => {
          if (search !== "") {
            return (
              row.year
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.documentNo
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.employee.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (row.total &&
                row.total
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              row.createdAt.toLowerCase().indexOf(search.toLowerCase()) !==
                -1 ||
              row.status.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (row.division &&
                row.division.name
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1)
            );
          } else {
            return row;
          }
        });

  let filteredDate = filteredRows();

  filteredRows = () =>
    filteredDate.filter((item) => {
      if (selectedDate !== null) {
        return (
          item.year
            .toString()
            .toLowerCase()
            .indexOf(moment(selectedDate).format("YYYY").toString()) !== -1
        );
      } else {
        return item;
      }
    });
  let filtered = filteredRows();

  filteredRows = () =>
    filtered.filter((row) => {
      if (filter !== "") {
        return row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      } else {
        return row;
      }
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectFilter = (e, val) => {
    setFilter(val.substr(0, 20));
    handleCloseFilter();
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  let isHead = profile
    ? profile &&
      profile.groups &&
      profile.groups.some(
        (grp) => grp.name === "head" && grp.module === "budget"
      )
    : false;

  let isManager = profile
    ? profile &&
      profile.groups &&
      profile.groups.some(
        (grp) => grp.name === "manager" && grp.module === "budget"
      )
    : false;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div>
        {feed.success ? (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="success"
            message={feed.message}
          />
        ) : (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="error"
            message={feed.message}
          />
        )}
        {/* Modals Starts here */}
        <AnnualModal open={open} handleClose={handleClose} />

        {/* Modals Ends here */}
        <Typography variant="overline" gutterBottom className={classes.title}>
          Annual Budgets
        </Typography>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={3}
          className={classes.marginBottom}
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              className={classes.createBtn}
              onClick={handleOpenModal}
            >
              Create
            </Button>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={3}
          className={classes.marginBottom}
        >
          <Grid item>
            <SearchComponent
              updateSearch={updateSearch}
              placeholder="Search..."
              search={search}
              ResetSearch={ResetSearch}
            />
          </Grid>
          <Grid item>
            <DatePicker
              autoOk
              views={["year"]}
              label="Filter By Year"
              // disablePast
              // maxDate={new Date(moment().add(5, "years")._d)}
              inputVariant="outlined"
              value={selectedDate}
              onChange={handleChangeDate}
              InputProps={{
                endAdornment: (
                  <>
                    <Divider
                      className={classes.divider2}
                      orientation="vertical"
                    />
                    <InputAdornment position="end">
                      <IconButton
                        disabled={selectedDate === null}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedDate(null);
                          setFilter("");
                        }}
                      >
                        {selectedDate ? <CloseIcon /> : <FilterListIcon />}
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Paper>
          <Grid container justify="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <IconButton
                style={{ marginRight: 10, marginTop: 10 }}
                onClick={handleOpenFilter}
              >
                <FilterListIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseFilter}
                TransitionComponent={Fade}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                  All
                </MenuItem>
                <MenuItem onClick={(e) => handleSelectFilter(e, "Completed")}>
                  Completed
                </MenuItem>
                <MenuItem onClick={(e) => handleSelectFilter(e, "Approved")}>
                  Approved
                </MenuItem>
                <MenuItem onClick={(e) => handleSelectFilter(e, "Submitted")}>
                  Submitted
                </MenuItem>
                <MenuItem onClick={(e) => handleSelectFilter(e, "Draft")}>
                  Draft
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>

          <TableContainer>
            <Table
              className={classes.table}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Document No.</TableCell>
                  <TableCell>Employee</TableCell>
                  <TableCell>Div./Subdiv.</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredRows().slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredRows()
                ).map((row, i) => {
                  return (
                    <TableRow hover key={row._id}>
                      <TableCell component="th" scope="row">
                        <Chip label={row.documentNo} variant="outlined" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.employee}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.division && row.division.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          style={{ padding: 10 }}
                          icon={
                            <Icon
                              className="fas fa-calendar-alt"
                              fontSize="small"
                              color="disabled"
                            />
                          }
                          label={row.createdAt}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.year}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color:
                            row.total <= 200000
                              ? theme.palette.secondary.light
                              : theme.palette.success.main,
                        }}
                      >
                        {row.total
                          ? formatCurrency(row.total, "en-NG", "NGN")
                          : formatCurrency(0, "en-NG", "NGN")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          label={row.status.toUpperCase()}
                          className={clsx(
                            row.status === "draft" ||
                              row.status === "toclarify" ||
                              row.status === "tomodify" ||
                              row.status === "clarified" ||
                              row.status === "modified"
                              ? classes.statusYellow
                              : row.status === "submitted"
                              ? classes.statusOrange
                              : row.status === "approved" ||
                                row.status === "reviewed" ||
                                row.status === "accepted"
                              ? classes.statusGreen
                              : row.status === "completed"
                              ? classes.statusDarkGreen
                              : classes.statusRed,
                            classes.chip
                          )}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="center">
                        {row.status === "draft" ? (
                          <Tooltip title="Add BudgetLine" arrow>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                navigateById(
                                  "/budget/annual/budgetline",
                                  row._id
                                )
                              }
                              disabled={profile._id !== row.createdBy._id}
                            >
                              <Icon
                                className="fas fa-pencil-alt"
                                color="primary"
                              />
                            </IconButton>
                          </Tooltip>
                        ) : row.status === "declined" ? (
                          <Tooltip title="Edit BudgetLine" arrow>
                            <IconButton
                              color="primary"
                              // onClick={() =>
                              //   navigateById(
                              //     "/budget/annual/budgetline/edit",
                              //     row._id
                              //   )
                              // }
                              onClick={() =>
                                navigateById(
                                  "/budget/annual/budgetline",
                                  row._id
                                )
                              }
                              disabled={profile._id !== row.createdBy._id}
                            >
                              <Icon
                                className="fas fa-pencil-alt"
                                color="primary"
                              />
                            </IconButton>
                          </Tooltip>
                        ) : row.status === "rejected" && isManager ? (
                          <Tooltip title="Edit BudgetLine" arrow>
                            <IconButton
                              color="primary"
                              // onClick={() =>
                              //   navigateById(
                              //     "/budget/annual/budgetline/edit",
                              //     row._id
                              //   )
                              // }
                              onClick={() =>
                                navigateById(
                                  "/budget/annual/budgetline",
                                  row._id
                                )
                              }
                              disabled={!isManager}
                            >
                              <Icon
                                className="fas fa-pencil-alt"
                                color="primary"
                              />
                            </IconButton>
                          </Tooltip>
                        ) : row.status === "tomodify" && isHead ? (
                          <Tooltip title="Edit BudgetLine" arrow>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                navigateById(
                                  "/budget/annual/budgetline",
                                  row._id
                                )
                              }
                            >
                              <Icon
                                className="fas fa-pencil-alt"
                                color="primary"
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip arrow title="View BudgetLine">
                            <IconButton
                              onClick={() =>
                                navigateById("/budget/annual/view", row._id)
                              }
                            >
                              <Icon className="fas fa-eye" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {filteredRows().length === 0 && (
                  <TableRow hover>
                    <TableCell
                      colSpan={10}
                      align="center"
                      style={{ color: "#616161" }}
                      component="th"
                      scope="row"
                    >
                      No Records To Display
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            colSpan={3}
            count={filteredRows().length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  items: state.budgetItems.items.data,
  error: state.annualBudget.error,
  annualBudgets: state.annualBudget.annualBudgets.data,
  profile: state.user.profile,
});

export default connect(mapStateToProps, {
  getAnnualBudgets,
  submitAnnualBudget,
})(Annual);
