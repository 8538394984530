import {
  GET_COST_CODE,
  GET_COST_CODES_BY_TYPE,
  CREATE_COST_CODE,
  GET_ALL_COST_CODES,
  DELETE_COST_CODE,
  UPDATE_COST_CODE,
  COST_CODE_ERROR,
} from "../types";

import axios from "axios";

const token = localStorage.getItem("token:key");

export const getCostCodes = () => async (dispatch) => {
  try {
    const URL = `/api/v1/cost`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_COST_CODES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.message,
      });
    }
  }
};

export const createCostCode = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/cost`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_COST_CODE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateCostCode = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/cost/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_COST_CODE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getCostCode = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/cost/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_COST_CODE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getCostCodesByType = (type) => async (dispatch) => {
  try {
    const URL = `/api/v1/cost/type/${type}/_`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_COST_CODES_BY_TYPE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteCostCode = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/cost/${id}`;
    const res = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_COST_CODE,
      payload: id,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: COST_CODE_ERROR,
        error: error.message,
      });
    }
  }
};
