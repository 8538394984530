import React, { useState, useEffect } from "react";
import { TextField, Typography, Paper, makeStyles, Grid, darken, Backdrop,
    Radio,
    RadioGroup,
    Button,
    FormLabel,
    FormControlLabel, FormControl } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerVisitorAppointment, createCustomerFeedback } from "../../actions/visitorsActions";
import Swal from "sweetalert2";
import Feedback from "../atoms/Feedback";

import logo from '../../assets/logo.png';

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
        textAlign:'center',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        borderBottomStyle:'solid',
        paddingBottom: 10,
        marginBottom: 40,
    },
    title2: {
        display: "block",
        fontSize: 16,
        fontFamily: "Rubik",
        textTransform: "none",
        marginTop: 30
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        borderBottomStyle:'solid',
        paddingBottom: 10,
        marginBottom: 15,
    },
    homePaper: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(4),
        marginTop: theme.spacing(6),
        marginBottom: 55,
        marginLeft: theme.spacing(4), 
        marginRight: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper2: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: darken("#fff", 0.15),
    },
    paper3: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: darken("#fff", 0.08),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    top_bg: {
        backgroundColor: "#001c41",
        padding: 20,

    },
    top_bg_img: {
        height: 40,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    btnWrapper: {
        textAlign: "center",
    },
}));


const INIT_STATE = {
    employee: "",
    visitorName:"",
    visitorEmail: "",
    visitorPhone: "",
    visitPurpose: "",
    visitDate: "",
    visitTime:"",
    visitDetails:"",
    visitDuration: "",
    visitCode: "",
    status: "",

    howOften: "",
    recommend: "",
    comment:"",
    securityContact: [
        {
            type: "Courtesy",
            rating: "5",
        },
        {
            type: "Friendliness",
            rating: "5",
        },
        {
            type: "Approachable",
            rating: "5",
        },
        {
            type: "Helpful",
            rating: "5",
        },
        {
            type: "Customer Service",
            rating: "5",
        },
    ],
    welcomeCentre: [
        {
            type: "Courtesy",
            rating: "5",
        },
        {
            type: "Friendliness",
            rating: "5",
        },
        {
            type: "Approachable",
            rating: "5",
        },
        {
            type: "Helpful",
            rating: "5",
        },
        {
            type: "CustomerService",
            rating: "5",
        },
    ],
    frontDeskSupport: [
        {
            type: "Courtesy",
            rating: "5",
        },
        {
            type: "Friendliness",
            rating: "5",
        },
        {
            type: "Approachable",
            rating: "5",
        },
        {
            type: "Helpful",
            rating: "5",
        },
        {
            type: "Customer Service",
            rating: "5",
        },
    ],
    officeEnvironment: [
        {
            type: "Cleanliness",
            rating: "5",
        },
        {
            type: "Welcoming",
            rating: "5",
        },
        {
            type: "Interior Decoration",
            rating: "5",
        },
        {
            type: "Lighting",
            rating: "5",
        },
        {
            type: "Noise Level",
            rating: "5",
        },
        {
            type: "Smell",
            rating: "5",
        },
    ],
};

const CustomerFeedback = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [state, setState] = useState(INIT_STATE);
    const [shouldLoad, setShouldLoad] = useState(false);
    const { error, loading, visitor } = useSelector((state) => state.visitors);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        dispatch(getCustomerVisitorAppointment(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));

            const externalUrl = 'https://www.russelsmithgroup.com';

            setShouldLoad(false);
            setTimeout(() => {
                Swal.fire({
                    icon: "error",
                    title: `${error}`,
                    confirmButtonText: 'Close'
                }).then((result) => {
                    if(result){
                        window.location.href = externalUrl;
                    }
                });
            }, 1500);

            setTimeout(() => {
                window.location.href = externalUrl;
            }, 6000);
        }
    }, [error]);

    useEffect(() => {
        if(visitor){
            setShouldLoad(true);
            setState((prev) => ({
                ...prev,
                loading,
                visitorName: (visitor && visitor.visitorName) || "",
                visitorEmail: (visitor && visitor.visitorEmail) || "",
                visitorPhone: (visitor && visitor.visitorPhone) || "",
                
                visitPurpose: (visitor && visitor.visitPurpose) || "",
                visitDate: (visitor && visitor.visitDate) || "",
                visitTime: (visitor && visitor.visitTime) || "",
                visitDetails: (visitor && visitor.visitDetails) || "",
                visitDuration: (visitor && visitor.visitDuration) || "",
                visitCode: (visitor && visitor.visitCode) || "",
                
                status: (visitor && visitor.status) || "",
                employee: (visitor && visitor.employee) || "",
            }));
        }
    }, [visitor, loading]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    // capture security contact changes
    const handleSecurityContactChange = (i) => (e) => {
        e.persist();
        const newItems = state.securityContact.map((item, index) => {
            if (i !== index) {
                return item;
            }            
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            securityContact: newItems,
        }));
    };

    const handleWelcomeCenterChange = (i) => (e) => {
        e.persist();
        const newItems = state.welcomeCentre.map((item, index) => {
            if (i !== index) {
                return item;
            }            
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            welcomeCentre: newItems,
        }));
    };

    const handleFrontDeskChange = (i) => (e) => {
        e.persist();
        const newItems = state.frontDeskSupport.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            frontDeskSupport: newItems,
        }));
    };

    const handleOfficeEnvChange = (i) => (e) => {
        e.persist();
        const newItems = state.officeEnvironment.map((item, index) => {
            if (i !== index) {
                return item;
            }            
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            officeEnvironment: newItems,
        }));
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const { feedback, ...visitorWithoutFeedback} = visitor;
        const data = {
            ...visitorWithoutFeedback,
            feedback: {
                howOften: state.howOften,
                recommend: state.recommend,
                comment: state.comment,
            },
            securityContact: [...state.securityContact],
            welcomeCentre: [...state.welcomeCentre],
            frontDeskSupport: [...state.frontDeskSupport],
            officeEnvironment: [...state.officeEnvironment],
            id,
        };

        await dispatch(createCustomerFeedback(data));
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Your Feedback Has Been Submitted Successfully.",
            success: true,
        }));

        const externalUrl = 'https://www.russelsmithgroup.com';

        setTimeout(() => {
            Swal.fire({
                icon: "success",
                title: "Your Feedback Has Been Submitted Successfully.",
            }).then((result) => {
                if(result){
                    window.location.href = externalUrl;
                }
            });
        }, 1500);


        setTimeout(() => {
            window.location.href = externalUrl;
        }, 6000);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const validCheck = () =>
        !state.howOften ||
        !state.comment ||
        !state.recommend;


    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <>
                <Grid item className={classes.top_bg}>
                    <img src={logo} className={classes.top_bg_img} alt="RusselSmith Logo" />
                </Grid>
                <Paper className={classes.homePaper} >

                    {!shouldLoad ? <div style={{textAlign:'center'}}>{loading ? "loading" : `${error}` }</div> : 
                    
                    <>
                    <Typography variant="overline" className={classes.title}>
                        Customer Survey Feedback Form
                    </Typography>

                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Invited By</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                disabled
                                value={(state.employee && state.employee) || ""}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Your Name</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                disabled
                                value={(state.visitorName && state.visitorName) || ""}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Your Email</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={state.visitorEmail || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Your Phone</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                disabled
                                value={state.visitorPhone || ""}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Visit Details</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={(state.visitDetails && state.visitDetails) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Visit Date</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={(state.visitDate && state.visitDate) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Visit Time</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={(state.visitTime && state.visitTime) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Visit Duration</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={(state.visitDuration && state.visitDuration) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Visit Status</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={(state.status && state.status) || ""}
                                disabled
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormControl
                            fullWidth
                            required
                            variant="outlined"
                            component="fieldset"
                        >
                            <FormLabel style={{fontWeight: 'bold', color: "currentcolor", marginTop: 30}}> 
                                How often do you visit RusselSmith? 
                            </FormLabel>
                            <RadioGroup row onChange={handleChange}>
                                <FormControlLabel
                                    value="First visit"
                                    control={<Radio checked={(state.howOften === "First visit") ? true : false} />}
                                    label="First visit"
                                    name="howOften"
                                />
                                <FormControlLabel
                                    value="Rarely"
                                    control={<Radio checked={(state.howOften === "Rarely") ? true : false} />}
                                    label="Rarely"
                                    name="howOften"
                                />
                                <FormControlLabel
                                    value="Occasionally"
                                    control={<Radio checked={(state.howOften === "Occasionally") ? true : false} />}
                                    label="Occasionally"
                                    name="howOften"
                                />
                                <FormControlLabel
                                    value="Frequently"
                                    control={<Radio checked={(state.howOften === "Frequently") ? true : false} />}
                                    label="Frequently"
                                    name="howOften"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormControl
                            fullWidth
                            required
                            variant="outlined"
                            component="fieldset"
                        >
                            <FormLabel style={{fontWeight: 'bold', color: "currentcolor", marginTop: 30}}> 
                                Level of satisfaction
                            </FormLabel>
                            <span>Please specify your level of satisfaction on a scale of 1-5. 5 being the highest and 1 being the lowest.</span>

                            <Grid container spacing={2} className={classes.grid}>
                                
                                <Grid item xs={12} md={4}>
                                    <Paper variant="outlined" square className={classes.paper2} >
                                        <Typography variant="overline" className={classes.title3}>
                                            Security Contact
                                        </Typography>

                                        {state.securityContact.map((secItem, i) => (
                                            <div key={i}>
                                                <label style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                    {secItem.type}
                                                </label>
                                                <RadioGroup row style={{marginBottom: 10}} onChange={handleSecurityContactChange(i)}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio checked={(secItem.rating === "1") ? true : false} />}
                                                        label="1"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio checked={(secItem.rating === "2") ? true : false} />}
                                                        label="2"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio checked={(secItem.rating === "3") ? true : false} />}
                                                        label="3"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio checked={(secItem.rating === "4") ? true : false} />}
                                                        label="4"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio checked={(secItem.rating === "5") ? true : false} />}
                                                        label="5"
                                                        name="rating"
                                                    />
                                                </RadioGroup>
                                            </div>
                                        ))}
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Paper variant="outlined" square className={classes.paper3} >
                                        <Typography variant="overline" className={classes.title3}>
                                            Welcome Center
                                        </Typography>

                                        {state.welcomeCentre.map((secItem, i) => (
                                            <div key={i}>
                                                <label style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                    {secItem.type}
                                                </label>
                                                <RadioGroup row style={{marginBottom: 10}} onChange={handleWelcomeCenterChange(i)}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio checked={(secItem.rating === "1") ? true : false} />}
                                                        label="1"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio checked={(secItem.rating === "2") ? true : false} />}
                                                        label="2"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio checked={(secItem.rating === "3") ? true : false} />}
                                                        label="3"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio checked={(secItem.rating === "4") ? true : false} />}
                                                        label="4"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio checked={(secItem.rating === "5") ? true : false} />}
                                                        label="5"
                                                        name="rating"
                                                    />
                                                </RadioGroup>
                                            </div>
                                        ))}
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Paper variant="outlined" square className={classes.paper2} >
                                        <Typography variant="overline" className={classes.title3}>
                                            Front Desk Support
                                        </Typography>

                                        {state.frontDeskSupport.map((secItem, i) => (
                                            <div key={i}>
                                                <label style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                    {secItem.type}
                                                </label>
                                                <RadioGroup row style={{marginBottom: 10}} onChange={handleFrontDeskChange(i)}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio checked={(secItem.rating === "1") ? true : false} />}
                                                        label="1"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio checked={(secItem.rating === "2") ? true : false} />}
                                                        label="2"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio checked={(secItem.rating === "3") ? true : false} />}
                                                        label="3"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio checked={(secItem.rating === "4") ? true : false} />}
                                                        label="4"
                                                        name="rating"
                                                    />
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio checked={(secItem.rating === "5") ? true : false} />}
                                                        label="5"
                                                        name="rating"
                                                    />
                                                </RadioGroup>
                                            </div>
                                        ))}
                                    </Paper>
                                </Grid>

                            </Grid>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormControl
                            fullWidth
                            required
                            variant="outlined"
                            component="fieldset"
                        >
                            <FormLabel style={{fontWeight: 'bold', color: "currentcolor", marginTop: 30}}> 
                                Level of satisfaction with the Office Environment
                            </FormLabel>
                            <span>Please specify your level of satisfaction on a scale of 1-5. 5 being the highest and 1 being the lowest.</span>

                            <Grid container spacing={2} className={classes.grid}>

                                <Grid item xs={12} md={12}>
                                    <Paper variant="outlined" square className={classes.paper3} >
                                        {/* <Grid container spacing={2} className={classes.grid}> */}
                                            <Grid item xs={4} md={4}>
                                                {state.officeEnvironment.map((secItem, i) => (
                                                    <div key={i}>
                                                        <label style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                            {secItem.type}
                                                        </label>
                                                        <RadioGroup row style={{marginBottom: 10}} onChange={handleOfficeEnvChange(i)}>
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio checked={(secItem.rating === "1") ? true : false} />}
                                                                label="1"
                                                                name="rating"
                                                            />
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio checked={(secItem.rating === "2") ? true : false} />}
                                                                label="2"
                                                                name="rating"
                                                            />
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio checked={(secItem.rating === "3") ? true : false} />}
                                                                label="3"
                                                                name="rating"
                                                            />
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio checked={(secItem.rating === "4") ? true : false} />}
                                                                label="4"
                                                                name="rating"
                                                            />
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio checked={(secItem.rating === "5") ? true : false} />}
                                                                label="5"
                                                                name="rating"
                                                            />
                                                        </RadioGroup>
                                                    </div>
                                                ))}
                                            </Grid>
                                        {/* </Grid> */}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormControl
                            fullWidth
                            required
                            variant="outlined"
                            component="fieldset"
                        >
                            <FormLabel style={{fontWeight: 'bold', color: "currentcolor", marginTop: 30}}> 
                                Considering your experience with our company, how likely would you be to recommend our company to others?
                            </FormLabel>
                            <span>On a scale of 1-10. 10 being the highest (extremely likely) and 1 being the lowest (very unlikely).</span>

                            <RadioGroup row onChange={handleChange}>
                                <FormControlLabel
                                    value="1"
                                    control={<Radio checked={(state.recommend === "1") ? true : false} />}
                                    label="1"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="Rarely"
                                    control={<Radio checked={(state.recommend === "2") ? true : false} />}
                                    label="2"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio checked={(state.recommend === "3") ? true : false} />}
                                    label="4"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="4"
                                    control={<Radio checked={(state.recommend === "4") ? true : false} />}
                                    label="4"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="5"
                                    control={<Radio checked={(state.recommend === "5") ? true : false} />}
                                    label="5"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="6"
                                    control={<Radio checked={(state.recommend === "6") ? true : false} />}
                                    label="6"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="7"
                                    control={<Radio checked={(state.recommend === "7") ? true : false} />}
                                    label="7"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="8"
                                    control={<Radio checked={(state.recommend === "8") ? true : false} />}
                                    label="8"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="9"
                                    control={<Radio checked={(state.recommend === "9") ? true : false} />}
                                    label="9"
                                    name="recommend"
                                />
                                <FormControlLabel
                                    value="10"
                                    control={<Radio checked={(state.recommend === "10") ? true : false} />}
                                    label="10"
                                    name="recommend"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid container spacing={2} className={classes.grid} style={{marginTop: 30}}>
                        <Grid item xs={12} md={12}>
                            <label style={{ fontWeight: "bold" }}>Any complaint, area of improvement or commendation for the company</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                name="comment"
                                value={(state.comment && state.comment) || ""}
                                required
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    
                    <div className={classes.btnWrapper}>
                        <Button
                            type="submit"
                            className={classes.saveBtn}
                            disabled={loading || validCheck()}
                            color="primary"
                            onClick={handleSubmitHandler}
                            variant="contained"
                        >
                            {loading ? "Loading..." : "Submit"}
                        </Button>
                    </div>

                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    </>
                    }
                </Paper>
            </>
        </div>
    );
};

export default CustomerFeedback;
