import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  ButtonGroup,
  Button,
  DialogActions,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  submitAnnualBudget,
  getAnnualBudgets,
  getAnnualBudgetById,
  modifyAnnualBudget,
} from "../../../../actions/annualAction";
import Feedback from "../../../atoms/Feedback";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const SubmitModifyModal = ({
  openModify,
  handleCloseModify,
  id,
  error,
  getAnnualBudgets,
  getAnnualBudgetById,
  annualBudget,
  modifyAnnualBudget,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const submitBudget = () => {
    setLoading((prev) => !prev);

    const data = {
      id,
      path: "budget/annual/view",
    };

    modifyAnnualBudget(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Annual Budget has been modified successfully, budget now awaiting action.`,
          success: true,
        });
        dispatch(getAnnualBudgets);
        dispatch(getAnnualBudgetById(id));
      })
      .catch(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `An Error has occured, failed to submit Annual Budget`,
          success: false,
        });
      })
      .finally(() => {
        handleCloseModify();
        setTimeout(() => {
          history.push("/budget/annual");
        }, 2000);
      });
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message ||
            "Annual Budget has been modified successfully, budget now awaiting action."
          }
        />
      )}
      <Dialog
        open={openModify}
        onClose={handleCloseModify}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Submit Budget?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseModify}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="overline" className={classes.title3}>
            Note: Once submitted, it's assumed you're done preparing this budget
            and won't be available for modification except if rejected.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              color="secondary"
              onClick={handleCloseModify}
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              onClick={submitBudget}
              variant="contained"
              className={classes.saveBtn}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  annualBudget: state.annualBudget.annualBudget.data,
  error: state.annualBudget.error,
});

const mapDispatchToProps = {
  submitAnnualBudget,
  getAnnualBudgets,
  getAnnualBudgetById,
  modifyAnnualBudget,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitModifyModal);
