import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  Typography,
  Divider,
  TextField,
  makeStyles,
  Grid,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";

import { useHistory } from "react-router-dom";
import {
  clarifyMonthlyBudget,
  getMonthlyBudgetById,
} from "../../../../actions/monthlyBudgetAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const SubmitMonthlyClarifyModal = ({
  openClarify,
  handleCloseClarify,
  id,
  clarifyMonthlyBudget,
  getMonthlyBudgetById,
  error,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [clarification, setClarification] = useState("");
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    e.persist();
    setClarification(e.target.value);
  };

  const handleClarify = () => {
    setLoading((prev) => !prev);

    let data = {};

    data.clarification = clarification;
    data.path = "budget/monthly/view";
    data.id = id;
    clarifyMonthlyBudget(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message:
            "Clarification submitted successful, an email has been sent to that effect.",
          success: true,
        });
        setClarification("");
        dispatch(getMonthlyBudgetById(id));
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `An Unexpected Error has occurred, kindly contact Administrator.`,
          success: false,
        });
      })
      .finally(() => {
        handleCloseClarify();
        setTimeout(() => {
          history.push("/budget/monthly");
        }, 2000);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message ||
            "Clarification submitted successful, an email has been sent to that effect."
          }
        />
      )}

      <Dialog
        open={openClarify}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseClarify}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {" "}
                {"Send Clarification"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseClarify}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider light />
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Clarification"
            name="clarification"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            value={clarification}
            helperText={
              <Typography variant="overline">
                Provide Clarification for this budget.
              </Typography>
            }
            required
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseClarify}
              color="secondary"
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleClarify}
              color="primary"
              disabled={!clarification || loading}
              className={classes.saveBtn}
              variant="contained"
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "Send"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.monthlyBudget.error,
});

const mapDispatchToProps = {
  clarifyMonthlyBudget,
  getMonthlyBudgetById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitMonthlyClarifyModal);
