import {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  VERIFY_USER,
  GET_PROFILE,
  LOGOUT,
  ERROR,
  GET_ALL_USERS,
  CREATE_USER_ACCOUNT,
  GET_USER_BY_TOKEN,
  SET_PASSWORD_BY_TOKEN,
  GET_USER,
  UPDATE_USER,
  DELETE_USER,
  DISABLE_USER,
  UPLOAD_USER_PHOTO,
  UPLOAD_USER_PHOTO_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_FAIL,
  UPDATE_USER_PASSWORD_REQUEST,
  AUTH_ERROR,
  USERS_ERROR,
  RESEND_PASSWORD_LINK,
  ENABLE_2FA,
  ENABLE_2FA_REQUEST,
  ENABLE_2FA_FAIL,
  DISABLE_2FA,
  DISABLE_2FA_FAIL,
  DISABLE_2FA_REQUEST,
  BULK_RESEND_PASSWORD_LINK_REQUEST,
  BULK_RESEND_PASSWORD_LINK,
  BULK_RESEND_PASSWORD_LINK_FAIL,
  UPLOAD_SIGNATURE,
  UPLOAD_SIGNATURE_FAIL,
  UPLOAD_SIGNATURE_REQUEST,
  REMOVE_SIGNATURE,
  REMOVE_SIGNATURE_FAIL,
  REMOVE_SIGNATURE_REQUEST,
  REMOVE_PROFILE_PICTURE,
  REMOVE_PROFILE_PICTURE_FAIL,
  REMOVE_PROFILE_PICTURE_REQUEST,
} from "../types";
import axios from "axios";

const token = localStorage.getItem("token:key");

export const getProfile = () => async (dispatch) => {
  try {
    if (token !== undefined) {
      const res = await axios.get("/api/v1/auth/profile", {

        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      dispatch({
        type: GET_PROFILE,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: AUTH_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: AUTH_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
        error: error.message,
      });
    }
  }
};

export const loginUser = ({ email, password }) => async (dispatch) => {
  try {
    const res = await axios.post("/api/v1/auth/login", {
      email,
      password,
    });

    dispatch({
      type: LOGIN_USER,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: LOGIN_USER_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: LOGIN_USER_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: LOGIN_USER_ERROR,
        error: error.message,
      });
    }
  }
};

export const verifyAuth = (data) => async (dispatch) => {
  try {
    const URL = "/api/v1/auth/verify2FA";
    const res = await axios.post(URL, data);
    dispatch({
      type: VERIFY_USER,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: AUTH_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: AUTH_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
        error: error.message,
      });
    }
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/auth/logout`);
    dispatch({
      type: LOGOUT,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: AUTH_ERROR,
        error: error.response.data,
      });
      return await error.response.data;
    } else if (error.request) {
      dispatch({
        type: AUTH_ERROR,
        error: error.request.data,
      });
      return await error.request.data;
    } else {
      dispatch({
        type: AUTH_ERROR,
        error: error.message,
      });
      return await error.message;
    }
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/user/${id}`;
    await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
    return id;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: USERS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: USERS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: USERS_ERROR,
        error: error.message,
      });
      return await error;
    }
  }
};

export const disableUser = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/ppmuser/disable/${id}`;
    await axios.put(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DISABLE_USER,
      payload: id,
    });
    return id;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: USERS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: USERS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: USERS_ERROR,
        error: error.message,
      });
      return await error;
    }
  }
};

export const getUsersForPPM = () => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/ppmuser`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: USERS_ERROR,
        error: error.response.data,
      });
      return await error.response.data;
    } else if (error.request) {
      dispatch({
        type: USERS_ERROR,
        error: error.request.data,
      });
      return await error.request.data;
    } else {
      dispatch({
        type: USERS_ERROR,
        error: error.message,
      });
      return await error.message;
    }
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/user/`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: USERS_ERROR,
        error: error.response.data,
      });
      return await error.response.data;
    } else if (error.request) {
      dispatch({
        type: USERS_ERROR,
        error: error.request.data,
      });
      return await error.request.data;
    } else {
      dispatch({
        type: USERS_ERROR,
        error: error.message,
      });
      return await error.message;
    }
  }
};

export const getUserByToken = (token) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/user/${token}`;
    const res = await axios.get(URL);
    dispatch({
      type: GET_USER_BY_TOKEN,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const getUserById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/user/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const updateUser = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/user/updateInfo/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: USERS_ERROR,
        payload: error.response.data,
      });
      return await error.response.data;
    } else if (error.request) {
      dispatch({
        type: USERS_ERROR,
        payload: error.request.data,
      });
      return await error.request.data;
    } else {
      dispatch({
        type: USERS_ERROR,
        payload: error.message,
      });
      return await error.message;
    }
  }
};

export const uploadPhoto = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/user/profilePic`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPLOAD_USER_PHOTO,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPLOAD_USER_PHOTO_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: UPLOAD_USER_PHOTO_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: UPLOAD_USER_PHOTO_ERROR,
        payload: error.message,
      });
    }
  }
};

export const updateProfile = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/user/updateProfile`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: res.data.message,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const updateUserPassword = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/user/updatePassword`;
    dispatch({
      type: UPDATE_USER_PASSWORD_REQUEST,
    });
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_USER_PASSWORD,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const setUserPasswordFromToken = ({ token, password }) => async (
  dispatch
) => {
  try {
    const URL = `/api/v1/auth/setPassword/${token}`;
    const res = await axios.put(URL, { password });

    dispatch({
      type: SET_PASSWORD_BY_TOKEN,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const createUserAccount = (data) => async (dispatch) => {
  try {
    const URL = "/api/v1/admin/user/register";
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_USER_ACCOUNT,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const resendPasswordLink = (data) => async (dispatch) => {
  try {
    const URL = "/api/v1/admin/user/resendLink";
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: RESEND_PASSWORD_LINK,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    dispatch({
      type: USERS_ERROR,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.requset
            ? error.request
            : error.message,
    });
    return error.response && error.response.data.message
      ? error.response.data.message
      : error.requset
        ? error.request
        : error.message;
  }
};

export const enable2FA = (body) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/twofactor/enable`;
    dispatch({
      type: ENABLE_2FA_REQUEST,
    });
    const { data } = await axios.put(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: ENABLE_2FA,
      payload: data,
    });
    return data.message;
  } catch (error) {
    dispatch({
      type: ENABLE_2FA_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const disable2FA = (body) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/twofactor/disable`;
    dispatch({
      type: DISABLE_2FA_REQUEST,
    });
    const { data } = await axios.put(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DISABLE_2FA,
      payload: data,
    });
    return data.message;
  } catch (error) {
    dispatch({
      type: DISABLE_2FA_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const bulkResendPasswordLink = (body) => async (dispatch) => {
  try {
    const URL = "/api/v1/admin/user/resendLink/multi";

    dispatch({
      type: BULK_RESEND_PASSWORD_LINK_REQUEST,
    });

    const { data } = await axios.put(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: BULK_RESEND_PASSWORD_LINK,
      payload: data,
    });
    return data.message;
  } catch (error) {
    dispatch({
      type: BULK_RESEND_PASSWORD_LINK_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const uploadSignature = (body) => async (dispatch) => {
  try {
    const URL = `/api/v1/user/signature`;
    dispatch({
      type: UPLOAD_SIGNATURE_REQUEST,
    });
    const { data } = await axios.put(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPLOAD_SIGNATURE,
      payload: data,
    });
    return data.userImage;
  } catch (error) {
    dispatch({
      type: UPLOAD_SIGNATURE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const removeSignature = () => async (dispatch) => {
  try {
    const URL = `/api/v1/user/signature/remove`;
    dispatch({
      type: REMOVE_SIGNATURE_REQUEST,
    });
    const { data } = await axios.put(
      URL,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: REMOVE_SIGNATURE,
      payload: data,
    });
    return data.message;
  } catch (error) {
    dispatch({
      type: REMOVE_SIGNATURE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const removeProfilePhoto = () => async (dispatch) => {
  try {
    const URL = `/api/v1/user/photo/remove`;
    dispatch({
      type: REMOVE_PROFILE_PICTURE_REQUEST,
    });
    const { data } = await axios.put(
      URL,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: REMOVE_PROFILE_PICTURE,
      payload: data,
    });
    return data.message;
  } catch (error) {
    dispatch({
      type: REMOVE_PROFILE_PICTURE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};
