import axios from "axios";
import {
  CREATE_CONTACT_PERSON,
  CREATE_CONTACT_PERSON_FAIL,
  CREATE_CONTACT_PERSON_REQUEST,
  UPDATE_CONTACT_PERSON,
  UPDATE_CONTACT_PERSON_FAIL,
  UPDATE_CONTACT_PERSON_REQUEST,
  GET_CONTACT_PERSON,
  GET_CONTACT_PERSON_REQUEST,
  GET_CONTACT_PERSON_FAIL,
  GET_CONTACT_PERSON_BY_CUSTOMER,
  GET_CONTACT_PERSON_BY_CUSTOMER_REQUEST,
  GET_CONTACT_PERSON_BY_CUSTOMER_FAIL,
  GET_CONTACT_PERSONS,
  GET_CONTACT_PERSONS_REQUEST,
  GET_CONTACT_PERSONS_FAIL,
  DELETE_CONTACT_PERSON,
  DELETE_CONTACT_PERSON_REQUEST,
  DELETE_CONTACT_PERSON_FAIL,
} from "../types";

const token = localStorage.getItem("token:key");

const URL = `/api/v1/sales/contact`;

export const createContactPerson = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_CONTACT_PERSON_REQUEST,
    });
    const { data } = await axios.post(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_CONTACT_PERSON,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_CONTACT_PERSON_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateContactPerson = (body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CONTACT_PERSON_REQUEST,
    });
    const { data } = await axios.put(`${URL}/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_CONTACT_PERSON,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CONTACT_PERSON_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteContactPerson = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_CONTACT_PERSON_REQUEST,
    });
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_CONTACT_PERSON,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CONTACT_PERSON_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getContactPersons = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONTACT_PERSONS_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_CONTACT_PERSONS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONTACT_PERSONS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getContactPerson = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONTACT_PERSON_REQUEST,
    });
    const { data } = await axios.get(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_CONTACT_PERSON,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONTACT_PERSON_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getContactPersonByCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONTACT_PERSON_BY_CUSTOMER_REQUEST,
    });
    const { data } = await axios.get(`${URL}/customer/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_CONTACT_PERSON_BY_CUSTOMER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONTACT_PERSON_BY_CUSTOMER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
