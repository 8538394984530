import React from "react";

import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import {
  updateCurrency,
  getCurrencies,
  getCurrency,
} from "../../../../actions/currencyActions";

import Feedback from "../../../atoms/Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  code: "",
  rate: "",
  name: "",
};

const EditCurrencyModal = ({
  openEdit,
  id,
  handleCloseEdit,
  getCurrencies,
  getCurrency,
  currency,
  updateCurrency,
  error,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = React.useState(INIT_STATE);

  const [loading, setLoading] = React.useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const currencyCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    if (id !== null) {
      getCurrency(id).then(() => {
        setLoading(false);
      });
    }
  }, [getCurrency, id]);

  React.useEffect(() => {
    currencyCallback();
  }, [currencyCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      name: (currency && currency.name) || "",
      code: (currency && currency.code) || "",
      rate: (currency && currency.rate) || "",
    }));
  }, [currency]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);

    let data = {
      ...state,
      id,
    };

    updateCurrency(data)
      .then(() => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          message: `Currency Rate updated successfully.`,
          success: true,
        });

        dispatch(getCurrencies);
      })
      .catch(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          success: false,
        });
      })
      .finally(() => {
        handleCloseEdit();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Update Rate
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseEdit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={loading ? "Loading..." : state.name || ""}
                  required
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Code"
                  name="code"
                  value={loading ? "Loading..." : state.code || ""}
                  required
                  variant="outlined"
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Rate"
                  name="rate"
                  value={loading ? "Loading..." : state.rate || ""}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={state.code !== "USD"}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  disabled={loading}
                  className={classes.saveBtn}
                >
                  {loading ? "Loading..." : "Update"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currencyLists: state.currency?.currencyLists?.data,
  currency: state.currency?.currency?.data,
  error: state.currency?.error,
});

const mapDispatchToProps = {
  getCurrencies,
  updateCurrency,
  getCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCurrencyModal);
