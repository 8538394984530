import {
  GET_TOTAL_ANNUAL_BUDGET,
  GET_TOTAL_ANNUAL_BUDGET_REQUEST,
  GET_TOTAL_ANNUAL_BUDGET_FAIL,
  GET_TOTAL_MONTHLY_BUDGET,
  GET_TOTAL_MONTHLY_BUDGET_FAIL,
  GET_TOTAL_MONTHLY_BUDGET_REQUEST,
  GET_TOTAL_SUPPLEMENTARY_BUDGET,
  GET_TOTAL_SUPPLEMENTARY_BUDGET_FAIL,
  GET_TOTAL_SUPPLEMENTARY_BUDGET_REQUEST,
} from "../../types/analytics";

const initState = {
  annualTotal: 0,
  loadingAnnual: false,
  loadingMonthly: false,
  loadingSupplementary: false,
  monthlyTotal: 0,
  supplementaryTotal: 0,
};

const analyticsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TOTAL_ANNUAL_BUDGET_REQUEST:
      return {
        ...state,
        loadingAnnual: true,
      };
    case GET_TOTAL_ANNUAL_BUDGET:
      return {
        ...state,
        loadingAnnual: false,
        annualTotal: action.payload.total,
      };
    case GET_TOTAL_ANNUAL_BUDGET_FAIL:
      return {
        ...state,
        loadingAnnual: false,
        error: action.payload,
      };
    case GET_TOTAL_MONTHLY_BUDGET_REQUEST:
      return {
        ...state,
        loadingMonthly: true,
      };
    case GET_TOTAL_MONTHLY_BUDGET:
      return {
        ...state,
        loadingMonthly: false,
        monthlyTotal: action.payload.total,
      };
    case GET_TOTAL_MONTHLY_BUDGET_FAIL:
      return {
        ...state,
        loadingMonthly: false,
        error: action.payload,
      };

    case GET_TOTAL_SUPPLEMENTARY_BUDGET_REQUEST:
      return {
        ...state,
        loadingSupplementary: true,
      };
    case GET_TOTAL_SUPPLEMENTARY_BUDGET:
      return {
        ...state,
        loadingSupplementary: false,
        supplementaryTotal: action.payload.total,
      };
    case GET_TOTAL_SUPPLEMENTARY_BUDGET_FAIL:
      return {
        ...state,
        loadingSupplementary: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default analyticsReducer;
