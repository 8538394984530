import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    fontWeight:"bold",
  },
  img: {
    width: '100%',
    height: 650,
    border: "1px solid #ccc",
    borderRadius: 10,
    objectFit: "contain",
    objectPosition: "center",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  small: {
    fontSize: 11,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  link: {
    display: "block",
    margin: "auto",
  },
}));

const InvoiceViewModal = ({ openDoc, handleCloseDoc, docs }) => {
    const classes = useStyles();
    return (
        <div>
            <Dialog
                open={openDoc}
                onClose={handleCloseDoc}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                View Invoice Document
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseDoc}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                <List>
                    {docs && (
                        <ListItem>
                        <object
                            data={docs}
                            className={classes.img}
                        >
                            <img src={docs} className={classes.img} alt={`document`} />

                            <Typography component="small" className={classes.small}>
                                Your Browser doesn't have a PDF Plugin. Instead you can{" "}
                                <Typography
                                    component="a"
                                    href={docs}
                                    target="_blank"
                                    rel="noopener"
                                    className={classes.small}
                                    download
                                >
                                    Click here to download the PDF file.
                                </Typography>
                            </Typography>
                        </object>
                        </ListItem>
                    )}
                </List>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default InvoiceViewModal;
