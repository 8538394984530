const {
  GET_COUNT,
  GET_COUNT_REQUEST,
  GET_COUNT_FAIL,
} = require("../../types/count");

const initState = {
  loadingCount: false,
  count: null,
  error: null,
};

const countReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_COUNT_REQUEST:
      return {
        ...state,
        loadingCount: true,
      };
    case GET_COUNT:
      return {
        ...state,
        loadingCount: false,
        count: action.payload,
      };
    case GET_COUNT_FAIL:
      return {
        ...state,
        loadingCount: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default countReducer;
