import {
  GET_GROUPS_BY_MODULE,
  CREATE_GROUP,
  GET_ALL_GROUPS,
  DELETE_GROUP,
  GET_GROUP,
  UPDATE_GROUP,
} from "../types/";
import axios from "axios";

const token = localStorage.getItem("token:key");

export const getAllGroups = () => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/group`;

    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_GROUPS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const getGroupsByModule = (module) => async (dispatch) => {
  try {
    if (module !== "") {
      const URL = `/api/v1/admin/group/module/${module}`;

      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: GET_GROUPS_BY_MODULE,
        payload: res.data,
      });
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const createGroup = ({ name, module }) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/group`;

    const res = await axios.post(
      URL,
      { name, module },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_GROUP,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const getGroup = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/group/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_GROUP,
      payload: res.data.data,
    });
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const updateGroup = ({ name, module, id }) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/group/${id}`;
    const res = await axios.put(
      URL,
      { name, module },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_GROUP,
      payload: res.data.data,
    });
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const deleteGroup = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/group/${id}`;
    await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_GROUP,
      payload: id,
    });
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};
