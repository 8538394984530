import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  Typography,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";

import {
  approveMonthlyBudget,
  getMonthlyBudgetById,
} from "../../../../actions/monthlyBudgetAction";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const MonthlyApproveBudgetModal = ({
  openApprove,
  handleCloseApprove,
  id,

  approveMonthlyBudget,
  getMonthlyBudgetById,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleApprove = () => {
    setLoading((prev) => !prev);
    const data = {
      id,
      path: "budget/all/monthly",
    };
    approveMonthlyBudget(data)
      .then((doc) => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `You have successfully approved this budget.`,
          success: true,
        });
        dispatch(getMonthlyBudgetById(id));
      })
      .catch(() => {
        setLoading(false);

        console.log(`An error has occured, kindly contact admin.`);
      })
      .finally(() => {
        handleCloseApprove();
        setTimeout(() => {
          history.push("/budget/monthly");
        }, 1500);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openApprove}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseApprove}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {" "}
                {"Approve budget?"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="overline" className={classes.title}>
              By Clicking 'Approve', it means you're satisfied with this budget.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseApprove}
              color="secondary"
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApprove}
              color="primary"
              disabled={loading}
              variant="contained"
              className={classes.saveBtn}
            >
              {loading ? "Loading..." : "Approve"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = {
  approveMonthlyBudget,
  getMonthlyBudgetById,
};

export default connect(null, mapDispatchToProps)(MonthlyApproveBudgetModal);
