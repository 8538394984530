import {
  GET_ALL_ACCESS_RIGHTS,
  CREATE_ACCESS_RIGHT,
  DELETE_SINGLE_ACCESS_RIGHT,
} from "../types/";
import axios from "axios";

const token = localStorage.getItem("token:key");

export const getAllAccessRights = () => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/access`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_ACCESS_RIGHTS,
      payload: res.data,
    });
    // return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createAccessRight = ({
  name,
  object,
  group,
  createOwn,
  createAny,
  readOwn,
  readAny,
  updateOwn,
  updateAny,
  deleteOwn,
  deleteAny,
}) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/access`;
    const res = await axios.post(
      URL,
      {
        name,
        object,
        group,
        createOwn,
        createAny,
        readOwn,
        readAny,
        updateOwn,
        updateAny,
        deleteOwn,
        deleteAny,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_ACCESS_RIGHT,
      payload: res.data,
    });
  } catch (error) {
    return error.response.data || error.message;
  }
};

export const deleteAccess = (name) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/access`;
    await axios.delete(
      URL,

      {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          name,
        },
      }
    );
    dispatch({
      type: DELETE_SINGLE_ACCESS_RIGHT,
    });
  } catch (error) {
    return error.response.data || error.message;
  }
};
