import React, { useState } from "react";
import {
  TextField,
  makeStyles,
  Typography,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Backdrop,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,

  // DialogContentText,
  // DialogActions,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";

// import WarningIcon from "@material-ui/icons/Warning";

import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";

import SearchComponent from "../../SearchComponent";

import { connect, useDispatch } from "react-redux";
import {
  createGroup,
  getAllGroups,
  deleteGroup,
} from "../../../actions/groupsAction";
import { getAllModulesAndSubmodules } from "../../../actions/moduleAction";

import Feedback from "../../atoms/Feedback";
import EditGroupModal from "./modals/EditGroupModal";
import { TablePaginationActions } from "../../TablePaginationActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  marginBottom: {
    marginBottom: 30,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  name: "",
  module: "",
};

const CreateGroups = ({
  createGroup,
  groups,
  getAllGroups,
  deleteGroup,
  getAllModulesAndSubmodules,
  allmodules,
}) => {
  const classes = useStyles();

  const [state, setState] = useState(INIT_STATE);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  // const [getGroups, setGetGroups] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [getId, setGetId] = useState("");

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  // const allGroupsCallback = React.useCallback(() => {
  //   setOpenBackdrop((prev) => !prev);
  //   getAllGroups().then(() => {
  //     setOpenBackdrop(false);
  //     // setGetGroups(groups);
  //   });
  // }, [getAllGroups]);

  React.useEffect(() => {
    getAllModulesAndSubmodules();
  }, [getAllModulesAndSubmodules]);

  React.useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getAllGroups().then(() => {
      setOpenBackdrop(false);
      // setGetGroups(groups);
    });
  }, [getAllGroups]);

  // React.useEffect(() => {
  //   setGetGroups(groups);
  // }, [groups]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleEditOpen = (id) => {
    setGetId(id);

    setOpenEdit(true);
  };

  // const handleDeleteOpen = (id) => {
  //   setGetId(id);
  //   setOpenDelete(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  // const handleCloseDelete = () => {
  //   setOpenDelete(false);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();

    setSearch("");
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const filteredRows = () =>
    groups === undefined
      ? []
      : groups.filter((row) => {
          if (search !== "") {
            return (
              row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.module.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...state,
      name: state.name.toLowerCase(),
    };
    setOpenBackdrop(!openBackdrop);

    createGroup(data)
      .then((doc) => {
        setState(INIT_STATE);
        setLoading(false);

        setOpenBackdrop(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Group created Successfully`,
          success: true,
        });
        dispatch(getAllGroups);
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: err,
          success: false,
        });
        setOpen(false);

        console.error(err);
      });
  };

  // const handleDeleteGroup = () => {
  //   setOpenBackdrop((prev) => !prev);
  //   setLoading((prev) => !prev);
  //   deleteGroup(getId)
  //     .then(() => {
  //       setOpenBackdrop(false);
  //       setFeed({
  //         loading: false,
  //         open: !feed.open,
  //         message: `Group with ID: ${getId} deleted Successfully`,
  //         success: true,
  //       });
  //       dispatch(getAllGroups);
  //       setLoading(false);
  //       setOpenDelete(false);
  //     })
  //     .catch((err) => {
  //       setOpenBackdrop(false);
  //       setFeed({
  //         loading: false,
  //         open: !feed.open,
  //         message: err,
  //         success: false,
  //       });
  //       setLoading(false);
  //       setOpenDelete(false);
  //     });
  // };

  const validCheck = () => {
    return !state.name || !state.module;
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <EditGroupModal
        openEdit={openEdit}
        handleCloseEdit={handleCloseEdit}
        id={getId}
      />
      <Typography variant="overline" className={classes.title}>
        Groups
      </Typography>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={handleClickOpen}
            className={classes.btn}
          >
            Create
          </Button>
        </Grid>

        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Module</TableCell>
                <TableCell>Display Name</TableCell>
                <TableCell>Edit</TableCell>
                {/* <TableCell>Delete</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.module}</TableCell>
                    <TableCell>{row.displayName}</TableCell>

                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditOpen(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    {/* <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteOpen(row._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              })}

              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={4}
                    align="center"
                    style={{ color: "#616161" }}
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* 
            <Dialog
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="responsive-dialog-title"
              fullWidth={true}
              maxWidth="md"
            >
              <DialogTitle>
                {"Are you sure you want to delete this group?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  color="secondary"
                  style={{ display: "flex" }}
                >
                  <WarningIcon color="secondary" /> Warning: By clicking Delete,
                  the data will be removed and won't be restored except
                  recreated.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={handleCloseDelete}
                  color="primary"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleDeleteGroup}
                  color="secondary"
                  autoFocus
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      Loading...
                      <CircularProgress color="secondary" size={15} />{" "}
                    </span>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </DialogActions>
            </Dialog> */}
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Group
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={state.name}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="module-label-id">Module</InputLabel>
                  <Select
                    labelId="module-label-id"
                    id="module"
                    name="module"
                    onChange={handleChange}
                    value={state.module}
                  >
                    <MenuItem value="---SELECT MODULE---" disabled>
                      <em>Select Module</em>
                    </MenuItem>
                    {allmodules &&
                      allmodules.map((mod) => (
                        <MenuItem key={mod._id} value={mod.code}>
                          {mod.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.saveBtn}
                disabled={validCheck() || loading}
                endIcon={
                  loading ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : null
                }
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  groups: state.groups.groups && state.groups.groups.data,
  allmodules: state.module.allmodules,
});

export default connect(mapStateToProps, {
  createGroup,
  getAllGroups,
  deleteGroup,
  getAllModulesAndSubmodules,
})(CreateGroups);
