import React from "react";
import { Paper, Typography, makeStyles, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    left: 0,
    color: "#fff",
    bottom: 0,
    margin: 24,
    outline: "none",
    padding: 24,
    zIndex: 2000,
    position: "fixed",
    maxWidth: 500,
    backgroundColor: "#000",
  },
  box: {
    display: "flex",
    marginTop: 16,
    justifyContent: "flex-end",
  },
  btn: {
    color: "#000",
    backgroundColor: "#fff",
  },
  link: {
    color: "#fff",
  },
  cookie: {
    display: "block",
    position: "absolute",
    left: "40%",
    top: -20,
  },
  typo: {
    paddingTop: 20,
  },
}));

export const CookiePolicy = () => {
  const classes = useStyles();
  const [show, setShow] = React.useState(true);

  const handleAgree = () => {
    localStorage.setItem("agreedPolicy", "Agreed");
    setShow((prev) => !prev);
  };
  return (
    <div>
      {show && (
        <Paper elevation={3} className={classes.root}>
          <img
            src="/img/cookies.svg"
            alt="Cookies"
            className={classes.cookie}
          />
          <Typography className={classes.typo}>
            We use Cookies to ensure we give you the best experience on our
            application. Read our{" "}
            <Link to="/privacy" className={classes.link}>
              Privacy Policy
            </Link>
          </Typography>
          <Box className={classes.box}>
            <Button
              variant="contained"
              type="button"
              className={classes.btn}
              onClick={handleAgree}
            >
              I agree
            </Button>
          </Box>
        </Paper>
      )}
    </div>
  );
};
