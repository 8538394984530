import {
  GET_DIVISIONS,
  CREATE_DIVISION,
  DIVISION_ERROR,
  GET_DIVISION_BY_ID,
  UPDATE_DIVISION,
  GET_DIVISIONS_BY_SERVICE,
  GET_DIVISIONS_BY_SERVICE_REQUEST,
  GET_DIVISIONS_BY_SERVICE_FAIL,
  DELETE_DIVISION,
  DELETE_DIVISION_FAIL,
  DELETE_DIVISION_REQUEST,
} from "../types/";

const INIT_STATE = {
  division: null,
  divisions: [],
  error: null,
  loading: false,
};

const divisionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DIVISIONS:
      return {
        ...state,
        divisions: action.payload.data,
      };
    case CREATE_DIVISION:
      return {
        ...state,
        divisions: [action.payload, ...state.divisions],
      };
    case GET_DIVISION_BY_ID:
      return {
        ...state,
        division: action.payload.data,
      };
    case UPDATE_DIVISION:
      return {
        ...state,
        division: action.payload,
      };
    case DIVISION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case GET_DIVISIONS_BY_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DIVISIONS_BY_SERVICE:
      return {
        ...state,
        loading: false,
        divisions: action.payload.data,
      };
    case GET_DIVISIONS_BY_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_DIVISION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DIVISION:
      return {
        ...state,
        loading: false,
        divisions: state.divisions.filter((div) => div._id !== action.payload),
      };
    case DELETE_DIVISION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default divisionReducer;
