import React, { useState, useEffect } from "react";
import {
    Paper, darken,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    IconButton,
    Dialog,
    Tooltip,
    Backdrop,
    AppBar,
    Toolbar,
    Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from 'moment';

import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../../../../actions/currencyActions";
import { getDivisionsByService } from "../../../../../actions/divisionActions";
import {
    getMaterialPriceListById, updateMaterialPriceList, getAllMaterialPriceList
} from "../../../../../actions/masterListActions/materialActions";
import Feedback from "../../../../atoms/Feedback";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: "bold",
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 2,
    },
    container: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 300,
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const initialState = {
    description: "",
    division: "",
    category: "",
    condition: "",
    code: "",
    cost: "",
    depRate: "",
    dateOfPurchase: "",
    wearRate: "",
    margin: "",
    currency: "",
    currentPeriod: "",
    usedLife: "",
    accumDep: "",
    netBookValue: "",
    usefulLife: "",
    dayRate: "",
    wear: "",
    withholdingRate: "",
    withholdingTax: "",
    totalDayRate: "",
    display: "",
};

const EditMaterialModal = ({ openEdit, handleCloseEdit, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = useState(initialState);
    const [selectedDate, setSelectedDate] = useState(null);

    const { currencies } = useSelector((state) => state.currency);
    const { divisions } = useSelector((state) => state.division);
    const { loading, error, material } = useSelector((state) => state.material);

    useEffect(() => {
        dispatch(getMaterialPriceListById(id));
    }, [dispatch, id]);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            description: (material && material.description) || "",
            division: (material && material.division) || "",
            category: (material && material.category) || "",
            condition: (material && material.condition) || "",
            currentPeriod: (material && material.currentPeriod) || "",
            code: (material && material.code) || "",
            dayRate: (material && material.dayRate) || "",
            usedLife: (material && material.usedLife) || "",
            cost: (material && material.cost) || "",
            dateOfPurchase: (material && material.dateOfPurchase) || "",
            netBookValue: (material && material.netBookValue) || "",
            accumDep: (material && material.accumDep) || "",
            depRate: (material && material.depRate) || "",
            selectedDate: (material && material.selectedDate) || "",
            withholdingTax: (material && material.withholdingTax) || "",
            withholdingRate: (material && material.withholdingRate) || "",
            wearRate: (material && material.wearRate) || "",
            wear: (material && material.wear) || "",
            margin: (material && material.margin) || "",
            usefulLife: (material && material.usefulLife) || "",
            totalDayRate: (material && material.totalDayRate) || "",
            display: (material && material.display) || "",
            currency: (material && material.currency) || "",
        }));
    }, [material]);

    useEffect(() => {
        dispatch(getCurrencies());
        dispatch(getDivisionsByService());
    }, [dispatch]);

    const handleChangeDate = (date) => {
        setSelectedDate(date);
        if (selectedDate) {
            setState((prev) => ({
                ...prev,
                dateOfPurchase: moment(selectedDate).toDate(),
            }));
        }
    };

    useEffect(() => {
        const withholdingTax = (state.cost * state.withholdingRate);
        setState((prev) => ({
            ...prev, withholdingTax
        }));
    }, [state.cost, state.withholdingRate]);

    useEffect(() => {
        const totalDayRate = (state.dayRate * state.margin);
        setState((prev) => ({
            ...prev, totalDayRate
        }));
    }, [state.dayRate, state.margin]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            id,
        };
        dispatch(updateMaterialPriceList(data));
        setTimeout(() => {
            handleCloseEdit();
        }, 1500);

        setTimeout(() => {
            dispatch(getAllMaterialPriceList());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Material Price List Updated Successfully.",
                success: true,
            }));
        }, 2000);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Dialog
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                fullScreen
                maxWidth="md"
            >
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.openEdit}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.openEdit}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <AppBar
                    className={classes.appBar}
                    variant="elevation"
                    position="fixed"
                    color="inherit"
                    elevation={1}
                >
                    <Toolbar>
                        <Tooltip title="close" arrow>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseEdit}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <div className={classes.container}>
                    <Typography className={classes.title} variant="overline">
                        Edit Material Price List
                    </Typography>
                    <div>
                        <form
                            component={Paper}
                            className={classes.form}
                            onSubmit={handleEditSubmit}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        value={state.description}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Code"
                                        name="code"
                                        variant="outlined"
                                        value={state.code}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Division</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "division",
                                                name: "division",
                                            }}
                                            value={state.division}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Division</MenuItem>
                                            {divisions.map((division) => (
                                                <MenuItem key={division._id} value={division._id}>
                                                    {division.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Condition</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "condition",
                                                name: "condition",
                                            }}
                                            value={state.condition}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Condition</MenuItem>
                                            <MenuItem value="New">New</MenuItem>
                                            <MenuItem value="Good">Good</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        autoOk
                                        format="DD/MM/yyyy"
                                        views={["year", "month", "date"]}
                                        label="Date of Purchase"
                                        value={state.dateOfPurchase}
                                        name="dateOfPurchase"
                                        maxDate={new Date()}
                                        onChange={handleChangeDate}
                                        fullWidth
                                        inputVariant="outlined"
                                        animateYearScrolling={true}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Currency</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "currency",
                                                name: "currency",
                                            }}
                                            value={state.currency}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Currency</MenuItem>
                                            {currencies.map((currency) => (
                                                <MenuItem key={currency._id} value={currency.code}>
                                                    {currency.code}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Cost"
                                        name="cost"
                                        type="number"
                                        variant="outlined"
                                        value={state.cost}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Damage Stock"
                                        name="wear"
                                        type="number"
                                        variant="outlined"
                                        value={state.wear}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Day Rate"
                                        name="dayRate"
                                        type="number"
                                        variant="outlined"
                                        value={state.dayRate}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Withholding Rate (%)"
                                        name="withholdingRate"
                                        type="number"
                                        variant="outlined"
                                        value={state.withholdingRate}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Withholding Tax"
                                        name="withholdingTax"
                                        type="number"
                                        variant="outlined"
                                        value={state.withholdingTax || 0}
                                        onChange={handleChange}
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Margin (%)"
                                        name="margin"
                                        type="number"
                                        variant="outlined"
                                        value={state.margin + "%" || 0}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Total Day Rate"
                                        name="totalDayRate"
                                        type="number"
                                        variant="outlined"
                                        value={state.totalDayRate}
                                        onChange={handleChange}
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <Grid container justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <Button
                                        onClick={handleEditSubmit}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading}
                                        endIcon={loading ? <CircularProgress size={20} /> : null}
                                    >
                                        {loading ? "updating" : "Save Changes"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};

export default EditMaterialModal;
