import {
  GET_ALL_CURRENCIES,
  GET_CURRENCY,
  GET_CURRENCY_LISTS,
  UPDATE_CURRENCY,
  CREATE_CURRENCY,
  DELETE_CURRENCY,
  CURRENCY_ERROR,
} from "../types/index";

import axios from "axios";

const token = localStorage.getItem("token:key");

export const getCurrencies = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/currency");
    dispatch({
      type: GET_ALL_CURRENCIES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.message,
      });
    }
  }
};

export const getCurrency = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/currency/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_CURRENCY,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.message,
      });
    }
  }
};

export const getCurrencyLists = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/currency/currencies/all", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_CURRENCY_LISTS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.message,
      });
    }
  }
};

export const createCurrency = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/api/v1/currency", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_CURRENCY,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateCurrency = (data) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/currency/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_CURRENCY,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteCurrency = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/v1/currency/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_CURRENCY,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CURRENCY_ERROR,
        error: error.message,
      });
    }
  }
};
