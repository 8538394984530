import {
  CREATE_REIMBURSEMENT,
  GET_ALL_REIMBURSEMENT,
  REIMBURSEMENT_ERROR,
  GET_REIMBURSEMENT_BY_ID,
  UPDATE_REIMBURSEMENT,
  UPLOAD_DOC,
  SUBMIT_REIMBURSEMENT,
  APPROVE_REIMBURSEMENT,
  REJECT_REIMBURSEMENT,
  REVIEW_REIMBURSEMENT,
  DECLINE_REIMBURSEMENT,
  EMPLOYEE_PAY_REIMBURSEMENT,
  FINANCE_PAY_REIMBURSEMENT,
} from "../../types";

const initState = {
  reimbursements: [],
  reimbursement: {},
  reimburseError: null,
  doc: null,
};

const reimburseReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_REIMBURSEMENT:
      return {
        ...state,
        reimbursements: action.payload.data,
      };
    case GET_REIMBURSEMENT_BY_ID:
      return {
        ...state,
        reimbursement: action.payload.data,
      };
    case CREATE_REIMBURSEMENT:
      return {
        ...state,
        reimbursement: action.payload.data,
      };
    case UPDATE_REIMBURSEMENT:
      return {
        ...state,
        reimbursements: state.reimbursements.map((doc) =>
          doc._id === action.payload._id ? (doc = action.payload) : doc
        ),
      };
    case SUBMIT_REIMBURSEMENT:
      return {
        ...state,
        reimbursement: action.payload.data,
      };
    case EMPLOYEE_PAY_REIMBURSEMENT:
      return {
        ...state,
        reimbursement: action.payload.data,
      };
    case FINANCE_PAY_REIMBURSEMENT:
      return {
        ...state,
        reimbursement: action.payload.data,
      };

    case UPLOAD_DOC:
      return {
        ...state,
        doc: action.payload,
      };
    case APPROVE_REIMBURSEMENT:
      return {
        ...state,
        reimbursement: action.payload.data,
      };
    case REJECT_REIMBURSEMENT:
      return {
        ...state,
        reimbursement: action.payload.data,
      };
    case REIMBURSEMENT_ERROR:
      return {
        ...state,
        reimburseError: action.error,
      };
    case REVIEW_REIMBURSEMENT:
      return {
        ...state,
        reimbursement: action.payload.data,
      };
    case DECLINE_REIMBURSEMENT:
      return {
        ...state,
        reimbursement: action.payload.data,
      };
    default:
      return state;
  }
};

export default reimburseReducer;
