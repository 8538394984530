import {
  GET_USER_DATA_BY_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
} from "../types/";

const initialState = {
  userInfo: {},
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA_BY_TOKEN:
      return {
        ...state,
        userInfo: action.payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        userInfo: action.payload,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
