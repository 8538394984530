import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AllGuard = ({ children, vendor, profile, ...rest }) => {
  const token = localStorage.getItem("token:key");

  let isVendor =
    vendor &&
    vendor &&
    vendor.groups &&
    vendor.groups.some((grp) => grp.name === "vendor");

  return (
    <Route
      {...rest}
      render={({ location }) =>
        (token && isVendor) || (token && profile) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/`,
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  vendor: state.vendors.vendor,
  profile: state.user.profile,
});

export default connect(mapStateToProps, {})(AllGuard);
