import * as types from "../types/assets";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/asset`;

//////////////////////////////////////////////
///////////// Asset Acquisition //////////////
//////////////////////////////////////////////
export const createAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.post(`${URL}/acquisition`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_ASSET_ACQUISITION,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_ASSET_ACQUISITION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAssetAcquisitions = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_ASSET_ACQUISITIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/acquisition`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_ASSET_ACQUISITIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_ASSET_ACQUISITIONS_FAIL,
            payload: error
        });
    }
};

export const getAssetAcquisition = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.get(`${URL}/acquisition/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ASSET_ACQUISITION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_ASSET_ACQUISITION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_ASSET_ACQUISITION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteAssetAcquisition = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_ASSET_ACQUISITION_REQUEST,
        });
        await axios.delete(`${URL}/acquisition/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_ASSET_ACQUISITION,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const disapproveAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DISAPPROVE_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/disapprove/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DISAPPROVE_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DISAPPROVE_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const reviewAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log(data)
        
        dispatch({
            type: types.DECLINE_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const authorizeAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.AUTHORIZE_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/authorize/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.AUTHORIZE_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.AUTHORIZE_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deauthorizeAssetAcquisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DEAUTHORIZE_ASSET_ACQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/acquisition/deauthorize/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log(data)
        
        dispatch({
            type: types.DEAUTHORIZE_ASSET_ACQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DEAUTHORIZE_ASSET_ACQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
