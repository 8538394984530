import axios from "axios";
import {
  GET_TOTAL_ANNUAL_BUDGET,
  GET_TOTAL_ANNUAL_BUDGET_FAIL,
  GET_TOTAL_ANNUAL_BUDGET_REQUEST,
  GET_TOTAL_MONTHLY_BUDGET,
  GET_TOTAL_MONTHLY_BUDGET_FAIL,
  GET_TOTAL_MONTHLY_BUDGET_REQUEST,
  GET_TOTAL_SUPPLEMENTARY_BUDGET,
  GET_TOTAL_SUPPLEMENTARY_BUDGET_FAIL,
  GET_TOTAL_SUPPLEMENTARY_BUDGET_REQUEST,
} from "../types/analytics";

const token = localStorage.getItem("token:key");

const URL = `/api/v1/budget`;

export const getTotalAnnualBudget = (year) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TOTAL_ANNUAL_BUDGET_REQUEST,
    });
    const { data } = await axios.get(`${URL}/annual/total/${year}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_TOTAL_ANNUAL_BUDGET,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TOTAL_ANNUAL_BUDGET_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getTotalMonthlyBudget = (month) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TOTAL_MONTHLY_BUDGET_REQUEST,
    });
    const { data } = await axios.get(`${URL}/monthly/total/${month}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_TOTAL_MONTHLY_BUDGET,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TOTAL_MONTHLY_BUDGET_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getTotalSupplementaryBudget = (month) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TOTAL_SUPPLEMENTARY_BUDGET_REQUEST,
    });
    const { data } = await axios.get(`${URL}/extra/total/${month}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_TOTAL_SUPPLEMENTARY_BUDGET,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TOTAL_SUPPLEMENTARY_BUDGET_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
