import React from "react";
import { connect, useDispatch } from "react-redux";
import {
  Grid,
  makeStyles,
  Typography,
  Hidden,
  TextField,
  Button,
  Divider,
  Avatar,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SecurityIcon from "@material-ui/icons/Security";
import clsx from "clsx";
import { useInterval } from "../../hooks";
import { green } from "@material-ui/core/colors";
import { verifyAuth, getProfile } from "../../actions/userActions";
import { useParams, useHistory } from "react-router-dom";

import Feedback from "./Feedback";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxHeight: "100vh",
    height: "100vh",
    fontFamily: "Quicksand",
  },
  startBackground: {
    background: "#66bb6a",
    transition: "background 300ms linear",
  },
  orangeBackground: {
    background: "#e57373",
    transition: "background 300ms linear",
  },
  endBackground: {
    background: "#ef5350",
    transition: "background 300ms linear",
  },

  centeredDiv: {
    display: "grid",
    justifyItems: "center",
    alignContent: "center",
    marginTop: 30,

    padding: theme.spacing(4),
    // transform: "translate(-50%, -50%)",
    "& :first-child": {
      //   marginTop: 20,
    },
  },
  textField: {
    width: "70%",
    zIndex: 90000,
    background: "#fff",
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  divider3: {
    height: "100%",
    margin: 4,
  },
  avatar: {
    marginTop: "5%",
    marginBottom: -50,
    width: 180,
    height: 180,
    border: `6px solid ${theme.palette.background.paper}`,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.65)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10%",
    },
  },
  typography: {
    fontSize: 14,
  },
  centeredGrid: {
    display: "grid",
    justifyItems: "center",
    alignContent: "center",

    boxShadow: "0px 5px 10px #888888",
    padding: theme.spacing(3),
    color: "#fff",
    lineHeight: "10px",
    textAlign: "left",
  },
  timer: {
    textAlign: "center",
  },
  text: {
    color: "#fff",
  },
  value: {
    fontSize: 40,
  },
  paper: {
    width: 200,
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 26,
    marginTop: 25,
  },
  animateCounter: {
    transform: "translateY(0px)",
    animation: "$fadeUp 1s ease 0s infinite normal forwards",
    transformOrigin: "center",
  },
  "@keyframes fadeUp": {
    "0.0%": {
      transform: "translate(0, 0px)",

      opacity: 1,
    },
    "100%": {
      transform: "translate(0, -10px)",
      opacity: 0,
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  counterMsg: {
    transform: "translateY(0px)",
    animation: "$fadeIn .5s ease 0s linear normal forwards",
    transformOrigin: "center",
    fontSize: 13,
    color: "#ff5252",
    textTransform: "uppercase",

    fontWeight: 500,
    padding: 8,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    paddingLeft: 20,
    paddingRight: 20,
    "&:hover": {
      backgroundColor: green[700],
    },
  },

  mobileInfo: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      left: 0,
      color: "#fff",
      bottom: 0,
      marginLeft: 10,
      marginRight: 10,

      outline: "none",
      padding: 24,
      zIndex: 2000,
      position: "fixed",
      maxWidth: "100%",
      // backgroundColor: "#000",
      display: "grid",
      alignContent: "center",
      justifyItems: "center",
    },
  },
  iconContainer: {
    width: 100,
    height: 100,
    marginTop: -70,
    display: "grid",
    justifyItems: "center",
    alignContent: "center",
    textAlign: "center",
    // border: "7px inset #fafafa, 7px solid #fafafa",

    borderRadius: "50%",
    // boxShadow: "inset 0 0 10px rgba(0,0,0,0.15)",
    "&::after": {
      position: "absolute",
      content: "''",
      width: 100,
      height: 100,
      borderRadius: "50%",
      // border: "10px solid #fff",
      boxShadow: "inset 0 0 1px #fff",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 30,
    position: "absolute",
    top: 10,
    left: 10,
  },
  verify: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    width: 200,
  },
}));

const TwoFactorAuth = ({ verifyAuth, error, getProfile }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [counter, setCounter] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { id } = useParams();

  useInterval(() => {
    setCounter((prev) => (prev === 0 ? (prev = 20) : prev - 1));
  }, 1000);

  const handleToken = (e) => {
    setToken(e.target.value);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleClick();
    } else {
      return;
    }
  };

  const handleClick = () => {
    setLoading((prev) => !prev);
    let data = {
      id,
      token,
    };
    verifyAuth(data)
      .then((doc) => {
        setLoading(false);

        if (doc.token) {
          localStorage.setItem("token:key", doc.token);

          setSuccess((prev) => !prev);
          setFeed({
            open: !feed.open,
            loading: false,
            // message: doc.error,
            message: "Account verification successful.",
            success: true,
          });

          setTimeout(() => {
            dispatch(getProfile);
            history.push("/dashboard");
            window.location.reload();
          }, 5000);
        } else {
          setFeed({
            open: !feed.open,
            loading: false,
            message: doc.error,
            success: false,
          });
        }
      })
      .catch(() => {
        setFeed({
          open: !feed.open,
          loading: false,
          // message: doc.error,

          success: false,
        });
      })
      .finally(() => {
        setToken("");
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <Grid container className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        // style={{ position: "absolute", top: 10, left: 10 }}
        className={classes.backBtn}
        onClick={history.goBack}
        startIcon={<ArrowBackIosIcon />}
      >
        Go Back
      </Button>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      )}
      <Grid item md={8} xs={12}>
        <div className={classes.centeredDiv}>
          <Paper className={classes.paper} variant="outlined" square>
            {counter === 0 ? (
              <span className={classes.counterMsg}>Countdown Expired</span>
            ) : (
              <>
                <span>00</span>
                <span style={{ display: "inline-block" }}>:</span>

                <span className={classes.animateCounter}>
                  {counter < 10 ? `0${counter}` : counter}
                </span>
              </>
            )}
          </Paper>

          <Avatar className={classes.avatar} src="/img/spy-icon.png" />

          <TextField
            variant="outlined"
            className={classes.textField}
            onChange={handleToken}
            value={token}
            onKeyDown={keyPress}
            InputProps={{
              endAdornment: (
                <>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    className={clsx(classes.verify, {
                      [classes.buttonSuccess]: success,
                    })}
                    onClick={handleClick}
                    disabled={!token || loading}
                    endIcon={
                      loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : success ? (
                        <CheckIcon color="primary" />
                      ) : null
                    }
                  >
                    {loading ? "Verifying..." : success ? "Verified" : "Verify"}
                  </Button>
                </>
              ),
            }}
          />
          <Typography className={classes.typography}>
            Type/Paste in your TOTP token to verify your account
          </Typography>
        </div>
      </Grid>
      <Hidden smDown>
        <Grid
          item
          md={4}
          className={clsx(
            {
              [classes.startBackground]: counter <= 20,
              [classes.orangeBackground]: counter <= 15,
              [classes.endBackground]: counter <= 5,
            },
            [classes.centeredGrid]
          )}
        >
          <SecurityIcon
            className={clsx(classes.icon)}
            fontSize="large"
            style={{
              width: 150,
              height: 150,
            }}
          />
          <Typography>
            RS EDGE uses Authy 2FA Software to generate a "Time-Based One-Time
            Password" Token which is used to authenticate your credentials, so
            kindly download the Twilio Authy application if you haven't already,
            and register with your company email account and phone number.
          </Typography>
        </Grid>
      </Hidden>
      {/* <Paper
        elevation={3}
        className={clsx(
          {
            [classes.startBackground]: counter <= 20,
            [classes.orangeBackground]: counter <= 15,
            [classes.endBackground]: counter <= 5,
          },
          classes.mobileInfo
        )}
      >
        <div
          className={clsx(
            {
              [classes.startBackground]: counter <= 20,
              [classes.orangeBackground]: counter <= 15,
              [classes.endBackground]: counter <= 5,
            },
            classes.iconContainer
          )}
        >
          <Icon
            className={(clsx(classes.icon), "fas fa-shield-alt")}
            fontSize="large"
            style={{
              width: 50,
              height: 50,
            }}
          />
        </div>
        RS EDGE uses Authy 2FA Application to generate a "Time-Based One-Time
        Password" Token which is used to authenticate your credentials, so
        kindly download the application if you haven't already, and register
        with your company email account and phone number.
      </Paper> */}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  error: state.user.authError,
});

const mapDispatchToProps = {
  verifyAuth,
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth);
