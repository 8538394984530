import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  Divider,
  Fab,
  InputAdornment,
  darken,
  Backdrop,
  Icon,
  ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Feedback from "../../atoms/Feedback";
import { getCurrencies } from "../../../actions/currencyActions";
import { getCostCodesByType } from "../../../actions/costCodeAction";
import { formatAmount } from "../../../utils";
// import { getAllMonthlyBudgetItems } from "../../../actions/monthlyBudgetAction";
import { getDivisionYearAnnualBudgetLines } from "../../../actions/annualAction";

import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { getCode, getSymbol } from "../../../utils/currencyconvert";
import {
  getReimbursementById,
  getAllReimbursements,
  updateReimbursement,
  submitReimbursement,
  uploadDoc,
} from "../../../actions/reimburseActions";
import { DocumentsTable } from "./DocumentsTable";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  submitBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  uploadBtn: {
    background: "#388e3c",
    color: "#fff",
    padding: theme.spacing(1.2),
    marginLeft: 5,
    width: 150,
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  status: {
    width: 140,
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  textGreen: {
    color: "#388e3c",
    fontWeight: 900,
  },
  input: {
    display: "none",
  },
  total: {
    marginTop: 30,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const INIT_STATE = {
  employeeName: "",
  employeeId: "",
  division: "",
  month: moment().format("MMMM"),
  year: moment().format("YYYY"),
  purpose: "",
  costType: "",
  costCenter: "",
  rate: 1,
  items: [
    {
      item: "",
      amount: 0,
      description: "",
    },
  ],
  status: "",
  total: 0,
  currency: "NGN",
  convertedTotal: 0,
};

const INIT_BREAKDOWN = {
  item: "",
  amount: 0,
  description: "",
};

const EditReimbursement = ({
  getCurrencies,
  currencies,
  getCostCodesByType,
  costcodes,
  // getAllMonthlyBudgetItems,
  getDivisionYearAnnualBudgetLines,
  profile,
  // items,
  annualBudgetLines,
  getReimbursementById,
  getAllReimbursements,
  updateReimbursement,
  submitReimbursement,
  reimbursement,
  uploadDoc,
  error,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [state, setState] = useState(INIT_STATE);
  const [total, setTotal] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [docs, setDocs] = useState([]);
  const [doc, setDoc] = useState(null);
  const [loadUpload, setLoadUpload] = useState(false);

  const [breakdown, setBreakdown] = useState({
    lists: [INIT_BREAKDOWN],
  });

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  // const getAllMonthlyBudgetItemsCallback = useCallback(() => {
  //   setLoad((prev) => !prev);
  //   let year = reimbursement && reimbursement.year;
  //   let month = reimbursement && reimbursement.month;
  //   let data = {
  //     year,
  //     month,
  //   };
  //   if (year !== undefined || month !== undefined) {
  //     getAllMonthlyBudgetItems(data).then(() => {
  //       setLoad(false);
  //     });
  //   }
  // }, [getAllMonthlyBudgetItems, reimbursement]);

  // useEffect(() => {
  //   getAllMonthlyBudgetItemsCallback();
  // }, [getAllMonthlyBudgetItemsCallback]);


  const getDivisionYearAnnualBudgetLinesCallback = useCallback(() => {
    let data = {
      year: reimbursement && reimbursement.year,
      division: reimbursement && reimbursement.division && reimbursement.division._id,
    };
    getDivisionYearAnnualBudgetLines(data);
  }, [getDivisionYearAnnualBudgetLines, reimbursement]);

  useEffect(() => {
    getDivisionYearAnnualBudgetLinesCallback();
  }, [getDivisionYearAnnualBudgetLinesCallback]);

  // console.log(annualBudgetLines)

  const getReimbursementByIdCallback = useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getReimbursementById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [getReimbursementById, id]);

  useEffect(() => {
    getReimbursementByIdCallback();
  }, [getReimbursementByIdCallback]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: reimbursement && reimbursement.employee,
      employeeId: reimbursement && reimbursement.employeeId,
      division:
        reimbursement && reimbursement.division && reimbursement.division.name,
      month: reimbursement && reimbursement.month,
      year:
        reimbursement && reimbursement.year && reimbursement.year.toString(),
      purpose: reimbursement && reimbursement.purpose,
      costType: reimbursement && reimbursement.costType,
      costCenter: reimbursement && reimbursement.costCenter,
      rate: (reimbursement && reimbursement.rate) || 1,
      status: reimbursement && reimbursement.status,
      currency: (reimbursement && reimbursement.currency) || "NGN",
      convertedTotal: reimbursement && reimbursement.convertedTotal,
    }));
  }, [reimbursement]);

  useEffect(() => {
    setLoad((prev) => !prev);
    getCurrencies().then(() => {
      setLoad(false);
    });
  }, [getCurrencies]);

  const codeTypeCallback = useCallback(() => {
    setLoad((prev) => !prev);
    if (state.costType !== "") {
      getCostCodesByType(state.costType).then(() => {
        setLoad(false);
      });
    }
  }, [getCostCodesByType, state]);

  useEffect(() => {
    codeTypeCallback();
  }, [codeTypeCallback]);

  useEffect(() => {
    setBreakdown({
      lists: reimbursement && reimbursement.items,
    });
  }, [reimbursement]);

  useEffect(() => {
    setDocs(reimbursement && reimbursement.documents);
  }, [reimbursement]);

  useEffect(() => {
    let total =
      breakdown.lists &&
      breakdown.lists.reduce((acc, cur) => {
        return acc + +cur.amount;
      }, 0);
    setTotal(total);
  }, [breakdown, state.rate]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFile = (e) => {
    e.persist();
    const { files } = e.target;
    setDoc(files[0]);
  };

  const removeDoc = (i) => {
    setDocs((prev) => prev.filter((_, index) => i !== index));
  };

  const uploadDocument = () => {
    setLoadUpload((prev) => !prev);
    let formData = new FormData();
    formData.append("doc", doc);
    uploadDoc(formData)
      .then((res) => {
        setLoadUpload(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Document uploaded successfully.`,
          success: true,
        });
        setDocs((prev) => [...prev, res]);
        setDoc(null);
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Failed to upload document.`,
          success: true,
        });
      });
  };

  const handleChangeBreakdown = (i) => (e) => {
    e.persist();
    const newBreakdown = breakdown.lists.map((item, index) => {
      if (i !== index) {
        return item;
      }

      return {
        ...item,
        [e.target.name]: e.target.value,
      };
    });
    setBreakdown({
      lists: newBreakdown,
    });
  };

  let code = getCode(state.rate, currencies);

  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    setOpenBackdrop((prev) => !prev);
    let items = breakdown.lists.map((item) => {
      let amount;
      if (item.amount) {
        amount = +item.amount;
      }
      return {
        ...item,
        amount: amount,
      };
    });
    const data = {
      year: state.year,
      month: state.month,
      purpose: state.purpose,
      costCenter: state.costCenter,
      costType: state.costType,
      amountToReimburse: total,
      rate: state.rate,
      currency: code,
      documents: docs,
      items,
      id: id,
    };

    updateReimbursement(data)
      .then((doc) => {
        if (!doc) {
          setLoading(false);
          setOpenBackdrop(false);
          setFeed({
            loading: false,
            open: !feed.open,

            success: false,
          });
        } else {
          setFeed({
            loading: false,
            open: !feed.open,

            success: true,
          });
          dispatch(getReimbursementById(id));

          setLoading(false);
          setOpenBackdrop(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setOpenBackdrop(false);

        setFeed({
          loading: false,
          open: !feed.open,

          success: false,
        });
      });
  };

  const handleSubmitReimbursement = () => {
    setLoadSubmit((prev) => !prev);
    setOpenBackdrop((prev) => !prev);
    let data = {
      id,
      path: "reimbursement/view",
    };
    submitReimbursement(data)
      .then((doc) => {
        if (!doc) {
          setLoadSubmit(false);
          setOpenBackdrop(false);

          setFeed({
            loading: false,
            open: !feed.open,
            message: "Failed to Submit Reimbursement",
            success: false,
          });
        }

        setFeed({
          loading: false,
          open: !feed.open,
          message: `Reimbursement Submitted Successfully`,
          success: true,
        });
        dispatch(getAllReimbursements);

        setLoadSubmit(false);
        setOpenBackdrop(false);

        setTimeout(() => {
          history.push("/reimbursement");
        }, 1500);
      })
      .catch(() => {
        setOpenBackdrop(false);

        setLoadSubmit(false);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleAddBreakdown = () => {
    let data = {
      item: "",
      amount: 0,
      description: "",
    };
    setBreakdown({
      lists: breakdown.lists.concat([data]),
    });
  };
  const handleRemoveBreakdown = (i) => {
    setBreakdown((prev) => ({
      ...prev,
      lists: prev.lists.filter((_, index) => index !== i),
    }));
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error.error) || feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "Reimbursement Updated successfully"}
        />
      )}

      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        style={{ marginBottom: 30 }}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title}>
            Reimbursement Details Form
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIosIcon />}
            className={classes.createBtn}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              state.status === "draft"
                ? classes.statusYellow
                : state.status === "submitted" || state.status === "tomodify"
                ? classes.statusOrange
                : state.status === "approved" || state.status === "confirmed"
                ? classes.statusGreen
                : state.status === "completed" || state.status === "reviewed"
                ? classes.statusDarkGreen
                : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop
              ? "Loading..."
              : state.status && state.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.container}>
        <form component={Paper} onSubmit={handleUpdate}>
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                label="Employee Name"
                name="employeeName"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.employeeName || ""}
                disabled
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="EID"
                name="employeeId"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.employeeId || ""}
                disabled
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Division/Subdivision"
                name="division"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.division || ""}
                disabled
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Month"
                name="month"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.month || ""}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Year"
                name="year"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.year || ""}
                disabled
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Purpose for Reimbursement"
                name="purpose"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={state.purpose || ""}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="costTypeId">Cost Type</InputLabel>
                <Select
                  labelId="costTypeId"
                  name="costType"
                  inputProps={{
                    id: "costType",
                    name: "costType",
                  }}
                  onChange={handleChange}
                  value={state.costType || ""}
                >
                  <MenuItem value="project">Project</MenuItem>

                  <MenuItem value="division">Division/Subdivision</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="costCodeId">Cost Center</InputLabel>
                <Select
                  labelId="costCodeId"
                  name="costCenter"
                  inputProps={{
                    id: "costCenter",
                    name: "costCenter",
                  }}
                  onChange={handleChange}
                  value={state.costCenter || ""}
                  disabled={!state.costType}
                >
                  <MenuItem value="Solutions" disabled>
                    <em>---SELECT COST CENTER----</em>
                  </MenuItem>
                  {costcodes &&
                    costcodes.map((cost) => (
                      <MenuItem key={cost._id} value={cost.name}>
                        {cost.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Divider />
              <Typography variant="overline" className={classes.title2}>
                Line Items
              </Typography>
            </Grid>

            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12} md={12}>
                <label htmlFor="document">
                  <Tooltip arrow title="Select File Attachment">
                    <Fab color="primary" component="span">
                      {/* <AttachmentIcon /> */}
                      <Icon className="fas fa-paperclip" />
                    </Fab>
                  </Tooltip>
                </label>

                <input
                  id="document"
                  name="document"
                  type="file"
                  accept="image/*,.pdf"
                  className={classes.input}
                  onChange={handleFile}
                  // onChange={handleChangeBreakdown(i)}
                />

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.uploadBtn}
                  endIcon={<Icon className="fas fa-upload" fontSize="small" />}
                  size="small"
                  disabled={!doc || loadUpload}
                  onClick={uploadDocument}
                >
                  {loadUpload ? "Loading..." : "Upload"}
                </Button>

                <DocumentsTable
                  {...{ documents: docs, isDel: true, removeDoc }}
                />
              </Grid>
              <Grid
                item
                style={{ marginRight: 10, marginLeft: 20 }}
                xs={12}
                md={12}
              >
                <FormControl style={{ width: 130 }} variant="outlined" required>
                  <InputLabel id="currencyId">Currency</InputLabel>
                  <Select
                    labelId="currencyId"
                    name="rate"
                    inputProps={{
                      id: "rate",
                      name: "rate",
                    }}
                    value={state.rate || 1}
                    onChange={handleChange}
                  >
                    <MenuItem value={1} disabled>
                      <em>NGN</em>
                    </MenuItem>
                    {currencies &&
                      currencies.map((currency) => (
                        <MenuItem key={currency._id} value={currency.rate}>
                          {currency.code}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {breakdown &&
              breakdown.lists &&
              breakdown.lists.map((item, i) => (
                <Grid
                  container
                  spacing={3}
                  key={i}
                  justify="center"
                  alignItems="center"
                  style={{ marginRight: 10, marginTop: 10, marginLeft: 5 }}
                >
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="itemId">Expense Category</InputLabel>
                      <Select
                        labelId="itemId"
                        name="item"
                        inputProps={{
                          id: "item",
                          name: "item",
                        }}
                        value={(item.item && item.item._id) || item.item}
                        onChange={handleChangeBreakdown(i)}
                      >
                        <MenuItem value="Hardware" disabled>
                          <em>---SELECT EXPENSE CATEGORY----</em>
                        </MenuItem>
                        {annualBudgetLines &&
                          annualBudgetLines.map((row, i) => (
                            <MenuItem key={i} value={row?.item?._id}>
                              {row?.item?.name} {" - "} {formatAmount(row.subTotal) || ""}
                            </MenuItem>
                          ))}
                        {/* {items &&
                          items.map((row) => (
                            <MenuItem key={row.item._id} value={row.item._id}>
                              {row.item.name}
                            </MenuItem>
                          ))} */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Amount"
                      name="amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={item.amount || ""}
                      onChange={handleChangeBreakdown(i)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Description"
                      name="description"
                      variant="outlined"
                      fullWidth
                      value={item.description || ""}
                      onChange={handleChangeBreakdown(i)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <Tooltip arrow title="Remove">
                      <Fab
                        size="small"
                        color="secondary"
                        onClick={() => handleRemoveBreakdown(i)}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
            <Grid
              item
              xs={12}
              md={12}
              style={{ marginTop: 20, marginLeft: 20 }}
            >
              <Button
                variant="outlined"
                color="default"
                onClick={() => handleAddBreakdown(breakdown.length + 1)}
                endIcon={<AddIcon fontSize="large" />}
              >
                Insert Item
              </Button>
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <TextField
                label="Total Amount To Reimburse"
                variant="outlined"
                fullWidth
                value={formatAmount(total || 0)}
                className={classes.total}
                disabled
                InputProps={{
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        {/* &#8358; */}
                        {getSymbol(code, total)}
                      </InputAdornment>
                      <Divider
                        className={classes.divider2}
                        orientation="vertical"
                      />
                    </>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {(state.status === "draft" || state.status === "rejected") &&
            profile &&
            profile._id ===
              (reimbursement &&
                reimbursement.createdBy &&
                reimbursement.createdBy._id) && (
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <ButtonGroup>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      className={classes.saveBtn}
                      disabled={loading || load}
                    >
                      {loading ? "Loading..." : "Save"}
                    </Button>
                    <Button
                      onClick={handleSubmitReimbursement}
                      size="large"
                      variant="contained"
                      className={classes.submitBtn}
                      disabled={loadSubmit}
                    >
                      {loadSubmit ? "Loading..." : "Submit"}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}
        </form>
      </div>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currencies: state.currency.currencies,
  costcodes: state.costcode.costcodes && state.costcode.costcodes.data,
  profile: state.user.profile,
  // items: state.monthlyBudget.items,
  annualBudgetLines: state.annualBudget.annualBudgetLines,
  reimbursement: state.reimburse.reimbursement,
  error: state.reimburse.reimburseError,
});

const mapDispatchToProps = {
  getReimbursementById,
  getAllReimbursements,
  updateReimbursement,
  submitReimbursement,
  getCostCodesByType,
  getCurrencies,
  // getAllMonthlyBudgetItems,
  getDivisionYearAnnualBudgetLines,
  uploadDoc,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditReimbursement);
