module.exports = {
    CREATE_QUOTATION: "CREATE_QUOTATION",
    CREATE_QUOTATION_REQUEST: "CREATE_QUOTATION_REQUEST",
    CREATE_QUOTATION_FAIL: "CREATE_QUOTATION_FAIL",

    UPDATE_QUOTATION: "UPDATE_QUOTATION",
    UPDATE_QUOTATION_REQUEST: "UPDATE_QUOTATION_REQUEST",
    UPDATE_QUOTATION_FAIL: "UPDATE_QUOTATION_FAIL",

    DELETE_QUOTATION: "DELETE_QUOTATION",
    DELETE_QUOTATION_REQUEST: "DELETE_QUOTATION_REQUEST",
    DELETE_QUOTATION_FAIL: "DELETE_QUOTATION_FAIL",

    SUBMIT_QUOTATION: "SUBMIT_QUOTATION",
    SUBMIT_QUOTATION_REQUEST: "SUBMIT_QUOTATION_REQUEST",
    SUBMIT_QUOTATION_FAIL: "SUBMIT_QUOTATION_FAIL",

    GET_QUOTATIONS: "GET_QUOTATIONS",
    GET_QUOTATIONS_REQUEST: "GET_QUOTATIONS_REQUEST",
    GET_QUOTATIONS_FAIL: "GET_QUOTATIONS_FAIL",

    GET_QUOTATION: "GET_QUOTATION",
    GET_QUOTATION_REQUEST: "GET_QUOTATION_REQUEST",
    GET_QUOTATION_FAIL: "GET_QUOTATION_FAIL",

    REVIEW_QUOTATION: "REVIEW_QUOTATION",
    REVIEW_QUOTATION_REQUEST: "REVIEW_QUOTATION_REQUEST",
    REVIEW_QUOTATION_FAIL: "REVIEW_QUOTATION_FAIL",

    DECLINE_QUOTATION: "DECLINE_QUOTATION",
    DECLINE_QUOTATION_REQUEST: "DECLINE_QUOTATION_REQUEST",
    DECLINE_QUOTATION_FAIL: "DECLINE_QUOTATION_FAIL",

    APPROVE_QUOTATION: "APPROVE_QUOTATION",
    APPROVE_QUOTATION_REQUEST: "APPROVE_QUOTATION_REQUEST",
    APPROVE_QUOTATION_FAIL: "APPROVE_QUOTATION_FAIL",

    REJECT_QUOTATION: "REJECT_QUOTATION",
    REJECT_QUOTATION_REQUEST: "REJECT_QUOTATION_REQUEST",
    REJECT_QUOTATION_FAIL: "REJECT_QUOTATION_FAIL",

    SEND_QUOTATION: "SEND_QUOTATION",
    SEND_QUOTATION_REQUEST: "SEND_QUOTATION_REQUEST",
    SEND_QUOTATION_FAIL: "SEND_QUOTATION_FAIL",

    CLOSE_QUOTATION: "CLOSE_QUOTATION",
    CLOSE_QUOTATION_REQUEST: "CLOSE_QUOTATION_REQUEST",
    CLOSE_QUOTATION_FAIL: "CLOSE_QUOTATION_FAIL",

    UPLOAD_QUOTATION_DOCUMENT: "UPLOAD_QUOTATION_DOCUMENT",
    UPLOAD_QUOTATION_DOCUMENT_REQUEST: "UPLOAD_QUOTATION_DOCUMENT_REQUEST",
    UPLOAD_QUOTATION_DOCUMENT_FAIL: "UPLOAD_QUOTATION_DOCUMENT_FAIL",

    GET_CUSTOMER_QUOTATION_REQUEST: "GET_CUSTOMER_QUOTATION_REQUEST",
    GET_CUSTOMER_QUOTATION: "GET_CUSTOMER_QUOTATION",
    GET_CUSTOMER_QUOTATION_ERROR: "GET_CUSTOMER_QUOTATION_ERROR",

    MARK_CUSTOMER_QUOTATION_REQUEST: "MARK_CUSTOMER_QUOTATION_REQUEST",
    MARK_CUSTOMER_QUOTATION: "MARK_CUSTOMER_QUOTATION",
    MARK_CUSTOMER_QUOTATION_ERROR: "MARK_CUSTOMER_QUOTATION_ERROR",

    RESET_QUOTATION: "RESET_QUOTATION",

};
