import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    Fab,
    DialogContent,
    Backdrop,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../../atoms/Feedback";
import { getProfile } from "../../../../../actions/userActions";
import {
    createRequisition,
    getAllInventoryItems,
    getAllStores,
    getLocations,
    getRequisitions,
    getAllInventoryCategories,
    getInventoryCategoryItems,
    getInventoryItem
} from "../../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: "#444",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    mainCategory: "",
    purpose: "",
    dateNeeded: "",
    store: "",
    locationFrom: "",
    locationTo: "",
    items: [],
    totalQuantityRequested: null,
};

const INIT_ITEM = {
    itemId: "",
    item: "",
    category: "",
    quantity: "",
    quantityRequested: 1,
    condition: "Good",
    toBeReturned: "Not To Be Returned",
}

const CreateRequisitionModal = ({ openCreate, handleCloseCreate, createRequisition }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [dateNeeded, setDateNeeded] = useState(new Date());
    const [state, setState] = useState(INIT_STATE);
    const [item, setItem] = useState(INIT_ITEM);
    const [category, setCategory] = useState("");
    const [invCate, setMainCategories] = useState("");

    const { profile } = useSelector((state) => state.user);
    const { invItems, invCates, invCateItems, invItem } = useSelector((state) => state.inventoryItem);
    const { locations } = useSelector((state) => state.location);
    const { allstores } = useSelector((state) => state.stores);
    const { loading, error } = useSelector((state) => state.requisition);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllInventoryItems());
        dispatch(getAllStores());
        dispatch(getLocations());
        dispatch(getAllInventoryCategories());
        if (category !== "") {
            dispatch(getInventoryCategoryItems(category));
        }
        if (item.item !== "") {
            dispatch(getInventoryItem(item.item));
        }
    }, [dispatch, category, item]);

    const handleChangeCategory = (e) => {
        setItem(INIT_ITEM);
        setCategory(e.target.value);
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile.userName,
            division: profile && profile.division && profile.division._id,
            dateNeeded: moment(dateNeeded).toDate(),
        }));
    }, [profile, dateNeeded]);

    useEffect(() => {
        if (invItem) {
            setItem((prev) => ({
                ...prev,
                quantity: invItem && invItem.totalQuantity,
            }));
        }
    }, [invItem]);

    const handleChangeDate = (date) => {
        setDateNeeded(date);
    };

    useEffect(() => {
        if (state.items && state.items.length > 0) {
            let total1 = state.items.reduce((acc, cur) => { return acc + +cur.quantityRequested; }, 0);
            setState((prev) => ({
                ...prev,
                totalQuantityRequested: total1,
            }));
        }
    }, [state.items]);

    useEffect(() => {
        if (state.mainCategory === "IT Equipments") {
            let cates = invCates.filter((item) => item.name === "Solutions");
            setMainCategories(cates)
        } else if (state.mainCategory === "Others") {
            let cates = invCates.filter((item) => item.name !== "Solutions");
            let cates2 = cates.filter((item) => item.name !== "PPE");
            setMainCategories(cates2)
        } else if (state.mainCategory === "PPE") {
            let cates = invCates.filter((item) => item.name === "PPE");
            setMainCategories(cates)
        }
    }, [state.mainCategory, invCates]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleItemChange = (e) => {
        e.persist();
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = (item) => {
        if (item.quantity > "0" && item.category !== "" && item.item !== "" && item.quantityRequested !== "" && item.condition !== "" && item.toBeReturned !== "" && (item.quantityRequested <= item.quantity)) {
            setState((prev) => ({
                ...prev,
                items: prev.items.concat([item]),
            }));
            setItem(INIT_ITEM);
        }
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(createRequisition(data));
        });
        if (res) {
            dispatch(getRequisitions());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Requisition Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/inventory/requisition/edit/${res._id}`);
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.purpose ||
        !state.store ||
        !state.locationFrom ||
        !state.locationTo ||
        (state.items.length === 0) ||
        !state.dateNeeded ||
        (state.totalQuantityRequested === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Inventory Requisition
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel>Inventory Store</InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "store",
                                                    name: "store",
                                                }}
                                                value={state.store}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select Store</MenuItem>
                                                {allstores.map((store) => (
                                                    <MenuItem key={store._id} value={store._id}>
                                                        {store.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Date Needed"
                                            fullWidth
                                            required
                                            inputVariant="outlined"
                                            value={dateNeeded}
                                            onChange={handleChangeDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel id="locationFrom">Current Location</InputLabel>
                                            <Select
                                                labelId="locationFrom"
                                                name="locationFrom"
                                                required
                                                variant="outlined"
                                                value={state.locationFrom || ""}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                {locations &&
                                                    locations.map((location) => (
                                                        <MenuItem key={location._id} value={location._id}>
                                                            {location.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel id="locationTo">Where Item is Going / Needed</InputLabel>
                                            <Select
                                                labelId="locationTo"
                                                name="locationTo"
                                                required
                                                variant="outlined"
                                                value={state.locationTo || ""}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                {locations &&
                                                    locations.map((location) => (
                                                        <MenuItem key={location._id} value={location._id}>
                                                            {location.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Purpose"
                                            multiline
                                            rows={3}
                                            name="purpose"
                                            value={state.purpose || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            component="fieldset"
                                        >
                                            <FormLabel>Please select the category the inventory belongs to?</FormLabel>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    value="IT Equipments"
                                                    control={<Radio disabled={(state.items && state.items.length > 0) ? true : false} />}
                                                    label="IT Equipments"
                                                    name="mainCategory"
                                                    onChange={handleChange}
                                                />
                                                <FormControlLabel
                                                    value="PPE"
                                                    control={<Radio disabled={(state.items && state.items.length > 0)? true : false} />}
                                                    label="PPE"
                                                    name="mainCategory"
                                                    onChange={handleChange}
                                                />
                                                <FormControlLabel
                                                    value="Others"
                                                    control={<Radio disabled={(state.items && state.items.length > 0) ? true : false} />}
                                                    label="Others"
                                                    name="mainCategory"
                                                    onChange={handleChange}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Typography variant="overline" className={classes.title2}>
                                        Please select a category and then an item. Click the circular button to add. You can add as many as you require. Only items with available quantity greater than 0 can be added to the request. Also you cannot request more than the available quantity.
                                    </Typography>
                                </Paper>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {item ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="item">
                                                        Inventory Category
                                                    </InputLabel>
                                                    {(state.mainCategory === "Others") ? (
                                                        <Select
                                                            labelId="category"
                                                            label="Inventory Category"
                                                            name="category"
                                                            required
                                                            value={item.category || ""}
                                                            onChange={handleChangeCategory}
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            {invCate &&
                                                                invCate.map((cate) => (
                                                                    <MenuItem key={cate._id} value={cate._id}>
                                                                        {cate.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    ) : ((state.mainCategory === "IT Equipments" || state.mainCategory === "PPE") ? (
                                                        <Select
                                                            labelId="category"
                                                            label="Inventory Category"
                                                            name="category"
                                                            required
                                                            value={item.category || ""}
                                                            onChange={handleChangeCategory}
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            {invCate &&
                                                                invCate.map((cate) => (
                                                                    <MenuItem key={cate._id} value={cate._id}>
                                                                        {cate.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    ) : null
                                                    )
                                                    }
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="item">
                                                        Inventory item
                                                    </InputLabel>
                                                    <Select
                                                        labelId="item"
                                                        name="item"
                                                        label="Inventory Item"
                                                        required
                                                        value={item.item || ""}
                                                        onChange={handleItemChange}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        {invCateItems &&
                                                            invCateItems.map((item) => (
                                                                <MenuItem key={item._id} value={item._id}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Available Quantity"
                                                    type="text"
                                                    name="quantity"
                                                    disabled
                                                    value={item.quantity || "0"}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity Needed"
                                                    type="number"
                                                    name="quantityRequested"
                                                    value={item.quantityRequested || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="condition">
                                                        Condition
                                                    </InputLabel>
                                                    <Select
                                                        labelId="condition"
                                                        required
                                                        value={item.condition || ""}
                                                        name="condition"
                                                        onChange={handleItemChange}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        <MenuItem value="New">New</MenuItem>
                                                        <MenuItem value="Good">Good</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="toBeReturned">
                                                        To Be Returned?
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="toBeReturned"
                                                        value={item.toBeReturned || ""}
                                                        name="toBeReturned"
                                                        onChange={handleItemChange}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        <MenuItem value="To Be Returned">Yes</MenuItem>
                                                        <MenuItem value="Not To Be Returned">No</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    onClick={() => handleAddItemsArray(item)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                {/* Items requested */}
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Requested Items
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper}>
                                        {state.items ? state.items.map((sitem, i) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                                key={i}
                                            >
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <label style={{ fontWeight: "bold" }}>Inventory Category</label>
                                                        <Select
                                                            labelId="category"
                                                            name="category"
                                                            required
                                                            value={sitem.category || ""}
                                                            disabled
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            {invCates &&
                                                                invCates.map((cate) => (
                                                                    <MenuItem key={cate._id} value={cate._id}>
                                                                        {cate.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <label style={{ fontWeight: "bold" }}>Inventory Item</label>
                                                        <Select
                                                            labelId="item"
                                                            name="item"
                                                            required
                                                            value={sitem.item || ""}
                                                            disabled
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            {invItems &&
                                                                invItems.map((nitem) => (
                                                                    <MenuItem key={nitem._id} value={nitem._id}>
                                                                        {nitem.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Quantity</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        type="number"
                                                        name="quantityRequested"
                                                        value={sitem.quantityRequested || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <label style={{ fontWeight: "bold" }}>Condition</label>
                                                        <Select
                                                            labelId="condition"
                                                            required
                                                            value={sitem.condition || ""}
                                                            name="condition"
                                                            disabled
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            <MenuItem value="New">New</MenuItem>
                                                            <MenuItem value="Good">Good</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <label style={{ fontWeight: "bold" }}>To Be Returned</label>
                                                        <Select
                                                            required
                                                            labelId="toBeReturned"
                                                            value={sitem.toBeReturned || ""}
                                                            name="toBeReturned"
                                                            disabled
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            <MenuItem value="To Be Returned">Yes</MenuItem>
                                                            <MenuItem value="Not To Be Returned">No</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <label>&nbsp;</label>
                                                    <Fab
                                                        color="secondary"
                                                        onClick={() => handleRemoveItemsArray(i)}
                                                    >
                                                        <CloseIcon />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        )) : <>
                                            <Typography variant="overline" className={classes.title2}>
                                                No Requested Items
                                            </Typography>
                                        </>}
                                    </Paper>
                                </>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={9} />
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Total Quantity Requested"
                                            name="totalQuantityRequested"
                                            value={state.totalQuantityRequested || "0"}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createRequisition })(CreateRequisitionModal);
