import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    DialogTitle,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";
import { getAStore, updateAStore, getAllStores, } from "../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    centered: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
    },
}));

const INIT_STATE = {
    name: "",
    code: "",
    office: "",
    keeper: "",
    category: "",
    owner: "",
};

const EditStoreModal = ({ openEdit, handleCloseEdit, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = React.useState(INIT_STATE);
    const { loading, error, onestore } = useSelector((state) => state.stores);
    const { users } = useSelector((state) => state.user);
    const { offices } = useSelector((state) => state.office);

    React.useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    React.useEffect(() => {
        setState((prev) => ({
            ...prev,
            name: (onestore && onestore.name) || "",
            code: (onestore && onestore.code) || "",
            keeper: (onestore && onestore.keeper?._id) || "",
            category: (onestore && onestore.category) || "",
            owner: (onestore && onestore.owner) || "",
            office: (onestore && onestore.office?._id) || "",
        }));
    }, [onestore]);

    React.useEffect(() => {
        dispatch(getAStore(id));
    }, [dispatch, id]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            id,
        };
        await dispatch(updateAStore(data));
        setTimeout(() => {
            handleCloseEdit();
            dispatch(getAllStores());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Store updated Successfully.",
                success: true,
            }));
        }, 2000);
    };

    return (
        <div>
            {error && error.error ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message || (error && error.error)}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message || `Store updated successfully.`}
                />
            )}

            <Dialog
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Update Store
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseEdit}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleEditSubmit}>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Store Name"
                                    name="name"
                                    value={state.name || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel id="office">Store Office</InputLabel>
                                    <Select
                                        id="office"
                                        labelId="office"
                                        name="office"
                                        inputProps={{
                                            id: "office",
                                            name: "office",
                                        }}
                                        value={state.office || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Select Office" disabled>
                                            <em>Select Office</em>
                                        </MenuItem>
                                        {offices &&
                                            offices.map((item) => (
                                                <MenuItem key={item._id} value={item._id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Store Code"
                                    name="code"
                                    value={state.code || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel id="keeper">Assign Store Keeper</InputLabel>
                                    <Select
                                        id="keeper"
                                        labelId="keeper"
                                        name="keeper"
                                        inputProps={{
                                            id: "keeper",
                                            name: "keeper",
                                        }}
                                        value={state.keeper || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Select User" disabled>
                                            <em>Select User</em>
                                        </MenuItem>
                                        {users &&
                                            users.map((item) => (
                                                <MenuItem key={item._id} value={item._id}>
                                                    {item.firstName} {item.lastName}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel id="category">Store Category</InputLabel>
                                    <Select
                                        id="category"
                                        labelId="category"
                                        name="category"
                                        inputProps={{
                                            id: "category",
                                            name: "category",
                                        }}
                                        value={state.category || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Select Category" disabled>
                                            <em>Select Category</em>
                                        </MenuItem>
                                        <MenuItem value="Onshore">Onshore</MenuItem>
                                        <MenuItem value="Offshore">Offshore</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel id="owner">Store Owner</InputLabel>
                                    <Select
                                        id="owner"
                                        labelId="owner"
                                        name="owner"
                                        inputProps={{
                                            id: "owner",
                                            name: "owner",
                                        }}
                                        value={state.owner || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Select Owner" disabled>
                                            <em>Select Owner</em>
                                        </MenuItem>
                                        <MenuItem value="Own">Own Facility</MenuItem>
                                        <MenuItem value="Customer">Customer Facility</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    disabled={loading}
                                    className={classes.saveBtn}
                                >
                                    {loading ? "Loading..." : "Update"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EditStoreModal;
