import * as types from "../../types/purchasing";

const initState = {
    rfqs: [],
    rfqs_accepted: [],
    rfq: {},
    error: null,
    success: false,
    loading: false,
};

const rfqReducer = (state = initState, action) => {
    switch (action.type) {
        case types.UPDATE_REQUEST_FOR_QUOTE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_REQUEST_FOR_QUOTE:
            return {
                ...state,
                loading: false,
                success: true,
                rfq: state.rfqs.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_REQUEST_FOR_QUOTE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.ACCEPT_REQUEST_FOR_QUOTE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_REQUEST_FOR_QUOTE:
            return {
                ...state,
                loading: false,
                rfq: action.payload.data,
            };
        case types.ACCEPT_REQUEST_FOR_QUOTE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_REQUEST_FOR_QUOTE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_REQUEST_FOR_QUOTE:
            return {
                ...state,
                loading: false,
                rfq: action.payload.data,
            };
        case types.DECLINE_REQUEST_FOR_QUOTE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            
        case types.DELETE_REQUEST_FOR_QUOTE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_REQUEST_FOR_QUOTE:
            return {
                ...state,
                loading: false,
                success: false,
                rfqs: state.rfqs.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_REQUEST_FOR_QUOTE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.GET_ALL_REQUEST_FOR_QUOTES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_REQUEST_FOR_QUOTES:
            return {
                ...state,
                loading: false,
                success: true,
                rfqs: action.payload.data,
            };
        case types.GET_ALL_REQUEST_FOR_QUOTES_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ONE_REQUEST_FOR_QUOTE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ONE_REQUEST_FOR_QUOTE:
            return {
                ...state,
                loading: false,
                success: true,
                rfq: action.payload.data,
            };
        case types.GET_ONE_REQUEST_FOR_QUOTE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ACCEPTED_REQUEST_FOR_QUOTES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ACCEPTED_REQUEST_FOR_QUOTES:
            return {
                ...state,
                loading: false,
                success: true,
                rfqs_accepted: action.payload.data,
            };
        case types.GET_ACCEPTED_REQUEST_FOR_QUOTES_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default rfqReducer;
