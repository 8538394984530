import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Backdrop,
  IconButton,
  Chip,
  Icon,
  Tooltip,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";
import { useHistory } from "react-router-dom";
import { getTodaysAttendances } from "../../../actions/peoplesActions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),

    // width: "100%",
    overflow: "hidden",
  },

  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 350,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
}));

const Attendance = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { todayattendances, loading } = useSelector((state) => state.peoples);

  useEffect(() => {
    dispatch(getTodaysAttendances());
  }, [dispatch]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  let filteredRows = () =>
    todayattendances === undefined
      ? []
      : todayattendances.filter((row) => {
          if (search !== "") {
            return (
              (row.employee &&
                row.employee.userName
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              (row.division &&
                row.division.name
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              (row.jobTitle &&
                row.jobTitle?.name
                  ?.toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              (row.location &&
                row.location.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              (row.date &&
                row.date.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (row.week &&
                row.week.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (row.timeIn &&
                row.timeIn.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              (row.timeOut &&
                row.timeOut.toLowerCase().indexOf(search.toLowerCase()) !== -1)
            );
          } else {
            return row;
          }
        });

  let filteredDate = filteredRows();

  filteredRows = () =>
    filteredDate.filter((item) => {
      if (selectedDate !== null) {
        let date = moment(selectedDate, "LL").format("MMMM Do YYYY");
        return (
          item.date.toString().toLowerCase().indexOf(date.toLowerCase()) !== -1
        );
      } else {
        return item;
      }
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // console.log(moment(selectedDate, "LL").format("MMMM Do YYYY"));

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(10000).length || filteredRows().length) - page * rowsPerPage
    );

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography variant="overline" gutterBottom className={classes.title}>
          Attendance Records
        </Typography>

        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={3}
          className={classes.marginBottom}
        >
          <Grid item>
            <SearchComponent
              updateSearch={updateSearch}
              placeholder="Search..."
              search={search}
              ResetSearch={ResetSearch}
            />
          </Grid>
          <Grid item>
            <DatePicker
              disableFuture
              autoOk
              format="MMMM Do yyyy"
              views={["year", "month", "date"]}
              label="Select Date"
              // disablePast
              // maxDate={new Date(moment().add(5, "years")._d)}
              inputVariant="outlined"
              value={selectedDate}
              onChange={handleChangeDate}
              helperText="Current date is set as default"
              style={{ marginBottom: -20 }}
              InputProps={{
                endAdornment: (
                  <>
                    <Divider
                      className={classes.divider2}
                      orientation="vertical"
                    />
                    <InputAdornment position="end">
                      <IconButton
                        disabled={selectedDate === null}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedDate(null);
                        }}
                      >
                        {selectedDate ? <CloseIcon /> : <FilterListIcon />}
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Paper>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {/* <TableCell>Position</TableCell> */}
                  <TableCell>Div./Subdiv.</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Week</TableCell>
                  <TableCell>TimeIn</TableCell>
                  <TableCell align="center">TimeOut</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredRows().slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredRows()
                ).map((row, i) => {
                  return (
                    <TableRow hover key={row._id}>
                      <TableCell component="th" scope="row">
                        <Chip
                          label={row.employee.userName}
                          variant="outlined"
                        />
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {row.jobTitle && row.jobTitle?.name}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {row.division && row.division.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.location && row.location}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.week}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.timeIn}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {row.timeOut ? row.timeOut : "-- • --"}
                      </TableCell>
                      <TableCell>
                        <Tooltip arrow title="View Attendance">
                          <IconButton
                            onClick={() =>
                              navigateById("/attendance/view", row._id)
                            }
                          >
                            <Icon className="fas fa-eye" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {filteredRows().length === 0 && (
                  <TableRow hover>
                    <TableCell
                      colSpan={10}
                      align="center"
                      style={{
                        color: "#616161",
                        textAlign: "center",
                      }}
                      component="th"
                      scope="row"
                    >
                      No Records To Display
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            colSpan={3}
            count={filteredRows().length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default Attendance;
