import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PeoplesGuard = ({ children, profile, ...rest }) => {
  const token = localStorage.getItem("token:key");

  let isPeoplesManagement = profile
    ? (profile &&
        profile.groups &&
        profile.groups.some(
          (grp) =>
            grp.name === "admin" ||
            (grp.name === "ppm" && grp.module === "cash_recovery")
        )) ||
      (profile &&
        profile.division &&
        profile.division.name === "People Management")
    : false;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && isPeoplesManagement ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

export default connect(mapStateToProps, {})(PeoplesGuard);
