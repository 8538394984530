import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Icon,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import {
    updateWorkCompletion,
    submitWorkCompletion,
    getWorkCompletion,
    getWorkCompletions,
} from "../../../../actions/qualityActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 14,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    division: "",
    type:"",
    projectDescription: "",
    vendorId:"",
    vendorName: "",
    vendorEmail:"",
    vendorPhone: "",
    vendorContactPerson: "",
    purchaseOrderNo: "",
};

const EditWorkCompletion = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, workcompletion } = useSelector((state) => state.workcompletion);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getWorkCompletions());
        dispatch(getWorkCompletion(id));
    }, [dispatch, id]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (workcompletion && workcompletion.employee) || "",
            division: (workcompletion && workcompletion.division) || "",
            type: (workcompletion && workcompletion.type) || "",
            projectDescription: (workcompletion && workcompletion.projectDescription) || "",
            vendorName: (workcompletion && workcompletion.vendorName) || "",
            vendorEmail: (workcompletion && workcompletion.vendorEmail) || "",
            vendorPhone: (workcompletion && workcompletion.vendorPhone) || "",
            vendorContactPerson: (workcompletion && workcompletion.vendorContactPerson) || "",
            purchaseOrderNo: (workcompletion && workcompletion.purchaseOrderNo) || "",
            
            status: (workcompletion && workcompletion.status) || "",
            documentNo: (workcompletion && workcompletion.documentNo) || "",

            createdBy: (workcompletion && workcompletion.createdBy) || "",
            createdAt: (workcompletion && workcompletion.createdAt) || "",

            submittedBy: (workcompletion && workcompletion.submittedBy) || "",
            submittedAt: (workcompletion && workcompletion.submittedAt) || "",

            approvedBy: (workcompletion && workcompletion.approvedBy) || "",
            approvedAt: (workcompletion && workcompletion.approvedAt) || "",
            approveComment: (workcompletion && workcompletion.approveComment) || "",
            
            rejectedBy: (workcompletion && workcompletion.rejectedBy) || "",
            rejectedAt: (workcompletion && workcompletion.rejectedAt) || "",
            rejectComment: (workcompletion && workcompletion.rejectComment) || "",

            acceptedBy: (workcompletion && workcompletion.acceptedBy) || "",
            acceptedAt: (workcompletion && workcompletion.acceptedAt) || "",
            acceptComment: (workcompletion && workcompletion.acceptComment) || "",

            declinedBy: (workcompletion && workcompletion.declinedBy) || "",
            declinedAt: (workcompletion && workcompletion.declinedAt) || "",
            declineComment: (workcompletion && workcompletion.declineComment) || "",

        }));
    }, [workcompletion]);

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            id,
            path: `quality/workcompletion/view/${id}`,
        };
        await dispatch(submitWorkCompletion(data));
        await dispatch(getWorkCompletions);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Work Completion Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/quality/workcompletion");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            vendorName: state.vendorName,
            vendorPhone: state.vendorPhone,
            vendorContactPerson: state.vendorContactPerson,
            purchaseOrderNo: state.purchaseOrderNo,
            projectDescription: state.projectDescription,
            id
        }
        await dispatch(updateWorkCompletion(data));
        setTimeout(async () => {
            await dispatch(getWorkCompletion(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Work Completion Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" ||
            state.status === "declined" ||
            state.status === "rejected" ||
            state.status === "disapproved")
        )
        : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" className={classes.title}>
                    Edit Work Completion
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/quality/workcompletion")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Paper variant="outlined" className={classes.status}>
                            <Typography variant="overline" className={classes.statusTitle}>
                                Document No.
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            {loading ? "Loading..." : state.documentNo}
                        </Paper>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ marginBottom: 20 }}
                >
                    <Hidden smDown>
                        <Grid item></Grid>
                    </Hidden>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : state.status === "reviewed"
                                            ? classes.statusGreen
                                            : (state.status === "approved" || state.status === "sent")
                                                ? classes.statusDarkGreen
                                                : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmitHandler}>
                    <div>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Employee Name"
                                    name="employeeName"
                                    variant="outlined"
                                    fullWidth
                                    value={(state.createdBy && state.createdBy.userName) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Division/Subdivision"
                                    name="division"
                                    variant="outlined"
                                    fullWidth
                                    value={(state.division && state.division.name) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Document No"
                                    name="documentNo"
                                    variant="outlined"
                                    fullWidth
                                    value={state.documentNo || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Type"
                                    name="type"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    value={(state.type && state.type) || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    label="Project Description"
                                    name="projectDescription"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    value={(state.projectDescription && state.projectDescription) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Vendor Name"
                                    name="vendor"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    value={(state.vendorName && state.vendorName) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={3}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Vendor Phone"
                                    name="vendorPhone"
                                    value={(state.vendorPhone && state.vendorPhone) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Vendor Email"
                                    name="vendorEmail"
                                    value={(state.vendorEmail && state.vendorEmail) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Vendor Contact Person"
                                    name="vendorContactPerson"
                                    value={(state.vendorContactPerson && state.vendorContactPerson) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Purchase Order No"
                                    name="purchaseOrderNo"
                                    value={(state.purchaseOrderNo && state.purchaseOrderNo) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                            style={{ marginBottom: 5 }}
                        >
                            <Grid item>
                                <Typography variant="overline" className={classes.title2}>
                                    Audit Trail
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Icon className="fas fa-clipboard-list" color="disabled" />
                            </Grid>
                        </Grid>
                        <Paper variant="outlined" square className={classes.paper}>
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                {state.status === "draft" && (
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Created By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdAt}</span>
                                        </Paper>
                                    </Grid>
                                )}
                                {state.status !== "draft" && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Submitted By</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedAt}</span>
                                            </Paper>
                                        </Grid>
                                        {(state.rejectedAt && state.rejectedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Rejected By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                        {(state.declinedAt && state.declinedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Declined By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declineComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Paper>

                        {isCanEdit ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleSave}
                                        >
                                            {loading ? "Loading..." : "Save Changes"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            onClick={handleSubmitHandler}
                                            disabled={loading}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                    </div>
                </form>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditWorkCompletion;
