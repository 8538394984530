import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Icon,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { getCRF } from "../../../../actions/ppmActions/changeMgtActions";
import { getProfile } from "../../../../actions/userActions";

// Board
import ApproveChangeRequest from "./_modals/ApproveChangeRequest";
import RejectChangeRequest from "./_modals/RejectChangeRequest";

// Coordinator
import ReviewChangeRequest from "./_modals/ReviewChangeRequest";
import DeclineChangeRequest from "./_modals/DeclineChangeRequest";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper1: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: darken("#fff", 0.15),
    },
    paper0: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: darken("#fff", 0.05),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    month: "",
    year: "",
    requestDescription: "",
    justification: "",
    priority: "",
    resourceRequirements: "",
    budgetProjection: "",
    timelines: [],
    outcomes: [],
    riskFactors: "",
    opportunityFactors: "",
    trainingRequired: "",
    reason: "",
    recommendation: "",
};

const ViewChangeRequest = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const { profile } = useSelector((state) => state.user);
    const { error, loading, crf } = useSelector((state) => state.changeMgt);
    
    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openReview, setOpenReview] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile())
        dispatch(getCRF(id));
    }, [dispatch, id]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            requestDescription: (crf && crf.requestDescription) || "",
            requestDate: (crf && crf.requestDate) || "",
            justification: (crf && crf.justification) || "",
            resourceRequirements: (crf && crf.resourceRequirements) || "",
            budgetProjection: (crf && crf.budgetProjection) || "",
            priority: (crf && crf.priority) || "",
            division: (crf && crf.division) || "",
            month: (crf && crf.month) || "",
            year: (crf && crf.year) || "",

            timelines: (crf && crf.timelines) || [],
            outcomes: (crf && crf.outcomes) || [],
            status: (crf && crf.status) || "",
            documentNo: (crf && crf.documentNo) || "",
            
            riskFactors: (crf && crf.riskFactors) || "",
            opportunityFactors: (crf && crf.opportunityFactors) || "",
            decision: (crf && crf.decision) || "",
            trainingRequired: (crf && crf.trainingRequired) || "",
            recommendation: (crf && crf.recommendation) || "",
            
            createdBy: (crf && crf.createdBy) || "",
            createdAt: (crf && moment(crf.createdAt).format("l")) || "",

            submittedBy: (crf && crf.submittedBy) || "",
            submittedAt: (crf && crf.submittedAt) || "",

            approvedBy: (crf && crf.approvedBy) || "",
            approvedAt: (crf && crf.approvedAt) || "",
            approveComment: (crf && crf.approveComment) || "",
            
            rejectedBy: (crf && crf.rejectedBy) || "",
            rejectedAt: (crf && crf.rejectedAt) || "",
            rejectComment: (crf && crf.rejectComment) || "",

            reviewedBy: (crf && crf.reviewedBy) || "",
            reviewedAt: (crf && crf.reviewedAt) || "",
            reviewComment: (crf && crf.reviewComment) || "",

            declinedBy: (crf && crf.declinedBy) || "",
            declinedAt: (crf && crf.declinedAt) || "",
            declineComment: (crf && crf.declineComment) || "",

        }));
    }, [crf]);

    const validCheck = () =>
        !state.riskFactors ||
        !state.opportunityFactors ||
        !state.decision ||
        !state.trainingRequired ||
        !state.recommendation;

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some((grp) => grp.name === "admin") : false;

    const isCoordinator = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "coordinator" && grp.module === "change_request"
        )
        : false;

    const isBoard = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "board" && grp.module === "change_request"
        )
        : false;

    // change coordinator
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    // change initiator
    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                {/* Change Board */}
                <ApproveChangeRequest {...{ openApprove, handleCloseApprove, id }} />
                <RejectChangeRequest {...{ openReject, handleCloseReject, id }} />

                {/* Change Coordinator */}
                <ReviewChangeRequest {...{ openReview, handleCloseReview, id, 
                    data: {
                        riskFactors: state.riskFactors,
                        opportunityFactors: state.opportunityFactors,
                        decision: state.decision,
                        trainingRequired: state.trainingRequired,
                        recommendation: state.recommendation,
                        path: `change/request/view/${id}`,
                    }
                 }} />
                <DeclineChangeRequest {...{ openDecline, handleCloseDecline, id }} />

                <Typography variant="overline" className={classes.title}>
                    View Change Request
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/change/requests")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Paper variant="outlined" className={classes.status}>
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        Document No.
                                    </Typography>
                                    <Divider className={classes.divider2} orientation="vertical" />
                                    {loading ? "Loading..." : state.documentNo}
                                    
                                </Paper>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;
                            <Grid item>
                                <Paper
                                    variant="outlined"
                                    className={clsx(
                                        state.status === "draft"
                                            ? classes.statusYellow
                                            : state.status === "submitted"
                                                ? classes.statusOrange
                                                : state.status === "reviewed"
                                                    ? classes.statusGreen
                                                    : (state.status === "approved" || state.status === "sent")
                                                        ? classes.statusDarkGreen
                                                        : classes.statusRed,
                                        classes.status
                                    )}
                                >
                                
                                <Typography variant="overline" className={classes.statusTitle}>
                                    Status
                                </Typography>
                                <Divider className={classes.divider2} orientation="vertical" />
                                <Typography variant="overline" className={classes.statusTitle}>
                                    {loading ? "Loading..." : state.status}
                                </Typography>

                                </Paper>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </Grid>

                <Paper variant="outlined" square spacing={3} className={classes.paper}>
                    <div>
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Employee Name"
                                    name="employeeName"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={(state.createdBy && state.createdBy.userName) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                label="Change Request No"
                                name="requestNo"
                                variant="outlined"
                                fullWidth
                                value={(state.requestNo && state.requestNo) || ""}
                                disabled
                                required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Division/Subdivision"
                                    name="division"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={(state.division && state.division.name) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    className={classes.marginBottom}
                                    autoOk
                                    format="DD/MM/yyyy"
                                    views={["year", "month", "date"]}
                                    label="Request Date"
                                    fullWidth
                                    required
                                    inputVariant="outlined"
                                    value={(state.requestDate && state.requestDate) || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    multiline
                                    rows={2}
                                    label="Change Request Description"
                                    name="requestDescription"
                                    value={state.requestDescription || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    multiline
                                    rows={2}
                                    label="Business or Technical Justification"
                                    name="justification"
                                    value={state.justification || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Resource Requirements"
                                    name="resourceRequirements"
                                    value={state.resourceRequirements || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Budget Projection"
                                    name="budgetProjection"
                                    value={state.budgetProjection || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="outlined"
                                    component="fieldset"
                                >
                                    <FormLabel style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                        Priority 
                                    </FormLabel>
                                    <RadioGroup row disabled>
                                        <FormControlLabel
                                            value="Critical"
                                            control={<Radio checked={(state.priority === "Critical") ? true : false} />}
                                            label="Critical"
                                            name="priority"
                                        />
                                        <FormControlLabel
                                            value="High"
                                            control={<Radio checked={(state.priority === "High") ? true : false} />}
                                            label="High"
                                            name="priority"
                                        />
                                        <FormControlLabel
                                            value="Medium"
                                            control={<Radio checked={(state.priority === "Medium") ? true : false} />}
                                            label="Medium"
                                            name="priority"
                                        />
                                        <FormControlLabel
                                            value="Low"
                                            control={<Radio checked={(state.priority === "Low") ? true : false} />}
                                            label="Low"
                                            name="priority"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Timelines Added */}
                        {(state.timelines && state.timelines.length > 0) ? 
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Project Timelines
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper0}>
                                {(state.timelines && state.timelines.length > 0) ? state.timelines.map((sitem, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={9}>
                                            <label style={{ fontWeight: "bold" }}>Action</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="action"
                                                value={sitem.action || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <label style={{ fontWeight: "bold" }}>Due Date</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="dueDate"
                                                value={sitem.dueDate || ""}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                )) : <>
                                    <Typography variant="overline" className={classes.title2}>
                                        No Project Timeline added yet
                                    </Typography>
                                </>}
                            </Paper>
                        </> : null }

                        {/* Outcomes Added */}
                        {(state.outcomes && state.outcomes.length > 0) ? 
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Expected Outcomes
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper0}>
                                {(state.outcomes && state.outcomes.length > 0) ? state.outcomes.map((sitem, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={12}>
                                            <label style={{ fontWeight: "bold" }}>Description</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="description"
                                                value={sitem.description || ""}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                )) : <>
                                    <Typography variant="overline" className={classes.title2}>
                                        No Expected Outcome added yet
                                    </Typography>
                                </>}
                            </Paper>
                        </> : null }

                        {((isCoordinator || isAdmin) && (state.status !== "submitted" || state.status !== "draft")) ? 
                            <>
                                <Typography variant="overline" className={classes.title}>
                                    Change Impact Assessment
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper1}>

                                    <Grid container spacing={2} className={classes.grid}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                multiline
                                                rows={2}
                                                label="Risk Factors"
                                                name="riskFactors"
                                                value={state.riskFactors || ""}
                                                onChange={handleChange}
                                                disabled={state.status === "reviewed" || state.status === "approved"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                multiline
                                                rows={2}
                                                label="Opportunity Factors"
                                                name="opportunityFactors"
                                                value={state.opportunityFactors || ""}
                                                onChange={handleChange}
                                                disabled={state.status === "reviewed" || state.status === "approved"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl
                                                fullWidth
                                                required
                                                variant="outlined"
                                                component="fieldset"
                                                disabled={state.status === "reviewed" || state.status === "approved"}
                                            >
                                                <FormLabel style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                    Change Request Resolution
                                                </FormLabel>
                                                <RadioGroup row onChange={handleChange} >
                                                    <FormControlLabel
                                                        value="Approved"
                                                        control={<Radio checked={(state.decision === "Approved") ? true : false} />}
                                                        label="Approved"
                                                        name="decision"
                                                    />
                                                    <FormControlLabel
                                                        value="On Hold"
                                                        control={<Radio checked={(state.decision === "On Hold") ? true : false} />}
                                                        label="On Hold"
                                                        name="decision"
                                                    />
                                                    <FormControlLabel
                                                        value="Denied"
                                                        control={<Radio checked={(state.decision === "Denied") ? true : false} />}
                                                        label="Denied"
                                                        name="decision"
                                                    />
                                                    <FormControlLabel
                                                        value="Deferred"
                                                        control={<Radio checked={(state.decision === "Deferred") ? true : false} />}
                                                        label="Deferred"
                                                        name="decision"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormControl
                                                fullWidth
                                                required
                                                variant="outlined"
                                                component="fieldset"
                                                disabled={state.status === "reviewed" || state.status === "approved"}
                                            >
                                                <FormLabel style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                    Training Required
                                                </FormLabel>
                                                <RadioGroup row onChange={handleChange}>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio checked={(state.trainingRequired === "Yes") ? true : false} />}
                                                        label="Yes"
                                                        name="trainingRequired"
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio checked={(state.trainingRequired === "No") ? true : false} />}
                                                        label="No"
                                                        name="trainingRequired"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormControl
                                                fullWidth
                                                required
                                                variant="outlined"
                                                component="fieldset"
                                                disabled={state.status === "reviewed" || state.status === "approved"}
                                            >
                                                <FormLabel style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                    Decision Recommendation
                                                </FormLabel>
                                                <RadioGroup row onChange={handleChange}>
                                                    <FormControlLabel
                                                        value="CEO"
                                                        control={<Radio checked={(state.recommendation === "CEO") ? true : false} />}
                                                        label="CEO"
                                                        name="recommendation"
                                                    />
                                                    <FormControlLabel
                                                        value="Advisory Board"
                                                        control={<Radio checked={(state.recommendation === "Advisory Board") ? true : false} />}
                                                        label="Advisory Board"
                                                        name="recommendation"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </> : null
                        }

                        <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                            style={{ marginBottom: 5 }}
                        >
                            <Grid item>
                                <Typography variant="overline" className={classes.title2}>
                                    Audit Trail
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Icon className="fas fa-clipboard-list" color="disabled" />
                            </Grid>
                        </Grid>
                        <Paper variant="outlined" square className={classes.paper}>
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                {state.status === "draft" && (
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Created By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdAt}</span>
                                        </Paper>
                                    </Grid>
                                )}
                                {state.status !== "draft" && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Submitted By</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedAt}</span>
                                            </Paper>
                                        </Grid>
                                        {(state.reviewedAt && state.reviewedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Reviewed By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.reviewedBy && state.reviewedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.reviewedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.reviewComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                        {(state.declinedAt && state.declinedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Declined By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declineComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                        {(state.approvedAt && state.approvedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Approved By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.approvedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.approveComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                        {(state.rejectedAt && state.rejectedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Rejected By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Paper>

                        {(isCoordinator || isAdmin) && state.status === "submitted" ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <div className={classes.centered}>
                                        <Paper className={classes.paperBlack}>
                                            <HelpOutlineIcon fontSize="large" />
                                            <Typography placeholder="Info" className={classes.info2}>
                                                This change request form review or decline is to be done by the Change Coordinator.
                                            </Typography>
                                        </Paper>
                                    </div>
                                    <ButtonGroup>
                                        <Button
                                            onClick={handleOpenReview}
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            disabled={loading || validCheck() }
                                        >
                                            Review
                                        </Button>
                                        <Button
                                            onClick={handleOpenDecline}
                                            className={classes.saveBtn}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Decline
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                        {(isBoard || isAdmin) && state.status === "reviewed" ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <div className={classes.centered}>
                                        <Paper className={classes.paperBlack}>
                                            <HelpOutlineIcon fontSize="large" />
                                            <Typography placeholder="Info" className={classes.info2}>
                                                This change request decision approval or rejection is to be done by the Chief Executive Officer.
                                            </Typography>
                                        </Paper>
                                    </div>
                                    <ButtonGroup>
                                        <Button
                                            onClick={handleOpenApprove}
                                            className={classes.saveBtn2}
                                            variant="contained"
                                        >
                                            Approve
                                        </Button>
                                        <Button
                                            onClick={handleOpenReject}
                                            className={classes.saveBtn}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Reject
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                    </div>
                </Paper>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewChangeRequest;
