import React, { useState } from "react";
import {
  TextField,
  makeStyles,
  Typography,
  Grid,
  Divider,
  Button,
  Backdrop,
  InputAdornment,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { formatAmount } from "../../../utils";
import { getMonthlyBudgetLineById } from "../../../actions/monthlyBudgetAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  btn: {
    marginBottom: 15,
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  table: {
    minWidth: 500,
  },
  divider: {
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  grid: {
    marginTop: 15,
  },
  textField2: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  textFieldSmall: {
    width: 100,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const INIT_STATE = {
  employeeName: "",
  head: "",
  subHead: "",
  item: "",
  description: "",
  costCenter: "",
  periods: [],
  year: "",
  subTotal: 0,
  amount: 0,
  type: "",
};

const ViewMonthlyBudgetLine = ({
  getMonthlyBudgetLineById,

  monthlyBudgetLine,
}) => {
  const classes = useStyles();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [state, setState] = useState(INIT_STATE);

  const history = useHistory();
  const { id } = useParams();

  const monthlyBudgetLineCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getMonthlyBudgetLineById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [id, getMonthlyBudgetLineById]);

  React.useEffect(() => {
    monthlyBudgetLineCallback();
  }, [monthlyBudgetLineCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: monthlyBudgetLine && monthlyBudgetLine.employee,
      head: monthlyBudgetLine && monthlyBudgetLine.head,
      subHead: monthlyBudgetLine && monthlyBudgetLine.subHead,
      item: monthlyBudgetLine && monthlyBudgetLine.item,
      costCenter: monthlyBudgetLine && monthlyBudgetLine.costCenter,
      description: monthlyBudgetLine && monthlyBudgetLine.description,
      amount:
        (monthlyBudgetLine && formatAmount(monthlyBudgetLine.amount)) ||
        formatAmount(0),
      year: monthlyBudgetLine && monthlyBudgetLine.year,
      month: monthlyBudgetLine && monthlyBudgetLine.month,
      type: monthlyBudgetLine && monthlyBudgetLine.type,
    }));
  }, [monthlyBudgetLine]);

  return (
    <div>
      <Typography variant="overline" className={classes.title}>
        View BudgetLine /{" "}
        {state.type === "supplementary" ? "Supplementary" : "Monthly"}
      </Typography>
      <Button
        onClick={() => history.goBack()}
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        className={classes.backBtn}
      >
        Back
      </Button>

      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Grid item xs={12} md={6}>
          <TextField
            label="Employee Name"
            fullWidth
            variant="outlined"
            className={classes.textField}
            name="employeeName"
            value={state.employeeName || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Expense Head"
            fullWidth
            variant="outlined"
            name="head"
            className={classes.textField}
            value={state.head || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Expense SubHead"
            fullWidth
            variant="outlined"
            name="subHead"
            className={classes.textField}
            value={state.subHead || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Budget Category"
            fullWidth
            variant="outlined"
            name="item"
            className={classes.textField}
            value={state.item || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Description"
            fullWidth
            variant="outlined"
            name="description"
            className={classes.textField}
            value={state.description || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Year"
            fullWidth
            variant="outlined"
            name="year"
            className={classes.textField}
            value={state.year || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Month"
            fullWidth
            variant="outlined"
            name="month"
            className={classes.textField}
            value={state.month || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Cost Center"
            fullWidth
            variant="outlined"
            name="costCenter"
            className={classes.textField}
            value={state.costCenter || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Amount"
            variant="outlined"
            disabled
            value={state.amount}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">&#8358;</InputAdornment>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                </>
              ),
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  monthlyBudgetLine:
    state.monthlyBudget.monthlyBudgetLine &&
    state.monthlyBudget.monthlyBudgetLine.data,
});

const mapDispatchToProps = {
  getMonthlyBudgetLineById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewMonthlyBudgetLine);
