import React, { useState, useEffect, useCallback } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    AppBar,
    IconButton,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Tooltip,
    Toolbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { getDivisions } from "../../../../actions/divisionActions";
import { getGroupsByModule } from "../../../../actions/groupsAction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "../../../../utils";
import Feedback from "../../../atoms/Feedback";
import {
    getUserById,
    getUsers,
    updateUser,
    getProfile,
} from "../../../../actions/userActions";
import { getJobTitles } from "../../../../actions/jobTitleActions";
import {
    getAllModules,
    getAllModulesAndSubmodules,
} from "../../../../actions/moduleAction";

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) =>
                String.fromCodePoint(char.charCodeAt(0) + 127397)
            )
        : isoCode;
}

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: 2,
        },
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 6px",
        },
    },
    btn: {
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    firstname: "",
    lastname: "",
    division: "",
    divisions: [],
    email: "",
    employeeId: "",
    jobTitle: "",
    phone: "",
    countryCode: "",
    module: "",
};

const EditUserModal = ({
    openEdit,
    handleCloseEdit,
    id,
    getUserById,
    user,
    error,
    getDivisions,
    getJobTitles,
    jobTitles,
    getProfile,
    getUsers,
    updateUser,
    getAllModulesAndSubmodules,
}) => {
    const classes = useStyles();
    const [state, setState] = useState(INIT_STATE);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(null);
    const [loadTitles, setLoadTitles] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [allTitles, setAllTitles] = useState([]);
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        getAllModulesAndSubmodules();
    }, [getAllModulesAndSubmodules]);

    const getUserCallback = useCallback(() => {
        setOpenBackdrop((prev) => !prev);
        getUserById(id).then(() => {
            setOpenBackdrop(false);
        });
    }, [getUserById, id]);

    useEffect(() => {
        getUserCallback();
    }, [getUserCallback]);

    useEffect(() => {
        setLoad((prev) => !prev);
        getDivisions().then((data) => {
            setLoad(false);
            setState((prevState) => ({
                ...prevState,
                divisions: data.data,
            }));
        });
    }, [getDivisions]);

    useEffect(() => {
        let code = countries
            .map((country) => country)
            .filter(
                (item) =>
                    item.phone ===
                    (user && user.countryCode && user.countryCode.split("+")[1])
            );

        setState((prev) => ({
            ...prev,
            firstname: user && user.firstName,
            lastname: user && user.lastName,
            division: user && user.division && user.division._id,
            email: user && user.email,
            employeeId: user && user.employeeId,
            jobTitle: user && user.jobTitle && user.jobTitle._id,
            phone: user && user.phone,
        }));

        setCode(code && code[0]);
    }, [user]);

    const jobTitleCallback = React.useCallback(() => {
        setLoadTitles((prev) => !prev);
        getJobTitles().then(() => {
            setLoadTitles(false);
        });
    }, [getJobTitles]);

    useEffect(() => {
        jobTitleCallback();
    }, [jobTitleCallback]);

    useEffect(() => {
        setAllTitles(jobTitles);
    }, [jobTitles]);

    const defaultProps = {
        options: countries,
        getOptionLabel: (option) => option.phone,
        renderOption: (option) => (
            <React.Fragment>
                {option.label} ({option.code}) +{option.phone}
            </React.Fragment>
        ),
    };

    const handleChange = (e) => {
        e.persist();
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading((prev) => !prev);
        const data = {
            id,
            firstName: state.firstname,
            lastName: state.lastname,
            email: state.email,
            employeeId: state.employeeId,
            division: state.division,
            jobTitle: state.jobTitle,
            phone: state.phone,
            countryCode: code ? `+${code.phone}` : "",
        };

        try {
            const doc = await new Promise((resolve) => {
                resolve(updateUser(data));
            });
            if (doc.success === true) {
                setLoading(false);
                setFeed({
                    loading: false,
                    open: !feed.open,
                    message: "User data updated successfully",
                    success: true,
                });
                getUserById(id);
                getUsers();
                getProfile();
                handleCloseEdit();
            } else {
                setLoading(false);
                setFeed({
                    loading: false,
                    open: !feed.open,
                    message:
                        doc.error ||
                        "An Unexpected error has occurred, please contact admin.",
                    success: false,
                });
                handleCloseEdit();
            }
        } catch (error) {
            setLoading(false);
            setFeed({
                loading: false,
                open: !feed.open,
                message:
                    error.error ||
                    "An Unexpected error has occurred, please contact admin.",
                success: false,
            });
        }
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <Dialog
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                fullScreen
            >
                <AppBar
                    className={classes.appBar}
                    variant="elevation"
                    position="fixed"
                    color="default"
                >
                    <Toolbar>
                        <Tooltip title="close" arrow>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseEdit}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <div className={classes.container}>
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="overline" className={classes.title}>
                            Edit Employee Details
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <form onSubmit={handleUpdate}>
                            <Paper className={classes.paper} variant="outlined">

                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            id="firstname"
                                            name="firstname"
                                            label="FirstName"
                                            fullWidth
                                            onChange={handleChange}
                                            value={
                                                openBackdrop ? "Loading..." : state.firstname || ""
                                            }
                                            disabled={openBackdrop}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            id="lastname"
                                            name="lastname"
                                            label="LastName"
                                            fullWidth
                                            onChange={handleChange}
                                            value={
                                                openBackdrop ? "Loading..." : state.lastname || ""
                                            }
                                            disabled={openBackdrop}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="division-label-id">Division</InputLabel>
                                            <Select
                                                labelId="division-label-id"
                                                id="division"
                                                label="Division"
                                                inputProps={{
                                                    id: "division",
                                                    name: "division",
                                                }}
                                                onChange={handleChange}
                                                value={state.division || ""}
                                                name="division"
                                            >
                                                <MenuItem value="" disabled>
                                                    --- Select Division ---
                                                </MenuItem>
                                                {load ? (
                                                    <MenuItem>Loading</MenuItem>
                                                ) : (
                                                    state.divisions &&
                                                    state.divisions.map((division) => (
                                                        <MenuItem key={division._id} value={division._id}>
                                                            {division.name}
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            type="email"
                                            id="email"
                                            name="email"
                                            label="Email"
                                            fullWidth
                                            onChange={handleChange}
                                            value={openBackdrop ? "Loading..." : state.email || ""}
                                            disabled={openBackdrop}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            id="employeeId"
                                            name="employeeId"
                                            label="EmployeeId"
                                            fullWidth
                                            onChange={handleChange}
                                            value={
                                                openBackdrop ? "Loading..." : state.employeeId || ""
                                            }
                                            disabled={openBackdrop}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="division-label-id">
                                                Job Title
                                            </InputLabel>
                                            <Select
                                                labelId="division-label-id"
                                                id="jobTitle"
                                                label="Job Title"
                                                inputProps={{
                                                    id: "jobTitle",
                                                    name: "jobTitle",
                                                }}
                                                onChange={handleChange}
                                                value={state.jobTitle || ""}
                                            >
                                                <MenuItem value="" disabled>
                                                    --- Select JobTitle ---
                                                </MenuItem>
                                                {loadTitles ? (
                                                    <MenuItem>Loading</MenuItem>
                                                ) : (
                                                    allTitles &&
                                                    allTitles.map((title) => (
                                                        <MenuItem key={title._id} value={title._id}>
                                                            {title.name}
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            id="phone"
                                            name="phone"
                                            label="Phone"
                                            fullWidth
                                            onChange={handleChange}
                                            value={openBackdrop ? "Loading..." : state.phone || ""}
                                            disabled={openBackdrop}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            className={classes.textField}
                                            id="country-code"
                                            {...defaultProps}
                                            value={code || null}
                                            onChange={(event, newValue) => {
                                                setCode(newValue);
                                            }}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    <span>{countryToFlag(option.code)}</span>
                                                    {option.label} ({option.code}) +{option.phone}
                                                </React.Fragment>
                                            )}
                                            style={{ marginTop: -15 }}
                                            autoComplete
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Country Code"
                                                    margin="normal"
                                                    fullWidth
                                                    variant="outlined"
                                                    // error={!code ? true : false}
                                                    value={code}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "disabled",
                                                        name: "countryCode",
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Divider />
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        style={{
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                            marginTop: 1,
                                            textAlign: "center",
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            className={classes.saveBtn}
                                            disabled={loading}
                                            endIcon={
                                                loading && (
                                                    <CircularProgress size={20} color="primary" />
                                                )
                                            }
                                        >
                                            {loading ? "Loading..." : "Update"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </form>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.user.data,
    error: state.user.error,
    jobTitles: state.jobTitle.jobTitles.data,
    groups: state.groups.groups,
    modules: state.module.modules,
    allmodules: state.module.allmodules,
});

export default connect(mapStateToProps, {
    getUserById,
    getDivisions,
    getJobTitles,
    getGroupsByModule,
    getUsers,
    updateUser,
    getProfile,
    getAllModules,
    getAllModulesAndSubmodules,
})(EditUserModal);
