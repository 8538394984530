import React from "react";
import { Typography } from "@material-ui/core";

export const Quotes = () => {
  return (
    <>
      <Typography>Quotes</Typography>
    </>
  );
};
