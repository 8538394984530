import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {
  makeStyles,
  Grid,
  Hidden,
  Button,
  Divider,
  fade,
  Backdrop,
  // Avatar,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";

import clsx from "clsx";

import { connect } from "react-redux";
import Feedback from "../atoms/Feedback";
import { useHistory } from "react-router-dom";
import {
  getResetUserDataByToken,
  resetPassword,
} from "../../actions/resetPasswordAction";
import Logo from "../layout/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxHeight: "100vh",
    height: "100vh",
    fontFamily: "Quicksand",
  },
  title: {
    color: "#6F6767",
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      // color: fade(theme.palette.background.paper, 1),
      color: "#000",
    },
  },
  gridBackground: {
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(/img/highrise.jpg)",
    backgroundColor: "#485461",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",

    padding: theme.spacing(6),
    color: fade(theme.palette.background.paper, 0.8),
    "&:before": {
      content: "",
      backgroundColor: "pink",
      display: "block",
      width: "100%",
      height: "100%",
    },
  },
  block: {
    display: "block",
  },
  block2: {
    display: "block",
    color: theme.palette.background.paper,
  },
  leftGrid: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      // backgroundImage:
      //   "linear-gradient(0deg, rgb(53, 55, 55), rgba(208, 208, 208, 0.91)), url(/img/highrise.jpg)",
      // backgroundRepeat: "no-repeat",
      // backgroundPosition: "center",
      // backgroundSize: "cover",
    },
  },
  textField: {
    margin: "10px 0",
    fontFamily: "Quicksand",
    // [theme.breakpoints.down("sm")]: {
    //   "& label": {
    //     color: "#fff",
    //   },
    //   "& ::placeholder": {
    //     color: "#fff",
    //   },
    //   "& input": {
    //     color: theme.palette.background.paper,
    //   },
    // },
  },
  btn: {
    marginTop: 10,
    padding: theme.spacing(1.5),
  },
  welcome: {
    textTransform: "uppercase",
    fontFamily: "Rubik",
    fontWeight: 900,
  },
  content: {
    color: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  helperText: {
    lineHeight: 1.5,
    fontWeight: 400,
  },
  logo: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: "auto",
    marginBottom: 20,
    boxShadow: "1px 1px 5px rgba(0,0,0,0.1)",

    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: 20,
    },
  },
  logoText: {
    fontWeight: 900,
    // fontFamily: "Rubik",
    fontFamily: "Carter One",
    letterSpacing: 1.5,
    fontSize: 26,
    color:
      theme.palette.type === "light"
        ? fade(theme.palette.common.black, 0.75)
        : "#fff",
    textDecoration: "none",
    display: "block",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  centered: {
    textAlign: "center",
  },
}));

const INIT_STATE = {
  name: "",
  email: "",
  newpassword: "",
  confirmnewpassword: "",
};

const PasswordReset = ({ getResetUserDataByToken, match, resetPassword }) => {
  const classes = useStyles();
  const [state, setState] = useState(INIT_STATE);
  const [isPassword, showIsPassword] = useState(false);
  const [isConfirmPassword, showIsConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const token = match.params.token;

  const userCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getResetUserDataByToken(token)
      .then((doc) => {
        setState((prev) => ({
          ...prev,
          name:
            (doc.data && doc.data.userName) ||
            (doc.data && doc.data.companyName),
          email: doc.data && doc.data.email,
        }));
        setOpenBackdrop(false);
      })
      .catch((err) => console.log(err));
  }, [token, getResetUserDataByToken]);

  useEffect(() => {
    userCallback();
  }, [userCallback]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      token,
      password: state.newpassword,
    };

    setLoading((prev) => !prev);
    resetPassword(data)
      .then((doc) => {
        if (doc.success === true) {
          setLoading(false);

          setFeed({
            loading: false,
            open: !feed.open,
            message: `Password Set successfully`,
            success: true,
          });
          setTimeout(() => {
            history.push("/login");
          }, 1500);
        } else if (state.newpassword !== state.confirmnewpassword) {
          setLoading(false);

          setFeed({
            loading: false,
            open: !feed.open,
            message: `Password does not match`,
            success: false,
          });
        } else {
          setLoading(false);

          setFeed({
            loading: false,
            open: !feed.open,
            message: doc.error,
            success: false,
          });
        }
      })
      .catch((err) => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          message: err.error,
          success: false,
        });
      });
  };

  const showVisibility = () => {
    showIsPassword(!isPassword);
  };

  const showConfirmVisibility = () => {
    showIsConfirmPassword(!isConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => {
    return (
      !state.email ||
      !state.name ||
      !state.newpassword ||
      !state.confirmnewpassword
    );
  };

  return (
    <Grid container className={classes.root}>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Grid item xs={12} md={5} className={classes.leftGrid}>
        <div className={classes.centered}>
          <Logo path="#" size={26} />
        </div>
        <Typography
          className={clsx(classes.block, classes.title)}
          variant="overline"
          gutterBottom
        >
          Reset Your Password
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit}>
          <TextField
            id="name"
            name="name"
            label="Name"
            placeholder="Name"
            variant="outlined"
            fullWidth
            type="text"
            value={state.name || ""}
            onChange={handleChange}
            className={classes.textField}
            disabled
          />
          <TextField
            id="email"
            name="email"
            label="Email"
            placeholder="Email"
            variant="outlined"
            fullWidth
            type="email"
            value={state.email || ""}
            onChange={handleChange}
            className={classes.textField}
            disabled
          />
          <TextField
            id="newpassword"
            name="newpassword"
            label="New Password"
            placeholder="Enter New Password"
            variant="outlined"
            fullWidth
            type={isPassword ? "text" : "password"}
            className={classes.textField}
            value={state.newpassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {isPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={
              <span className={classes.helperText}>
                Password needs to have at least one lower case, one uppercase,
                one number, one special character, and must be at least 8
                characters but no more than 35
              </span>
            }
          />
          <TextField
            id="confirmnewpassword"
            name="confirmnewpassword"
            label="Confirm New Password"
            placeholder="Confirm New Password"
            variant="outlined"
            fullWidth
            type={isConfirmPassword ? "text" : "password"}
            className={classes.textField}
            value={state.confirmnewpassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showConfirmVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {isConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Grid container justify="center">
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.saveBtn}
              disabled={validCheck() || loading}
              endIcon={
                loading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : null
              }
            >
              {loading ? "Loading..." : "Reset"}
            </Button>
          </Grid>
        </form>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} md={7} className={classes.gridBackground}>
          <Typography
            variant="overline"
            style={{
              fontWeight: 900,
              fontSize: 28,
              display: "block",
              letterSpacing: 1.5,
            }}
          >
            Reset Your Password
          </Typography>
          <Typography
            variant="overline"
            className={classes.block2}
            gutterBottom
            style={{ fontWeight: 900 }}
          >
            russelsmith's official enterprise resource planning application
          </Typography>
          <Divider style={{ background: "#b6b8b9" }} />

          <Typography
            className={classes.block2}
            variant="overline"
            style={{ fontWeight: 900 }}
          >
            Reset your password and continue to enjoy the rs edge experience, we
            love having you around.
          </Typography>
        </Grid>
      </Hidden>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default connect(null, {
  getResetUserDataByToken,
  resetPassword,
})(PasswordReset);
