import * as types from "../../types/purchasing";

const initState = {
    purchase_orders: [],
    vendor_purchase_orders: [],
    purchase_order: {},
    error: null,
    success: false,
    loading: false,
};

const purchaseOrderReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_PURCHASE_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_PURCHASE_ORDERS:
            return {
                ...state,
                loading: false,
                success: true,
                purchase_orders: action.payload.data,
            };
        case types.GET_ALL_PURCHASE_ORDERS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_VENDOR_PURCHASE_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_VENDOR_PURCHASE_ORDERS:
            return {
                ...state,
                loading: false,
                success: true,
                vendor_purchase_orders: action.payload.data,
            };
        case types.GET_VENDOR_PURCHASE_ORDERS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                success: true,
                purchase_order: action.payload.data,
            };
        case types.GET_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                success: true,
                purchase_order: state.purchase_orders.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                success: false,
                purchase_orders: state.purchase_orders.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                purchase_order: action.payload.data,
            };
        case types.SUBMIT_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.APPROVE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                purchase_order: action.payload.data,
            };
        case types.APPROVE_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                purchase_order: action.payload.data,
            };
        case types.REJECT_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                purchase_order: action.payload.data,
            };
        case types.ACCEPT_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_PURCHASE_ORDER:
            return {
                ...state,
                loading: false,
                purchase_order: action.payload.data,
            };
        case types.DECLINE_PURCHASE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_PURCHASE_ORDER:
            return {
                purchase_order: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default purchaseOrderReducer;
