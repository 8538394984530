import {
  GET_GROUPS_BY_MODULE,
  CREATE_GROUP,
  GET_ALL_GROUPS,
  DELETE_GROUP,
  UPDATE_GROUP,
  GET_GROUP,
} from "../types/";

const INIT_STATE = {
  groups: [],
  group: {},
};

const groupsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case GET_GROUPS_BY_MODULE:
      return {
        ...state,
        groups: action.payload,
      };
    case GET_GROUP:
      return {
        ...state,
        group: action.payload,
      };

    case DELETE_GROUP:
      return {
        ...state,
        groups: state.groups.filter((grp) => grp._id !== action.payload),
      };
    case CREATE_GROUP:
      return {
        ...state,
        groups: [action.payload, ...state.groups],
      };

    case UPDATE_GROUP:
      return {
        ...state,
        groups: state.groups.map((grp) =>
          grp._id === action.payload._id ? (grp = action.payload) : grp
        ),
      };

    default:
      return state;
  }
};

export default groupsReducer;
