import {
  CREATE_CASH_RECOVERY,
  DELETE_CASH_RECOVERY,
  GET_ALL_CASH_RECOVERY,
  GET_CASH_RECOVERY_BY_ID,
  PPM_RECOVER_CASH,
  FINANCE_ALLOW_CASH_RECOVERY,
  FINANCE_EXEMPT_CASH_RECOVERY,
  CASH_RECOVERY_ERROR,
  CASH_RECOVERY_LOG_ERROR,
  GET_ALL_CASH_RECOVERY_LOG,
  FINANCE_COMPLETE_RECOVERY,
  FINANCE_COMPLETE_RECOVERY_FAIL,
  FINANCE_COMPLETE_RECOVERY_REQUEST,
  PPM_COMPLETE_RECOVERY,
  PPM_COMPLETE_RECOVERY_FAIL,
  PPM_COMPLETE_RECOVERY_REQUEST,
} from "../types/";
import axios from "axios";

let token = localStorage.getItem("token:key");

export const getAllCashRecovery = () => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/all`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_CASH_RECOVERY,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    dispatch({
      type: CASH_RECOVERY_ERROR,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getAllCashRecoveryLog = () => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/log`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_CASH_RECOVERY_LOG,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    dispatch({
      type: CASH_RECOVERY_LOG_ERROR,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const createCashRecovery = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_CASH_RECOVERY,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.message,
      });
    }
  }
};

export const getCashRecoveryById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_CASH_RECOVERY_BY_ID,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.message,
      });
    }
  }
};

export const financeAllowCashRecovery = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/allow/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FINANCE_ALLOW_CASH_RECOVERY,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.message,
      });
    }
  }
};

export const financeExemptCashRecovery = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/exempt/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FINANCE_EXEMPT_CASH_RECOVERY,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.message,
      });
    }
  }
};

export const peoplesRecoverCashRecovery = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/recover/${id}`;
    const res = await axios.put(
      URL,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: PPM_RECOVER_CASH,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_RECOVERY_LOG_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_RECOVERY_LOG_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_RECOVERY_LOG_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteCashRecovery = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/${id}`;
    const res = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_CASH_RECOVERY,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_RECOVERY_ERROR,
        error: error.message,
      });
    }
  }
};

export const financeCompleteRecovery = (body) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/complete/${body.id}`;
    dispatch({
      type: FINANCE_COMPLETE_RECOVERY_REQUEST,
    });
    const { data } = await axios.put(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FINANCE_COMPLETE_RECOVERY,
      payload: data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: FINANCE_COMPLETE_RECOVERY_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const ppmCompleteRecovery = (body) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/recovery/acknowledge/${body.id}`;
    dispatch({
      type: PPM_COMPLETE_RECOVERY_REQUEST,
    });
    const { data } = await axios.put(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: PPM_COMPLETE_RECOVERY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PPM_COMPLETE_RECOVERY_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
