import React from "react";
import { connect } from "react-redux";

import Skeleton from "@material-ui/lab/Skeleton";

import UsersDashboard from "./UsersDashboard";
import VendorDashboard from "./VendorDashboard";

const Dashboard = ({ vendor, profile, weather, error }) => {
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader((prev) => !prev);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  }, []);

  let isVendor =
    vendor &&
    vendor &&
    vendor.groups &&
    vendor.groups.some((grp) => grp.name === "vendor");
  return (
    <div>
      {isVendor ? (
        loader ? (
          <>
            <Skeleton
              variant="text"
              width={260}
              height={30}
              style={{
                background: "#e0e0e0",
                padding: 30,
                borderRadius: 3,
                marginBottom: 10,
              }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={518}
              style={{ background: "#e0e0e0", padding: 10, borderRadius: 3 }}
            />
          </>
        ) : (
          <VendorDashboard weather={weather} />
        )
      ) : profile && loader ? (
        <>
          <Skeleton
            variant="text"
            width={260}
            height={30}
            style={{
              background: "#e0e0e0",
              padding: 30,
              borderRadius: 3,
              marginBottom: 10,
            }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={518}
            style={{ background: "#e0e0e0", padding: 10, borderRadius: 3 }}
          />
        </>
      ) : (
        <UsersDashboard weather={weather} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  vendor: state.vendors.vendor,
  profile: state.user.profile,
  error: state.user.error,
});

export default connect(mapStateToProps, null)(Dashboard);
