import * as types from "../../types/people";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/change`;

//////////////////////////////////////////////
//////////// Change Request Forms ////////////
//////////////////////////////////////////////
export const createCRF = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_CRF_REQUEST,
        });
        const { data } = await axios.post(`${URL}/crf`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_CRF,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_CRF_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitCRF = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_CRF_REQUEST,
        });
        const { data } = await axios.put(`${URL}/crf/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_CRF,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_CRF_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCRFs = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_CRFS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/crf`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_CRFS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_CRFS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCRF = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_CRF_REQUEST,
        });
        const { data } = await axios.get(`${URL}/crf/${id}/single`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_CRF,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_CRF_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getApprovedCRFs = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_APPROVED_CRFS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/crf/approved`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_APPROVED_CRFS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_APPROVED_CRFS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateCRF = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_CRF_REQUEST,
        });
        const { data } = await axios.put(`${URL}/crf/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_CRF,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_CRF_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteCRF = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_CRF_REQUEST,
        });
        await axios.delete(`${URL}/crf/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_CRF,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_CRF_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const approveCRF = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_CRF_REQUEST,
        });
        const { data } = await axios.put(`${URL}/crf/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_CRF,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_CRF_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectCRF = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_CRF_REQUEST,
        });
        const { data } = await axios.put(`${URL}/crf/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_CRF,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_CRF_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const reviewCRF = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_CRF_REQUEST,
        });
        const { data } = await axios.put(`${URL}/crf/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_CRF,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_CRF_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineCRF = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_CRF_REQUEST,
        });
        const { data } = await axios.put(`${URL}/crf/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        
        dispatch({
            type: types.DECLINE_CRF,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_CRF_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


//////////////////////////////////////////////
///////// Change Impact Monitoring ///////////
//////////////////////////////////////////////
export const createCIM = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_CIM_REQUEST,
        });
        const { data } = await axios.post(`${URL}/impact`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_CIM,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_CIM_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitCIM = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_CIM_REQUEST,
        });
        const { data } = await axios.put(`${URL}/impact/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_CIM,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_CIM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCIMs = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_CIMS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/impact`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_CIMS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_CIMS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCIM = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_CIM_REQUEST,
        });
        const { data } = await axios.get(`${URL}/impact/${id}/single`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_CIM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_CIM_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getApprovedCIMs = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_APPROVED_CIMS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/impact/approved`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_APPROVED_CIMS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_APPROVED_CIMS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateCIM = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_CIM_REQUEST,
        });
        const { data } = await axios.put(`${URL}/impact/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_CIM,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_CIM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteCIM = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_CIM_REQUEST,
        });
        await axios.delete(`${URL}/impact/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_CIM,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_CIM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const approveCIM = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_CIM_REQUEST,
        });
        const { data } = await axios.put(`${URL}/impact/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_CIM,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_CIM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectCIM = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_CIM_REQUEST,
        });
        const { data } = await axios.put(`${URL}/impact/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_CIM,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_CIM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const reviewCIM = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_CIM_REQUEST,
        });
        const { data } = await axios.put(`${URL}/impact/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_CIM,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_CIM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineCIM = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_CIM_REQUEST,
        });
        const { data } = await axios.put(`${URL}/impact/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        
        dispatch({
            type: types.DECLINE_CIM,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_CIM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};



//////////////////////////////////////////////
///////////// Field Force Analysis ///////////
//////////////////////////////////////////////
export const createFIELD = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_FIELD_REQUEST,
        });
        const { data } = await axios.post(`${URL}/force`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_FIELD,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_FIELD_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitFIELD = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_FIELD_REQUEST,
        });
        const { data } = await axios.put(`${URL}/force/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_FIELD,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_FIELD_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getFIELDs = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_FIELDS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/force`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_FIELDS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_FIELDS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getFIELD = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_FIELD_REQUEST,
        });
        const { data } = await axios.get(`${URL}/force/${id}/single`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_FIELD,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_FIELD_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getApprovedFIELDs = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_APPROVED_FIELDS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/force/approved`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_APPROVED_FIELDS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_APPROVED_FIELDS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateFIELD = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_FIELD_REQUEST,
        });
        const { data } = await axios.put(`${URL}/force/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_FIELD,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_FIELD_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteFIELD = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_FIELD_REQUEST,
        });
        await axios.delete(`${URL}/force/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_FIELD,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_FIELD_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const approveFIELD = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_FIELD_REQUEST,
        });
        const { data } = await axios.put(`${URL}/force/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_FIELD,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_FIELD_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectFIELD = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_FIELD_REQUEST,
        });
        const { data } = await axios.put(`${URL}/force/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_FIELD,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_FIELD_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const reviewFIELD = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_FIELD_REQUEST,
        });
        const { data } = await axios.put(`${URL}/force/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_FIELD,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_FIELD_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineFIELD = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_FIELD_REQUEST,
        });
        const { data } = await axios.put(`${URL}/force/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        
        dispatch({
            type: types.DECLINE_FIELD,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_FIELD_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
