import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Checkbox,
  Typography,
  Button,
  Grid,
  Fade,
  darken,
  withStyles,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import SearchComponent from "../../SearchComponent";
import { TablePaginationActions } from "../../TablePaginationActions";
import { connect, useDispatch } from "react-redux";
import { getGroupsByModule } from "../../../actions/groupsAction";
import {
  getAllAccessRights,
  createAccessRight,
  deleteAccess,
} from "../../../actions/accessRightsActions";
import Feedback from "../../atoms/Feedback";
import { getAllModules } from "../../../actions/moduleAction";
import DeleteAccessModal from "./modals/DeleteAccessModal";
// import EditAccessModal from "./Modals/EditAccessModal";

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  action: {
    marginBottom: 30,
  },
  menu: {
    width: 150,
    background: darken(theme.palette.primary.dark, 0.3),
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    padding: theme.spacing(1.5),
  },
  marginBottom: {
    marginBottom: 30,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    // border: "1px solid #d3d4d5",
    background: "#332A2A",
    color: theme.palette.background.paper,
    width: 200,
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    background: "#332A2A",
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.white,
    "&:focus": {
      backgroundColor: "#655757",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      backgroundColor: "#655757",
    },
  },
}))(MenuItem);

const INIT_STATE = {
  name: "",
  object: "",
  group: "",
};

const INIT_RIGHTS = {
  createOwn: false,
  createAny: false,
  readOwn: false,
  readAny: false,
  updateOwn: false,
  updateAny: false,
  deleteOwn: false,
  deleteAny: false,
};

const UserAccess = ({
  getGroupsByModule,
  groups,
  getAllAccessRights,
  createAccessRight,
  accessRights,
  deleteAccess,
  getAllModules,
  modules,
}) => {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [accessList, setAccessList] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(INIT_STATE);
  const [checked, setChecked] = React.useState(INIT_RIGHTS);
  const [loadGroups, setLoadGroups] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [getName, setGetName] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  // const [openEdit, setOpenEdit] = React.useState(false);

  // const [getId, setGetId] = React.useState(null);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    getAllModules();
  }, [getAllModules]);

  const getGroupsCallback = React.useCallback(() => {
    setLoadGroups(true);
    getGroupsByModule(state.object);
    setLoadGroups(false);
  }, [state.object, getGroupsByModule]);

  React.useEffect(() => {
    const abortController = new AbortController();
    getGroupsCallback();
    return () => {
      abortController.abort();
    };
  }, [getGroupsCallback]);

  React.useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getAllAccessRights().then(() => {
      setOpenBackdrop(false);
    });
  }, [getAllAccessRights]);

  // const handleEditOpen = (id) => {
  //   setGetId(id);

  //   setOpenEdit(true);
  // };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDelete = (name) => {
    setGetName(name);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // const handleCloseEdit = () => {
  //   setOpenEdit(false);
  // };

  // const [selected, setSelected] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();

    setSearch("");
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChecked = (e) => {
    e.persist();
    setChecked((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      ...state,
      ...checked,
    };
    setOpenBackdrop((prev) => !prev);

    createAccessRight(data)
      .then(() => {
        setState(INIT_STATE);
        setChecked(INIT_RIGHTS);
        dispatch(getAllAccessRights);
        setOpenBackdrop(false);
        setLoading(false);
        handleClose();
        setFeed({
          loading: false,
          open: !feed.open,
          message: "Access Right created Successfully",
          success: true,
        });
      })
      .catch((err) => {
        setOpenBackdrop(false);
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          message: err,
          success: false,
        });
      });
  };

  const handleAccessChange = (e, id) => {
    e.persist();

    setAccessList((prev) =>
      e.target.checked === false && prev.includes(id)
        ? prev.filter((acc) => acc !== id)
        : [...prev, id]
    );
  };

  const filteredRows = () =>
    accessRights === undefined
      ? []
      : accessRights.filter((row) => {
          if (search !== "") {
            return (
              row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.object.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.group.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );
  const isValid = () => {
    return !state.group || !state.object;
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <DeleteAccessModal
        {...{ openDelete, handleCloseDelete, name: getName }}
      />

      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title}>
            Access Rights
          </Typography>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={handleClickOpen}
            className={classes.btn}
          >
            Create
          </Button>

          {/* 
            <EditAccessModal
              openEdit={openEdit}
              id={getId}
              handleCloseEdit={handleCloseEdit}
            /> */}

          {accessList.length > 0 ? (
            <>
              <Fade in={accessList.length !== 0}>
                <Button
                  color="default"
                  variant="contained"
                  onClick={handleOpenMenu}
                  className={classes.btn}
                >
                  Action{" "}
                  {Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
              </Fade>
              <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <StyledMenuItem onClick={handleCloseMenu}>
                  Export
                </StyledMenuItem>
                <StyledMenuItem onClick={handleCloseMenu}>
                  Deactivate
                </StyledMenuItem>
                <StyledMenuItem onClick={handleCloseMenu}>
                  Archive
                </StyledMenuItem>
                <StyledMenuItem onClick={handleCloseMenu}>
                  Unarchive
                </StyledMenuItem>
              </StyledMenu>
            </>
          ) : null}
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>
      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Object</TableCell>
                <TableCell align="right">Group</TableCell>
                <TableCell align="right">CreateOwn</TableCell>
                <TableCell align="right">CreateAny</TableCell>
                <TableCell align="right">ReadOwn</TableCell>
                <TableCell align="right">ReadAny</TableCell>
                <TableCell align="right">UpdateOwn</TableCell>
                <TableCell align="right">UpdateAny</TableCell>
                <TableCell align="right">DeleteOwn</TableCell>
                <TableCell align="right">DeleteAny</TableCell>
                {/* <TableCell align="right">Edit</TableCell> */}
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => handleAccessChange(e, row._id)}
                            checked={accessList.includes(row._id)}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.object}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.group}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <Checkbox disabled checked={row.createOwn} />
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <Checkbox disabled checked={row.createAny} />
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <Checkbox disabled checked={row.readOwn} />
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <Checkbox disabled checked={row.readAny} />
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <Checkbox disabled checked={row.updateOwn} />
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <Checkbox disabled checked={row.updateAny} />
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <Checkbox disabled checked={row.deleteOwn} />
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <Checkbox disabled checked={row.deleteAny} />
                    </TableCell>
                    {/* <TableCell style={{ width: 160 }} align="right">
                      <IconButton
                        color="primary"
                        onClick={() => handleEditOpen(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell> */}
                    <TableCell style={{ width: 160 }} align="right">
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDelete(row.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}

              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={13}
                    align="center"
                    style={{ color: "#616161" }}
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Access Rights
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="object-label-id">Module</InputLabel>
                  <Select
                    labelId="object-label-id"
                    id="object"
                    inputProps={{
                      id: "object",
                      name: "object",
                    }}
                    label="Object"
                    name="object"
                    value={state.object}
                    onChange={handleChange}
                  >
                    <MenuItem value="---SELECT MODULE---" disabled>
                      <em>Select Module</em>
                    </MenuItem>
                    {modules &&
                      modules.map((mod) => (
                        <MenuItem key={mod._id} value={mod.code}>
                          {mod.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="group-label-id">Group</InputLabel>
                  <Select
                    labelId="group-label-id"
                    id="group"
                    inputProps={{
                      id: "group",
                      name: "group",
                    }}
                    label="Group"
                    name="group"
                    value={state.group}
                    onChange={handleChange}
                  >
                    <MenuItem value="---Select Group---" disabled>
                      ---Select Group---
                    </MenuItem>
                    {loadGroups ? (
                      <MenuItem value="" disabled>
                        Loading...
                      </MenuItem>
                    ) : (
                      groups.data &&
                      groups.data.map((group) => (
                        <MenuItem key={group._id} value={`${group.name}`}>
                          {group.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                container
                justify="center"
                alignItems="center"
                component={Paper}
                variant="outlined"
                className={classes.paper}
              >
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.createOwn}
                        onChange={handleChecked}
                        name="createOwn"
                      />
                    }
                    label="Create Own"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="createAny"
                        checked={checked.createAny}
                        onChange={handleChecked}
                      />
                    }
                    label="Create Any"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="readOwn"
                        checked={checked.readOwn}
                        onChange={handleChecked}
                      />
                    }
                    label="Read Own"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="readAny"
                        checked={checked.readAny}
                        onChange={handleChecked}
                      />
                    }
                    label="Read Any"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="updateOwn"
                        checked={checked.updateOwn}
                        onChange={handleChecked}
                      />
                    }
                    label="Update Own"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="updateAny"
                        checked={checked.updateAny}
                        onChange={handleChecked}
                      />
                    }
                    label="Update Any"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="deleteOwn"
                        checked={checked.deleteOwn}
                        onChange={handleChecked}
                      />
                    }
                    label="Delete Own"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="deleteAny"
                        checked={checked.deleteAny}
                        onChange={handleChecked}
                      />
                    }
                    label="Delete Any"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Button
                type="submit"
                // onClick={handleClose}
                color="primary"
                variant="contained"
                fullWidth
                className={classes.saveBtn}
                disabled={isValid() || loading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  groups: state.groups.groups,
  accessRights: state.accessRights.accessRights.data,
  modules: state.module.modules,
});

export default connect(mapStateToProps, {
  getGroupsByModule,
  getAllAccessRights,
  createAccessRight,
  deleteAccess,
  getAllModules,
})(UserAccess);
