import * as types from "../../types/projectMob";

const initState = {
    mobnotices: [],
    mobnotice: null,
    customermobnotice: null,
    loading: false,
    error: null,
    success: false,
    doc: null,
};

const projectMobReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_MOBNOTICE:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_MOBNOTICES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_MOBNOTICES:
            return {
                ...state,
                loading: false,
                mobnotices: action.payload.data,
                success: true,
            };
        case types.GET_MOBNOTICES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.GET_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_MOBNOTICE:
            return {
                ...state,
                loading: false,
                mobnotice: action.payload.data,
                success: true,
            };
        case types.GET_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };

        case types.GET_CUSTOMER_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_CUSTOMER_MOBNOTICE:
            return {
                ...state,
                loading: false,
                customermobnotice: action.payload,
                success: false,
            };
        case types.GET_CUSTOMER_MOBNOTICE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };












            
        case types.MARK_CUSTOMER_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.MARK_CUSTOMER_MOBNOTICE:
            return {
                ...state,
                loading: false,
            };
        case types.MARK_CUSTOMER_MOBNOTICE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case types.UPDATE_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_MOBNOTICE:
            return {
                ...state,
                loading: false,
                mobnotice: state.mobnotices.map((item) =>
                    item._id === action.payload.data.id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case types.DELETE_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_MOBNOTICE:
            return {
                ...state,
                loading: false,
                mobnotices: state.mobnotices.filter(
                    (item) => item._id !== action.payload
                ),
                success: false,
            };
        case types.DELETE_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case types.APPROVE_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_MOBNOTICE:
            return {
                ...state,
                loading: false,
                mobnotice: action.payload.data,
            };
        case types.APPROVE_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_MOBNOTICE:
            return {
                ...state,
                loading: false,
                mobnotice: action.payload.data,
            };
        case types.REJECT_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_MOBNOTICE:
            return {
                ...state,
                loading: false,
                mobnotice: action.payload.data,
            };
        case types.DECLINE_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REVIEW_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REVIEW_MOBNOTICE:
            return {
                ...state,
                loading: false,
                mobnotice: action.payload.data,
            };
        case types.REVIEW_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPLOAD_MOBNOTICE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPLOAD_MOBNOTICE_DOCUMENT:
            return {
                ...state,
                loading: false,
                doc: action.payload.doc,
            };
        case types.UPLOAD_MOBNOTICE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.SEND_MOBNOTICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SEND_MOBNOTICE:
            return {
                ...state,
                loading: false,
                mobnotice: action.payload.data,
            };
        case types.SEND_MOBNOTICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_MOBNOTICE: {
            return {
                ...state,
                loading: false,
                mobnotice: null,
                error: null,
                success: false,
            };
        }

        default:
            return state;
    }
};

export default projectMobReducer;
