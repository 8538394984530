import * as types from "../../types/purchasing";

const initState = {
    purchase_requisitions: [],
    purchase_requisitions_accepted: [],
    purchase_requisition: {},
    error: null,
    success: false,
    loading: false,
};

const purchaseRequisitionReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_PURCHASE_REQUISITIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_PURCHASE_REQUISITIONS:
            return {
                ...state,
                loading: false,
                success: true,
                purchase_requisitions: action.payload.data,
            };
        case types.GET_ALL_PURCHASE_REQUISITIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ACCEPTED_PURCHASE_REQUISITIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ACCEPTED_PURCHASE_REQUISITIONS:
            return {
                ...state,
                loading: false,
                success: true,
                purchase_requisitions_accepted: action.payload.data,
            };
        case types.GET_ACCEPTED_PURCHASE_REQUISITIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
                purchase_requisition: action.payload.data,
            };
        case types.GET_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
                purchase_requisition: state.purchase_requisitions.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                success: false,
                purchase_requisitions: state.purchase_requisitions.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                purchase_requisition: action.payload.data,
            };
        case types.SUBMIT_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.APPROVE_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                purchase_requisition: action.payload.data,
            };
        case types.APPROVE_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                purchase_requisition: action.payload.data,
            };
        case types.REJECT_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                purchase_requisition: action.payload.data,
            };
        case types.ACCEPT_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_PURCHASE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_PURCHASE_REQUISITION:
            return {
                ...state,
                loading: false,
                purchase_requisition: action.payload.data,
            };
        case types.DECLINE_PURCHASE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_PURCHASE_REQUISITION:
            return {
                purchase_requisition: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default purchaseRequisitionReducer;
