import axios from "axios";
import {
  GET_ALL_BUDGET_HEADS,
  ERROR,
  CREATE_BUDGET_HEAD,
  GET_BUDGET_HEAD_BY_ID,
  UPDATE_BUDGET_HEAD,
  DELETE_BUDGET_HEAD,
} from "../types";

const token = localStorage.getItem("token:key");

const URL = `/api/v1/budget/head`;

export const getAllBudgetHeads = () => async (dispatch) => {
  try {
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_BUDGET_HEADS,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const getBudgetHeadById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/head/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_BUDGET_HEAD_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const createBudgetHead = (data) => async (dispatch) => {
  try {
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_BUDGET_HEAD,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const updateBudgetHead = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/head/${data.id}`;

    const res = await axios.patch(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_BUDGET_HEAD,
      payload: res.data.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteBudgetHead = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/head/${id}`;
    const res = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_BUDGET_HEAD,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};
