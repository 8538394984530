import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  withStyles,
  Badge,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Divider,
  Fab,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import DeleteIcon from "@material-ui/icons/Delete";

import { connect, useDispatch } from "react-redux";

import {
  createAnnualBudgetLine,
  getAllAnnualBudgetLineByBudgetId,
  getAnnualBudgetById,
} from "../../../../actions/annualAction";
import { getAllBudgetHeads } from "../../../../actions/budgetHeadsActions";
import {
  getBudgetItemBySubheadAndDivision,
  getAllBudgetItems,
} from "../../../../actions/budgetItemActions";
import { getBudgetSubheadByHead } from "../../../../actions/budgetSubheadAction";
import { formatAmount } from "../../../../utils";
import Feedback from "../../../atoms/Feedback";
import { currencyConvert } from "../../../../utils/currencyconvert";
import { getCurrencies } from "../../../../actions/currencyActions";
import { getCostCodesByType } from "../../../../actions/costCodeAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  container: {
    // marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 300,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  floatingBtn: {
    transform: "translateY(0px)",
    animation: "$floating 1.2s infinite alternate",
  },

  "@keyframes floating": {
    "0%": {
      transform: "translateY(0px)",
    },

    "100%": {
      transform: "translateY(-10px)",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperText: {
    fontFamily: "Rubik",
    fontSize: 13,
    fontWeight: 500,
    color: "#fff",

    height: "fit-content",
    padding: theme.spacing(1) + 2,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#0176ff",

    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      right: -5,
      top: "40%",
      width: 10,
      height: 10,

      backgroundColor: "#0176ff",

      transform: "rotate(45deg)",
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  addBtn: {
    letterSpacing: 2.5,
    // margin: "30px 0",
    padding: theme.spacing(1),
  },
  saveBtn: {
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
  },
}));

const RippleEffect = withStyles((theme) => ({
  root: {
    // backgroundColor: "#44b700",
    backgroundColor: theme.palette.secondary.light,
    // color: "#44b700",
    color: theme.palette.secondary.light,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginLeft: -5,

    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const INIT_STATE = {
  budgetHead: "",
  budgetSubhead: "",
  budgetItem: "",
  description: "",
  costCategory: "",
  costCenter: "",
};

const INIT_PERIOD = {
  amount: 0,
  month: null,
  formatAmount: 0,
  rate: 1,
};

const CreateAnnualBudgetLineModal = ({
  openCreate,
  handleCreateClose,
  getAllAnnualBudgetLineByBudgetId,
  createAnnualBudgetLine,
  getAllBudgetHeads,
  getBudgetItemBySubheadAndDivision,
  getBudgetSubheadByHead,
  error,
  heads,
  subheads,
  items,
  profile,
  id,
  getCurrencies,
  currencies,
  getAnnualBudgetById,
  getCostCodesByType,
  costcodes,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [state, setState] = React.useState(INIT_STATE);
  const [subTotal, setSubTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [isMonth, setIsMonth] = React.useState(false);

  const [addMonths, setAddMonths] = React.useState({
    periods: [INIT_PERIOD],
  });
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  React.useEffect(() => {
    getAllBudgetHeads();
  }, [getAllBudgetHeads]);

  React.useEffect(() => {
    setLoading((prev) => !prev);
    getCurrencies().then(() => {
      setLoading(false);
    });
  }, [getCurrencies]);

  const getAnnualBudgetByIdCallback = React.useCallback(() => {
    getAnnualBudgetById(id);
  }, [id, getAnnualBudgetById]);

  React.useEffect(() => {
    getAnnualBudgetByIdCallback();
  }, [getAnnualBudgetByIdCallback]);

  const getBudgetSubheadCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    if (state.budgetHead !== "") {
      getBudgetSubheadByHead(state.budgetHead).then(() => {
        setLoading(false);
      });
    }
  }, [state.budgetHead, getBudgetSubheadByHead]);

  React.useEffect(() => {
    getBudgetSubheadCallback();
  }, [getBudgetSubheadCallback]);

  React.useEffect(() => {
    let subTotal = addMonths.periods.reduce((acc, cur) => {
      return acc + currencyConvert(+cur.formatAmount, +cur.rate);
    }, 0);
    subTotal = formatAmount(subTotal);
    setSubTotal(subTotal);
  }, [addMonths]);

  const itemCallback = React.useCallback(() => {
    const data = {
      subhead: state.budgetSubhead,
      division: profile && profile.division && profile.division._id,
    };
    if (state.budgetSubhead !== "") {
      getBudgetItemBySubheadAndDivision(data);
    }
  }, [state.budgetSubhead, profile, getBudgetItemBySubheadAndDivision]);

  React.useEffect(() => {
    const abortController = new AbortController();
    itemCallback();
    return () => {
      abortController.abort();
    };
  }, [itemCallback]);

  const codeTypeCallback = React.useCallback(() => {
    setLoading((prev) => !prev);

    if (state.costCategory !== "") {
      getCostCodesByType(state.costCategory).then(() => {
        setLoading(false);
      });
    }
  }, [getCostCodesByType, state]);

  React.useEffect(() => {
    codeTypeCallback();
  }, [codeTypeCallback]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeMonth = (i) => (e) => {
    e.persist();

    const newPeriod = addMonths.periods.map((item, index) => {
      if (i !== index) {
        return item;
      }
      setIsMonth(!!e.target.value);

      // console.log(item);

      return {
        ...item,
        [e.target.name]: e.target.value,
        amount:
          e.target.name === "formatAmout" ? e.target.value : e.target.value,
      };
    });

    setAddMonths({ periods: newPeriod });
  };

  // console.log(addMonths);
  // console.log(subTotal);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading((prev) => !prev);
    const {
      description,
      budgetHead,
      budgetSubhead,
      budgetItem,
      costCenter,
    } = state;
    let subTotal = addMonths.periods.reduce((acc, cur) => {
      return acc + currencyConvert(+cur.formatAmount, +cur.rate);
    }, 0);

    let data = {};

    data.subHead = budgetSubhead;
    data.description = description;
    data.head = budgetHead;
    data.item = budgetItem;
    data.costCenter = costCenter;
    data.period = addMonths.periods;
    data.subTotal = +subTotal;
    data.budgetId = id;

    createAnnualBudgetLine(data)
      .then((doc) => {
        if (!doc.success) {
          setLoading(false);
          setState(INIT_STATE);

          setAddMonths({
            periods: [INIT_PERIOD],
          });
          setFeed({
            loading: false,
            open: !feed.open,
            message: "Failed to Create BudgetLine",
            success: false,
          });
        }
        setState(INIT_STATE);
        setAddMonths({
          periods: [INIT_PERIOD],
        });

        setFeed({
          loading: false,
          open: !feed.open,
          message: `BudgetLine Created Successfully`,
          success: true,
        });
        dispatch(getAllAnnualBudgetLineByBudgetId(id));

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setState(INIT_STATE);
        // setPeriods([]);
        // console.error("ERROR!!!");
        // setFeed({
        //   loading: false,
        //   open: !feed.open,

        //   success: false,
        // });
      })
      .finally(() => {
        handleCreateClose();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => {
    return (
      !state.budgetHead ||
      !state.budgetSubhead ||
      !state.budgetItem ||
      isMonth === false
    );
  };

  const handleAddNewMonth = () => {
    let data = {
      month: "",
      amount: 0,
      formatAmount: 0,
      rate: 1,
    };
    setAddMonths({
      periods: addMonths.periods.concat([data]),
    });
  };

  const handleRemoveMonth = (i) => {
    setAddMonths((prev) => ({
      ...prev,
      periods: prev.periods.filter((_, index) => index !== i),
    }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error.error) || feed.message}
        />
      )}
      <Dialog
        open={openCreate}
        onClose={handleCreateClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        // fullScreen
        maxWidth="md"
      >
        <div className={classes.container}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <DialogTitle id="form-dialog-title">
                <Typography variant="overline" className={classes.title}>
                  Create Budget Line
                </Typography>
              </DialogTitle>
            </Grid>
            <Grid item>
              <Tooltip title="close" arrow style={{ marginLeft: "auto" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCreateClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <DialogContent className={classes.dialogContent}>
            <Paper className={classes.paper}>
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="budget-head">Expense Head</InputLabel>
                      <Select
                        labelId="budget-head"
                        id="budgetHead"
                        name="budgetHead"
                        inputProps={{
                          id: "budgetHead",
                          name: "budgetHead",
                        }}
                        value={state.budgetHead || ""}
                        onChange={handleChange}
                      >
                        <MenuItem disabled value="">
                          --- Select Expense Head ---
                        </MenuItem>
                        {heads &&
                          heads.map((head) => (
                            <MenuItem key={head._id} value={head._id}>
                              {head.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="budget-subhead">
                        Expense SubHead
                      </InputLabel>
                      <Select
                        labelId="budget-subhead"
                        id="budgetSubhead"
                        name="budgetSubHead"
                        inputProps={{
                          id: "budgetSubhead",
                          name: "budgetSubhead",
                        }}
                        value={state.budgetSubhead || ""}
                        onChange={handleChange}
                      >
                        <MenuItem disabled value="">
                          --- Select Expense SubHead ---
                        </MenuItem>
                        {subheads &&
                          subheads.map((subhead) => (
                            <MenuItem key={subhead._id} value={subhead._id}>
                              {subhead.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="budgetItem">Budget Category</InputLabel>
                      <Select
                        labelId="budgetItem"
                        id="budgetItem"
                        inputProps={{
                          id: "budgetItem",
                          name: "budgetItem",
                        }}
                        value={state.budgetItem || ""}
                        onChange={handleChange}
                        name="budgetItem"
                      >
                        <MenuItem disabled value="" divider>
                          --- Select Budget Category ---
                        </MenuItem>
                        {items &&
                          items.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Description"
                      id="description"
                      name="description"
                      onChange={handleChange}
                      value={state.description || ""}
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="costCategory">Cost Type</InputLabel>
                      <Select
                        labelId="costCategory"
                        id="costCategory"
                        inputProps={{
                          id: "costCategory",
                          name: "costCategory",
                        }}
                        value={state.costCategory || ""}
                        onChange={handleChange}
                        name="costCategory"
                        required
                      >
                        <MenuItem disabled value="">
                          --- Select Cost Category ---
                        </MenuItem>
                        <MenuItem value="project">Project</MenuItem>
                        <MenuItem value="division">
                          Division/Sub-division
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="costCenter">Cost Center</InputLabel>
                      <Select
                        labelId="costCenter"
                        id="costCenter"
                        inputProps={{
                          id: "costCenter",
                          name: "costCenter",
                        }}
                        value={state.costCenter || ""}
                        onChange={handleChange}
                        name="costCode"
                        disabled={loading}
                        required
                      >
                        <MenuItem disabled value="">
                          --- Select Cost Center ---
                        </MenuItem>
                        {costcodes &&
                          costcodes.map((cost) => (
                            <MenuItem key={cost._id} value={cost.code}>
                              {cost.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider light />
                    <Typography variant="overline">Add Month(s)</Typography>
                  </Grid>

                  {addMonths.periods.map((item, i) => (
                    <Grid
                      container
                      spacing={3}
                      justify="center"
                      alignItems="center"
                      key={i}
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <Grid item xs={12} md={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          required
                          // className={classes.textField}
                        >
                          <InputLabel id="head-label-id">Month</InputLabel>
                          <Select
                            labelId="head-label-id"
                            id="month"
                            label="Month"
                            inputProps={{
                              id: "month",
                              name: "month",
                            }}
                            value={item.month || ""}
                            onChange={handleChangeMonth(i)}
                            name="month"
                          >
                            <MenuItem value="" disabled>
                              --- Select Month ---
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          required
                          // className={classes.textField}
                        >
                          <InputLabel id="head-label-id">Currency</InputLabel>
                          <Select
                            labelId="head-label-id"
                            id="rate"
                            label="Rate"
                            inputProps={{
                              id: "rate",
                              name: "rate",
                            }}
                            value={item.rate || 1}
                            onChange={handleChangeMonth(i)}
                            name="rate"
                          >
                            <MenuItem value="" disabled>
                              --- Select Currency ---
                            </MenuItem>
                            {currencies &&
                              currencies.map((currency) => (
                                <MenuItem
                                  key={currency._id}
                                  value={currency.rate}
                                >
                                  {currency.code}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="formatAmount"
                          label="Amount"
                          name="formatAmount"
                          type="number"
                          fullWidth
                          variant="outlined"
                          value={item.formatAmount || ""}
                          onChange={handleChangeMonth(i)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id="amount"
                          label="To Naira"
                          name="amount"
                          type="number"
                          fullWidth
                          variant="outlined"
                          value={
                            currencyConvert(item.formatAmount, item.rate) || 0
                          }
                          // onChange={handleChangeMonth(i)}
                          disabled
                        />
                      </Grid>

                      <Grid item md={1} xs={12}>
                        <Tooltip arrow title="Remove" placement="right">
                          <Fab
                            color="secondary"
                            onClick={() => handleRemoveMonth(i)}
                            style={{ marginBottom: 10 }}
                          >
                            <DeleteIcon />
                          </Fab>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid
                    container
                    justify="flex-start"
                    spacing={3}
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginTop: 10,
                    }}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        arrow
                        title="Click button to add a new month"
                        placement="right"
                      >
                        <Button
                          color="default"
                          variant="outlined"
                          onClick={() =>
                            handleAddNewMonth(addMonths.length + 1)
                          }
                          className={classes.addBtn}
                        >
                          Add New Month
                        </Button>
                      </Tooltip>
                      <RippleEffect variant="dot" overlap="circle" />
                    </Grid>
                  </Grid>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item></Grid>
                    <Grid item>
                      <TextField
                        label="SubTotal"
                        variant="outlined"
                        disabled
                        className={classes.textField}
                        value={subTotal}
                        InputProps={{
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                &#8358;
                              </InputAdornment>
                              <Divider
                                className={classes.divider2}
                                orientation="vertical"
                              />
                            </>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justify="center">
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      // color="secondary"
                      style={{
                        color: validCheck() ? "" : "#fff",
                        background: validCheck() || loading ? "" : "#4caf50",
                      }}
                      className={classes.saveBtn}
                      size="large"
                      disabled={validCheck() || loading}
                    >
                      {loading ? "Loading..." : "Save"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  heads: state.budgetHeads.heads.data,
  subheads: state.budgetSubheads.subheads.data,
  items: state.budgetItems.items.data,
  profile: state.user.profile,
  // error: state.annualBudget.annualBudgetLineError,
  error: state.error.error,
  // annualBudget: state.annualBudget.annualBudget.data,
  annualBudgetLines: state.annualBudget.annualBudgetLines,
  currencies: state.currency.currencies,
  costcodes: state.costcode.costcodes.data,
});

const mapDispatchToProps = {
  getAllBudgetHeads,
  getBudgetItemBySubheadAndDivision,
  getBudgetSubheadByHead,
  getAllBudgetItems,
  createAnnualBudgetLine,
  getAllAnnualBudgetLineByBudgetId,
  getAnnualBudgetById,
  getCurrencies,
  getCostCodesByType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAnnualBudgetLineModal);
