import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ChangeMgtGuard = ({ children, ...rest }) => {
    const token = localStorage.getItem("token:key");

    const { profile } = useSelector((state) => state.user);

    let ChangeGuard = profile
        ? (profile &&
            profile.groups &&
            profile.groups.some(
            (grp) =>
                grp.name === "admin" ||
                (grp.module === "change_request") ||
                (grp.module === "change_impact") ||
                (grp.module === "change_force")
            )) ||
        (profile &&
            profile.division &&
            profile.division.name === "People Management")
        : false;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token && ChangeGuard ? (
                children
                ) : (
                <Redirect
                    to={{
                        pathname: "/dashboard",
                        state: {
                            from: location,
                        },
                    }}
                />
                )
            }
        />
    );
};

export default ChangeMgtGuard;
