import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    Fab,
    DialogContent,
    Backdrop,
    darken,
    Radio,
    RadioGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Feedback from "../../../../atoms/Feedback";
import { getProfile } from "../../../../../actions/userActions";
import { createCIM, getApprovedCRFs, getCIMs } from "../../../../../actions/ppmActions/changeMgtActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        marginBottom: 30,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper1: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: darken("#fff", 0.05),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    month: "",
    year: "",
    changeRequest: null,
    changeRequestId: null,
    expectedOutcomes: [],
};

const INIT_OUTCOME = {
    duration: "",
    outcome: "",
    category: "",
    activity: "",
}

const CreateChangeImpactModal = ({ openCreate, handleCloseCreate, createCIM }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [requestDate] = useState(moment(new Date()));
    const [month] = useState(moment().format("MMMM"));
    const [year] = useState(moment().format("YYYY"));
    const [state, setState] = useState(INIT_STATE);
    const [outcome, setOutcome] = useState(INIT_OUTCOME);

    const { profile } = useSelector((state) => state.user);
    const { loading, error, crfs_approved } = useSelector((state) => state.changeMgt);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            month: month,
            year: year,
            requestDate: moment(requestDate).toDate(),
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
        }));
    }, [profile, year, month, requestDate]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getApprovedCRFs());
    }, [dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    
    const handleOutcomeChange = (e) => {
        e.persist();
        setOutcome((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddOutcomeArray = (item) => {
        if (item.duration !== "" && item.outcome !== "" && item.positive !== "" && item.negative !== "") {
            setState((prev) => ({
                ...prev,
                expectedOutcomes: prev.expectedOutcomes.concat([item]),
            }));
            setOutcome(INIT_OUTCOME);
        }
    };

    const handleRemoveOutcomeArray = (i) => {
        setState((prev) => ({
            ...prev,
            expectedOutcomes: prev.expectedOutcomes.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            changeRequestId: state.changeRequest?._id,
            expectedOutcomes: state.expectedOutcomes,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(createCIM(data));
        });
        
        if (res) {
            dispatch(getCIMs());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Change Impact Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.changeRequest || 
        !state.changeRequest?._id || 
        (state.expectedOutcomes.length === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title3}>
                                    Create Change Impact Monitoring
                                </Typography>

                                <Typography variant="overline" className={classes.title}>
                                    Change Request Details
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper1}>
                                    <Grid container spacing={2} className={classes.grid}>
                                        <Grid item xs={12} md={3}>
                                            <Autocomplete
                                                id="changeRequest"
                                                options={crfs_approved}
                                                label="Search for CRF"
                                                value={state.changeRequest}
                                                debug
                                                onChange={(event, newValue) => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        changeRequest: newValue,
                                                    }));
                                                }}
                                                getOptionLabel={(option) => option.requestDescription || ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>{option.requestDescription} - {option.requestNo}</React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            placeholder="Search for Approved CRF..."
                                                            fullWidth
                                                            required
                                                            variant="outlined"
                                                            value={(state.changeRequest && state.changeRequest) || ""}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "disabled",
                                                                name: "changeRequest",
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                            label="Change Initiator"
                                            name="employeeName"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.employeeName && state.employeeName) || ""}
                                            disabled
                                            required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                label="EID"
                                                name="employeeId"
                                                variant="outlined"
                                                fullWidth
                                                onChange={handleChange}
                                                value={state.employeeId || ""}
                                                disabled
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                label="Division/Subdivision"
                                                name="division"
                                                variant="outlined"
                                                fullWidth
                                                value={(state.division && state.divisionName) || ""}
                                                disabled
                                                required
                                            />
                                        </Grid>
                                        {state.changeRequest !== null ? <>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Request No"
                                                name="changeRequestNo"
                                                value={state.changeRequest?.requestNo || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Initiator"
                                                name="changeInitiator"
                                                value={state.changeRequest?.createdBy?.userName || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Request Coordinator"
                                                name="changeCoordinator"
                                                value={state.changeRequest?.reviewedBy?.userName || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Request Description"
                                                name="changeRequestDescription"
                                                value={state.changeRequest?.requestDescription || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                component="fieldset"
                                                disabled
                                            >
                                                <FormLabel style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                    Priority 
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Critical"
                                                        control={<Radio checked={(state.changeRequest?.priority === "Critical") ? true : false} />}
                                                        label="Critical"
                                                        name="priority"
                                                    />
                                                    <FormControlLabel
                                                        value="High"
                                                        control={<Radio checked={(state.changeRequest?.priority === "High") ? true : false} />}
                                                        label="High"
                                                        name="priority"
                                                    />
                                                    <FormControlLabel
                                                        value="Medium"
                                                        control={<Radio checked={(state.changeRequest?.priority === "Medium") ? true : false} />}
                                                        label="Medium"
                                                        name="priority"
                                                    />
                                                    <FormControlLabel
                                                        value="Low"
                                                        control={<Radio checked={(state.changeRequest?.priority === "Low") ? true : false} />}
                                                        label="Low"
                                                        name="priority"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        </> : null }
                                    </Grid>

                                    {/* Timelines Added */}
                                    {(state.changeRequest?.timelines && state.changeRequest?.timelines.length > 0) ? 
                                    <>
                                        <Typography variant="overline" className={classes.title2}>
                                            Project Timelines
                                        </Typography>

                                        <Paper variant="outlined" square className={classes.paper1}>
                                            {(state.changeRequest?.timelines && state.changeRequest?.timelines.length > 0) ? state.changeRequest?.timelines.map((sitem, i) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                    style={{marginBottom: 0}}
                                                    key={i}
                                                >
                                                    <Grid item xs={12} md={8}>
                                                        <label style={{ fontWeight: "bold" }}>Action</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            name="action"
                                                            value={sitem.action || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <label style={{ fontWeight: "bold" }}>Due Date</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            name="dueDate"
                                                            value={sitem.dueDate || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )) : <>
                                                <Typography variant="overline" className={classes.title2}>
                                                    No Project Timeline added yet
                                                </Typography>
                                            </>}
                                        </Paper>
                                    </> : null }
                                </Paper>

                                <Typography variant="overline" className={classes.title}>
                                    Add Expected Outcome
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper1}>
                                    {outcome ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="duration">
                                                        Duration
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="duration"
                                                        value={(outcome.duration && outcome.duration) || ""}
                                                        name="duration"
                                                        onChange={handleOutcomeChange}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        <MenuItem value="7">7 Days</MenuItem>
                                                        <MenuItem value="30">30 Days</MenuItem>
                                                        <MenuItem value="90">3 months</MenuItem>
                                                        <MenuItem value="180">6 months</MenuItem>
                                                        <MenuItem value="365">1 year</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Expected Outcome"
                                                    name="outcome"
                                                    value={(outcome.outcome && outcome.outcome) || ""}
                                                    onChange={handleOutcomeChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    component="fieldset"
                                                >
                                                    <FormLabel>Outcome Category</FormLabel>
                                                    <RadioGroup row onChange={handleOutcomeChange}>
                                                        <FormControlLabel
                                                            value="positive"
                                                            control={<Radio checked={(outcome.category === "positive") ? true : false} />}
                                                            label="Positive"
                                                            name="category"
                                                        />
                                                        <FormControlLabel
                                                            value="negative"
                                                            control={<Radio checked={(outcome.category === "negative") ? true : false} />}
                                                            label="Negative"
                                                            name="category"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    component="fieldset"
                                                >
                                                    <FormLabel>Action To Take</FormLabel>
                                                    <RadioGroup row onChange={handleOutcomeChange}>
                                                        <FormControlLabel
                                                            value="Stop"
                                                            control={<Radio checked={(outcome.activity === "Stop") ? true : false} />}
                                                            label="Stop"
                                                            name="activity"
                                                        />
                                                        <FormControlLabel
                                                            value="Start"
                                                            control={<Radio checked={(outcome.activity === "Start") ? true : false} />}
                                                            label="Start"
                                                            name="activity"
                                                        />
                                                        <FormControlLabel
                                                            value="Continue"
                                                            control={<Radio checked={(outcome.activity === "Continue") ? true : false} />}
                                                            label="Continue"
                                                            name="activity"
                                                        />
                                                        <FormControlLabel
                                                            value="Change"
                                                            control={<Radio checked={(outcome.activity === "Change") ? true : false} />}
                                                            label="Change"
                                                            name="activity"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    style={{ backgroundColor: "#1877F2" }}
                                                    onClick={() => handleAddOutcomeArray(outcome)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                {/* Outcomes Added */}
                                {(state.expectedOutcomes && state.expectedOutcomes.length > 0) ? 
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Expected Outcomes
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper1}>
                                        {(state.expectedOutcomes && state.expectedOutcomes.length > 0) ? state.expectedOutcomes.map((sitem, i) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                                key={i}
                                            >
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Duration</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="duration"
                                                        value={`${sitem.duration + ' days' }` || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <label style={{ fontWeight: "bold" }}>Expected Outcome</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="outcome"
                                                        value={sitem.outcome || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <label style={{ fontWeight: "bold" }}>Outcome Category</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="category"
                                                        value={sitem.category || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <label style={{ fontWeight: "bold" }}>Action to Take</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="activity"
                                                        value={sitem.activity || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <label>&nbsp;</label>
                                                    <Fab
                                                        color="secondary"
                                                        onClick={() => handleRemoveOutcomeArray(i)}
                                                    >
                                                        <CloseIcon />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        )) : <>
                                            <Typography variant="overline" className={classes.title2}>
                                                No Expected Outcome has been added yet
                                            </Typography>
                                        </>}
                                    </Paper>
                                </> : null }

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createCIM, getCIMs })(CreateChangeImpactModal);
