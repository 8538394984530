import {
  GET_ALL_BUDGET_HEADS,
  CREATE_BUDGET_HEAD,
  ERROR,
  GET_BUDGET_HEAD_BY_ID,
  UPDATE_BUDGET_HEAD,
  DELETE_BUDGET_HEAD,
} from "../../types";

const initState = {
  heads: [],
  head: {},
  error: null,
};

const budgetHeadReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_BUDGET_HEADS:
      return {
        ...state,
        heads: action.payload,
      };
    case CREATE_BUDGET_HEAD:
      return {
        ...state,
        heads: [action.payload, ...state.heads],
      };
    case GET_BUDGET_HEAD_BY_ID:
      return {
        ...state,
        head: action.payload,
      };
    case UPDATE_BUDGET_HEAD:
      return {
        ...state,
        heads: state.heads.map((head) =>
          head._id === action.payload._id ? (head = action.payload) : head
        ),
      };
    case DELETE_BUDGET_HEAD:
      return {
        ...state,
        heads: state.heads.filter((head) => head._id !== action.payload._id),
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default budgetHeadReducer;
