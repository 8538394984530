import * as types from "../../types/inventory";

const initState = {
    gatepasses: [],
    gatepass: {},
    error: null,
    success: false,
    loading: false,
};

const gatepassReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_GATEPASS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_GATEPASSES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_GATEPASSES:
            return {
                ...state,
                loading: false,
                success: true,
                gatepasses: action.payload.data,
            };
        case types.GET_ALL_GATEPASSES_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_GATEPASS:
            return {
                ...state,
                loading: false,
                success: true,
                gatepass: action.payload.data,
            };
        case types.GET_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_GATEPASS:
            return {
                ...state,
                loading: false,
                success: true,
                gatepass: state.gatepasses.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_GATEPASS:
            return {
                ...state,
                loading: false,
                success: false,
                gatepasses: state.gatepasses.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_GATEPASS:
            return {
                ...state,
                loading: false,
                gatepass: action.payload.data,
            };
        case types.SUBMIT_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REVIEW_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REVIEW_GATEPASS:
            return {
                ...state,
                loading: false,
                gatepass: action.payload.data,
            };
        case types.REVIEW_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_GATEPASS:
            return {
                ...state,
                loading: false,
                gatepass: action.payload.data,
            };
        case types.ACCEPT_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.APPROVE_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_GATEPASS:
            return {
                ...state,
                loading: false,
                gatepass: action.payload.data,
            };
        case types.APPROVE_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.GRANT_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GRANT_GATEPASS:
            return {
                ...state,
                loading: false,
                gatepass: action.payload.data,
            };
        case types.GRANT_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_GATEPASS:
            return {
                ...state,
                loading: false,
                gatepass: action.payload.data,
            };
        case types.REJECT_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.COMPLETE_GATEPASS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.COMPLETE_GATEPASS:
            return {
                ...state,
                loading: false,
                gatepass: action.payload.data,
            };
        case types.COMPLETE_GATEPASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_GATEPASS:
            return {
                gatepass: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default gatepassReducer;
