import React, { useState } from "react";

import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  DialogTitle,
  IconButton,
  darken,
  Fab,
  Tooltip,
  Icon,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Feedback from "../../../atoms/Feedback";
import {
  retireCashAdvance,
  uploadDocument,
  getCashAdvanceById,
} from "../../../../actions/cashAdvanceAction";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  submitBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },

    // padding: theme.spacing(2),
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  input: {
    display: "none",
  },
}));

const INIT_STATE = {
  amount: 0,
  comment: "",
};

const RetireModal = ({
  error,
  openRetire,
  handleCloseRetire,
  retireCashAdvance,
  uploadDocument,
  url,
  id,
  getCashAdvanceById,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFile = (e) => {
    e.persist();
    const { files } = e.target;

    setDocument(files[0]);
  };

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const handleUpload = () => {
    setOpenBackdrop((prev) => !prev);
    const formData = new FormData();
    formData.append("doc", document);
    formData.append("id", id);

    uploadDocument(formData)
      .then((doc) => {
        if (!doc) {
          setOpenBackdrop(false);

          setFeed({
            loading: false,
            open: !feed.open,
            message: `Failed to upload document`,
            success: false,
          });
        } else {
          setOpenBackdrop(false);
          setDocuments((prev) => [...prev, doc]);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `File upload successful.`,
            success: true,
          });
        }
      })
      .catch(() => {
        setOpenBackdrop(false);

        setFeed({
          loading: false,
          open: !feed.open,
          message: `Failed to upload document`,
          success: false,
        });
      });
  };

  const handleRetire = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    let data = {
      ...state,
      documents,
      id,
      path: "cashadvance/view",
    };
    retireCashAdvance(data)
      .then((doc) => {
        setLoading(false);
        setState(INIT_STATE);
        setDocuments([]);
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `Cash Advance Retirement successful.`,
          success: true,
        });
        if (doc && doc.amountToReimburse) {
          navigateById("/reimbursement/view", doc._id);
        } else {
          dispatch(getCashAdvanceById(id));
        }
        handleCloseRetire();
      })
      .catch(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `An Unexpected Error has occurred.`,
          success: false,
        });
      })
      .finally(() => {
        handleCloseRetire();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  let validCheck = () =>
    !state.amount || !state.comment || documents.length === 0;

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error.error) || feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || `Cash Advance Retirement successful.`}
        />
      )}

      <Dialog
        open={openRetire}
        onClose={handleCloseRetire}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Retire
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseRetire}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleRetire}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="amount"
                  label="Amount"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="comment"
                  label="Comment"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <input
                  type="file"
                  id="document"
                  name="document"
                  accept="image/*,.pdf"
                  className={classes.input}
                  onChange={handleFile}
                />
                <label htmlFor="document">
                  <Tooltip arrow title="Select file" placement="bottom">
                    <Fab color="secondary" component="span">
                      <AttachFileIcon />
                    </Fab>
                  </Tooltip>
                </label>

                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  endIcon={<Icon className="fas fa-upload" fontSize="small" />}
                  size="small"
                  onClick={handleUpload}
                  disabled={!document || openBackdrop}
                >
                  {openBackdrop ? "Loading..." : "Upload"}
                </Button>
                <Typography variant="overline"><br></br>
                  Please check to ensure you are uploading the correct document. Always preview documents before you submit.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* <Avatar src={mediaPreview} variant="square" /> */}
              </Grid>
            </Grid>
            <ul>
              {documents.map((doc, i) => (
                <p key={i}><a href={doc} target="_blank" rel="noreferrer">{doc}</a></p>
              ))}
            </ul>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.submitBtn}
                  disabled={validCheck() || loading || openBackdrop}
                >
                  {loading ? "Loading..." : "Retire"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.cashAdvance.cashAdvanceError,
  url: state.cashAdvance.url,
});

const mapDispatchToProps = {
  getCashAdvanceById,
  retireCashAdvance,
  uploadDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(RetireModal);
