import * as types from "../types/visitorsType";

const initState = {
    visitors: [],
    myvisitors: [],
    todayvisitors: [],
    visitor: null,
    loading: false,
    error: null,
    success: false,
};

const visitorReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_VISITOR_APPOINTMENTS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_VISITOR_APPOINTMENTS:
            return {
                ...state,
                loading: false,
                visitors: action.payload.data,
                success: false,
            };
        case types.GET_VISITOR_APPOINTMENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.GET_TODAY_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_TODAY_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                todayvisitors: action.payload.data,
                success: false,
            };
        case types.GET_TODAY_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.GET_MY_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_MY_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                myvisitors: action.payload.data,
                success: false,
            };
        case types.GET_MY_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.GET_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                visitor: action.payload.data,
                success: false,
            };
        case types.GET_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };

        case types.UPDATE_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                visitor: state.visitors.map((item) =>
                    item._id === action.payload.data.id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case types.DELETE_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                visitors: state.visitors.filter(
                    (item) => item._id !== action.payload
                ),
                success: false,
            };
        case types.DELETE_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };

        case types.APPROVE_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                visitor: action.payload.data,
            };
        case types.APPROVE_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                visitor: action.payload.data,
            };
        case types.DECLINE_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.CHECKIN_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CHECKIN_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                visitor: action.payload.data,
            };
        case types.CHECKIN_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.CHECKOUT_VISITOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CHECKOUT_VISITOR_APPOINTMENT:
            return {
                ...state,
                loading: false,
                visitor: action.payload.data,
            };
        case types.CHECKOUT_VISITOR_APPOINTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_VISITOR_APPOINTMENT: {
            return {
                ...state,
                loading: false,
                visitor: null,
                error: null,
                success: false,
            };
        }

        case types.CREATE_CUSTOMER_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_CUSTOMER_FEEDBACK:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_CUSTOMER_FEEDBACK_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default visitorReducer;
