import * as types from "../types/projectMob";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/projectMob`;

//////////////////////////////////////////////
/////////// Project Mobilization /////////////
//////////////////////////////////////////////
export const createProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.post(`${URL}/notice`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_MOBNOTICE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: types.SUBMIT_MOBNOTICE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getProjectMobs = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_MOBNOTICES_REQUEST,
        });
        const { data } = await axios.get(`${URL}/notice`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_MOBNOTICES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_MOBNOTICES_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getProjectMob = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.get(`${URL}/notice/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_MOBNOTICE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCustomerProjectMob = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_CUSTOMER_MOBNOTICE_REQUEST,
        });
        const res = await axios.get(`${URL}/customer/${id}`);
        dispatch({
            type: types.GET_CUSTOMER_MOBNOTICE,
            payload: res.data,
        });
        return await res.data;
    } catch (error) {
        if (error.response) {
            dispatch({
                type: types.GET_CUSTOMER_MOBNOTICE_ERROR,
                error: error.response.data,
            });
        } else if (error.request) {
            dispatch({
                type: types.GET_CUSTOMER_MOBNOTICE_ERROR,
                error: error.request.data,
            });
        } else {
            dispatch({
                type: types.GET_CUSTOMER_MOBNOTICE_ERROR,
                error: error.message,
            });
        }
    }
};

export const markCustomerProjectMob = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.MARK_CUSTOMER_MOBNOTICE_REQUEST,
        });
        const res = await axios.post(`${URL}/read/${id}`);
        dispatch({
            type: types.MARK_CUSTOMER_MOBNOTICE,
            payload: res.data.data,
        });
        return await res.data.data;
    } catch (error) {
        if (error.response) {
            dispatch({
                type: types.MARK_CUSTOMER_MOBNOTICE_ERROR,
                error: error.response.data,
            });
        } else if (error.request) {
            dispatch({
                type: types.MARK_CUSTOMER_MOBNOTICE_ERROR,
                error: error.request.data,
            });
        } else {
            dispatch({
                type: types.MARK_CUSTOMER_MOBNOTICE_ERROR,
                error: error.message,
            });
        }
    }
};

export const updateProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_MOBNOTICE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_MOBNOTICE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteProjectMob = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_MOBNOTICE_REQUEST,
        });
        await axios.delete(`${URL}/notice/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_MOBNOTICE,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_MOBNOTICE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_MOBNOTICE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_MOBNOTICE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.REJECT_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

// service division accepts mob notice 
export const acceptProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_MOBNOTICE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

// service division declines mob notice 
export const declineProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_MOBNOTICE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

// reviews the ProjectMob
export const reviewProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_MOBNOTICE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


// confirm mobilization the MOBNOTICE
export const confirmProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CONFIRM_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/confirm/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CONFIRM_MOBNOTICE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CONFIRM_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const demobProjectMob = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DEMOB_MOBNOTICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/notice/demob/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DEMOB_MOBNOTICE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DEMOB_MOBNOTICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


//////////////////////////////////////////////
/////////// Equipment Manifest ///////////////
//////////////////////////////////////////////


