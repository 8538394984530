import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
    TextField,
    Button,
    IconButton,
    Backdrop,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../../../actions/userActions";
import { getOffices } from "../../../../actions/officeActions";
import { createAStore, getAllStores } from "../../../../actions/inventoryActions";
import Feedback from "../../../atoms/Feedback";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },
    btn: {
        margin: "30px 0",
        width: 300,
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down("sm")]: {
            width: 200,
            // minWidth: "100%",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 10000,
        color: "#fff",
    },
}));

const INIT_STATE = {
    name: "",
    keeper: "",
    owner: "",
    category: "",
    office: "",
    code: "",
};

const CreateStoreModal = ({ openCreate, handleCloseCreate }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const { error, loading } = useSelector((state) => state.stores);
    const { users } = useSelector((state) => state.user);
    const { offices } = useSelector((state) => state.office);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        let abortController = new AbortController();

        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
        return () => {
            abortController.abort();
            dispatch({
                type: "RESET_STORE",
            });
        };
    }, [error, dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getOffices());
    }, [dispatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await new Promise((resolve, reject) => {
            resolve(dispatch(createAStore(state)));
        });
        if (res) {
            handleCloseCreate();
            dispatch(getAllStores());
            setState(INIT_STATE);
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Store Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/inventory/stores`);
            }, 2000);
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isValid = () =>
        !state.name || !state.keeper || !state.owner || !state.category || !state.office || !state.code;

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            <Dialog
                open={openCreate}
                onClose={handleCloseCreate}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Create Store
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseCreate}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justify="center" alignItems="center">
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Store Name"
                                    value={state.name || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Store Keeper</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "keeper",
                                            name: "keeper",
                                        }}
                                        value={state.keeper}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select User</MenuItem>
                                        {users.map((user) => (
                                            <MenuItem key={user._id} value={user._id}>
                                                {user.firstName} {user.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="code"
                                    name="code"
                                    label="Code"
                                    value={state.code || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Store Office</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "office",
                                            name: "office",
                                        }}
                                        value={state.office}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select Office</MenuItem>
                                        {offices.map((office) => (
                                            <MenuItem key={office._id} value={office._id}>
                                                {office.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Store Category</InputLabel>
                                    <Select
                                        name="category"
                                        inputProps={{ id: "category", }}
                                        value={state.category || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="" disabled><em>Select</em></MenuItem>
                                        <MenuItem value="Onshore">Onshore</MenuItem>
                                        <MenuItem value="Offshore">Offshore</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Store Owner</InputLabel>
                                    <Select
                                        name="owner"
                                        inputProps={{ id: "owner", }}
                                        value={state.owner || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="" disabled><em>Select</em></MenuItem>
                                        <MenuItem value="Own">Own Facility</MenuItem>
                                        <MenuItem value="Customer">Customer Facility</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                color="primary"
                                className={classes.btn}
                                disabled={isValid() || loading}
                                endIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="primary" />
                                    ) : null
                                }
                            >
                                {loading ? "Loading..." : "Create"}
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default CreateStoreModal;