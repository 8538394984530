import {
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_FAIL,
  CREATE_CUSTOMER_REQUEST,
  GET_CUSTOMERS,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMER,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_REQUEST,
} from "../types";

import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/sales/customer`;

export const createCustomer = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_CUSTOMER_REQUEST,
    });
    const { data } = await axios.post(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_CUSTOMER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_CUSTOMER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getCustomers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMERS_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_CUSTOMERS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMERS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_REQUEST,
    });
    const { data } = await axios.get(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_CUSTOMER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateCustomer = (body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CUSTOMER_REQUEST,
    });
    const { data } = await axios.put(`${URL}/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_CUSTOMER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CUSTOMER_FAIL,
      payload:
        error && error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_CUSTOMER_REQUEST,
    });
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_CUSTOMER,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUSTOMER_FAIL,
      payload:
        error && error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
