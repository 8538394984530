import * as types from "../../types/inventory";

const initState = {
    requisitions: [],
    requisition: {},
    error: null,
    success: false,
    loading: false,
};

const requisitionReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_REQUISITIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_REQUISITIONS:
            return {
                ...state,
                loading: false,
                success: true,
                requisitions: action.payload.data,
            };
        case types.GET_ALL_REQUISITIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
                requisition: action.payload.data,
            };
        case types.GET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
                requisition: state.requisitions.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_REQUISITION:
            return {
                ...state,
                loading: false,
                success: false,
                requisitions: state.requisitions.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.SUBMIT_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.ACCEPT_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.DECLINE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.CONSENT_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CONSENT_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.CONSENT_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REFUSE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REFUSE_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.REFUSE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.CONFIRM_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CONFIRM_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.CONFIRM_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.REJECT_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ISSUE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ISSUE_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.ISSUE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.CANCEL_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CANCEL_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.CANCEL_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.COLLECT_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.COLLECT_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.COLLECT_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.LEAVE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.LEAVE_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.LEAVE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.TRANSFER_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.TRANSFER_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.TRANSFER_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RECEIVE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.RECEIVE_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.RECEIVE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RETURN_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.RETURN_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.RETURN_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.COMPLETE_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.COMPLETE_REQUISITION:
            return {
                ...state,
                loading: false,
                requisition: action.payload.data,
            };
        case types.COMPLETE_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.RESET_REQUISITION:
            return {
                requisition: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default requisitionReducer;
