import React, { useEffect } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Divider,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import PersonnelPriceList from "./PersonnelPriceList";
import EquipmentPriceList from "./EquipmentPriceList";
import MobilizationPriceList from "./MobilizationPriceList";
import MaterialPriceList from "./MaterialPriceList";
import { useDispatch, useSelector } from "react-redux";
import { getAllPersonnelPriceList } from "../../../../actions/masterListActions/personnelActions";
import { getAllEquipmentPriceList } from "../../../../actions/masterListActions/equipmentAction";
import { getAllMobilizationPriceList } from "../../../../actions/masterListActions/mobilizationActions";
import { getAllMaterialPriceList } from "../../../../actions/masterListActions/materialActions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    fontWeight: "bold"
  },
  title2: {
    display: "block",
    fontSize: 14,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
}));

const Index = () => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { loading, personnels } = useSelector((state) => state.personnel);
  const { equipments } = useSelector((state) => state.equipment);
  const { mobs } = useSelector((state) => state.mob);
  const { materials } = useSelector((state) => state.material);

  useEffect(() => {
    dispatch(getAllPersonnelPriceList());
    dispatch(getAllEquipmentPriceList());
    dispatch(getAllMobilizationPriceList());
    dispatch(getAllMaterialPriceList());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Typography variant="overline" className={classes.title} gutterBottom>
        Master Price List
      </Typography>

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        variant={matches ? "scrollable" : "fullWidth"}
        scrollButtons={matches ? "on" : "off"}
      >
        <Tab
          label={
            <Typography className={classes.title2} gutterBottom>
              Personnel
            </Typography>
          }
          fullWidth
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Typography className={classes.title2} gutterBottom>
              Equipment
            </Typography>
          }
          fullWidth
          {...a11yProps(1)}
        />
        <Tab
          label={
            <Typography className={classes.title2} gutterBottom>
              MOB/DEMOB
            </Typography>
          }
          fullWidth
          {...a11yProps(2)}
        />
        <Tab
          label={
            <Typography className={classes.title2} gutterBottom>
              Materials
            </Typography>
          }
          fullWidth
          {...a11yProps(3)}
        />
        {/* <Tab
          label={
            <Typography className={classes.title2} gutterBottom>
              Unit of measures
            </Typography>
          }
          fullWidth
          {...a11yProps(4)}
        /> */}
      </Tabs>
      <Divider />

      <TabPanel value={value} index={0}>
        <PersonnelPriceList {...{ loading, personnels }} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EquipmentPriceList {...{ loading, equipments }} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MobilizationPriceList {...{ loading, mobs }} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MaterialPriceList {...{ loading, materials }} />
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        Item Five
      </TabPanel> */}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Index;
