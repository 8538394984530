import {
  GET_COST_CODE,
  CREATE_COST_CODE,
  GET_ALL_COST_CODES,
  DELETE_COST_CODE,
  UPDATE_COST_CODE,
  COST_CODE_ERROR,
  GET_COST_CODES_BY_TYPE,
} from "../types";

const initState = {
  costcodes: [],
  costcode: {},
  error: null,
};

const costCodeReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_COST_CODES:
      return {
        ...state,
        costcodes: action.payload.data,
      };
    case GET_COST_CODE:
      return {
        ...state,
        costcode: action.payload,
      };
    case GET_COST_CODES_BY_TYPE:
      return {
        ...state,
        costcodes: action.payload,
      };
    case CREATE_COST_CODE:
      return {
        ...state,
        costcode: action.payload,
      };
    case UPDATE_COST_CODE:
      return {
        ...state,
        costcode: action.payload,
      };
    case DELETE_COST_CODE:
      return {
        ...state,
        costcodes: state.costcodes.filter(
          (code) => code._id !== action.payload
        ),
      };
    case COST_CODE_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default costCodeReducer;
