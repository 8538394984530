import * as types from "../../../types/priceListType";

const initState = {
    mobs: [],
    mob: null,
    loading: false,
    error: null,
    success: false,
};

const mobilizationReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_MOBILIZATION_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_MOBILIZATION_PRICE_LIST:
            return {
                ...state,
                loading: false,
                success: true,
                mob: action.payload,
            };
        case types.CREATE_MOBILIZATION_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.GET_ALL_MOBILIZATION_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_MOBILIZATION_PRICE_LIST:
            return {
                ...state,
                loading: false,
                mobs: action.payload,
            };
        case types.GET_ALL_MOBILIZATION_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.RESET_MOBILIZATION_PRICE_LIST:
            return {
                mob: null,
                loading: false,
                error: null,
                success: false,
            };
        case types.GET_MOBILIZATION_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_MOBILIZATION_PRICE_LIST:
            return {
                ...state,
                loading: false,
                mob: action.payload.data,
            };
        case types.GET_MOBILIZATION_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_MOBILIZATION_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_MOBILIZATION_PRICE_LIST:
            return {
                ...state,
                loading: false,
                mobs: state.mobs.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_MOBILIZATION_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DELETE_MOBILIZATION_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_MOBILIZATION_PRICE_LIST:
            return {
                ...state,
                loading: false,
                mobs: state.mobs.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_MOBILIZATION_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default mobilizationReducer;
