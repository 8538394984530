import axios from "axios";
import * as types from "../../types/priceListType";

const token = localStorage.getItem("token:key");

export const createMobilizationPriceList = (data) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_MOBILIZATION_PRICE_LIST_REQUEST,
        });
        const URL = `/api/v1/pricelist/mob`;
        const res = await axios.post(URL, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_MOBILIZATION_PRICE_LIST,
            payload: res.data?.data,
        });
    } catch (error) {
        dispatch({
            type: types.CREATE_MOBILIZATION_PRICE_LIST_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAllMobilizationPriceList = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_MOBILIZATION_PRICE_LIST_REQUEST,
        });
        const URL = `/api/v1/pricelist/mob`;
        const res = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_MOBILIZATION_PRICE_LIST,
            payload: res.data?.data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_ALL_MOBILIZATION_PRICE_LIST_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateMobilizationPriceList = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_MOBILIZATION_PRICE_LIST_REQUEST,
        });
        const URL = `/api/v1/pricelist/mob`;
        const { data } = await axios.put(`${URL}/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_MOBILIZATION_PRICE_LIST,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_MOBILIZATION_PRICE_LIST_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteMobilizationPriceList = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_MOBILIZATION_PRICE_LIST_REQUEST,
        });
        const URL = `/api/v1/pricelist/mob`;
        await axios.delete(`${URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_MOBILIZATION_PRICE_LIST,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_MOBILIZATION_PRICE_LIST_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getMobilizationPriceListById = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_MOBILIZATION_PRICE_LIST_REQUEST,
        });
        const URL = `/api/v1/pricelist/mob`;
        const { data } = await axios.get(`${URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_MOBILIZATION_PRICE_LIST,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_MOBILIZATION_PRICE_LIST_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
