import {
  CREATE_SEQUENCE,
  UPDATE_SEQUENCE,
  GET_ALL_SEQUENCES,
  GET_SEQUENCE,
  DELETE_SEQUENCE,
  SEQUENCE_ERROR,
} from "../types/";

const initState = {
  sequences: [],
  sequence: {},
  error: null,
};

const sequenceReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_SEQUENCES:
      return {
        ...state,
        sequences: action.payload,
      };
    case GET_SEQUENCE:
      return {
        ...state,
        sequence: action.payload,
      };
    case CREATE_SEQUENCE:
      return {
        ...state,
        sequence: action.payload,
      };
    case UPDATE_SEQUENCE:
      return {
        ...state,
        sequences: state.sequences.map((sequence) =>
          sequence._id === action.payload.data._id
            ? (sequence = action.payload.data)
            : sequence
        ),
      };
    case DELETE_SEQUENCE:
      return {
        ...state,
        sequences: state.sequences.filter(
          (seq) => seq._id !== action.payload.data._id
        ),
      };
    case SEQUENCE_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default sequenceReducer;
