import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  Divider,
  darken,
  Backdrop,
  ButtonGroup,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";

import clsx from "clsx";
import {
  isCashManager,
  isCashApprover,
  isCashApproverCeo,
} from "../../Utils/guards";
import { getReimbursementById } from "../../../actions/reimburseActions";
import { DocumentsTable } from "./DocumentsTable";
import ReimbursementItemTable from "./ReimbursementItemTable";
import ReimburseApprovalModal from "./modals/ReimburseApprovalModal";
import RejectReimbursementModal from "./modals/RejectReimbursementModal";

import ReimburseReviewModal from "./modals/ReimburseReviewModal";
import DeclineReimbursementModal from "./modals/DeclineReimbursementModal";

import EmployeeReimbursementPaymentModal from "./modals/EmployeeReimbursementPaymentModal";
import { formatCurrency } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  submitBtn: {
    // margin: "30px 0",
    marginLeft: 10,
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    // padding: theme.spacing(2),
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  paper3: {
    marginTop: 20,
    marginBottom: 20,
    padding: 20,
  },
  info: {
    fontWeight: 400,
    color: "rgba(70, 61, 61, 0.87)",
  },
  textGreen: {
    color: "#388e3c",
    fontSize: 15,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const INIT_STATE = {
  employeeName: "",
  employeeId: "",
  division: "",
  month: "",
  year: "",
  purpose: "",
  costType: "",
  costCenter: "",
  rate: 1,
  items: [
    {
      item: "",
      amount: 0,
      description: "",
    },
  ],
  status: "",
  total: 0,
  currency: "",
  convertedTotal: 0,
  documents: [],
  amountToReimburse: 0,
  documentNo: "",
};

const ViewReimbursement = ({
  profile,
  getReimbursementById,
  reimbursement,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [state, setState] = useState(INIT_STATE);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openPay, setOpenPay] = useState(false);

  const getReimbursementByIdCallback = useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getReimbursementById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [getReimbursementById, id]);

  useEffect(() => {
    getReimbursementByIdCallback();
  }, [getReimbursementByIdCallback]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: reimbursement && reimbursement.employee,
      employeeId: reimbursement && reimbursement.employeeId,
      division:
        reimbursement && reimbursement.division && reimbursement.division.name,
      month: reimbursement && reimbursement.month,
      year:
        reimbursement && reimbursement.year && reimbursement.year.toString(),
      purpose: reimbursement && reimbursement.purpose,
      costType: reimbursement && reimbursement.costType,
      costCenter: reimbursement && reimbursement.costCenter,
      rate: reimbursement && reimbursement.rate,
      status: reimbursement && reimbursement.status,
      items: reimbursement && reimbursement.items,
      amountToReimburse: reimbursement && reimbursement.amountToReimburse,
      currency: reimbursement && reimbursement.currency,
      convertedTotal: reimbursement && reimbursement.convertedTotal,
      documents:
        (reimbursement &&
          reimbursement.retirement &&
          reimbursement.retirement.documents) ||
        (reimbursement && reimbursement.documents),
      documentNo: reimbursement && reimbursement.documentNo,
    }));
  }, [reimbursement]);

  const handleOpenApprove = () => {
    setOpenApprove(true);
  };
  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const handleOpenReject = () => {
    setOpenReject(true);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const handleOpenReview = () => {
    setOpenReview(true);
  };
  const handleCloseReview = () => {
    setOpenReview(false);
  };
  const handleOpenDecline = () => {
    setOpenDecline(true);
  };
  const handleCloseDecline = () => {
    setOpenDecline(false);
  };

  const handleOpenPay = () => {
    setOpenPay(true);
  };

  const handleClosePay = () => {
    setOpenPay(false);
  };

  return (
    <div>
      <ReimburseApprovalModal {...{ openApprove, handleCloseApprove, id }} />
      <RejectReimbursementModal {...{ openReject, handleCloseReject, id }} />

      <ReimburseReviewModal {...{ openReview, handleCloseReview, id }} />
      <DeclineReimbursementModal {...{ openDecline, handleCloseDecline, id }} />

      <EmployeeReimbursementPaymentModal
        {...{ openPay, handleClosePay, currency: state.currency }}
      />
      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <Grid item>
          <Typography className={classes.title} variant="overline">
            View Reimbursement
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIosIcon />}
            className={classes.backBtn}
          >
            Back
          </Button>

          {state.status === "to reimburse" &&
            (profile && profile._id) ===
            (reimbursement &&
              reimbursement.createdBy &&
              reimbursement.createdBy._id) ? (
            <Button
              color="primary"
              variant="contained"
              className={classes.submitBtn}
              onClick={handleOpenPay}
            >
              Refund
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              state.status === "draft"
                ? classes.statusYellow
                : state.status === "submitted" ||
                  state.status === "tomodify" ||
                  state.status === "modified" ||
                  state.status === "to be reimbursed" ||
                  state.status === "to reimburse" ||
                  state.status === "reimburse"
                  ? classes.statusOrange
                  : state.status === "approved" || state.status === "confirmed"
                    ? classes.statusGreen
                    : state.status === "completed" ||
                      state.status === "reviewed" ||
                      state.status === "retired" ||
                      state.status === "paid" ||
                      state.status === "reimbursed"
                      ? classes.statusDarkGreen
                      : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop
              ? "Loading..."
              : state.status && state.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        style={{ marginBottom: 30 }}
      >
        <Grid item></Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(classes.status)}
          // style={{ marginBottom: 30, marginTop: 5 }}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Doc No.
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop ? "Loading..." : state.documentNo}
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            label="Employee Name"
            variant="outlined"
            fullWidth
            value={state.employeeName || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="EID"
            variant="outlined"
            fullWidth
            value={state.employeeId || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Division/Subdivison"
            variant="outlined"
            fullWidth
            value={state.division || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Month"
            variant="outlined"
            fullWidth
            value={state.month || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Year"
            variant="outlined"
            fullWidth
            value={state.year || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Purpose"
            variant="outlined"
            fullWidth
            value={state.purpose || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Cost Type"
            variant="outlined"
            fullWidth
            value={state.costType || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Cost Center"
            variant="outlined"
            fullWidth
            value={state.costCenter || ""}
            disabled
          />
        </Grid>
      </Grid>
      {state.status === "to reimburse" ||
        state.status === "to be reimbursed" ? (
        <Paper className={classes.paper3}>
          <Typography component="p" variant="overline" className={classes.info}>
            Amount Requested:{" "}
            <span className={classes.textGreen}>
              {formatCurrency(
                (reimbursement && reimbursement.amount) ||
                (reimbursement && reimbursement.amountToReimburse) ||
                0,
                "en-NG",
                reimbursement.currency
              )}
            </span>
          </Typography>

          <Typography component="p" variant="overline" className={classes.info}>
            Amount Received:{" "}
            <span className={classes.textGreen}>
              {formatCurrency(
                (reimbursement &&
                  reimbursement.payment &&
                  reimbursement.payment.amount) ||
                0,
                "en-NG",
                reimbursement.currency
              )}
            </span>
          </Typography>
          <Typography component="p" variant="overline" className={classes.info}>
            Amount Retired:{" "}
            <span className={classes.textGreen}>
              {formatCurrency(
                (reimbursement &&
                  reimbursement.retirement &&
                  reimbursement.retirement.amount) ||
                0,
                "en-NG",
                reimbursement.currency
              )}
            </span>
          </Typography>
        </Paper>
      ) : null}
      <Divider className={classes.divider} />
      <DocumentsTable {...{ documents: state.documents, isDel: false }} />

      <ReimbursementItemTable
        {...{
          items: state.items,
          amountToReimburse: state.amountToReimburse,
          currency: state.currency,
          convertedTotal: state.convertedTotal,
        }}
      />

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Line Manager reviews or declines the reimbursement */}
      {state.status === "submitted" &&
        isCashManager(profile) && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 30 }}
            spacing={3}
          >
            <Grid item>
              <ButtonGroup>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenReview}
                  className={classes.saveBtn}
                >
                  Review
                </Button>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenDecline}
                  className={classes.saveBtn}
                >
                  Decline
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}

      {state.status === "reviewed" &&
        isCashApprover(profile) &&
        +state.amountToReimburse <= 500000 && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 30 }}
            spacing={3}
          >
            <Grid item>
              <ButtonGroup>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenReject}
                  className={classes.saveBtn}
                >
                  Reject
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenApprove}
                  className={classes.saveBtn}
                >
                  Approve
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}

      {state.status === "reviewed" &&
        isCashApproverCeo(profile) &&
        +state.amountToReimburse > 500000 && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 30 }}
            spacing={3}
          >
            <Grid item>
              <ButtonGroup>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenReject}
                  className={classes.saveBtn}
                >
                  Reject
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenApprove}
                  className={classes.saveBtn}
                >
                  Approve
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  reimbursement: state.reimburse.reimbursement,
  profile: state.user.profile,
});

const mapDispatchToProps = {
  getReimbursementById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewReimbursement);
