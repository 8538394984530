import React, { useState, useEffect } from "react";
import {
    Typography,
    makeStyles,
    Grid,
    Divider,
    Button,
    darken,
    Paper,
    Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionActions from '@material-ui/core/ExpansionPanelActions';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../atoms/Feedback";
import { getLesson } from "../../../actions/ppmActions/lmsActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
        marginBottom: 20,
        borderBottomColor:'#ccc',
        borderBottomWidth:1,
        borderBottomStyle:'solid',
        paddingBottom: 20,
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper3: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: darken("#fff", 0.008),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
}));

const INIT_STATE = {
    title: "",
    description: "",
    image:"",
    code:"",
    modules:[],
    quizzes: [],
};

const LessonView = () => {    
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const { error, loading, lesson } = useSelector((state) => state.lms);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    console.log(lesson)

    useEffect(() => {
        dispatch(getLesson(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            title: (lesson && lesson.title) || "",
            code: (lesson && lesson.code) || "",
            description: (lesson && lesson.description) || "",
            image: (lesson && lesson.image) || "",
            modules:  (lesson && lesson.modules) || [],
            quizzes:  (lesson && lesson.quizzes) || [],
        }));
    }, [lesson]);

    const navigateById = (path, id) => {
        history.push(`${path}/${id}`);
    };
    
    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            
            <Typography variant="overline" className={classes.title}>
                {state.title && state.title}
            </Typography>

            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Lesson Code.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.code}
                    </Paper>
                </Grid>
            </Grid>
            
            <Paper variant="outlined" square className={classes.paper} style={{ marginTop: 20 }}>
                <Typography variant="overline" className={classes.title2}>
                    Lesson Topics
                </Typography>


                <Grid container spacing={2} className={classes.grid}>
                    {state.modules && state.modules.map((row, i) => {
                        return (
                            <Grid item xs={12} md={12} key={i}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        {row.title && row.title}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {row.description && row.description}
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Button 
                                            className="btn"
                                            onClick={() => navigateById("/lesson", row._id)}
                                        >
                                            Open Lesson
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default LessonView;
