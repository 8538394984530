import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";

import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  CircularProgress,
  Grid,
  IconButton,
} from "@material-ui/core";
import Feedback from "../../../atoms/Feedback";

import { useHistory } from "react-router-dom";
import {
  financeAllowCashRecovery,
  getAllCashRecovery,
} from "../../../../actions/cashRecoveryAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const RecoverModal = ({
  openRecover,
  handleCloseRecover,
  financeAllowCashRecovery,
  getAllCashRecovery,
  error,
  id,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState("");
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    e.persist();
    setType(e.target.value);
  };

  const handleRecover = () => {
    setLoading((prev) => !prev);
    const data = {
      id,
      type,
      path: "recovery/log/view",
    };
    financeAllowCashRecovery(data)
      .then(() => {
        setLoading(false);
        setFeed((prev) => ({
          ...prev,
          loading: false,
          open: !prev.open,
          message: `You have successfully Recovered this cash recovery.`,
          success: true,
        }));
        setType("");
        dispatch(getAllCashRecovery);
      })
      .catch(() => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          // message: error && error.error,
          // success: false,
        });
      })
      .finally(() => {
        handleCloseRecover();
        setTimeout(() => {
          history.push("/recovery/review");
        }, 1500);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.success === false ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message || `You have successfully Exempted this recovery.`
          }
        />
      )}
      <Dialog
        open={openRecover}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRecover}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {"Recover Cash?"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseRecover}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Recovery Type</InputLabel>
            <Select
              id="type"
              name="type"
              inputProps={{
                id: "type",
              }}
              onChange={handleChange}
              value={type}
            >
              <MenuItem disabled>---- SELECT TYPE ----</MenuItem>
              <MenuItem value="Salary Deduction">Salary Deduction</MenuItem>
              <MenuItem value="Salary Hold">Salary Hold</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseRecover} className={classes.saveBtn}>
              Cancel
            </Button>
            <Button
              onClick={handleRecover}
              color="primary"
              disabled={loading || !type}
              className={classes.saveBtn}
              variant="contained"
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "To Recover"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.cashRecovery.cashRecoveryError,
});

const mapDispatchToProps = {
  financeAllowCashRecovery,
  getAllCashRecovery,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverModal);
