import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Button,
  Divider,
  darken,
  Backdrop,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";

import clsx from "clsx";
import { getReimbursementById } from "../../../actions/reimburseActions";
import ReimbursementItemTable from "./ReimbursementItemTable";
import FinanceReimbursementPaymentModal from "./modals/FinanceReimbursementPaymentModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    marginBottom: 20,
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  paper: {
    padding: theme.spacing(2),
    marginBottom: 30,

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  submitBtn: {
    marginLeft: 10,
    // padding: theme.spacing(2),
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  avatar: {
    marginTop: -25,
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: `5px solid ${theme.palette.background.paper}`,
    // zIndex: 10000,
  },
  docs: {
    // padding: theme.spacing(3),
    overflow: "hidden",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const INIT_STATE = {
  employeeName: "",
  employeeId: "",
  division: "",
  month: "",
  year: "",
  purpose: "",
  costType: "",
  costCenter: "",
  rate: 1,
  items: [
    {
      item: "",
      amount: 0,
      description: "",
    },
  ],
  status: "",
  amountToReimburse: 0,
  currency: "",
  convertedTotal: 0,
  documents: [],
};
const ViewReimbursePayment = ({
  getReimbursementById,
  reimbursement,
  profile,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [state, setState] = useState(INIT_STATE);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openPay, setOpenPay] = useState(false);

  const getReimbursementByIdCallback = useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getReimbursementById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [getReimbursementById, id]);

  useEffect(() => {
    getReimbursementByIdCallback();
  }, [getReimbursementByIdCallback]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: reimbursement && reimbursement.employee,
      employeeId: reimbursement && reimbursement.employeeId,
      division:
        reimbursement && reimbursement.division && reimbursement.division.name,
      month: reimbursement && reimbursement.month,
      year:
        reimbursement && reimbursement.year && reimbursement.year.toString(),
      purpose: reimbursement && reimbursement.purpose,
      costType: reimbursement && reimbursement.costType,
      costCenter: reimbursement && reimbursement.costCenter,
      rate: reimbursement && reimbursement.rate,
      status: reimbursement && reimbursement.status,
      items: reimbursement && reimbursement.items,
      amountToReimburse: reimbursement && reimbursement.amountToReimburse,
      currency: reimbursement && reimbursement.currency,
      convertedTotal: reimbursement && reimbursement.convertedTotal,
      documents:
        (reimbursement &&
          reimbursement.retirement &&
          reimbursement.retirement.documents) ||
        (reimbursement && reimbursement.documents),
    }));
  }, [reimbursement]);

  const handleOpenPay = () => {
    setOpenPay(true);
  };

  const handleClosePay = () => {
    setOpenPay(false);
  };

  return (
    <div>
      <FinanceReimbursementPaymentModal
        {...{ openPay, handleClosePay, currency: state.currency }}
      />
      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIosIcon />}
            className={classes.backBtn}
          >
            Back
          </Button>

          {state.status !== "paid" || state.status !== "reimbursed" ? (
            <Button
              color="primary"
              variant="contained"
              className={classes.submitBtn}
              onClick={handleOpenPay}
            >
              Refund
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              state.status === "draft"
                ? classes.statusYellow
                : state.status === "submitted" ||
                  state.status === "tomodify" ||
                  state.status === "modified" ||
                  state.status === "to be reimbursed" ||
                  state.status === "to reimburse"
                ? classes.statusOrange
                : state.status === "approved" || state.status === "confirmed"
                ? classes.statusGreen
                : state.status === "completed" ||
                  state.status === "reviewed" ||
                  state.status === "retired" ||
                  state.status === "reimbursed"
                ? classes.statusDarkGreen
                : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop
              ? "Loading..."
              : state.status && state.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={3}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="overline" className={classes.title2}>
            Requestor Info.
          </Typography>

          <Paper className={classes.paper}>
            {/* <Grid container justify="center" alignItems="center">
              <Grid item>
                <Avatar className={classes.avatar} />
              </Grid>
            </Grid> */}
            <Typography variant="overline" className={classes.title2}>
              <strong>Name</strong>: {state.employeeName || "Loading..."}
            </Typography>
            <Typography variant="overline" className={classes.title2}>
              <strong>Employee ID</strong>: {state.employeeId || "Loading..."}
            </Typography>
            <Typography variant="overline" className={classes.title2}>
              <strong>Division</strong>: {state.division || "Loading..."}
            </Typography>
            <Typography variant="overline" className={classes.title2}>
              <strong> Month</strong>: {state.month || "Loading..."}
            </Typography>
            <Typography variant="overline" className={classes.title2}>
              <strong>Year</strong>: {state.year || "Loading..."}
            </Typography>
            <Typography variant="overline" className={classes.title2}>
              <strong>Purpose</strong>: {state.purpose || "Loading..."}
            </Typography>
            <Typography variant="overline" className={classes.title2}>
              <strong>Cost Type</strong>: {state.costType || "Loading..."}
            </Typography>
            <Typography variant="overline" className={classes.title2}>
              <strong>Cost Center</strong>: {state.costCenter || "Loading..."}
            </Typography>
            <Divider light />
            <Typography variant="overline" className={classes.title2}>
              Document(s)
            </Typography>
            <ul className={classes.docs}>
              {state.documents &&
                state.documents.map((doc, i) => (
                  // <li key={i}>
                  //   <a href={doc} target="_blank" rel="noopener noreferrer">
                  //     {doc}
                  //   </a>

                  // </li>
                  <li divider key={i}>
                    <span>{i + 1}.</span>
                    <a href={doc} target="_blank" rel="noopener noreferrer">
                      {/* {doc} */}
                      <Typography variant="overline" color="textSecondary">
                        {" "}
                        Document &mdash; {i + 1}
                      </Typography>
                    </a>
                  </li>
                ))}
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <ReimbursementItemTable
            {...{
              items: state.items,
              amountToReimburse: state.amountToReimburse,
              currency: state.currency,
              convertedTotal: state.convertedTotal,
            }}
          />
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reimbursement: state.reimburse.reimbursement,
  profile: state.user.profile,
});

const mapDispatchToProps = {
  getReimbursementById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewReimbursePayment);
