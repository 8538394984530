import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  ButtonGroup,
  Button,
  DialogActions,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Feedback from "../../../atoms/Feedback";
import { useHistory } from "react-router-dom";
import {
  getMonthlyBudgetById,
  getMonthlyBudgets,
  submitMonthlyBudget,
} from "../../../../actions/monthlyBudgetAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const SubmitMonthlyDeclinedModal = ({
  openDecline,
  handleCloseDecline,
  id,
  monthlyBudget,
  getMonthlyBudgetById,
  getMonthlyBudgets,
  submitMonthlyBudget,
  error,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const submitBudget = () => {
    setLoading((prev) => !prev);

    const data = {
      id,
      path: "budget/monthly/view",
    };

    submitMonthlyBudget(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,

          success: true,
        });
        dispatch(getMonthlyBudgets);
        dispatch(getMonthlyBudgetById(id));
      })
      .catch(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,

          success: false,
        });
      })
      .finally(() => {
        setTimeout(() => {
          history.push("/budget/monthly");
        }, 2000);
        handleCloseDecline();
      });
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error) || feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message ||
            `Your Monthly Budget has been modified and re-submitted for Approval.`
          }
        />
      )}
      <Dialog
        open={openDecline}
        onClose={handleCloseDecline}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Submit Budget?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDecline}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="overline" className={classes.title3}>
            Note : Once submitted, it's assumed you're done preparing this
            budget and won't be available for modification except if rejected.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              color="secondary"
              onClick={handleCloseDecline}
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              onClick={submitBudget}
              variant="contained"
              className={classes.saveBtn}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  monthlyBudget:
    state.monthlyBudget.monthlyBudget && state.monthlyBudget.monthlyBudget.data,
  error: state.monthlyBudget.budgetLineError,
});

const mapDispatchToProps = {
  getMonthlyBudgetById,
  getMonthlyBudgets,
  submitMonthlyBudget,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitMonthlyDeclinedModal);
