module.exports = {
    // CHANGE REQUEST FORMS
    CREATE_CRF: "CREATE_CRF",
    CREATE_CRF_REQUEST: "CREATE_CRF_REQUEST",
    CREATE_CRF_FAIL: "CREATE_CRF_FAIL",
    SUBMIT_CRF: "SUBMIT_CRF",
    SUBMIT_CRF_REQUEST: "SUBMIT_CRF_REQUEST",
    SUBMIT_CRF_FAIL: "SUBMIT_CRF_FAIL",
    UPDATE_CRF: "UPDATE_CRF",
    UPDATE_CRF_REQUEST: "UPDATE_CRF_REQUEST",
    UPDATE_CRF_FAIL: "UPDATE_CRF_FAIL",
    DELETE_CRF: "DELETE_CRF",
    DELETE_CRF_REQUEST: "DELETE_CRF_REQUEST",
    DELETE_CRF_FAIL: "DELETE_CRF_FAIL",
    GET_CRFS: "GET_CRFS",
    GET_CRFS_REQUEST: "GET_CRFS_REQUEST",
    GET_CRFS_FAIL: "GET_CRFS_FAIL",
    GET_CRF: "GET_CRF",
    GET_CRF_REQUEST: "GET_CRF_REQUEST",
    GET_CRF_FAIL: "GET_CRF_FAIL",
    GET_APPROVED_CRFS: "GET_APPROVED_CRFS",
    GET_APPROVED_CRFS_REQUEST: "GET_APPROVED_CRFS_REQUEST",
    GET_APPROVED_CRFS_FAIL: "GET_APPROVED_CRFS_FAIL",
    APPROVE_CRF: "APPROVE_CRF",
    APPROVE_CRF_REQUEST: "APPROVE_CRF_REQUEST",
    APPROVE_CRF_FAIL: "APPROVE_CRF_FAIL",
    REJECT_CRF: "REJECT_CRF",
    REJECT_CRF_REQUEST: "REJECT_CRF_REQUEST",
    REJECT_CRF_FAIL: "REJECT_CRF_FAIL",
    DECLINE_CRF: "DECLINE_CRF",
    DECLINE_CRF_REQUEST: "DECLINE_CRF_REQUEST",
    DECLINE_CRF_FAIL: "DECLINE_CRF_FAIL",
    REVIEW_CRF: "REVIEW_CRF",
    REVIEW_CRF_REQUEST: "REVIEW_CRF_REQUEST",
    REVIEW_CRF_FAIL: "REVIEW_CRF_FAIL",
    RESET_CRF: "RESET_CRF",


    // CHANGE IMPACT FORMS
    CREATE_CIM: "CREATE_CIM",
    CREATE_CIM_REQUEST: "CREATE_CIM_REQUEST",
    CREATE_CIM_FAIL: "CREATE_CIM_FAIL",
    SUBMIT_CIM: "SUBMIT_CIM",
    SUBMIT_CIM_REQUEST: "SUBMIT_CIM_REQUEST",
    SUBMIT_CIM_FAIL: "SUBMIT_CIM_FAIL",
    UPDATE_CIM: "UPDATE_CIM",
    UPDATE_CIM_REQUEST: "UPDATE_CIM_REQUEST",
    UPDATE_CIM_FAIL: "UPDATE_CIM_FAIL",
    DELETE_CIM: "DELETE_CIM",
    DELETE_CIM_REQUEST: "DELETE_CIM_REQUEST",
    DELETE_CIM_FAIL: "DELETE_CIM_FAIL",
    GET_CIMS: "GET_CIMS",
    GET_CIMS_REQUEST: "GET_CIMS_REQUEST",
    GET_CIMS_FAIL: "GET_CIMS_FAIL",
    GET_CIM: "GET_CIM",
    GET_CIM_REQUEST: "GET_CIM_REQUEST",
    GET_CIM_FAIL: "GET_CIM_FAIL",
    GET_APPROVED_CIMS: "GET_APPROVED_CIMS",
    GET_APPROVED_CIMS_REQUEST: "GET_APPROVED_CIMS_REQUEST",
    GET_APPROVED_CIMS_FAIL: "GET_APPROVED_CIMS_FAIL",
    APPROVE_CIM: "APPROVE_CIM",
    APPROVE_CIM_REQUEST: "APPROVE_CIM_REQUEST",
    APPROVE_CIM_FAIL: "APPROVE_CIM_FAIL",
    REJECT_CIM: "REJECT_CIM",
    REJECT_CIM_REQUEST: "REJECT_CIM_REQUEST",
    REJECT_CIM_FAIL: "REJECT_CIM_FAIL",
    DECLINE_CIM: "DECLINE_CIM",
    DECLINE_CIM_REQUEST: "DECLINE_CIM_REQUEST",
    DECLINE_CIM_FAIL: "DECLINE_CIM_FAIL",
    REVIEW_CIM: "REVIEW_CIM",
    REVIEW_CIM_REQUEST: "REVIEW_CIM_REQUEST",
    REVIEW_CIM_FAIL: "REVIEW_CIM_FAIL",
    RESET_CIM: "RESET_CIM",


    // FIELD FORCE ANALYSIS
    CREATE_FIELD: "CREATE_FIELD",
    CREATE_FIELD_REQUEST: "CREATE_FIELD_REQUEST",
    CREATE_FIELD_FAIL: "CREATE_FIELD_FAIL",
    SUBMIT_FIELD: "SUBMIT_FIELD",
    SUBMIT_FIELD_REQUEST: "SUBMIT_FIELD_REQUEST",
    SUBMIT_FIELD_FAIL: "SUBMIT_FIELD_FAIL",
    UPDATE_FIELD: "UPDATE_FIELD",
    UPDATE_FIELD_REQUEST: "UPDATE_FIELD_REQUEST",
    UPDATE_FIELD_FAIL: "UPDATE_FIELD_FAIL",
    DELETE_FIELD: "DELETE_FIELD",
    DELETE_FIELD_REQUEST: "DELETE_FIELD_REQUEST",
    DELETE_FIELD_FAIL: "DELETE_FIELD_FAIL",
    GET_FIELDS: "GET_FIELDS",
    GET_FIELDS_REQUEST: "GET_FIELDS_REQUEST",
    GET_FIELDS_FAIL: "GET_FIELDS_FAIL",
    GET_FIELD: "GET_FIELD",
    GET_FIELD_REQUEST: "GET_FIELD_REQUEST",
    GET_FIELD_FAIL: "GET_FIELD_FAIL",
    GET_APPROVED_FIELDS: "GET_APPROVED_FIELDS",
    GET_APPROVED_FIELDS_REQUEST: "GET_APPROVED_FIELDS_REQUEST",
    GET_APPROVED_FIELDS_FAIL: "GET_APPROVED_FIELDS_FAIL",
    APPROVE_FIELD: "APPROVE_FIELD",
    APPROVE_FIELD_REQUEST: "APPROVE_FIELD_REQUEST",
    APPROVE_FIELD_FAIL: "APPROVE_FIELD_FAIL",
    REJECT_FIELD: "REJECT_FIELD",
    REJECT_FIELD_REQUEST: "REJECT_FIELD_REQUEST",
    REJECT_FIELD_FAIL: "REJECT_FIELD_FAIL",
    DECLINE_FIELD: "DECLINE_FIELD",
    DECLINE_FIELD_REQUEST: "DECLINE_FIELD_REQUEST",
    DECLINE_FIELD_FAIL: "DECLINE_FIELD_FAIL",
    REVIEW_FIELD: "REVIEW_FIELD",
    REVIEW_FIELD_REQUEST: "REVIEW_FIELD_REQUEST",
    REVIEW_FIELD_FAIL: "REVIEW_FIELD_FAIL",
    RESET_FIELD: "RESET_FIELD",

};
