import {
  CREATE_JOB_TITLE,
  GET_JOB_TITLES,
  DELETE_JOB_TITLE,
  GET_JOB_TITLE_BY_ID,
  UPDATE_JOB_TITLE,
  JOB_TITLE_ERROR,
} from "../types/";

const initState = {
  jobTitle: {},
  jobTitles: [],
  error: null,
};

const jobTitleReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.payload,
      };
    case GET_JOB_TITLES:
      return {
        ...state,
        jobTitles: action.payload,
        error: action.error,
      };
    case GET_JOB_TITLE_BY_ID:
      return {
        ...state,
        jobTitle: action.payload,
      };
    case UPDATE_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.payload,
      };
    case DELETE_JOB_TITLE:
      return {
        ...state,
      };
    case JOB_TITLE_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default jobTitleReducer;
