import * as types from "../../types/assets";

const initState = {
    asset_requisitions: [],
    asset_requisition: {},
    error: null,
    success: false,
    loading: false,
};

const assetRequisitionReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_ASSET_REQUISITIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_ASSET_REQUISITIONS:
            return {
                ...state,
                loading: false,
                success: true,
                asset_requisitions: action.payload.data,
            };
        case types.GET_ALL_ASSET_REQUISITIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
                asset_requisition: action.payload.data,
            };
        case types.GET_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                success: true,
                asset_requisition: state.asset_requisitions.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                success: false,
                asset_requisitions: state.asset_requisitions.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.SUBMIT_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.APPROVE_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.APPROVE_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DISAPPROVE_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DISAPPROVE_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.DISAPPROVE_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REVIEW_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REVIEW_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.REVIEW_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.REJECT_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.ACCEPT_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.DECLINE_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.AUTHORIZE_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.AUTHORIZE_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.AUTHORIZE_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DEAUTHORIZE_ASSET_REQUISITION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DEAUTHORIZE_ASSET_REQUISITION:
            return {
                ...state,
                loading: false,
                asset_requisition: action.payload.data,
            };
        case types.DEAUTHORIZE_ASSET_REQUISITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_ASSET_REQUISITION:
            return {
                asset_requisition: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default assetRequisitionReducer;
