import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    Fab,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import {
    updateGatePass,
    submitGatePass,
    getGatePass,
    getAllGatePasses,
} from "../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: 500,
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    purpose: "",
    projectName: "",
    movementSpecification: "",
    dateCreated: "",
    items: [{
        assetDescription: "",
        assetSerialNo: "",
        previousLocation: "",
        newLocation: "",
        quantity: 1,
        condition: "Good",
        toBeReturned: "Yes",
    }],
};

const EditGatePass = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { error, loading, gatepass } = useSelector((state) => state.gatepass);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllGatePasses());
        dispatch(getGatePass(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            purpose: (gatepass && gatepass.purpose) || "",
            projectName: (gatepass && gatepass.projectName) || "",
            movementSpecification: (gatepass && gatepass.movementSpecification) || "",
            employee: (gatepass && gatepass.employee) || "",
            division: (gatepass && gatepass.division) || "",
            dateCreated: (gatepass && moment(gatepass.dateCreated).format("l")) || "",
            items: (gatepass && gatepass.items) || [],
            status: (gatepass && gatepass.status) || "",
            documentNo: (gatepass && gatepass.documentNo) || "",

        }));
    }, [gatepass]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile.userName,
            division: profile && profile.division && profile.division._id,
        }));
    }, [profile]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = () => {
        const data = {
            assetDescription: "",
            assetSerialNo: "",
            previousLocation: "",
            newLocation: "",
            quantity: 1,
            condition: "Good",
            toBeReturned: "Yes",
        };
        setState((prev) => ({
            ...prev,
            items: prev.items.concat([data]),
        }));
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleChangeItemsArray = (i) => (e) => {
        e.persist();
        const newItemsArray = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            items: newItemsArray,
        }));
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            path: "inventory/gatepass/view",
            id,
        };

        await dispatch(submitGatePass(data));
        await dispatch(getAllGatePasses);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Gate Pass Request Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/inventory/gatepass");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            division: profile && profile.division,
            id
        }

        await dispatch(updateGatePass(data));
        await dispatch(getGatePass(id));
        setTimeout(async () => {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Gate Pass Request Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" || state.status === "rejected")) : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" className={classes.title}>
                    Update Gate Pass
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/inventory/gatepass")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Paper variant="outlined" className={classes.status}>
                            <Typography variant="overline" className={classes.statusTitle}>
                                Document No.
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            {loading ? "Loading..." : state.documentNo}
                        </Paper>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ marginBottom: 20 }}
                >
                    <Hidden smDown>
                        <Grid item></Grid>
                    </Hidden>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : state.status === "accepted"
                                            ? classes.statusGreen
                                            : (state.status === "granted" || state.status === "completed")
                                                ? classes.statusDarkGreen
                                                : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmitHandler}>
                    <div>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    disabled
                                    label="Employee Name"
                                    name="employee"
                                    value={state.employee || ""}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DatePicker
                                    className={classes.marginBottom}
                                    autoOk
                                    format="DD/MM/yyyy"
                                    views={["year", "month", "date"]}
                                    fullWidth
                                    label="Date Created"
                                    disabled
                                    required
                                    name="dateCreated"
                                    inputVariant="outlined"
                                    value={state.dateCreated}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Project Name"
                                    name="projectName"
                                    value={state.projectName || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Purpose"
                                    required
                                    name="purpose"
                                    value={state.purpose || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    label="Movement Specification"
                                    variant="outlined"
                                    component="fieldset"
                                >
                                    <FormLabel>Movement Specification?</FormLabel>
                                    <RadioGroup row onChange={handleChange} value={state.movementSpecification}>
                                        <FormControlLabel
                                            value="Outward"
                                            control={<Radio />}
                                            label="Outward"
                                            name="movementSpecification"
                                        />
                                        <FormControlLabel
                                            value="Transit"
                                            control={<Radio />}
                                            label="Transit"
                                            name="movementSpecification"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* To be filled by the Requestor */}
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Select Inventory Item
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.items.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Asset Description"
                                                type="text"
                                                name="assetDescription"
                                                value={item.assetDescription}
                                                onChange={handleChangeItemsArray(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Asset Serial No"
                                                type="text"
                                                name="assetSerialNo"
                                                value={item.assetSerialNo}
                                                onChange={handleChangeItemsArray(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Previous Location"
                                                type="text"
                                                name="previousLocation"
                                                value={item.previousLocation}
                                                onChange={handleChangeItemsArray(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="New Location"
                                                type="text"
                                                name="newLocation"
                                                value={item.newLocation}
                                                onChange={handleChangeItemsArray(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity"
                                                type="text"
                                                name="quantity"
                                                value={item.quantity}
                                                onChange={handleChangeItemsArray(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <FormControl fullWidth required variant="outlined">
                                                <InputLabel id="condition">
                                                    Condition
                                                </InputLabel>
                                                <Select
                                                    labelId="condition"
                                                    required
                                                    value={item.condition || ""}
                                                    name="condition"
                                                    onChange={handleChangeItemsArray(i)}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    <MenuItem value="Good">Good</MenuItem>
                                                    <MenuItem value="Faulty">Faulty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <FormControl fullWidth required variant="outlined">
                                                <InputLabel id="toBeReturned">
                                                    To Be Returned?
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId="toBeReturned"
                                                    value={item.toBeReturned || ""}
                                                    name="toBeReturned"
                                                    onChange={handleChangeItemsArray(i)}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    <MenuItem value="Yes">Yes</MenuItem>
                                                    <MenuItem value="No">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveItemsArray(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}

                                <Button
                                    variant="outlined"
                                    color="default"
                                    onClick={() =>
                                        handleAddItemsArray(state.items.length + 1)
                                    }
                                    endIcon={<AddIcon fontSize="large" />}
                                >
                                    Insert Item
                                </Button>
                            </Paper>
                        </>


                        {isCanEdit ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleSave}
                                        >
                                            {loading ? "Loading..." : "Save Changes"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            onClick={handleSubmitHandler}
                                            disabled={loading}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                    </div>
                </form>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditGatePass;
