import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    Fab,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import {
    updateReceipt,
    submitReceipt,
    getReceipt,
    getAllInventoryItems,
    getAllStores,
    getLocations,
    getAllReceipts,
    getAllInventoryCategories,
    getInventoryCategoryItems,
} from "../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: 500,
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    vendor: "",
    orderNo: "",
    documentNo: "",
    store: "",
    location: "",
    totalQuantity: "",
    totalValue: "",
    items: [],
};


const INIT_ITEM = {
    name: "",
    item: "",
    category: "",
    quantityOrdered: "",
    quantityReceived: "",
    unit: '',
    cost: '',
    value: '',
};

const EditReceipt = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [item, setItem] = useState(INIT_ITEM);
    const [category, setCategory] = useState("");

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { invItems, invCates, invCateItems } = useSelector((state) => state.inventoryItem);
    const { locations } = useSelector((state) => state.location);
    const { allstores } = useSelector((state) => state.stores);
    const { vendors } = useSelector((state) => state.vendors);
    const { error, loading, receipt } = useSelector((state) => state.receipt);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllInventoryItems());
        dispatch(getLocations());
        dispatch(getAllStores());
        dispatch(getReceipt(id));
        dispatch(getAllInventoryCategories());
        if (category !== "") {
            dispatch(getInventoryCategoryItems(category));
        }
    }, [dispatch, category, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (receipt && receipt.employee) || "",
            division: (receipt && receipt.division) || "",
            store: (receipt && receipt.store?.id) || "",
            location: (receipt && receipt.location?._id) || "",
            documentNo: (receipt && receipt.documentNo) || "",
            orderNo: (receipt && receipt.orderNo) || "",
            vendor: (receipt && receipt.vendor?.id) || "",
            totalValue: (receipt && receipt.totalValue) || "",
            totalQuantity: (receipt && receipt.totalQuantity) || "",

            items: (receipt && receipt.items) || [],
            status: (receipt && receipt.status) || "",

        }));
    }, [receipt]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile.userName,
            division: profile && profile.division && profile.division._id,
        }));
    }, [profile]);

    useEffect(() => {
        if (state.items.length > 0) {
            let total1 = state.items.reduce((acc, cur) => { return acc + +cur.quantityReceived; }, 0);
            let total2 = state.items.reduce((acc, cur) => { return acc + +cur.value; }, 0);
            setState((prev) => ({
                ...prev,
                totalQuantity: total1,
                totalValue: total2,
            }));
        }
    }, [state.items]);

    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleItemChange = (e) => {
        e.persist();
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = (item) => {
        if (item.category !== "" && item.item !== "" && item.cost !== "" && item.quantityReceived !== "" && item.quantityOrdered !== "" && item.value !== "") {
            setState((prev) => ({
                ...prev,
                items: prev.items.concat([item]),
            }));
            setItem(INIT_ITEM);
        }
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleChangeItemsArray = (i) => (e) => {
        e.persist();
        const newItemsArray = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            items: newItemsArray,
        }));
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            totalQuantity: state.totalQuantity,
            totalValue: state.totalValue,
            id,
        };
        await dispatch(submitReceipt(data));
        await dispatch(getAllReceipts);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Receipt Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/inventory/receipt");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            division: profile && profile.division,
            totalQuantity: state.totalQuantity,
            totalValue: state.totalValue,
            id
        }
        await dispatch(updateReceipt(data));
        setTimeout(async () => {
            await dispatch(getReceipt(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Receipt Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" ||
            state.status === "declined" ||
            state.status === "rejected")
        )
        : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" className={classes.title}>
                    Edit Receipt
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/inventory/receipt")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Paper variant="outlined" className={classes.status}>
                            <Typography variant="overline" className={classes.statusTitle}>
                                Document No.
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            {loading ? "Loading..." : state.documentNo}
                        </Paper>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ marginBottom: 20 }}
                >
                    <Hidden smDown>
                        <Grid item></Grid>
                    </Hidden>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : (state.status === "accepted" || state.status === "received")
                                            ? classes.statusGreen
                                            : (state.status === "approved" || state.status === "received")
                                                ? classes.statusDarkGreen
                                                : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmitHandler}>
                    <div>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Employee"
                                    disabled
                                    name="employee"
                                    value={state.employee || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    disabled
                                    label="Document Number"
                                    name="documentNo"
                                    value={state.documentNo || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Order Number"
                                    name="orderNo"
                                    value={state.orderNo || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Vendor </InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "vendor",
                                            name: "vendor",
                                        }}
                                        value={state.vendor}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select Vendor</MenuItem>
                                        {vendors.map((vendor) => (
                                            <MenuItem key={vendor._id} value={vendor._id}>
                                                {vendor.companyName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Store</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "store",
                                            name: "store",
                                        }}
                                        value={state.store}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select Store</MenuItem>
                                        {allstores.map((store) => (
                                            <MenuItem key={store._id} value={store._id}>
                                                {store.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Location</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "location",
                                            name: "location",
                                        }}
                                        value={state.location}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select Location</MenuItem>
                                        {locations.map((location) => (
                                            <MenuItem key={location._id} value={location._id}>
                                                {location.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Paper variant="outlined" square className={classes.paper}>
                            {item ? (
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.grid}
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="category">
                                                Inventory Category
                                            </InputLabel>
                                            <Select
                                                labelId="category"
                                                name="category"
                                                value={item.category || ""}
                                                onChange={handleChangeCategory}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                {invCates &&
                                                    invCates.map((cate) => (
                                                        <MenuItem key={cate._id} value={cate._id}>
                                                            {cate.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="item">
                                                Inventory item
                                            </InputLabel>
                                            <Select
                                                labelId="item"
                                                name="item"
                                                value={item.item || ""}
                                                onChange={handleItemChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                {invCateItems &&
                                                    invCateItems.map((citem) => (
                                                        <MenuItem key={citem._id} value={citem._id}>
                                                            {citem.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Quantity Ordered"
                                            type="number"
                                            name="quantityOrdered"
                                            value={item.quantityOrdered || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Quantity Received"
                                            type="number"
                                            name="quantityReceived"
                                            value={item.quantityReceived || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Item Cost"
                                            type="number"
                                            name="cost"
                                            value={item.cost || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Item Value"
                                            type="number"
                                            name="value"
                                            value={item.value || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} >
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Unit</InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "unit",
                                                    name: "unit",
                                                }}
                                                value={item.unit}
                                                onChange={handleItemChange}
                                            >
                                                <MenuItem disabled>Select Unit</MenuItem>
                                                <MenuItem value="Packets">Packets</MenuItem>
                                                <MenuItem value="Reams">Reams</MenuItem>
                                                <MenuItem value="Kg">Kg</MenuItem>
                                                <MenuItem value="Cartons">Cartons</MenuItem>
                                                <MenuItem value="Crates">Crates</MenuItem>
                                                <MenuItem value="Meters">Meters</MenuItem>
                                                <MenuItem value="Kilometers">Kilometers</MenuItem>
                                                <MenuItem value="Pieces">Pieces</MenuItem>
                                                <MenuItem value="Dozens">Dozens</MenuItem>
                                                <MenuItem value="Litres">Litres</MenuItem>
                                                <MenuItem value="Gallons">Gallons</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Fab
                                            color="primary"
                                            onClick={() => handleAddItemsArray(item)}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Paper>

                        {/* To be filled by the Requestor */}
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Selected Items
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.items.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth required variant="outlined">
                                                <InputLabel id="item">
                                                    Inventory Item
                                                </InputLabel>
                                                <Select
                                                    labelId="item"
                                                    name="item"
                                                    value={item.item || ""}
                                                    onChange={handleChangeItemsArray(i)}
                                                    disabled={!isCanEdit}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    {invItems &&
                                                        invItems.map((item) => (
                                                            <MenuItem key={item._id} value={item._id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity Ordered"
                                                type="number"
                                                name="quantityOrdered"
                                                value={item.quantityOrdered}
                                                onChange={handleChangeItemsArray(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity Received"
                                                type="number"
                                                name="quantityReceived"
                                                value={item.quantityReceived}
                                                onChange={handleChangeItemsArray(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2} >
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel>Unit of Measurement</InputLabel>
                                                <Select
                                                    inputProps={{
                                                        id: "unit",
                                                        name: "unit",
                                                    }}
                                                    value={item.unit}
                                                    onChange={handleChangeItemsArray(i)}
                                                >
                                                    <MenuItem disabled>Select Unit</MenuItem>
                                                    <MenuItem value="Packets">Packets</MenuItem>
                                                    <MenuItem value="Reams">Reams</MenuItem>
                                                    <MenuItem value="Kg">Kg</MenuItem>
                                                    <MenuItem value="Cartons">Cartons</MenuItem>
                                                    <MenuItem value="Crates">Crates</MenuItem>
                                                    <MenuItem value="Meters">Meters</MenuItem>
                                                    <MenuItem value="Kilometers">Kilometers</MenuItem>
                                                    <MenuItem value="Pieces">Pieces</MenuItem>
                                                    <MenuItem value="Dozens">Dozens</MenuItem>
                                                    <MenuItem value="Litres">Litres</MenuItem>
                                                    <MenuItem value="Gallons">Gallons</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                label="Item Cost"
                                                name="cost"
                                                type="number"
                                                variant="outlined"
                                                value={item.cost}
                                                onChange={handleChangeItemsArray(i)}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                label="Item Value"
                                                name="value"
                                                type="number"
                                                variant="outlined"
                                                value={item.value}
                                                onChange={handleChangeItemsArray(i)}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveItemsArray(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Paper>
                        </>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={6} />
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Total Quantity Received"
                                    name="totalQuantity"
                                    value={state.totalQuantity || "0"}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Total Value"
                                    name="totalValue"
                                    value={state.totalValue || "0"}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>

                        {isCanEdit ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleSave}
                                        >
                                            {loading ? "Loading..." : "Save Changes"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            onClick={handleSubmitHandler}
                                            disabled={loading}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                    </div>
                </form>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
};

export default EditReceipt;
