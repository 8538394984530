import React from "react";

const KPI = () => {
  const iframe = `
    <iframe
        width="600"
        height="373.5"
        src="https://app.powerbi.com/view?r=eyJrIjoiNzU1YjQwYmQtZmUzMy00YjdkLWEwZDEtZWEwZGY3MWZjZWNmIiwidCI6IjQ5ZmRlNzBlLWU4YjYtNGJmNC1iYThkLTY0YjFlNGZjM2NjYSIsImMiOjh9&pageName=ReportSection"
        frameborder="0"
        allowfullScreen="true"
        title="KPI Dashboard"
      ></iframe>`;
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: iframe }} />
    </div>
  );
};

export default KPI;
