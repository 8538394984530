import * as types from "../../types/inventory";

const initState = {
    allstores: [],
    onestore: {},
    error: null,
    success: false,
    loading: false,
};

const storeReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_STORE:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.GET_ALL_STORES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_STORES:
            return {
                ...state,
                loading: false,
                success: true,
                allstores: action.payload.data,
            };
        case types.GET_ALL_STORES_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_STORE:
            return {
                ...state,
                loading: false,
                success: true,
                onestore: action.payload.data,
            };
        case types.GET_STORE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_STORE:
            return {
                ...state,
                loading: false,
                onestore: state.allstores.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DELETE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_STORE:
            return {
                ...state,
                loading: false,
                success: false,
                allstores: state.allstores.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.RESET_STORE:
            return {
                ...state,
                error: null,
                success: false,
                loading: false,
            };

        default:
            return state;
    }
};

export default storeReducer;
