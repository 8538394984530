import { ERROR } from "../types/";

const initState = {
  error: null,
};

const errorReducer = (state = initState, action) => {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default errorReducer;
