import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  Divider,
  darken,
  Backdrop,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Icon,
  ButtonGroup,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import CashAdvanceItemTable from "./CashAdvanceItemTable";
import { getCashAdvanceById } from "../../../actions/cashAdvanceAction";
import clsx from "clsx";

import CashApprovalModal from "./modals/CashApprovalModal";
import RejectCashAdvance from "./modals/RejectCashAdvance";

import CashReviewModal from "./modals/CashReviewModal";
import CashDeclineModal from "./modals/DeclineCashAdvance";

// HOD Retirememt
import RetirementHodConfirmModal from "./modals/RetirementHodConfirm";
import RetirementHodDeclineModal from "./modals/RetirementHodDecline";

// Finance Retirememt
import RetirementFinAcceptModal from "./modals/RetirementFinAccept";
import RetirementFinDeclineModal from "./modals/RetirementFinDecline";

import {
  isCashManager,
  isCashApprover,
  isCashApproverCeo,
} from "../../Utils/guards";
import RetireModal from "./modals/RetireModal";
import { formatCurrency } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  paper3: {
    padding: 20,
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  divider3: {
    marginTop: 10,
    marginBottom: 10,
  },
  submitBtn: {
    marginLeft: 10,
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,

    // padding: theme.spacing(2),
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  danger: {
    color: "#ef5350",
    fontSize: 13,
    // marginTop: -25,
    display: "block",
  },
  info: {
    fontWeight: 500,
    fontSize: 13,
  },
  textGreen: {
    color: "#388e3c",
    fontSize: 15,
  },
  docs: {
    padding: theme.spacing(3),
    overflow: "hidden",
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 250,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },

  audit: {
    padding: 10,
    fontFamily: "Rubik",
    fontSize: 10,
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    // fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditTitle: {
    padding: 10,
    // background: theme.palette.grey[600],
    color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
    fontFamily: "Rubik",
    fontSize: 10,
    fontWeight: "bold",
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditContainer: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  small: {
    fontSize: 11,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  link: {
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  centered: {
    textAlign: "center",
  },
  paperBlack: {
    color: "#f5f5f5",
    // background: "#3c3737",
    background: theme.palette.grey.A400,
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
    textAlign: "center",
    maxWidth: 400,
    marginLeft: "auto",
    marginRight: "auto",
  },
  info2: {
    marginBottom: 20,
    fontWeight: 500,
  },
}));

const INIT_STATE = {
  employeeName: "",
  employeeId: "",
  division: "",
  month: "",
  year: "",
  purpose: "",
  costType: "",
  costCenter: "",
  rate: 1,
  items: [
    {
      item: "",
      amount: 0,
      description: "",
    },
  ],
  status: "",
  total: 0,
  currency: "",
  convertedTotal: 0,
  overdueDate: "",
  documentNo: "",
};

const ViewCashAdvance = ({ getCashAdvanceById, cashAdvance, profile }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [state, setState] = useState(INIT_STATE);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openRetire, setOpenRetire] = useState(false);

  const [openReview, setOpenReview] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  // HOD
  const [openHodConfirm, setOpenHodConfirm] = useState(false);
  const [openHodDecline, setOpenHodDecline] = useState(false);
  const [openFinAccept, setOpenFinAccept] = useState(false);
  const [openFinDecline, setOpenFinDecline] = useState(false);

  const getCashAdvanceByIdCallback = useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getCashAdvanceById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [getCashAdvanceById, id]);

  useEffect(() => {
    getCashAdvanceByIdCallback();
  }, [getCashAdvanceByIdCallback]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: cashAdvance && cashAdvance.employee,
      employeeId: cashAdvance && cashAdvance.employeeId,
      division:
        cashAdvance && cashAdvance.division && cashAdvance.division.name,
      advanceDivision:
        cashAdvance && cashAdvance.advanceDivision && cashAdvance.advanceDivision.name,
      month: cashAdvance && cashAdvance.month,
      year: cashAdvance && cashAdvance.year && cashAdvance.year.toString(),
      purpose: cashAdvance && cashAdvance.purpose,
      costType: cashAdvance && cashAdvance.costType,
      costCenter: cashAdvance && cashAdvance.costCenter,
      rate: cashAdvance && cashAdvance.rate,
      status: cashAdvance && cashAdvance.status,
      items: cashAdvance && cashAdvance.items,
      total: cashAdvance && cashAdvance.total,
      currency: cashAdvance && cashAdvance.currency,
      convertedTotal: cashAdvance && cashAdvance.convertedTotal,
      overdueDate: cashAdvance && cashAdvance.overdueDate,
      documentNo: cashAdvance && cashAdvance.documentNo,

      createdBy: cashAdvance && cashAdvance.createdBy,
      createdAt: cashAdvance && cashAdvance.createdAt,

      reviewedBy: cashAdvance && cashAdvance.reviewedBy,
      reviewedAt: cashAdvance && cashAdvance.reviewedAt,
      reviewerComment: cashAdvance && cashAdvance.reviewerComment,

      declinedBy: cashAdvance && cashAdvance.declinedBy,
      declinedAt: cashAdvance && cashAdvance.declinedAt,
      declineComment: cashAdvance && cashAdvance.declineComment,

      approvedBy: cashAdvance && cashAdvance.approvedBy,
      approvedAt: cashAdvance && cashAdvance.approvedAt,
      approverComment: cashAdvance && cashAdvance.approverComment,

      rejectedBy: cashAdvance && cashAdvance.rejectedBy,
      rejectedAt: cashAdvance && cashAdvance.rejectedAt,
      rejectComment: cashAdvance && cashAdvance.rejectComment,

      paidAt: cashAdvance && cashAdvance.payment?.paidAt,
      paidComment: cashAdvance && cashAdvance.payment?.comment,

      retiredAt: cashAdvance && cashAdvance.retirement?.retiredAt,
      retiredComment: cashAdvance && cashAdvance.retirement?.comment,

      confirmedBy: cashAdvance && cashAdvance.retirement?.confirmedBy,
      confirmedAt: cashAdvance && cashAdvance.retirement?.confirmedAt,
      confirmComment: cashAdvance && cashAdvance.retirement?.confirmComment,

      acceptedBy: cashAdvance && cashAdvance.retirement?.acceptedBy,
      acceptedAt: cashAdvance && cashAdvance.retirement?.acceptedAt,
      acceptComment: cashAdvance && cashAdvance.retirement?.acceptComment,

      retireDeclinedBy: cashAdvance && cashAdvance.retirement?.declinedBy,
      retireDeclinedAt: cashAdvance && cashAdvance.retirement?.declinedAt,
      retireDeclineComment: cashAdvance && cashAdvance.retirement?.declineComment,

      retireRejectedBy: cashAdvance && cashAdvance.retirement?.rejectedBy,
      retireRejectedAt: cashAdvance && cashAdvance.retirement?.rejectedAt,
      retireRejectComment: cashAdvance && cashAdvance.retirement?.rejectComment,

    }));
  }, [cashAdvance]);

  // console.log(state, profile)

  const handleOpenApprove = () => {
    setOpenApprove(true);
  };
  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const handleOpenReject = () => {
    setOpenReject(true);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const handleOpenRetire = () => {
    setOpenRetire(true);
  };
  const handleCloseRetire = () => {
    setOpenRetire(false);
  };

  const handleOpenReview = () => {
    setOpenReview(true);
  };
  const handleCloseReview = () => {
    setOpenReview(false);
  };
  const handleOpenDecline = () => {
    setOpenDecline(true);
  };
  const handleCloseDecline = () => {
    setOpenDecline(false);
  };

  const handleOpenHodConfirm = () => {
    setOpenHodConfirm(true);
  };
  const handleCloseHodConfirm = () => {
    setOpenHodConfirm(false);
  };
  const handleOpenHodDecline = () => {
    setOpenHodDecline(true);
  };
  const handleCloseHodDecline = () => {
    setOpenHodDecline(false);
  };

  const handleOpenFinAccept = () => {
    setOpenFinAccept(true);
  };
  const handleCloseFinAccept = () => {
    setOpenFinAccept(false);
  };
  const handleOpenFinDecline = () => {
    setOpenFinDecline(true);
  };
  const handleCloseFinDecline = () => {
    setOpenFinDecline(false);
  };

  const isAdmin = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
        (grp) => grp.name === "admin" 
    )
    : false;

  const isCEO = profile
    ? profile &&
    profile.jobTitle &&
    profile?.jobTitle?.name === "Chief Executive Officer"
    : false;

  const isDivisionManager = profile
    ? profile &&
    (
      (profile?.id === cashAdvance.division?.manager)  || 
      (profile?.id === cashAdvance.division?.head) || 
      ((profile?.id === "5fb64accc521c80017c15e68") && (cashAdvance.advanceDivision !== null))
    )
    : false;

const isFinanceHead = profile
    ? profile &&
    (profile?.id === "5fb65e3fc521c80017c15e75")
    : false;

  return (
    <div>
      <CashApprovalModal {...{ openApprove, handleCloseApprove, id }} />
      <RejectCashAdvance {...{ openReject, handleCloseReject, id }} />
      <RetireModal {...{ openRetire, handleCloseRetire, id }} />

      <CashReviewModal {...{ openReview, handleCloseReview, id }} />
      <CashDeclineModal {...{ openDecline, handleCloseDecline, id }} />

      <RetirementHodConfirmModal {...{ openHodConfirm, handleCloseHodConfirm, id }} />
      <RetirementHodDeclineModal {...{ openHodDecline, handleCloseHodDecline, id }} />

      <RetirementFinAcceptModal {...{ openFinAccept, handleCloseFinAccept, id }} />
      <RetirementFinDeclineModal {...{ openFinDecline, handleCloseFinDecline, id }} />

      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIosIcon />}
            className={classes.createBtn}
          >
            Back
          </Button>
          {(state.status === "paid" || state.status === "overdue") &&
            (profile && profile._id) ===
            (cashAdvance &&
              cashAdvance.createdBy &&
              cashAdvance.createdBy._id) && (
              <Button className={classes.submitBtn} onClick={handleOpenRetire}>
                Retire
              </Button>
            )}
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              state.status === "draft"
                ? classes.statusYellow
                : state.status === "submitted" || state.status === "tomodify"
                  ? classes.statusOrange
                  : state.status === "approved" || state.status === "confirmed" || state.status === "accepted"
                    ? classes.statusGreen
                    : state.status === "completed" ||
                      state.status === "reviewed" ||
                      state.status === "retired" ||
                      state.status === "paid"
                      ? classes.statusDarkGreen
                      : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop
              ? "Loading..."
              : state.status && state.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 30, marginTop: 15 }}
      >
        <Grid item>
          <Typography className={classes.title} variant="overline">
            View Cash Advance
          </Typography>
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(classes.status)}
          // style={{ marginBottom: 30, marginTop: 5 }}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Doc No.
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop ? "Loading..." : state.documentNo}
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            label="Date Created"
            variant="outlined"
            fullWidth
            value={state.createdAt || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Currency"
            variant="outlined"
            fullWidth
            value={state.currency || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Employee Name"
            variant="outlined"
            fullWidth
            value={state.employeeName || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="EID"
            variant="outlined"
            fullWidth
            value={state.employeeId || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Division/Subdivison"
            variant="outlined"
            fullWidth
            value={state.division || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Purpose"
            variant="outlined"
            fullWidth
            value={state.purpose || ""}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label="Cost Type"
            variant="outlined"
            fullWidth
            value={state.costType || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Cost Center"
            variant="outlined"
            fullWidth
            value={state.costCenter || ""}
            disabled
          />
        </Grid>

        {state.advanceDivision && (
        <Grid item xs={12} md={4}>
          <TextField
            label="Cash Advance Division"
            variant="outlined"
            fullWidth
            value={state.advanceDivision || ""}
            disabled
          />
        </Grid>
        )}

        <Grid item xs={12} md={4}>
          {state.status === "overdue" && (
            <TextField
              label="Overdue Date"
              variant="outlined"
              fullWidth
              value={state.overdueDate || ""}
              disabled
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
      {(state.status === "retired" || state.status === "confirmed" || state.status === "accepted") && (
        <Paper className={classes.paper3}>
          <Typography variant="overline" component="p" className={classes.info}>
            Amount Requested:{" "}
            <span className={classes.textGreen}>
              {formatCurrency(
                cashAdvance && cashAdvance.total,
                "en-NG",
                cashAdvance && cashAdvance.currency
              )}
            </span>
          </Typography>

          <Typography variant="overline" component="p" className={classes.info}>
            Amount Received:{" "}
            <span className={classes.textGreen}>
              {formatCurrency(
                cashAdvance &&
                cashAdvance.payment &&
                cashAdvance.payment.amount,
                "en-NG",
                cashAdvance && cashAdvance.currency
              )}
            </span>
          </Typography>
          <Typography variant="overline" component="p" className={classes.info}>
            Amount Retired:{" "}
            <span className={classes.textGreen}>
              {formatCurrency(
                cashAdvance &&
                cashAdvance.retirement &&
                cashAdvance.retirement.amount,
                "en-NG",
                cashAdvance && cashAdvance.currency
              )}
            </span>
          </Typography>

          <Divider light className={classes.divider3} />
          <Typography
            variant="overline"
            component="p"
            style={{ fontWeight: 900 }}
          >
            Document(s)
          </Typography>
          <List>
            {cashAdvance &&
              cashAdvance.retirement &&
              cashAdvance.retirement.documents &&
              cashAdvance.retirement.documents.map((doc, i) => (
                <ListItem key={i} disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <Icon className="fas fa-link" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        color="secondary"
                        href={doc}
                      >
                        <Typography variant="overline" color="textSecondary">
                          {" "}
                          Document &mdash; {i + 1}
                        </Typography>
                      </a>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </Paper>
      )}
      <Divider className={classes.divider} />
      <CashAdvanceItemTable
        {...{
          items: state.items,
          total: state.total,
          currency: state.currency,
          convertedTotal: state.convertedTotal,
        }}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Audit trail */}
      {state.status !== "draft" ? (
        <>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            spacing={1}
            style={{ marginBottom: 5 }}
          >
            <Grid item>
              <Typography variant="overline" className={classes.title2}>
                Audit Trail
              </Typography>
            </Grid>
          </Grid>
          <Paper variant="outlined" square className={classes.paper}>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Submitted By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.createdBy?.userName}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.createdAt}</span>
                </Paper>
              </Grid>

              {state.reviewedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Reviewed By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.reviewedBy?.userName || ""}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.reviewedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.reviewerComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.declinedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Declined By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.declinedBy?.userName || ""}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.declinedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.declineComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.approvedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Approved By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.approvedBy?.userName || " "}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.approvedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.approverComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.rejectedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Rejected By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.rejectedBy?.userName || ""}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.rejectedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.rejectComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.paidAt && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Paid At</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.paidAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.paidComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.retiredAt && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Retired At</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retiredAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retiredComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.confirmedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Retirement Confirmed By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.confirmedBy?.userName}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.confirmedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.confirmComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.retireDeclinedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Retirement Declined By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireDeclinedBy?.userName}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireDeclinedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireDeclineComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.acceptedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Retirement Accepted By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.acceptedBy?.userName}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.acceptedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.acceptComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

              {state.retireRejectedBy && (
                <>
                  <Grid item xs={12} md={12}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Retirement Rejected By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireRejectedBy?.userName}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireRejectedAt}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.auditTitle}>Comment</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.retireRejectComment}</span>
                    </Paper>
                  </Grid>
                </>
              )}

            </Grid>
          </Paper>
        </>
      ) : null}

      {/* Line Manager reviews or declines cash advance */}
      {(isDivisionManager || isAdmin) && state.status === "submitted" &&
        isCashManager(profile) && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 30 }}
            spacing={3}
          >
            <Grid item>
              <div className={classes.centered}>
                <Paper className={classes.paperBlack}>
                  <HelpOutlineIcon fontSize="large" />
                  <Typography placeholder="Info" className={classes.info2}>
                    This cash advance review or decline is to be done by the Line Manager.
                  </Typography>
                </Paper>
              </div>
              <ButtonGroup>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenReview}
                  className={classes.saveBtn}
                >
                  Review
                </Button>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenDecline}
                  className={classes.saveBtn}
                >
                  Decline
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
      {(isDivisionManager || isAdmin) && state.status === "retired" &&
        isCashManager(profile) && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 30 }}
            spacing={3}
          >
            <Grid item>
              <div className={classes.centered}>
                <Paper className={classes.paperBlack}>
                  <HelpOutlineIcon fontSize="large" />
                  <Typography placeholder="Info" className={classes.info2}>
                    This cash retirement confirmation or decline is to be done by the Line Manager.
                  </Typography>
                </Paper>
              </div>
              <ButtonGroup>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenHodConfirm}
                  className={classes.saveBtn}
                >
                  Confirm Retirement
                </Button>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenHodDecline}
                  className={classes.saveBtn}
                >
                  Decline Retirement
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}

      {(isFinanceHead || isAdmin) && state.status === "reviewed" &&
        isCashApprover(profile) &&
        +state.convertedTotal <= 500000 && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 30 }}
            spacing={3}
          >
            <Grid item>
              <div className={classes.centered}>
                <Paper className={classes.paperBlack}>
                  <HelpOutlineIcon fontSize="large" />
                  <Typography placeholder="Info" className={classes.info2}>
                    This cash advance rejectioon or approval is to be done by the Head of Finance.
                  </Typography>
                </Paper>
              </div>
              <ButtonGroup>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenReject}
                  className={classes.saveBtn}
                >
                  Reject
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenApprove}
                  className={classes.saveBtn}
                >
                  Approve
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}

      {(isCEO  || isAdmin) && state.status === "reviewed" &&
        isCashApproverCeo(profile) &&
        +state.convertedTotal > 500000 && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 30 }}
            spacing={3}
          >
            <Grid item>
              <div className={classes.centered}>
                <Paper className={classes.paperBlack}>
                  <HelpOutlineIcon fontSize="large" />
                  <Typography placeholder="Info" className={classes.info2}>
                    This cash advance rejection or approval is to be done by the CEO.
                  </Typography>
                </Paper>
              </div>
              <ButtonGroup>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenReject}
                  className={classes.saveBtn}
                >
                  Reject
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenApprove}
                  className={classes.saveBtn}
                >
                  Approve
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}

      {(isFinanceHead || isAdmin) && state.status === "confirmed" &&
        isCashApprover(profile) && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 30 }}
            spacing={3}
          >
            <Grid item>
              <div className={classes.centered}>
                <Paper className={classes.paperBlack}>
                  <HelpOutlineIcon fontSize="large" />
                  <Typography placeholder="Info" className={classes.info2}>
                    This cash retirement final acceptance or decline is to be done by the Head of Finance.
                  </Typography>
                </Paper>
              </div>
              <ButtonGroup>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenFinAccept}
                  className={classes.saveBtn}
                >
                  Accept Retirement
                </Button>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleOpenFinDecline}
                  className={classes.saveBtn}
                >
                  Decline Retirement
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cashAdvance: state.cashAdvance.cashAdvance,
  profile: state.user.profile,
});

const mapDispatchToProps = {
  getCashAdvanceById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCashAdvance);
