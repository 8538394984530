import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";
import {
  getSubModule,
  updateSubModule,
  getAllSubModules,
} from "../../../../actions/moduleAction";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  name: "",
  code: "",
};

const EditSubModuleModal = ({
  openEdit,
  handleCloseEdit,
  getSubModule,
  getAllSubModules,
  updateSubModule,
  submodule,
  getId,

  error,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  console.log(getId);
  const getSubModuleCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    getSubModule(getId).then(() => {
      setLoading(false);
    });
  }, [getSubModule, getId]);

  React.useEffect(() => {
    getSubModuleCallback();
  }, [getSubModuleCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      name: submodule && submodule.name,
      code: submodule && submodule.code,
    }));
  }, [submodule]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    let data = {
      name: state.name,
      code: state.code,
      id: getId,
    };

    updateSubModule(data)
      .then(() => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          // message: `Sequence Created Successfully`,
          success: true,
        });
        // dispatch(getSubModule(getId));
        dispatch(getAllSubModules(id));
      })
      .catch(() => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          // message: `Sequence Created Successfully`,
          success: false,
        });
      })
      .finally(() => {
        handleCloseEdit();
      });
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error.error) || feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "SubModule Updated Successfully."}
        />
      )}
      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit SubModule
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseEdit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={loading ? "Loading..." : state.name || ""}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Code"
                  name="code"
                  value={loading ? "Loading..." : state.code || ""}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                color="primary"
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                disabled={loading}
                className={classes.saveBtn}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  submodule: state.module.submodule,
  error: state.module.submoduleError,
});

const mapDispatchToProps = {
  getSubModule,
  updateSubModule,
  getAllSubModules,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubModuleModal);
