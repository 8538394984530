import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { connect, useDispatch } from "react-redux";

import Feedback from "../../../atoms/Feedback";
import { getAllBudgetSubheads } from "../../../../actions/budgetSubheadAction";
import {
  createBudgetItem,
  getAllBudgetItems,
} from "../../../../actions/budgetItemActions";
import { getDivisions } from "../../../../actions/divisionActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  btn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  name: "",
  subHead: "",
  division: undefined,
  subheads: [],
};

const ItemModal = ({
  open,
  handleClose,
  getAllBudgetSubheads,
  createBudgetItem,
  getAllBudgetItems,
  getDivisions,
  divisions,
  error,
  subheads,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    setLoad((prev) => !prev);
    getAllBudgetSubheads().then(() => {
      setLoad(false);
    });
  }, [getAllBudgetSubheads]);

  useEffect(() => {
    setLoad((prev) => !prev);

    getDivisions().then(() => {
      setLoad(false);
    });
  }, [getDivisions]);

  useEffect(() => {
    if (subheads) {
      setState((prev) => ({
        ...prev,
        subheads,
      }));
    }
  }, [subheads]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      name: state.name,
      subHead: state.subHead,
      division: state.division,
    };
    createBudgetItem(data)
      .then(() => {
        setLoading(false);
        // setState(INIT_STATE);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Budget Item created successfully`,
          success: true,
        });
        dispatch(getAllBudgetItems);
      })
      .catch(() => {
        setLoading(false);
        handleClose();
        setFeed({
          loading: false,
          open: !feed.open,
          message: "An unexpected error has occured.",
          success: false,
        });
      })
      .finally(() => {
        handleClose();
      });
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => {
    return !state.name || !state.subHead;
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Category
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={state.name}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="head-label-id">SubHead</InputLabel>
                  <Select
                    labelId="head-label-id"
                    id="subHead"
                    label="SubHead"
                    inputProps={{
                      id: "subHead",
                      name: "subHead",
                    }}
                    onChange={handleChange}
                    value={state.subHead || ""}
                    name="subHead"
                  >
                    <MenuItem value="" disabled>
                      --- Select SubHead ---
                    </MenuItem>
                    {load ? (
                      <MenuItem>Loading</MenuItem>
                    ) : (
                      state.subheads &&
                      state.subheads.map((subhead) => (
                        <MenuItem key={subhead._id} value={subhead._id}>
                          {subhead.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="division-label-id">Divisions</InputLabel>
                  <Select
                    labelId="division-label-id"
                    id="division"
                    label="division"
                    inputProps={{
                      id: "division",
                      name: "division",
                    }}
                    onChange={handleChange}
                    value={state.division || ""}
                    name="division"
                  >
                    <MenuItem value="" disabled>
                      --- Select Division ---
                    </MenuItem>
                    <MenuItem value={undefined}>None</MenuItem>
                    {load ? (
                      <MenuItem>Loading</MenuItem>
                    ) : (
                      divisions &&
                      divisions.map((division) => (
                        <MenuItem key={division._id} value={division._id}>
                          {division.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  className={classes.saveBtn}
                  disabled={validCheck() || loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  subheads: state.budgetSubheads.subheads.data,
  divisions: state.division.divisions,
});

export default connect(mapStateToProps, {
  getAllBudgetSubheads,
  createBudgetItem,
  getDivisions,
  getAllBudgetItems,
})(ItemModal);
