import * as types from "../../types/people";

const initState = {
    cims: [],
    cims_approved: [],
    cim: null,
    loading: false,
    error: null,
    success: false,
};

const changeImpactReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_CIM_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_CIM:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_CIM_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };    
        case types.GET_CIMS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_CIMS:
            return {
                ...state,
                loading: false,
                cims: action.payload.data,
                success: true,
            };
        case types.GET_CIMS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case types.GET_APPROVED_CIMS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_APPROVED_CIMS:
            return {
                ...state,
                loading: false,
                success: true,
                cims_approved: action.payload.data,
            };
        case types.GET_APPROVED_CIMS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_CIM_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_CIM:
            return {
                ...state,
                loading: false,
                cim: action.payload.data,
                success: true,
            };
        case types.GET_CIM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };


        
        case types.UPDATE_CIM_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_CIM:
            return {
                ...state,
                loading: false,
                cim: state.cims.map((item) =>
                    item._id === action.payload.data.id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_CIM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case types.DELETE_CIM_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_CIM:
            return {
                ...state,
                loading: false,
                cims: state.cims.filter(
                    (item) => item._id !== action.payload
                ),
                success: false,
            };
        case types.DELETE_CIM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        


        default:
            return state;
    }
};

export default changeImpactReducer;
