import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Button,
  DialogTitle,
  IconButton,
  DialogActions,
  ButtonGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import Feedback from "../../../atoms/Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
}));

const DeleteUserModal = ({
  openDelete,
  handleCloseDelete,
  loading,
  handleDeleteUser,
}) => {
  const classes = useStyles();
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  return (
    <div>
      {feed.success === true ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || `User successfully deleted.`}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={
            feed.message || `An error has occurred, please contact admin.`
          }
        />
      )}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Delete User
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDelete}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.title3}>
            Warning: By clicking Delete, the data will be removed and won't be
            restored except recreated.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              autoFocus
              onClick={handleCloseDelete}
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteUser}
              color="secondary"
              autoFocus
              variant="contained"
              disabled={loading}
              className={classes.saveBtn}
            >
              {loading ? (
                <span>
                  Loading...
                  <CircularProgress color="secondary" size={15} />{" "}
                </span>
              ) : (
                "Delete"
              )}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteUserModal;
