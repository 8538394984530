import React, { useState, useEffect } from "react";
import {
    Paper,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    IconButton,
    Dialog,
    Tooltip,
    Backdrop,
    AppBar,
    Toolbar,
    Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from 'moment';

import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../../../../actions/currencyActions";
import { getDivisionsByService } from "../../../../../actions/divisionActions";
import {
    createMaterialPriceList,
    getAllMaterialPriceList,
} from "../../../../../actions/masterListActions/materialActions";
import Feedback from "../../../../atoms/Feedback";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: "bold",
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 2,
    },
    container: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 300,
    },
}));

const initialState = {
    description: "",
    division: "",
    condition: "",
    code: "",
    cost: "",
    dateOfPurchase: moment(new Date()).toDate(),
    margin: "",
    currency: "",
    dayRate: 0,
    wear: "",
    withholdingRate: 0,
    withholdingTax: "",
    totalDayRate: "",
};

const CreateMaterialModal = ({ open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = useState(initialState);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { currencies } = useSelector((state) => state.currency);
    const { divisions } = useSelector((state) => state.division);
    const { loading, success, error } = useSelector((state) => state.material);

    useEffect(() => {
        const abortController = new AbortController();

        if (error && error !== null) {
            setFeed((prev) => ({
                ...prev,
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
            setTimeout(() => {
                setFeed((prev) => ({
                    ...prev,
                    loading: false,
                    open: false,
                    message: "",
                    success: false,
                }));
                dispatch({
                    type: "RESET_MATERIAL_PRICE_LIST",
                });
            }, 6000);
        }
        return () => {
            abortController.abort();
        };
    }, [error, dispatch]);

    useEffect(() => {
        const abortController = new AbortController();

        if (success) {
            dispatch(getAllMaterialPriceList());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Material price list created successfully.",
                success: true,
            }));
            setState(initialState);
            dispatch({
                type: "RESET_MATERIAL_PRICE_LIST",
            });

            setTimeout(() => {
                handleClose();
            }, 2000);
        }

        return () => {
            abortController.abort();
        };
    }, [success, dispatch, handleClose]);

    useEffect(() => {
        dispatch(getCurrencies());
        dispatch(getDivisionsByService());
    }, [dispatch]);

    const handleChangeDate = (date) => {
        setState((prev) => ({
            ...prev,
            dateOfPurchase: moment(date).toDate(),
        }));
    };

    useEffect(() => {
        const withholdingTax = (state.cost * state.withholdingRate);
        setState((prev) => ({
            ...prev, withholdingTax
        }));
    }, [state.cost, state.withholdingRate]);

    useEffect(() => {
        const totalDayRate = (state.dayRate * state.margin);
        setState((prev) => ({
            ...prev, totalDayRate
        }));
    }, [state.dayRate, state.margin]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(createMaterialPriceList(state));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const validCheck = () =>
        !state.description ||
        !state.division ||
        !state.condition ||
        !state.code ||
        !state.dateOfPurchase ||
        !state.withholdingTax ||
        !state.withholdingRate ||
        !state.wear ||
        !state.margin ||
        !state.totalDayRate ||
        !state.currency;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                fullScreen
                maxWidth="md"
            >
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <AppBar
                    className={classes.appBar}
                    variant="elevation"
                    position="fixed"
                    color="inherit"
                    elevation={1}
                >
                    <Toolbar>
                        <Tooltip title="close" arrow>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <div className={classes.container}>
                    <Typography className={classes.title} variant="overline">
                        Create Material Price List
                    </Typography>
                    <div>
                        <form
                            component={Paper}
                            className={classes.form}
                            onSubmit={handleSubmit}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        value={state.description}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Code"
                                        name="code"
                                        variant="outlined"
                                        value={state.code}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Division</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "division",
                                                name: "division",
                                            }}
                                            value={state.division}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Division</MenuItem>
                                            {divisions.map((division) => (
                                                <MenuItem key={division._id} value={division._id}>
                                                    {division.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Condition</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "condition",
                                                name: "condition",
                                            }}
                                            value={state.condition}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Condition</MenuItem>
                                            <MenuItem value="New">New</MenuItem>
                                            <MenuItem value="Good">Good</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        autoOk
                                        format="DD/MM/yyyy"
                                        views={["year", "month", "date"]}
                                        label="Date of Purchase"
                                        value={state.dateOfPurchase}
                                        name="dateOfPurchase"
                                        onChange={handleChangeDate}
                                        fullWidth
                                        inputVariant="outlined"
                                        animateYearScrolling={true}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Currency</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "currency",
                                                name: "currency",
                                            }}
                                            value={state.currency}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Currency</MenuItem>
                                            {currencies.map((currency) => (
                                                <MenuItem key={currency._id} value={currency.code}>
                                                    {currency.code}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Cost"
                                        name="cost"
                                        type="number"
                                        variant="outlined"
                                        value={state.cost}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Damage Stock"
                                        name="wear"
                                        type="number"
                                        variant="outlined"
                                        value={state.wear}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Day Rate"
                                        name="dayRate"
                                        type="number"
                                        variant="outlined"
                                        value={state.dayRate}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Withholding Rate (%)"
                                        name="withholdingRate"
                                        type="number"
                                        variant="outlined"
                                        value={state.withholdingRate}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Withholding Tax"
                                        name="withholdingTax"
                                        type="number"
                                        variant="outlined"
                                        value={state.withholdingTax || 0}
                                        onChange={handleChange}
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Margin (%)"
                                        name="margin"
                                        type="number"
                                        variant="outlined"
                                        value={state.margin + "%" || 0}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Total Day Rate"
                                        name="totalDayRate"
                                        type="number"
                                        variant="outlined"
                                        value={state.totalDayRate}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>

                            <Grid container justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.saveBtn}
                                        type="submit"
                                        disabled={validCheck() || loading}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};

export default CreateMaterialModal;
