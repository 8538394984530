import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AdminQualityGuard = ({ children, profile, ...rest }) => {
  const token = localStorage.getItem("token:key");

  let isAdminAndQuality = profile
    ? profile &&
      profile.groups &&
      profile.groups.some(
        (grp) =>
          grp.name === "admin" ||
          (grp.name === "manager" && grp.module === "quality") ||
          grp.module === "quality"
      )
    : false;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && isAdminAndQuality ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

export default connect(mapStateToProps, {})(AdminQualityGuard);
