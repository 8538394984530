import {
  GET_ALL_BUDGET_ITEMS,
  GET_ALL_BUDGET_ITEMS_REQUEST,
  GET_ALL_BUDGET_ITEMS_FAIL,
  CREATE_BUDGET_ITEM,
  ERROR,
  GET_BUDGET_ITEM_BY_ID,
  UPDATE_BUDGET_ITEM,
  DELETE_BUDGET_ITEM,
  GET_BUDGET_ITEM_BY_SUBHEAD_AND_DIVISION,
  BUDGET_ITEM_ERROR,
} from "../types";
import axios from "axios";

const token = localStorage.getItem("token:key");

const URL = `/api/v1/budget/item`;

export const getAllBudgetItems = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BUDGET_ITEMS_REQUEST,
    });
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_BUDGET_ITEMS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BUDGET_ITEMS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const createBudgetItem = (data) => async (dispatch) => {
  try {
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_BUDGET_ITEM,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.message,
      });
    }
  }
};

export const getBudgetItemById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/item/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_BUDGET_ITEM_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const getBudgetItemBySubheadAndDivision = (data) => async (dispatch) => {
  try {
    const { subhead, division } = data;

    const URL = `/api/v1/budget/${subhead}/${division}/item`;

    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_BUDGET_ITEM_BY_SUBHEAD_AND_DIVISION,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateBudgetItem = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/item/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_BUDGET_ITEM,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteBudgetItem = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/item/${id}`;
    await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_BUDGET_ITEM,
      payload: id,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: BUDGET_ITEM_ERROR,
        error: error.message,
      });
    }
  }
};
