import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  Grid,
  Typography,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsByModule } from "../../../../actions/groupsAction";
import { getAllModulesAndSubmodules } from "../../../../actions/moduleAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  img: {
    width: 500,
    height: 400,
    border: "1px solid #ccc",
    borderRadius: 10,
    objectFit: "contain",
    objectPosition: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  small: {
    fontSize: 11,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  link: {
    display: "block",
    margin: "auto",
  },
  submitBtn: {
    letterSpacing: 2.5,
    marginTop: 20,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const INIT_STATE = {
  module: "",
  groups: "",
};

const GroupsModal = ({ openGroups, handleCloseGroups, userIds }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { groups } = useSelector((state) => state.groups);
  const { allmodules } = useSelector((state) => state.module);

  const [state, setState] = useState(INIT_STATE);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    dispatch(getAllModulesAndSubmodules());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGroupsByModule(state.module));
  }, [dispatch, state.module]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      groups: groupList.map((grp) => grp.group),
      userIds,
    };
    console.log(data);
  };

  const handleAddGroups = (e) => {
    const grp = state.groups.split("%");
    const data = {
      module: grp[1],
      group: grp[0],
    };
    setGroupList((prev) => [...prev, data]);

    setState((prevState) => ({
      ...prevState,
      module: "",
      groups: "",
    }));
  };

  const handleRemoveList = (i) => {
    setGroupList((prev) => prev.filter((_, index) => index !== i));
  };

  return (
    <div>
      <Dialog
        open={openGroups}
        onClose={handleCloseGroups}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Groups
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseGroups}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center" spacing={3}>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Module</InputLabel>
                  <Select
                    name="module"
                    label="Module"
                    value={state.module}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {allmodules &&
                      allmodules.map((mod) => (
                        <MenuItem value={mod.code} key={mod._id}>
                          {mod.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Groups</InputLabel>
                  <Select
                    name="groups"
                    label="Groups"
                    value={state.groups}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {groups &&
                      groups.data &&
                      groups.data.map((group) => (
                        <MenuItem
                          key={group._id}
                          value={`${group._id}%${group.displayName}`}
                        >
                          {group.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleAddGroups}
                  fullWidth
                  disabled={!state.module || !state.groups}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={12}>
                {groupList &&
                  groupList.map((group, i) => (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {/* <RippleEffect variant="dot" overlap="circle" /> */}
                      <Paper className={classes.paper2}>
                        <Typography variant="overline" color="initial">
                          {group.module}
                        </Typography>
                        <IconButton onClick={() => handleRemoveList(i)}>
                          <CloseIcon color="inherit" />
                        </IconButton>
                      </Paper>
                    </div>
                  ))}
              </Grid>
            </Grid>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.submitBtn}
              disabled={groupList.length === 0}
            >
              Update
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GroupsModal;
