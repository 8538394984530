import React from "react";
import { connect } from "react-redux";
import {
  Paper,
  Grid,
  Typography,
  makeStyles,
  TextField,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { TablePaginationActions } from "../../TablePaginationActions";
import { formatAmount } from "../../../utils";
import { getSymbol } from "../../../utils/currencyconvert";

const useStyles = makeStyles((theme) => ({
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    marginBottom: 10,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  textGreen: {
    color: "#388e3c",
    fontWeight: 600,
    fontSize: 11,
  },
  marginBottom: {
    marginBottom: 30,
  },
}));

const CashAdvanceItemTable = ({ items, total, currency, convertedTotal }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let filteredRows = () => (items === undefined ? [] : items);

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );
  return (
    <div>
      <Typography variant="overline" className={classes.title2}>
        Cash Advance Items
      </Typography>
      <Paper>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Budget Item</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow
                    hover
                    key={i}
                    style={{
                      background:
                        row.status === "postponed" ? "#eeeeee" : "none",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.item && row?.item?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.amount}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginTop: 30 }}
      >
        <Grid item></Grid>
        <Grid item>
          <TextField
            label="Total Amount"
            variant="outlined"
            fullWidth
            value={formatAmount(total || 0) || 0}
            disabled
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    {/* &#8358; */}
                    {getSymbol(currency ? currency : "NGN", total)}
                  </InputAdornment>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                </>
              ),
            }}
            helperText={
              <span className={classes.textGreen}>
                Naira Equivalent: NGN {formatAmount(convertedTotal || 0)}
              </span>
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashAdvanceItemTable);
