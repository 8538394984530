module.exports = {
    CREATE_OFFICE: "CREATE_OFFICE",
    CREATE_OFFICE_REQUEST: "CREATE_OFFICE_REQUEST",
    CREATE_OFFICE_FAIL: "CREATE_OFFICE_FAIL",

    GET_ALL_OFFICES: "GET_ALL_OFFICES",
    GET_ALL_OFFICES_REQUEST: "GET_ALL_OFFICES_REQUEST",
    GET_ALL_OFFICES_FAIL: "GET_ALL_OFFICES_FAIL",

    GET_OFFICE: "GET_OFFICE",
    GET_OFFICE_REQUEST: "GET_OFFICE_REQUEST",
    GET_OFFICE_FAIL: "GET_OFFICE_FAIL",

    GET_OFFICES_BY_LOCATION: "GET_OFFICES_BY_LOCATION",
    GET_OFFICES_BY_LOCATION_REQUEST: "GET_OFFICES_BY_LOCATION_REQUEST",
    GET_OFFICES_BY_LOCATION_FAIL: "GET_OFFICES_BY_LOCATION_FAIL",

    UPDATE_OFFICE: "UPDATE_OFFICE",
    UPDATE_OFFICE_REQUEST: "UPDATE_OFFICE_REQUEST",
    UPDATE_OFFICE_FAIL: "UPDATE_OFFICE_FAIL",

    DELETE_OFFICE: "DELETE_OFFICE",
    DELETE_OFFICE_REQUEST: "DELETE_OFFICE_REQUEST",
    DELETE_OFFICE_FAIL: "DELETE_OFFICE_FAIL",

    OFFICE_ERROR: "OFFICE_ERROR",
    RESET_OFFICE: "RESET_OFFICE",
}
