import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { formatAmount } from "../../../../../utils";

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
    },
    text: {
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 25,
    },
    title: {
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 15,
    },
    title2: {
        marginBottom: 50,
    },
    viewer: {
        width: "100%", //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
    textCenterBig: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1.5,
        marginBottom: 15,
    },
    textCenter: {
        textAlign: "center",
        fontSize: 13,
    },
    textRight: {
        textAlign: "right",
        fontSize: 13,
    },
    textLeft: {
        textAlign: "left",
        fontSize: 13,
    },
    table: {
        padding: 0,
        margin: 0,
        marginBottom: 30,
    },
    row: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #999',
        padding: 0,
        margin: 0,
    },
    th: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 4,
        margin: 0,
        backgroundColor: "#444",
        color: "#fff"
    },
    foot: {
        backgroundColor: "#afafaf",
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #999',
        padding: 0,
        margin: 0,
    },
    td: {
        fontSize: 10,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        color: "#444",
        border: "1px solid #999",
        borderTopWidth: 0,
    },
    desc: {
        width: "20%",
    },
    desc2: {
        width: "30%",
    },
    desc3: {
        width: "80%",
    },
    desc4: {
        width: "40%",
        textAlign: "right",
    },
    desc5: {
        width: "50%",
        textAlign: "right",
    },
    sn: {
        width: "10%",
    },
});

const TableHead = () => {
    return (
        <>
            <View style={[styles.row]}>
                <Text style={[styles.th, styles.desc2]}>Description</Text>
                <Text style={[styles.th, styles.sn]}>Qty</Text>
                <Text style={[styles.th, styles.desc]}>Duration (in days)</Text>
                <Text style={[styles.th, styles.desc]}>Daily Rate</Text>
                <Text style={[styles.th, styles.desc]}>Total Rate</Text>
            </View>
        </>
    )
}

// const TableFoot = ({ value, currency }) => {
//     const total = 0;
//     const sumall = value.map(item => item.totalRate).reduce((prev, curr) => prev + curr, 0);
//     return (
//         <>
//             <View style={[styles.foot]}>
//                 <Text style={[styles.th, styles.desc2]}>TOTAL:</Text>
//                 <Text style={[styles.th, styles.desc5]}>{currency ? currency : "NGN"}</Text>
//                 <Text style={[styles.th, styles.desc]}>
//                     {sumall ? formatAmount(sumall, "en-NG") : formatAmount(total, "en-NG")}
//                 </Text>
//             </View>
//         </>
//     )
// }

// const TableFoot2 = ({ value, currency }) => {
//     const total = 0;
//     const sumall = value.map(item => item.totalAmount).reduce((prev, curr) => prev + curr, 0);
//     return (
//         <>
//             <View style={[styles.foot]}>
//                 <Text style={[styles.th, styles.desc2]}>TOTAL:</Text>
//                 <Text style={[styles.th, styles.desc4]}>{currency ? currency : "NGN"}</Text>
//                 <Text style={[styles.th, styles.desc2]}>
//                     {sumall ? formatAmount(sumall, "en-NG") : formatAmount(total, "en-NG")}
//                 </Text>
//             </View>
//         </>
//     )
// }

// const TableFoot3 = ({ value, currency }) => {
//     const total = 0;
//     const sumall = value.map(item => item.totalRate).reduce((prev, curr) => prev + curr, 0);
//     return (
//         <>
//             <View style={[styles.foot]}>
//                 <Text style={[styles.th, styles.desc2]}>TOTAL:</Text>
//                 <Text style={[styles.th, styles.desc4]}>{currency ? currency : "NGN"}</Text>
//                 <Text style={[styles.th, styles.desc2]}>
//                     {sumall ? formatAmount(sumall, "en-NG") : formatAmount(total, "en-NG")}
//                 </Text>
//             </View>
//         </>
//     )
// }

// const CumulativeTotal = ({ personnel, equipment, consumable, material, demob, other, currency }) => {
//     const total = 0;
//     let cumall = personnel.map(item => item.totalRate).reduce((prev, curr) => prev + curr, 0);
//     cumall += equipment.map(item => item.totalRate).reduce((prev, curr) => prev + curr, 0);
//     cumall += material.map(item => item.totalRate).reduce((prev, curr) => prev + curr, 0);
//     cumall += consumable.map(item => item.totalRate).reduce((prev, curr) => prev + curr, 0);
//     cumall += demob.map(item => item.totalAmount).reduce((prev, curr) => prev + curr, 0);
//     cumall += other.map(item => item.totalAmount).reduce((prev, curr) => prev + curr, 0);
//     return (
//         (cumall > 0) ?
//             <>
//                 <Text style={styles.textLeft}>CUMULATIVE:</Text>
//                 <View style={[styles.foot]}>
//                     <Text style={[styles.th, styles.desc2]}>TOTAL:</Text>
//                     <Text style={[styles.th, styles.desc4]}>{currency ? currency : "NGN"}</Text>
//                     <Text style={[styles.th, styles.desc2]}>
//                         {cumall ? formatAmount(cumall, "en-NG") : formatAmount(total, "en-NG")}
//                     </Text>
//                 </View>
//             </> : null
//     )
// }

const TableHead2 = () => {
    return (
        <>
            <View style={[styles.row]}>
                <Text style={[styles.th, styles.desc2]}>Description</Text>
                <Text style={[styles.th, styles.sn]}>Qty</Text>
                <Text style={[styles.th, styles.desc2]}>Unit/Daily Rate</Text>
                <Text style={[styles.th, styles.desc2]}>Total Rate</Text>
            </View>
        </>
    )
}

const TableHead3 = () => {
    return (
        <>
            <View style={[styles.row]}>
                <Text style={[styles.th, styles.desc2]}>Description</Text>
                <Text style={[styles.th, styles.sn]}>Qty</Text>
                <Text style={[styles.th, styles.desc2]}>Type/Kind</Text>
                <Text style={[styles.th, styles.desc2]}>Total Amount</Text>
            </View>
        </>
    )
}

const PageTwo = ({ quote }) => {
    return (
        <>
            <View style={styles.section} break>
                <Text style={styles.textCenterBig}>SCHEDULE OF PRICES:</Text>

                {/* Personnel Charges */}
                {quote && quote.personnel && quote.personnel.length > 0 ? (
                    <>
                        <Text style={styles.textLeft}>PERSONNEL CHARGES:</Text>
                        <View style={styles.table}>
                            <TableHead />
                            {(quote.personnel).map((item, i) => (
                                <View style={[styles.row]} key={i}>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {item.description && item.description}
                                    </Text>
                                    <Text style={[styles.td, styles.sn]}>
                                        {item.quantity && item.quantity}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {item.duration && item.duration}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.unitRate
                                            ? formatAmount(item.unitRate, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.totalRate
                                            ? formatAmount(item.totalRate, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                </View>
                            ))}
                            {/* <TableFoot value={quote.personnel} currency={quote.currency} /> */}
                        </View>
                    </>
                ) : null}

                {/* Equipment Charges */}
                {quote && quote.equipment && quote.equipment.length > 0 ? (
                    <>
                        <Text style={styles.textLeft}>EQUIPMENT CHARGES:</Text>
                        <View style={styles.table}>
                            <TableHead />
                            {(quote.equipment).map((item, i) => (
                                <View style={[styles.row]} key={i}>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {item.description && item.description}
                                    </Text>
                                    <Text style={[styles.td, styles.sn]}>
                                        {item.quantity && item.quantity}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {item.duration && item.duration}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.unitRate
                                            ? formatAmount(item.unitRate, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.totalRate
                                            ? formatAmount(item.totalRate, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                </View>
                            ))}
                            {/* <TableFoot value={quote.equipment} currency={quote.currency} /> */}
                        </View>
                    </>
                ) : null}

                {/* Material Charges */}
                {quote && quote.material && quote.material.length > 0 ? (
                    <>
                        <Text style={styles.textLeft}>MATERIAL CHARGES:</Text>
                        <View style={styles.table}>
                            <TableHead />
                            {(quote.material).map((item, i) => (
                                <View style={[styles.row]} key={i}>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {item.description && item.description}
                                    </Text>
                                    <Text style={[styles.td, styles.sn]}>
                                        {item.quantity && item.quantity}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {item.duration && item.duration}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.unitRate
                                            ? formatAmount(item.unitRate, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                    <Text style={[styles.td, styles.desc]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.totalRate
                                            ? formatAmount(item.totalRate, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                </View>
                            ))}
                            {/* <TableFoot value={quote.material} currency={quote.currency} /> */}
                        </View>
                    </>
                ) : null}

                {/* Consumable Charges */}
                {quote && quote.consumable && quote.consumable.length > 0 ? (
                    <>
                        <Text style={styles.textLeft}>CONSUMABLE CHARGES:</Text>
                        <View style={styles.table}>
                            <TableHead2 />
                            {(quote.consumable).map((item, i) => (
                                <View style={[styles.row]} key={i}>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {item.description && item.description}
                                    </Text>
                                    <Text style={[styles.td, styles.sn]}>
                                        {item.quantity && item.quantity}
                                    </Text>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.unitRate
                                            ? formatAmount(item.unitRate, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.totalRate
                                            ? formatAmount(item.totalRate, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                </View>
                            ))}
                            {/* <TableFoot3 value={quote.consumable} currency={quote.currency} /> */}
                        </View>
                    </>
                ) : null}

                {/* MOB / DEMOB Charges */}
                {quote && quote.mob_demob && quote.mob_demob.length > 0 ? (
                    <>
                        <Text style={styles.textLeft}>MOB / DEMOB CHARGES:</Text>
                        <View style={styles.table}>
                            <TableHead3 />
                            {(quote.mob_demob).map((item, i) => (
                                <View style={[styles.row]} key={i}>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {item.description && item.description}
                                    </Text>
                                    <Text style={[styles.td, styles.sn]}>
                                        {item.quantity && item.quantity}
                                    </Text>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {item.kind && item.kind}
                                    </Text>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.totalAmount
                                            ? formatAmount(item.totalAmount, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                </View>
                            ))}
                            {/* <TableFoot2 value={quote.mob_demob} currency={quote.currency} /> */}
                        </View>
                    </>
                ) : null}

                {/* Other Charges */}
                {quote && quote.others && quote.others.length > 0 ? (
                    <>
                        <Text style={styles.textLeft}>Other CHARGES:</Text>
                        <View style={styles.table}>
                            <TableHead3 />
                            {(quote.others).map((item, i) => (
                                <View style={[styles.row]} key={i}>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {item.description && item.description}
                                    </Text>
                                    <Text style={[styles.td, styles.sn]}>
                                        {item.quantity && item.quantity}
                                    </Text>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {item.kind && item.kind}
                                    </Text>
                                    <Text style={[styles.td, styles.desc2]}>
                                        {(item.currency && item.currency) || "NGN"} {" "}
                                        {item.totalAmount
                                            ? formatAmount(item.totalAmount, "en-NG")
                                            : formatAmount(0, "en-NG")}
                                    </Text>
                                </View>
                            ))}
                            {/* <TableFoot2 value={quote.others} currency={quote.currency} /> */}
                        </View>
                    </>
                ) : null}

                {quote && quote.comment ? (
                    <>
                        <Text style={styles.textLeft}>Comment:</Text>
                        <Text style={styles.text}>
                            {quote && quote.comment ? quote.comment : null}
                        </Text>
                    </>
                ) : null}

                {/* Cumulative totals */}
                {/* {quote ? (
                    <>
                        <CumulativeTotal
                            personnel={quote.personnel}
                            equipment={quote.equipment}
                            material={quote.material}
                            consumable={quote.consumable}
                            demob={quote.mob_demob}
                            other={quote.others}
                            currency={quote.currency}
                        />
                    </>
                ) : null} */}
            </View>
        </>
    )
}

export default PageTwo;
