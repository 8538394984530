import * as types from "../../types/salesOrder";

const initState = {
    order_databases: [],
    customer_orders: [],
    order_database: {},
    error: null,
    success: false,
    loading: false,
};

const salesOrderReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_ORDER_DATABASES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_ORDER_DATABASES:
            return {
                ...state,
                loading: false,
                success: true,
                order_databases: action.payload.data,
            };
        case types.GET_ALL_ORDER_DATABASES_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_CUSTOMER_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_CUSTOMER_ORDERS:
            return {
                ...state,
                loading: false,
                success: true,
                customer_orders: action.payload.data,
            };
        case types.GET_CUSTOMER_ORDERS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
    
        case types.GET_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                success: true,
                order_database: action.payload.data,
            };
        case types.GET_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                success: true,
                order_database: state.order_databases.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                success: false,
                order_databases: state.order_databases.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                order_database: action.payload.data,
            };
        case types.SUBMIT_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.APPROVE_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                order_database: action.payload.data,
            };
        case types.APPROVE_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                order_database: action.payload.data,
            };
        case types.REJECT_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                order_database: action.payload.data,
            };
        case types.ACCEPT_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_ORDER_DATABASE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_ORDER_DATABASE:
            return {
                ...state,
                loading: false,
                order_database: action.payload.data,
            };
        case types.DECLINE_ORDER_DATABASE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_ORDER_DATABASE:
            return {
                order_database: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default salesOrderReducer;
