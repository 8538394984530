import React, { useState, useEffect } from "react";
import {
    Typography,
    makeStyles,
    Grid,
    Divider,
    Button,
    Paper,
    Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionActions from '@material-ui/core/ExpansionPanelActions';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../atoms/Feedback";
import { getCourse, getCourses, getCourseLessons } from "../../../actions/ppmActions/lmsActions";
import LessonViewModal from "./modals/LessonViewModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
        marginBottom: 20,
        borderBottomColor:'#ccc',
        borderBottomWidth:1,
        borderBottomStyle:'solid',
        paddingBottom: 20,
        backgroundColor:"#f0f0f0",
        padding: theme.spacing(3),
    },
    title3: {
        fontSize: 16,
        fontWeight: "bold",
        borderBottomColor:'#ccc',
        borderBottomWidth:1,
        borderBottomStyle:'solid',
        backgroundColor:"#f0f0f0",
    },
    appBar: {
        zIndex: 9000,
    },
    paper: {
        padding: theme.spacing(0),
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0),
        },
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    grid: {
        marginBottom: 0,
        padding: theme.spacing(2),
        marginTop: -20,
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    viewBtn: {
        padding: theme.spacing(1),
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
}));

const INIT_STATE = {
    name: "",
    code:"",
    description:"",
    documentNo: "",
    lessons: [],
};

const CourseView = () => {    
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [openCreate, setOpenCreate] = useState(false);
    const [getId, setGetId] = useState("");

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const { error, loading, course, courseLessons } = useSelector((state) => state.lms);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getCourses());
        dispatch(getCourse(id));
        dispatch(getCourseLessons(id))
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            name: (course && course.name) || "",
            code: (course && course.code) || "",
            description: (course && course.description) || "",
            documentNo: (course && course.documentNo) || "",
            lessons:  (courseLessons && courseLessons) || [],
        }));
    }, [course, courseLessons]);
    
    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleOpenCreate = (id) => {
        setGetId(id);
        setOpenCreate(true);
    };
    const handleCloseCreate = () => {
        setOpenCreate(false);
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                handleCloseFeed={handleCloseFeed}
                open={feed.open}
                severity="success"
                message={feed.message}
                />
            ) : (
                <Feedback
                handleCloseFeed={handleCloseFeed}
                open={feed.open}
                severity="error"
                message={feed.message}
                />
            )}
            
            <LessonViewModal {...{ openCreate, handleCloseCreate, id: getId  }} />

            <Typography variant="overline" className={classes.title}>
                {state.name && state.name}
            </Typography>

            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/mycourses")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Course No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>
            
            <Paper variant="outlined" square className={classes.paper} style={{ marginTop: 20 }}>
                <Typography variant="overline" className={classes.title2}>
                    Course Lessons
                </Typography>


                <Grid container spacing={0} className={classes.grid} >
                    {state.lessons && state.lessons.map((row, i) => {
                        return (
                            <Grid item xs={12} md={12} key={i}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className={classes.title3}
                                    >
                                        {row.title && row.title}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {row.description && row.description}
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Button 
                                            color="primary"
                                            variant="contained"
                                            className={classes.viewBtn}
                                            onClick={() => handleOpenCreate(row._id)}
                                        >
                                            Open Lesson
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default CourseView;
