import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

import FilterListIcon from "@material-ui/icons/FilterList";

import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
    borderWidth: 2,
  },
  statusGreen: {
    // background: "#00e676",
    borderWidth: 2,

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",
    borderWidth: 2,

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
    borderWidth: 2,
  },
  statusRed: {
    // background: "#ef5350",
    borderWidth: 2,
    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
}));

const RejectionLogs = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filteredRows = () =>
    [].filter((row) => {
      if (search !== "") {
        return (
          row.year.toString().toLowerCase().indexOf(search.toLowerCase()) !==
            -1 ||
          row.employee.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          row.total.toString().toLowerCase().indexOf(search.toLowerCase()) !==
            -1 ||
          row.createdAt.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          row.status.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          (row.division &&
            row.division.name.toLowerCase().indexOf(search.toLowerCase()) !==
              -1)
        );
      } else if (filter !== "") {
        return row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      } else {
        return row;
      }
    });

  const handleSelectFilter = (e, val) => {
    setFilter(val.substr(0, 20));
    handleCloseFilter();
  };

  const dataLength = Array(1000).length || filteredRows().length;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

  return (
    <div>
      <Typography variant="overline" gutterBottom className={classes.title}>
      Rejection Logs
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            className={classes.createBtn}
            // onClick={handleOpenModal}
          >
            Create
          </Button>
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton
              style={{ marginRight: 10, marginTop: 10 }}
              onClick={handleOpenFilter}
            >
              <FilterListIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseFilter}
              TransitionComponent={Fade}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                All
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Pending")}>
                Pending
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Approved")}>
                Approved
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Declined")}>
                Declined
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Draft")}>
                Draft
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Document No.</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Div./SubDiv.</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
};

export default RejectionLogs;