import React, { useState, useEffect, useCallback } from "react";
import {
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    Paper,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    Badge,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../../atoms/Feedback";
import { getProfile } from "../../../../../actions/userActions";
import { createGRN, getAllGRNS } from "../../../../../actions/inventoryActions";
import { getApprovedReceivingInspections } from "../../../../../actions/qualityActions";
import { formatAmount } from "../../../../../utils";
import InvoiceViewModal from "../../../../pages/quality/modals/InvoiceModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    division: "",
    vendor: "",
    vendorName: "",
    vendorEmail: "",
    costCenter: "",
    purchaseOrderNo: "",
    date: "",
    items: [],
};

const INIT_VENDOR = {
    id: "",
    vendorName: "",
    vendorEmail:"",
    purchaseOrderNo: "",
    items: [],
}

const CreateGRNModal = ({ openCreate, handleCloseCreate, createGRN }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [theUrl, setTheUrl] = useState("");
    const [openDoc, setOpenDoc] = useState(false);
    const [date] = useState(moment(new Date()));
    const [state, setState] = useState(INIT_STATE);
    const [vendor, setVendor] = useState(INIT_VENDOR);
    const { profile } = useSelector((state) => state.user);
    const { loading, error, receivings_accepted } = useSelector((state) => state.receiving);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);
    
    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
            date: moment(date).toDate(),
            totalCost: 0,
        }));
    }, [profile, date]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getApprovedReceivingInspections());
    }, [dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const totalCallback = useCallback(() => {
        if(vendor && vendor.items.length > 0){
            let totalCost = vendor.items.reduce((sum, item) => sum + (item.budgetAmount * item.quantityDelivered), 0);
            setState((prev) => ({
                ...prev,
                totalCost: totalCost,
            }));
        }
    }, [vendor]);

    useEffect(() => {
        totalCallback();
    }, [totalCallback]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        let newItems = vendor.items.map(item => ({
            description: item.description,
            quantityDelivered: item.quantityDelivered,
            quantityOrdered: item.quantityNeeded,
            total: parseFloat(item.quantityDelivered) * item.budgetAmount,
            cost: item.budgetAmount,
        }));

        const data = {
            ...state,
            vendor: vendor?.id,
            vendorName: vendor && vendor?.vendorName,
            vendorEmail: vendor && vendor?.vendorEmail,
            purchaseOrderNo: vendor?.purchaseOrderNo,
            invoices: vendor.invoices,
            items: newItems,
        };
        
        const res = await new Promise((resolve, reject) => {
            resolve(createGRN(data));
        });
        
        if (res) {
            dispatch(getAllGRNS());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Goods Received Note Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/inventory/grn`);
                handleCloseCreate();
            }, 2000);
        }
    };

    const handleOpenDoc = (url) => {
        setTheUrl(null);
        if(url){
            setTheUrl(url);
            setOpenDoc(true);
        }
    };
    
    const handleCloseDoc = () => {
        setOpenDoc(false);
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.date ||
        !state.totalCost ||
        !vendor.vendorName ||
        !vendor.vendorEmail ||
        !vendor.purchaseOrderNo ||
        !vendor ||
        (vendor.items.length === 0) ||
        (vendor.invoices.length === 0);
    
    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <InvoiceViewModal {...{ openDoc, handleCloseDoc, docs: theUrl }} />

                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Goods Received Note
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                        label="Employee Name"
                                        name="employeeName"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={state.employeeName || ""}
                                        disabled
                                        required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="EID"
                                            name="employeeId"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={state.employeeId || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Division/Subdivision"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.division && state.divisionName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Date"
                                            fullWidth
                                            required
                                            inputVariant="outlined"
                                            value={date}
                                            minDate={date}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Autocomplete
                                            style={{marginTop: -15}}
                                            id="vendor"
                                            options={receivings_accepted}
                                            // value={(vendor && vendor.id) || ""}
                                            onChange={(event, newValue) => {
                                                setVendor((prev) => ({
                                                    ...prev,
                                                    id: newValue && newValue._id,
                                                    purchaseOrderNo: newValue && newValue.purchaseOrderNo,
                                                    vendorName: newValue && (newValue.vendorName || newValue.vendor?.companyName),
                                                    vendorEmail: newValue && (newValue.vendorEmail || newValue.vendor?.email),
                                                    documentNo: newValue && newValue.documentNo,
                                                    items: newValue && newValue.items,
                                                    invoices: newValue && newValue.invoices,
                                                }));
                                            }}
                                            getOptionLabel={(option) => (option.vendorName || option.vendor?.companyName) || ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderOption={(option) => (
                                                <React.Fragment>{option.documentNo}</React.Fragment>
                                            )}
                                            autoComplete
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Receiving and Inspection"
                                                    margin="normal"
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    value={(vendor.documentNo && vendor.documentNo) || ""}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "disabled",
                                                        name: "vendor",
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Vendor/Supplier Name"
                                            variant="outlined"
                                            fullWidth
                                            name="vendorName"
                                            value={(vendor.vendorName && vendor.vendorName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Vendor/Supplier Email"
                                            variant="outlined"
                                            fullWidth
                                            name="vendorEmail"
                                            value={(vendor.vendorEmail && vendor.vendorEmail) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Purchase Order Number"
                                            variant="outlined"
                                            fullWidth
                                            name="purchaseOrderNo"
                                            value={(vendor.purchaseOrderNo && vendor.purchaseOrderNo) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                </Grid>

                                <Typography variant="overline" className={classes.title2}>
                                    Attached Invoices
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {(vendor.invoices && vendor.invoices.length > 0) ? vendor.invoices.map((sinvoice, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={11}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description of Invoice"
                                                    name="description"
                                                    value={sinvoice.description || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <IconButton
                                                    disabled={sinvoice.invoice === null}
                                                    onClick={() => handleOpenDoc(sinvoice.invoice)}
                                                >
                                                    <Badge
                                                        color="secondary"
                                                        badgeContent={sinvoice.invoice ? 1 : null}
                                                    >
                                                        <AttachFileIcon />
                                                    </Badge>
                                                </IconButton>
                                            </Grid>
                                    </Grid>
                                    )) : <>
                                        <Typography variant="overline" className={classes.title2}>
                                            No Attached Invoice
                                        </Typography>
                                    </>}
                                </Paper>

                                <Typography variant="overline" className={classes.title2}>
                                    Receiving and Inspection Items
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {vendor.items ? vendor.items.map((sitem, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={4}>
                                                <label style={{ fontWeight: "bold" }}>Product / Service Description</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name="description"
                                                    value={sitem.description || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <label style={{ fontWeight: "bold" }}>Quantity Ordered: ({sitem.unit || ""})</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    type="text"
                                                    name="quantityOrdered"
                                                    value={`${sitem.quantityNeeded + ' ' + sitem.unit}` || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <label style={{ fontWeight: "bold" }}>Quantity Supplied</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    type="number"
                                                    name="quantityDelivered"
                                                    value={sitem.quantityDelivered || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <label style={{ fontWeight: "bold" }}>Unit Cost</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    type="number"
                                                    name="cost"
                                                    value={sitem.budgetAmount || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <label style={{ fontWeight: "bold" }}>Total Cost</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    type="number"
                                                    name="total"
                                                    value={`${sitem.budgetAmount * sitem.quantityDelivered}`  || ""}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    )) : <>
                                        <Typography variant="overline" className={classes.title2}>
                                            No Requested Items
                                        </Typography>
                                    </>}
                                </Paper>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={10} />
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            label="Total Cost"
                                            name="totalCost"
                                            value={`${formatAmount(state.totalCost, "en-NG") || 0}` || ""}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createGRN, getAllGRNS, getApprovedReceivingInspections })(CreateGRNModal);
