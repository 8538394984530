import {
  CREATE_CASH_ADVANCE,
  GET_ALL_CASH_ADVANCES,
  GET_CASH_ADVANCE_BY_ID,
  CASH_ADVANCE_ERROR,
  UPDATE_CASH_ADVANCE,
  SUBMIT_CASH_ADVANCE,
  APPROVE_CASH_ADVANCE,
  REJECT_CASH_ADVANCE,

  REVIEW_CASH_ADVANCE,
  DECLINE_CASH_ADVANCE,
  HOD_CONFIRM_CASH_RETIREMENT,
  HOD_DECLINE_CASH_RETIREMENT,
  FIN_ACCEPT_CASH_RETIREMENT,
  FIN_REJECT_CASH_RETIREMENT,

  PAY_CASH_ADVANCE,
  GET_ALL_CASH_ADVANCES_AWAITING_FINANCE_PAY_OR_CEO_APPROVAL,
  RETIRE_CASH_ADVANCE,
  UPLOAD_DOCUMENT,
  GET_OUTSTANDING_TOTAL,
} from "../types";

import axios from "axios";

const token = localStorage.getItem("token:key");

export const getAllCashAdvances = () => async (dispatch) => {
  try {
    const URL = `/api/v1/advance`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_CASH_ADVANCES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllCashAdvancesAwaitingFinance = () => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/awaitingPayment`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_CASH_ADVANCES_AWAITING_FINANCE_PAY_OR_CEO_APPROVAL,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getCashAdvanceById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_CASH_ADVANCE_BY_ID,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const createCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
      return await error.response.data;
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
      return await error.request.data;
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
      return await error.message;
    }
  }
};

export const submitCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/submit/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SUBMIT_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
      return await error.response.data;
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
      return await error.request.data;
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
      return await error.message;
    }
  }
};

export const reviewCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/review/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REVIEW_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const declineCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/decline/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DECLINE_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};


export const approveCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/approve/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const rejectCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reject/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REJECT_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};


export const payCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/pay/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: PAY_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const retireCashAdvance = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/retire/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: RETIRE_CASH_ADVANCE,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const confirmHodCashRetirement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/confirm-retirement/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: HOD_CONFIRM_CASH_RETIREMENT,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const declineHodCashRetirement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/decline-retirement/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: HOD_DECLINE_CASH_RETIREMENT,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const acceptFinCashRetirement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/accept-retirement/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FIN_ACCEPT_CASH_RETIREMENT,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const rejectFinCashRetirement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reject-retirement/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FIN_REJECT_CASH_RETIREMENT,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const uploadDocument = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/upload/${data.get("id")}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPLOAD_DOCUMENT,
      payload: res.data,
    });
    return await res.data.doc;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getOutStandingTotal = () => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/outstanding/total`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_OUTSTANDING_TOTAL,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: CASH_ADVANCE_ERROR,
        error: error.message,
      });
    }
  }
};
