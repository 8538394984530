import React, { useState } from "react";
import {
  TextField,
  Paper,
  makeStyles,
  Typography,
  Grid,
  Divider,
  IconButton,
  Button,
  Backdrop,
  InputAdornment,
  ButtonGroup,
  Icon,
  useTheme,
  darken,
  Menu,
  Chip,
  MenuItem,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { connect } from "react-redux";
import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";
import { useHistory, useParams } from "react-router-dom";
import { getAnnualBudgetById } from "../../../actions/annualAction";
import { formatAmount, formatCurrency } from "../../../utils";
import clsx from "clsx";
import BudgetConfirmModal from "./Approvals/BudgetConfirmModal";
import { PeriodsModal } from "./modals/PeriodsModal";

import DeclineBudgetModal from "./Rejections/DeclineBudgetModal";
import BudgetApproveModal from "./Approvals/BudgetApproveModal";
import RejectBudgetModal from "./Rejections/RejectBudgetModal";
import InnerBudgetApproveModal from "./Approvals/InnerBudgetApproveModal";
import RequestModificationModal from "./Approvals/RequestModificationModal";
import RequestClarificationModal from "./Approvals/RequestClarificationModal";
import SubmitClarifyModal from "./modals/SubmitClarifyModal";
import CreateAnnualSuppBudgetLineModal from "./modals/CreateAnnualSuppBudgetLineModal";
import ApproveSupplementaryBudgetLineModal from "./modals/ApproveSupplementaryBudgetLineModal";
import DeclineSupplementaryBudgetLineModal from "./modals/DeclineSupplementaryBudgetLineModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  btn: {
    // marginBottom: 15,
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    // marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  table: {
    minWidth: 500,
  },
  divider: {
    marginBottom: 30,
    marginTop: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  grid: {
    marginTop: 15,
  },
  textField2: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  textFieldSmall: {
    width: 100,
  },
  status: {
    width: 140,
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  actions: {
    background: "#388e3c",
    color: "#fff",
    padding: theme.spacing(1.2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  btn2: {
    marginLeft: 10,

    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  embed: {
    width: "100%",
    height: 100,
    objectFit: "contain",
    objectPosition: "center",
    marginRight: 5,
    cursor: "pointer",
    transition: "all 300ms ease-in-out",
    zIndex: 1,
    "&:hover": {
      transform: "scale(2.5)",
      zIndex: 1000000000000,
    },
  },
  audit: {
    padding: 10,
    fontFamily: "Rubik",
    fontSize: 10,
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    // fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditTitle: {
    padding: 10,
    // background: theme.palette.grey[600],
    color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
    fontFamily: "Rubik",
    fontSize: 10,
    fontWeight: "bold",
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditContainer: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  small: {
    fontSize: 11,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  link: {
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  centered: {
    textAlign: "center",
  },
  paperBlack: {
    color: "#f5f5f5",
    // background: "#3c3737",
    background: theme.palette.grey.A400,
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
    textAlign: "center",
    maxWidth: 400,
    marginLeft: "auto",
    marginRight: "auto",
  },
  info2: {
    marginBottom: 20,
    fontWeight: 500,
  },
}));

const INIT_STATE = {
  employeeName: "",
  division: "",
  jobTitle: "",
  year: "",
  status: "",
  documentNo: "",
  clarification: "",
};

const ViewAnnualBudget = ({ getAnnualBudgetById, annualBudget, profile }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openClarify, setOpenClarify] = useState(false);
  const [approveAnchorEl, setApproveAnchorEl] = React.useState(null);

  const [openReject, setOpenReject] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openSuppApprove, setOpenSuppApprove] = useState(false);
  const [openSuppDecline, setOpenSuppDecline] = useState(false);
  const [openFinalApprove, setOpenFinalApprove] = useState(false);
  const [openRequestModification, setOpenRequestModification] = useState(false);
  const [openRequestClarification, setOpenRequestClarification] = useState(
    false
  );

  const [openDecline, setOpenDecline] = useState(false);
  const [openPeriods, setOpenPeriods] = useState(false);
  const [state, setState] = useState(INIT_STATE);
  const [total, setTotal] = React.useState(0);
  const [periods, setPeriods] = React.useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [budgetLines, setBudgetLines] = useState([]);
  const [iid, setIid] = useState(null);

  const history = useHistory();
  const { id } = useParams();

  const annualBudgetCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getAnnualBudgetById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [id, getAnnualBudgetById]);

  React.useEffect(() => {
    annualBudgetCallback();
  }, [annualBudgetCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: annualBudget && annualBudget.employee,
      division:
        annualBudget && annualBudget.division && annualBudget.division.name,
      jobTitle: annualBudget && annualBudget.jobTitle.name,
      year: annualBudget && annualBudget.year,
      status: annualBudget && annualBudget.status,
      documentNo: annualBudget && annualBudget.documentNo,
      clarification: annualBudget && annualBudget.clarification,

      submittedBy:
        (annualBudget && annualBudget.createdBy && annualBudget.createdBy.userName) || "",
      submittedAt: (annualBudget && annualBudget.createdAt) || "",

      approvedBy: (annualBudget && annualBudget.approvedBy && annualBudget.approvedBy.userName) || "",
      approvedAt: (annualBudget && annualBudget.approvedAt) || "",
      approverComment: (annualBudget && annualBudget.approverComment) || "",

      acceptedBy: (annualBudget && annualBudget.acceptedBy && annualBudget.acceptedBy.userName) || "",
      acceptedAt: (annualBudget && annualBudget.acceptedAt) || "",
      acceptComment: (annualBudget && annualBudget.financeComment) || "",

      rejectedBy: (annualBudget && annualBudget.rejectedBy && annualBudget.rejectedBy.userName) || "",
      rejectedAt: (annualBudget && annualBudget.rejectedAt) || "",
      rejectComment: (annualBudget && annualBudget.rejectComment) || "",

      declinedBy: (annualBudget && annualBudget.declinedBy && annualBudget.declinedBy.userName) || "",
      declinedAt: (annualBudget && annualBudget.declinedAt) || "",
      declineComment: (annualBudget && annualBudget.declineComment) || "",

      completedBy: (annualBudget && annualBudget.completedBy && annualBudget.completedBy.userName) || "",
      completedAt: (annualBudget && annualBudget.completedAt) || "",
      CEOComment: (annualBudget && annualBudget.CEOComment) || "",

      reviewedBy: (annualBudget && annualBudget.reviewedBy && annualBudget.reviewedBy.userName) || "",
      reviewedAt: (annualBudget && annualBudget.reviewedAt) || "",
      reviewerComment: (annualBudget && annualBudget.reviewerComment) || "",
    }));
  }, [annualBudget]);

  console.log(annualBudget)

  React.useEffect(() => {
    let total = (annualBudget && annualBudget.total) || 0;
    total = formatAmount(total);
    setTotal(total);
  }, [annualBudget]);

  React.useEffect(() => {
    setBudgetLines(annualBudget && annualBudget.budgetLines);
  }, [annualBudget]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };
  const filteredRows = () =>
    budgetLines === undefined
      ? []
      : budgetLines.filter((row) => {
        if (search !== "") {
          return (
            row.subTotal
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            (row.head &&
              row.head.name &&
              row.head.name.toLowerCase().indexOf(search.toLowerCase()) !==
              -1) ||
            (row.item &&
              row.item.name &&
              row.item.name.toLowerCase().indexOf(search.toLowerCase()) !==
              -1)
          );
        } else {
          return row;
        }
      });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Array(10).length - page * rowsPerPage);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenDecline = () => {
    setOpenDecline(true);
  };

  const handleCloseDecline = () => {
    setOpenDecline(false);
  };
  const handleOpenReject = () => {
    setOpenReject(true);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const handleOpenApprove = () => {
    setOpenApprove(true);
  };

  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const handleOpenPeriods = (periods, subTotal) => {
    setPeriods(periods);
    setSubTotal(subTotal);
    setOpenPeriods(true);
  };

  const handleClosePeriods = () => {
    setOpenPeriods(false);
  };

  const handleOpenApproveMenu = (event) => {
    setApproveAnchorEl(event.currentTarget);
  };

  const handleCloseApproveMenu = () => {
    setApproveAnchorEl(null);
  };

  const handleOpenFinalApprove = () => {
    setOpenFinalApprove(true);
    handleCloseApproveMenu();
  };

  const handleCloseFinalApprove = () => {
    setOpenFinalApprove(false);
  };

  const handleOpenRequestModification = () => {
    setOpenRequestModification(true);
    handleCloseApproveMenu();
  };

  const handleCloseRequestModification = () => {
    setOpenRequestModification(false);
  };

  const handleOpenRequestClarification = () => {
    setOpenRequestClarification(true);
    handleCloseApproveMenu();
  };

  const handleCloseRequestClarification = () => {
    setOpenRequestClarification(false);
  };

  const handleOpenClarify = () => {
    setOpenClarify(true);
  };

  const handleCloseClarify = () => {
    setOpenClarify(false);
  };

  
  const handleOpenSupplementaryApprove = (iid) => {
    setOpenSuppApprove(true);
    setIid(iid)
  };

  const handleCloseSupplementaryApprove = () => {
    setOpenSuppApprove(false);
  };

  const handleOpenSupplementaryDecline = (iid) => {
    setOpenSuppDecline(true);
    setIid(iid)
  };

  const handleCloseSupplementaryDecline = () => {
    setOpenSuppDecline(false);
  };


  // let isManager = profile
  //   ? profile &&
  //   profile.groups &&
  //   profile.groups.some(
  //     (grp) => grp.name === "head" && grp.module === "budget"
  //   )
  //   : false;

  const [openCreate, setOpenCreate] = useState(false);

  const isCEO = profile
      ? profile &&
      profile.jobTitle &&
      profile?.jobTitle?.name === "Chief Executive Officer"
      : false;

  const isManager = (profile && annualBudget)
      ? profile &&
      (profile.division && annualBudget.division) && (
      (profile?._id === annualBudget.division?.manager?._id) || 
      (profile?._id === annualBudget.division?.manager))
      : false;

  const isAdmin = profile
      ? profile &&
      profile.groups &&
      profile.groups.some(
          (grp) => grp.name === "admin" 
      )
      : false;

  const isCanAddSupplementary = (profile && annualBudget)
      ? (profile && annualBudget) &&
      (profile?.division?._id === annualBudget?.division?._id)
      : false;

  const handleCreateOpen = () => {
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  return (
    <div>
      <SubmitClarifyModal
        {...{
          openClarify,
          handleCloseClarify,
          id: annualBudget && annualBudget._id,
        }}
      />
      <BudgetConfirmModal
        openConfirm={openConfirm}
        handleCloseConfirm={handleCloseConfirm}
        id={annualBudget && annualBudget._id}
      />
      <BudgetApproveModal
        openApprove={openApprove}
        handleCloseApprove={handleCloseApprove}
        id={annualBudget && annualBudget._id}
      />
      <RejectBudgetModal
        openReject={openReject}
        handleCloseReject={handleCloseReject}
        id={annualBudget && annualBudget._id}
      />
      <DeclineBudgetModal
        openDecline={openDecline}
        handleCloseDecline={handleCloseDecline}
        id={annualBudget && annualBudget._id}
      />
      <PeriodsModal
        openPeriods={openPeriods}
        handleClosePeriods={handleClosePeriods}
        periods={periods}
        subTotal={subTotal}
      />

      <InnerBudgetApproveModal
        {...{ handleCloseFinalApprove, openFinalApprove, id }}
      />

      <RequestModificationModal
        {...{ handleCloseRequestModification, openRequestModification, id }}
      />

      <RequestClarificationModal
        {...{ handleCloseRequestClarification, openRequestClarification, id }}
      />

      <CreateAnnualSuppBudgetLineModal
        openCreate={openCreate}
        handleCreateClose={handleCreateClose}
        id={id}
      />

      <ApproveSupplementaryBudgetLineModal 
        openSuppApprove={openSuppApprove}
        handleCloseSuppApprove={handleCloseSupplementaryApprove}
        iid={iid && iid}
        id={id}
      />

      <DeclineSupplementaryBudgetLineModal 
        openSuppDecline={openSuppDecline}
        handleCloseSuppDecline={handleCloseSupplementaryDecline}
        iid={iid && iid}
        id={id}
      />

      <Grid container alignItems="center" spacing={6}>
        <Grid item>
          <Button
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            className={classes.backBtn}
          >
            Back
          </Button>
        </Grid>
        {isCEO &&
          (state.status === "accepted" ||
            state.status === "modified" ||
            state.status === "clarified") ? (
          <Grid item>
            <Button
              variant="contained"
              className={classes.actions}
              // size="large"
              onClick={handleOpenApproveMenu}
              endIcon={
                approveAnchorEl ? (
                  <KeyboardArrowUpIcon fontSize="large" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="large" />
                )
              }
            >
              Actions
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={approveAnchorEl}
              keepMounted
              open={Boolean(approveAnchorEl)}
              onClose={handleCloseApproveMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={handleOpenFinalApprove}>Approve</MenuItem>
              <MenuItem onClick={handleOpenRequestModification}>
                Request Modification
              </MenuItem>
              <MenuItem onClick={handleOpenRequestClarification}>
                Request Clarification
              </MenuItem>
            </Menu>
          </Grid>
        ) : null}
        {/* {(state.status === "approved")&& (
            <Grid item>
              <Button
                variant="contained"
                className={classes.actions}
                size="large"
              >
                Actions
              </Button>
            </Grid>
          )} */}
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
        spacing={2}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title}>
            View Annual Budget
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label="Document Number"
            value={state.documentNo || ""}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item>
        {(isCanAddSupplementary || isAdmin) && ((state.status === "approved") || (state.status === "completed")) && (
          <Button
            onClick={handleCreateOpen}
            variant="contained"
            className={classes.backBtn}
            color="primary"
            size="large"
            endIcon={<Icon className="fas fa-folder-plus" />}
          >
            Add Supplementary Budget Lines
          </Button>
          )}
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              state.status === "draft" ||
                state.status === "tomodify" ||
                state.status === "clarified" ||
                state.status === "modified" ||
                state.status === "toclarify"
                ? classes.statusYellow
                : state.status === "submitted"
                  ? classes.statusOrange
                  : state.status === "approved" || state.status === "reviewed"
                    ? classes.statusGreen
                    : state.status === "completed" || state.status === "accepted"
                      ? classes.statusDarkGreen
                      : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop
              ? "Loading..."
              : state.status && state.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="flex-start"
        className={classes.grid}
      >
        <Grid item xs={12} md={6}>
          <TextField
            label="Employee Name"
            fullWidth
            variant="outlined"
            className={classes.textField}
            name="employeeName"
            value={openBackdrop ? "Loading..." : state.employeeName || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Division"
            fullWidth
            variant="outlined"
            name="division"
            className={classes.textField}
            value={openBackdrop ? "Loading..." : state.division || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Job Title"
            fullWidth
            variant="outlined"
            name="jobTitle"
            className={classes.textField}
            value={openBackdrop ? "Loading..." : state.jobTitle || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Year"
            fullWidth
            variant="outlined"
            name="year"
            className={classes.textField}
            value={openBackdrop ? "Loading..." : state.year || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {state.clarification && (
            <TextField
              label="Clarification"
              fullWidth
              variant="outlined"
              name="clarification"
              multiline
              rows={2}
              className={classes.textField}
              value={state.clarification || ""}
              disabled
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>

      <Divider className={classes.divider} light />

      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Typography variant="overline" style={{ color: "#6F6767" }}>
        BudgetLines
      </Typography>
      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Expense Head</TableCell>
                <TableCell>Expense SubHead</TableCell>
                <TableCell>Budget Category</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>SubType</TableCell>

                <TableCell>SubTotal</TableCell>
                <TableCell>View Periods</TableCell>
                <TableCell>Supplementary Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.head && row.head.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.subHead && row.subHead.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.item && row.item.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                          label={row.status}
                          variant="outlined"
                          className={clsx(
                            row.status === "approved"
                              ? classes.statusOrange
                              : row.status === "completed" ||
                                row.status === "accepted"
                              ? classes.statusDarkGreen
                              : classes.chip,
                            classes.chip
                          )}
                        />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.subType && row.subType}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color:
                          row.subTotal <= 200000
                            ? theme.palette.warning.main
                            : theme.palette.success.main,
                      }}
                    >
                      {row.subTotal
                        ? formatCurrency(row.subTotal, "en-NG", "NGN")
                        : formatCurrency(0, "en-NG", "NGN")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() =>
                          handleOpenPeriods(row.period, row.subTotal)
                        }
                      >
                        <Icon className="fas fa-eye" />
                      </IconButton>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {(row.subType && (row.subType === "supplementary") && (row.status === "draft") && (isManager || isAdmin)) ? <>
                    
                      <IconButton
                        onClick={() =>
                          handleOpenSupplementaryApprove(row._id)
                        }
                        >
                        <Icon className="fas fa-thumbs-up" />
                      </IconButton>

                      <IconButton
                        onClick={() =>
                          handleOpenSupplementaryDecline(row._id)
                        }
                        style={{marginLeft:15}}
                        >
                        <Icon className="fas fa-thumbs-down" />
                      </IconButton>

                      </> : <Chip label={row.status && row.status} /> }
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Paper>
      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <TextField
            label="Total"
            variant="outlined"
            disabled
            className={classes.textField2}
            value={total}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">&#8358;</InputAdornment>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                </>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        spacing={1}
        style={{ marginBottom: 5 }}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title2}>
            Audit Trail
          </Typography>
        </Grid>
        <Grid item>
          <Icon className="fas fa-clipboard-list" color="disabled" />
        </Grid>
      </Grid>
      <Paper variant="outlined" square className={classes.paper}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          {(state.submittedBy) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Created By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.submittedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.submittedAt}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.reviewedBy || state.reviewedAt || state.reviewerComment) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Reviewed By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewerComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.rejectedBy || state.rejectedAt || state.rejectComment) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Rejected By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.approvedBy || state.approvedAt || state.approverComment) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Approved By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approvedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approvedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approverComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.declinedBy || state.declinedAt || state.declineComment) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Declined By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declinedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declinedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declineComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.acceptedBy || state.acceptedAt || state.acceptComment) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Accepted By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.acceptedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.acceptedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.acceptComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.completedBy || state.completedAt || state.CEOComment) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Completed By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.completedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.completedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.CEOComment}</span>
                </Paper>
              </Grid>
            </>
          )}


        </Grid>
      </Paper>

      {(doCheck(
        annualBudget && annualBudget.division && annualBudget.division.manager,
        profile && profile._id
      ) || isAdmin) &&
        state.status === "submitted" && (
          <>
            <Divider light className={classes.divider} />

            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={3}
              className={classes.grid}
            >
              <div className={classes.centered}>
                  <Paper className={classes.paperBlack}>
                      <HelpOutlineIcon fontSize="large" />
                      <Typography placeholder="Info" className={classes.info2}>
                          This annual budget actions are to be carried out by the Divisional Manager.
                      </Typography>
                  </Paper>
                  <ButtonGroup>
                    <Button
                      color="primary"
                      // style={{ marginRight: 3 }}
                      onClick={handleOpenConfirm}
                      className={classes.saveBtn}
                      variant="contained"
                      >
                      Review
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      // style={{ marginLeft: 3 }}
                      onClick={handleOpenDecline}
                      className={classes.saveBtn}
                      >
                      Decline
                    </Button>
                  </ButtonGroup>
                </div>
            </Grid>
          </>
        )}

      {(doCheck(
        annualBudget &&
        annualBudget.division &&
        annualBudget.division.head &&
        annualBudget.division.head._id,
        profile && profile._id
      ) || isAdmin) &&
        state.status === "reviewed" && (
          <>
            <Divider light className={classes.divider} />

            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={3}
              className={classes.grid}
            >
              <div className={classes.centered}>
                  <Paper className={classes.paperBlack}>
                      <HelpOutlineIcon fontSize="large" />
                      <Typography placeholder="Info" className={classes.info2}>
                          This annual budget actions are to be carried out by the Divisional Head.
                      </Typography>
                  </Paper>
                  <ButtonGroup>
                    <Button
                      color="primary"
                      // style={{ marginRight: 3 }}
                      onClick={handleOpenApprove}
                      className={classes.saveBtn}
                      variant="contained"
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      // style={{ marginLeft: 3 }}
                      onClick={handleOpenReject}
                      className={classes.saveBtn}
                      >
                      Reject
                    </Button>
                  </ButtonGroup>
                </div>
            </Grid>
          </>
        )}

      {(isManager || isAdmin) && state.status === "toclarify" ? (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <div className={classes.centered}>
                <Paper className={classes.paperBlack}>
                    <HelpOutlineIcon fontSize="large" />
                    <Typography placeholder="Info" className={classes.info2}>
                        This annual budget actions are to be carried out by the Head of the Division.
                    </Typography>
                </Paper>
              <Button
                onClick={handleOpenClarify}
                variant="contained"
                color="primary"
                className={classes.saveBtn}
              >
                Clarify
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

const doCheck = (check, validate) => {
  if (check !== undefined || validate !== undefined) {
    return check === validate;
  }
};

const mapStateToProps = (state) => ({
  annualBudget: state.annualBudget.annualBudget.data,
  profile: state.user.profile,
});

const mapDispatchToProps = {
  getAnnualBudgetById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAnnualBudget);
