export const CREATE_LEAD_REQUEST = "CREATE_LEAD_REQUEST";
export const CREATE_LEAD = "CREATE_LEAD";
export const CREATE_LEAD_FAIL = "CREATE_LEAD_FAIL";
export const GET_LEADS_REQUEST = "GET_LEADS_REQUEST";
export const GET_LEADS = "GET_LEADS";
export const GET_LEADS_FAIL = "GET_LEADS_FAIL";
export const GET_LEAD = "GET_LEAD";
export const GET_LEAD_REQUEST = "GET_LEAD_REQUEST";
export const GET_LEAD_FAIL = "GET_LEAD_FAIL";
export const UPDATE_LEAD_REQUEST = "UPDATE_LEAD_REQUEST";
export const UPDATE_LEAD = "UPDATE_LEAD";
export const UPDATE_LEAD_STAGE_REQUEST = "UPDATE_LEAD_STAGE_REQUEST";
export const UPDATE_LEAD_STAGE_FAIL = "UPDATE_LEAD_STAGE_FAIL";
export const UPDATE_LEAD_STAGE = "UPDATE_LEAD_STAGE";
export const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL";
export const DELETE_LEAD_REQUEST = "DELETE_LEAD_REQUEST";
export const DELETE_LEAD = "DELETE_LEAD";
export const DELETE_LEAD_FAIL = "DELETE_LEAD_FAIL";
export const RESET_LEADS = "RESET_LEADS";
