export const CREATE_SALES_CALL_REQUEST = "CREATE_SALES_CALL_REQUEST";
export const CREATE_SALES_CALL = "CREATE_SALES_CALL";
export const CREATE_SALES_CALL_FAIL = "CREATE_SALES_CALL_FAIL";
export const GET_ALL_SALES_CALL_REQUEST = "GET_ALL_SALES_CALL_REQUEST";
export const GET_ALL_SALES_CALL = "GET_ALL_SALES_CALL";
export const GET_ALL_SALES_CALL_FAIL = "GET_ALL_SALES_CALL_FAIL";
export const GET_SALES_CALL_BY_LEAD_REQUEST = "GET_SALES_CALL_BY_LEAD_REQUEST";
export const GET_SALES_CALL_BY_LEAD = "GET_SALES_CALL_BY_LEAD";
export const GET_SALES_CALL_BY_LEAD_FAIL = "GET_SALES_CALL_BY_LEAD_FAIL";
export const MARK_SALES_CALL_AS_DONE_REQUEST =
  "MARK_SALES_CALL_AS_DONE_REQUEST";
export const MARK_SALES_CALL_AS_DONE = "MARK_SALES_CALL_AS_DONE";
export const MARK_SALES_CALL_AS_DONE_FAIL = "MARK_SALES_CALL_AS_DONE_FAIL";
export const GET_SALES_CALL_BY_ID_REQUEST = "GET_SALES_CALL_BY_ID_REQUEST";
export const GET_SALES_CALL_BY_ID = "GET_SALES_CALL_BY_ID";
export const GET_SALES_CALL_BY_ID_FAIL = "GET_SALES_CALL_BY_ID_FAIL";
export const UPLOAD_SALES_CALL_DOCUMENT_REQUEST =
  "UPLOAD_SALES_CALL_DOCUMENT_REQUEST";
export const UPLOAD_SALES_CALL_DOCUMENT = "UPLOAD_SALES_CALL_DOCUMENT";
export const UPLOAD_SALES_CALL_DOCUMENT_FAIL =
  "UPLOAD_SALES_CALL_DOCUMENT_FAIL";
export const UPDATE_SALES_CALL_REQUEST = "UPDATE_SALES_CALL_REQUEST";
export const UPDATE_SALES_CALL = "UPDATE_SALES_CALL";
export const UPDATE_SALES_CALL_FAIL = "UPDATE_SALES_CALL_FAIL";
export const DELETE_SALES_CALL_REQUEST = "DELETE_SALES_CALL_REQUEST";
export const DELETE_SALES_CALL = "DELETE_SALES_CALL";
export const DELETE_SALES_CALL_FAIL = "DELETE_SALES_CALL_FAIL";
export const RESET_SALES_CALL = "RESET_SALES_CALL";
