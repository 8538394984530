import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import { getContactPersonByCustomer } from "../../../../actions/contactPersonActions";
import { getAllPersonnelPriceList } from "../../../../actions/masterListActions/personnelActions";
import { getAllEquipmentPriceList } from "../../../../actions/masterListActions/equipmentAction";
import { getAllMaterialPriceList } from "../../../../actions/masterListActions/materialActions";
import { getAllMobilizationPriceList } from "../../../../actions/masterListActions/mobilizationActions";
import { getQuotation } from "../../../../actions/quotationActions";

// Commercial Head
import ReviewQuotationModal from "../modals/QuotationModals/ReviewQuotationModal";
import DeclineQuotationModal from "../modals/QuotationModals/DeclineQuotationModal";
// CEO
import ApproveQuotationModal from "../modals/QuotationModals/ApproveQuotationModal";
import RejectQuotationModal from "../modals/QuotationModals/RejectQuotationModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
}));


const INIT_STATE = {
    title: "",
    employee: "",
    division: "",
    jobTitle: "",
    employeeId: "",
    customer: null,
    contactPerson: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonJob: "",
    contactPersonPhone: "",
    personnel: [],
    equipment: [],
    material: [],
    consumable: [],
    mob_demob: [],
    others: [],
    currency: "",
    comment: "",
    noteToPricing: "",
    sendComment: ""
};

const ViewQuotation = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [openReview, setOpenReview] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);

    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { contactPersons } = useSelector((state) => state.contactPerson);
    const { profile } = useSelector((state) => state.user);
    const { personnels } = useSelector((state) => state.personnel);
    const { equipments } = useSelector((state) => state.equipment);
    const { materials } = useSelector((state) => state.material);
    const { error, loading, quotation } = useSelector((state) => state.quotation);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllEquipmentPriceList());
        dispatch(getAllPersonnelPriceList());
        dispatch(getAllMaterialPriceList());
        dispatch(getAllMobilizationPriceList());
        dispatch(getQuotation(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (state.customer !== null) {
            dispatch(getContactPersonByCustomer(state.customer._id || ""));
        }
    }, [dispatch, state.customer]);

    useEffect(() => {
        if (state.contactPerson) {
            contactPersons &&
                contactPersons.map(
                    (item) =>
                        item.id === state.contactPerson.id &&
                        setState((prev) => ({
                            ...prev,
                            contactPersonName: item && item.name,
                            contactPersonEmail: item && item.email,
                            contactPersonJob: item && item.designation,
                            contactPersonPhone: item && item.phone,
                        }))
                );
        }
    }, [state.contactPerson, contactPersons]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            title: (quotation && quotation.title) || "",
            employee: (quotation && quotation.employee) || "",
            division: (quotation && quotation.division && quotation.division.name) || "",
            jobTitle: (quotation && quotation.jobTitle) || "",
            employeeId: (quotation && quotation.employeeId) || "",
            customer: (quotation && quotation.customer && quotation.customer.name) || "",
            contactPerson: (quotation && quotation.contactPerson) || "",
            contactPersonName: (quotation && quotation.contactPerson.name) || "",
            contactPersonEmail: (quotation && quotation.contactPersonEmail) || "",
            contactPersonJob: (quotation && quotation.contactPersonJob) || "",
            contactPersonPhone: (quotation && quotation.contactPersonPhone) || "",

            currency: (quotation && quotation.currency) || "",
            comment: (quotation && quotation.comment) || "",
            noteToPricing: (quotation && quotation.noteToPricing) || "",

            personnel: (quotation && quotation.personnel) || [],
            equipment: (quotation && quotation.equipment) || [],
            material: (quotation && quotation.material) || [],
            consumable: (quotation && quotation.consumable) || [],
            mob_demob: (quotation && quotation.mob_demob) || [],
            others: (quotation && quotation.others) || [],

            dateCreated: (quotation && moment(quotation.quotationDate).format("l")) || "",

            status: (quotation && quotation.status) || "",
            approvedBy: (quotation && quotation.approvedBy && quotation.approvedBy.userName) || "",
            approvedAt: (quotation && quotation.approvedAt) || "",
            approverComment: (quotation && quotation.approverComment) || "",
            reviewedBy: (quotation && quotation.reviewedBy && quotation.reviewedBy.userName) || "",
            reviewedAt: (quotation && quotation.reviewedAt) || "",
            reviewerComment: (quotation && quotation.reviewerComment) || "",
            sentBy: (quotation && quotation.sentBy && quotation.sentBy.userName) || "",
            sentAt: (quotation && quotation.sentAt) || "",
            modifiedBy: (quotation && quotation.modifiedBy && quotation.modifiedBy.userName) || "",
            modifiedAt: (quotation && quotation.modifiedAt) || "",
            submittedBy:
                (quotation && quotation.submittedBy && quotation.submittedBy.userName) || "",
            submittedAt: (quotation && quotation.submittedAt) || "",
            documentNo: (quotation && quotation.documentNo) || "",
            sendComment: (quotation && quotation.sendComment) || "",
        }));
    }, [quotation]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    // Commercial Head
    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };

    // CEO
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    const isCEO = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "ceo" && grp.module === "sales"
        )
        : false;

    const isCommHead = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "reviewer" && grp.module === "sales"
        )
        : false;

    const isComm = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "user" && grp.module === "sales"
        )
        : false;

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            {/* Commercial head */}
            <ReviewQuotationModal {...{ openReview, handleCloseReview, id }} />
            <DeclineQuotationModal {...{ openDecline, handleCloseDecline, id }} />

            {/* CEO */}
            <ApproveQuotationModal {...{ openApprove, handleCloseApprove, id }} />
            <RejectQuotationModal {...{ openReject, handleCloseReject, id }} />

            <Typography variant="overline" className={classes.title}>
                View Quotation
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/sales/quotation")}
                    >
                        Back
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        style={{ marginLeft: 10 }}
                        className={classes.backBtn}
                        onClick={() => history.push(`/sales/quotation/${id}`)}
                    >
                        Preview
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : state.status === "reviewed"
                                        ? classes.statusGreen
                                        : (state.status === "approved" || state.status === "sent" || state.status === "read")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Typography variant="overline" className={classes.title2}>
                Basic Information
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item xs={12} md={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Prepared By"
                            name="employee"
                            value={state.employee || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Division/Subdivision"
                            name="division"
                            value={state.division || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Currency"
                            name="currency"
                            value={state.currency || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Date Created"
                            name="dateCreated"
                            value={state.dateCreated || ""}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="overline" className={classes.title2}>
                Quotation &amp; Customer Information
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={1}
                    className={classes.grid}
                >
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Quotation Title"
                            name="title"
                            value={state.title || ""}
                            disabled={state.status !== "draft"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Customer Name"
                            name="customer"
                            value={state.customer || ""}
                            disabled={state.status !== "draft"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            variant="outlined"
                            fullWidth
                            required
                            type="text"
                            label="Contact Person"
                            name="contactPersonName"
                            value={state.contactPersonName || ""}
                            disabled={state.status !== "draft"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            variant="outlined"
                            fullWidth
                            required
                            type="email"
                            label="Contact Person Email"
                            name="contactPersonEmail"
                            value={state.contactPersonEmail || ""}
                            disabled={state.status !== "draft"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Job Title"
                            name="contactPersonJob"
                            value={state.contactPersonJob || ""}
                            disabled={state.status !== "draft"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Phone Number"
                            type="tel"
                            name="contactPersonPhone"
                            value={state.contactPersonPhone || ""}
                            disabled={state.status !== "draft"}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {(isCommHead || isCEO || isComm) ? (
                <>
                    {state.personnel && state.personnel.length > 0 ? (
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Personnel Charges
                            </Typography>
                            <Paper variant="outlined" square className={classes.paper}>
                                {state.personnel &&
                                    state.personnel.map((item, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="description">
                                                        Personnel Description
                                                    </InputLabel>
                                                    <Select
                                                        labelId="description"
                                                        label="Personnel Description"
                                                        name="description"
                                                        value={item.description || ""}
                                                        disabled={state.status !== "draft"}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        {personnels &&
                                                            personnels.map((item) => (
                                                                <MenuItem key={item._id} value={item.description}>
                                                                    {item.description}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity"
                                                    type="number"
                                                    name="quantity"
                                                    value={item.quantity}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Currency"
                                                    type="text"
                                                    name="currency"
                                                    value={item.currency || "NGN"}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Duration"
                                                    name="duration"
                                                    value={item.duration || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Daily Rate"
                                                    name="unitRate"
                                                    value={item.unitRate || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Total Rate"
                                                    name="totalRate"
                                                    value={item.totalRate || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Paper>
                        </>
                    ) : null}

                    {state.equipment && state.equipment.length > 0 ? (
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Equipment Charges
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.equipment &&
                                    state.equipment.map((item, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="description">
                                                        Equipment Description
                                                    </InputLabel>
                                                    <Select
                                                        labelId="description"
                                                        label="Equipment Description"
                                                        name="description"
                                                        value={item.description || ""}
                                                        disabled={state.status !== "draft"}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        {equipments &&
                                                            equipments.map((item) => (
                                                                <MenuItem key={item._id} value={item.description}>
                                                                    {item.description}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity"
                                                    type="number"
                                                    name="quantity"
                                                    value={item.quantity}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Currency"
                                                    type="text"
                                                    name="currency"
                                                    value={item.currency || "NGN"}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Duration"
                                                    name="duration"
                                                    value={item.duration || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Daily Rate"
                                                    name="unitRate"
                                                    value={item.unitRate || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Total Rate"
                                                    name="totalRate"
                                                    value={item.totalRate || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Paper>
                        </>) : null}

                    {state.material && state.material.length > 0 ? (
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Material Charges
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.material &&
                                    state.material.map((item, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="description">
                                                        Material Description
                                                    </InputLabel>
                                                    <Select
                                                        labelId="description"
                                                        name="description"
                                                        value={item.description || ""}
                                                        disabled={state.status !== "draft"}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        {materials &&
                                                            materials.map((item) => (
                                                                <MenuItem key={item._id} value={item.description}>
                                                                    {item.description}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity"
                                                    type="number"
                                                    name="quantity"
                                                    value={item.quantity}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Currency"
                                                    type="text"
                                                    name="currency"
                                                    value={item.currency || "NGN"}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Duration"
                                                    name="duration"
                                                    value={item.duration || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Daily Rate"
                                                    name="unitRate"
                                                    value={item.unitRate || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Total Rate"
                                                    name="totalRate"
                                                    value={item.totalRate || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Paper>
                        </>) : null}

                    {state.consumable && state.consumable.length > 0 ? (
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Consumable Charges
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.consumable &&
                                    state.consumable.map((item, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Description"
                                                    type="text"
                                                    name="description"
                                                    value={item.description}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity"
                                                    type="number"
                                                    name="quantity"
                                                    value={item.quantity}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Currency"
                                                    type="text"
                                                    name="currency"
                                                    value={item.currency || "NGN"}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Unit/Daily Rate"
                                                    name="unitRate"
                                                    value={item.unitRate || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Total Rate"
                                                    name="totalRate"
                                                    value={item.totalRate || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Paper> </>) : null}

                    {state.mob_demob && state.mob_demob.length > 0 ? (
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Mob / Demob Charges
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.mob_demob &&
                                    state.mob_demob.map((item, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description"
                                                    type="text"
                                                    name="description"
                                                    value={item.description || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity"
                                                    type="number"
                                                    name="quantity"
                                                    value={item.quantity}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Currency"
                                                    type="text"
                                                    name="currency"
                                                    value={item.currency || "NGN"}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Kind"
                                                    name="kind"
                                                    value={item.kind || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Total Amount"
                                                    name="totalAmount"
                                                    value={item.totalAmount || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Paper>
                        </>) : null}

                    {state.others && state.others.length > 0 ? (
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Other Charges
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.others &&
                                    state.others.map((item, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Description"
                                                    type="text"
                                                    name="description"
                                                    value={item.description}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity"
                                                    type="number"
                                                    name="quantity"
                                                    value={item.quantity}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Currency"
                                                    type="text"
                                                    name="currency"
                                                    value={item.currency || "NGN"}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Kind"
                                                    name="kind"
                                                    value={item.kind || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Total Amount"
                                                    name="totalAmount"
                                                    value={item.totalAmount || ""}
                                                    disabled={state.status !== "draft"}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Paper>
                        </>
                    ) : null}
                </>
            ) : null}

            <Typography variant="overline" className={classes.title2}>
                Note To Pricing
            </Typography>
            <Paper variant="outlined" square className={classes.paper}>
                <Grid item xs={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        rows={3}
                        label="Note to Pricing"
                        type="text"
                        name="noteToPricing"
                        value={state.noteToPricing || ""}
                        disabled={state.status !== "draft"}
                    />
                </Grid>
            </Paper>

            <Typography variant="overline" className={classes.title2}>
                Cover Letter to Customer
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid item xs={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Cover Letter to Customer"
                        multiline
                        rows={3}
                        type="text"
                        name="sendComment"
                        value={state.sendComment || ""}
                        disabled={state.status !== "draft"}
                    />
                </Grid>
            </Paper>

            <Typography variant="overline" className={classes.title2}>
                Comment
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid item xs={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Comment"
                        multiline
                        rows={3}
                        type="text"
                        name="comment"
                        value={state.comment || ""}
                        disabled={state.status !== "draft"}
                    />
                </Grid>
            </Paper>

            {state.status !== "draft" ? (
                <>
                    <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        spacing={1}
                        style={{ marginBottom: 5 }}
                    >
                        <Grid item>
                            <Typography variant="overline" className={classes.title2}>
                                Audit Trail
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Icon className="fas fa-clipboard-list" color="disabled" />
                        </Grid>
                    </Grid>
                    <Paper variant="outlined" square className={classes.paper}>
                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Submitted By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.submittedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.submittedAt}</span>
                                </Paper>
                            </Grid>

                            {state.reviewedBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Reviewed By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.reviewedBy || ""}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.reviewedAt}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.auditTitle}>Comment</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.reviewerComment}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}
                            {state.approvedBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Approved By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.approvedBy || ""}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.approvedAt}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.auditTitle}>Comment</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.approverComment}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}
                            {state.modifiedBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Modified By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.modifiedBy}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.modifiedAt}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}

                            {state.sentBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Sent By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.sentBy}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.sentAt}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}

                        </Grid>
                    </Paper>
                </>
            ) : null}

            {isCommHead && state.status === "submitted" ? (
                <Grid container justify="center">
                    <Grid item>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenReview}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Review
                            </Button>
                            <Button
                                onClick={handleOpenDecline}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Decline
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : (isCEO && state.status === "reviewed" ? (
                <Grid container justify="center">
                    <Grid item>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenApprove}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Approve
                            </Button>
                            <Button
                                onClick={handleOpenReject}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Reject
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : (isCommHead && state.status === "approved" ? (
                <Grid container justify="center">
                    <Grid item></Grid>
                </Grid>
            ) : null))
            }

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewQuotation;
