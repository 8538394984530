import * as types from "../../types/inventory";

const initState = {
    grns: [],
    grn: {},
    error: null,
    success: false,
    loading: false,
};

const grnReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_GRN_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_GRN:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_GRN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_GRN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_GRN:
            return {
                ...state,
                loading: false,
                grn: action.payload.data,
            };
        case types.SUBMIT_GRN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            
        case types.GET_ALL_GRNS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_GRNS:
            return {
                ...state,
                loading: false,
                success: true,
                grns: action.payload.data,
            };
        case types.GET_ALL_GRNS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_GRN_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_GRN:
            return {
                ...state,
                loading: false,
                success: true,
                grn: action.payload.data,
            };
        case types.GET_GRN_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_GRN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_GRN:
            return {
                ...state,
                loading: false,
                grns: state.grns.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_GRN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DELETE_GRN_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_GRN:
            return {
                ...state,
                loading: false,
                success: false,
                grns: state.grns.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_GRN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case types.ACCEPT_GRN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_GRN:
            return {
                ...state,
                loading: false,
                grn: action.payload.data,
            };
        case types.ACCEPT_GRN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_GRN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_GRN:
            return {
                ...state,
                loading: false,
                grn: action.payload.data,
            };
        case types.REJECT_GRN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case types.APPROVE_GRN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_GRN:
            return {
                ...state,
                loading: false,
                grn: action.payload.data,
            };
        case types.APPROVE_GRN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_GRN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_GRN:
            return {
                ...state,
                loading: false,
                grn: action.payload.data,
            };
        case types.DECLINE_GRN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case types.RESET_GRN:
            return {
                ...state,
                error: null,
                success: false,
                loading: false,
            };

        default:
            return state;
    }
};

export default grnReducer;
