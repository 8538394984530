import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    DialogTitle,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";
import { getLocation, updateLocation, getLocations, } from "../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    centered: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
    },
}));

const INIT_STATE = {
    name: "",
    store: "",
    locationType: "",
};

const EditLocationModal = ({ openEdit, handleCloseEdit, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = React.useState(INIT_STATE);
    const { loading, error, location } = useSelector((state) => state.location);
    const { allstores } = useSelector((state) => state.stores);

    React.useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    React.useEffect(() => {
        setState((prev) => ({
            ...prev,
            name: (location && location.name) || "",
            locationType: (location && location.locationType) || "",
            store: (location && location.store?._id) || "",
        }));
    }, [location]);

    React.useEffect(() => {
        dispatch(getLocation(id));
    }, [dispatch, id]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            id,
        };
        await dispatch(updateLocation(data));
        setTimeout(() => {
            handleCloseEdit();
            dispatch(getLocations());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Store Location updated Successfully.",
                success: true,
            }));
        }, 2000);
    };

    return (
        <div>
            {error && error.error ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message || (error && error.error)}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message || `Store Location Updated successfully.`}
                />
            )}

            <Dialog
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Update Store Location
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseEdit}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleEditSubmit}>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Location Name"
                                    name="name"
                                    value={state.name || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Location Type"
                                    name="locationType"
                                    value={state.locationType || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel id="store">Store</InputLabel>
                                    <Select
                                        id="store"
                                        labelId="store"
                                        name="store"
                                        inputProps={{
                                            id: "store",
                                            name: "store",
                                        }}
                                        value={state.store || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Select Store" disabled>
                                            <em>Select Store</em>
                                        </MenuItem>
                                        {allstores &&
                                            allstores.map((store) => (
                                                <MenuItem key={store._id} value={store._id}>
                                                    {store.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    disabled={loading}
                                    className={classes.saveBtn}
                                >
                                    {loading ? "Loading..." : "Update"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EditLocationModal;
