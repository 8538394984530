import * as salesCallType from "../types/salesCallType";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/crm/salescall`;

export const createSalesCall = (body) => async (dispatch) => {
  try {
    dispatch({
      type: salesCallType.CREATE_SALES_CALL_REQUEST,
    });
    const { data } = await axios.post(`${URL}/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: salesCallType.CREATE_SALES_CALL,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: salesCallType.CREATE_SALES_CALL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateSalesCall = (body) => async (dispatch) => {
  try {
    dispatch({
      type: salesCallType.UPDATE_SALES_CALL_REQUEST,
    });
    const { data } = await axios.put(`${URL}/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: salesCallType.UPDATE_SALES_CALL,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: salesCallType.UPDATE_SALES_CALL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteSalesCall = (id) => async (dispatch) => {
  try {
    dispatch({
      type: salesCallType.DELETE_SALES_CALL_REQUEST,
    });
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: salesCallType.DELETE_SALES_CALL,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: salesCallType.DELETE_SALES_CALL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const uploadSalesCallDocument = (doc) => async (dispatch) => {
  try {
    dispatch({
      type: salesCallType.UPLOAD_SALES_CALL_DOCUMENT_REQUEST,
    });
    const { data } = await axios.post(`${URL}/document/upload`, doc, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: salesCallType.UPLOAD_SALES_CALL_DOCUMENT,
      payload: data.doc,
    });
    return data.doc;
  } catch (error) {
    dispatch({
      type: salesCallType.UPLOAD_SALES_CALL_DOCUMENT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getSalesCallByLead = (leadId) => async (dispatch) => {
  try {
    dispatch({
      type: salesCallType.GET_SALES_CALL_BY_LEAD_REQUEST,
    });
    const { data } = await axios.get(`${URL}/lead/${leadId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: salesCallType.GET_SALES_CALL_BY_LEAD,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: salesCallType.GET_SALES_CALL_BY_LEAD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getAllSalesCalls = () => async (dispatch) => {
  try {
    dispatch({
      type: salesCallType.GET_ALL_SALES_CALL_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: salesCallType.GET_ALL_SALES_CALL,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: salesCallType.GET_ALL_SALES_CALL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getSalesCallById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: salesCallType.GET_SALES_CALL_BY_ID_REQUEST,
    });
    const { data } = await axios.get(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: salesCallType.GET_SALES_CALL_BY_ID,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: salesCallType.GET_SALES_CALL_BY_ID_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const markSalesCallAsDone = (id) => async (dispatch) => {
  try {
    dispatch({
      type: salesCallType.MARK_SALES_CALL_AS_DONE_REQUEST,
    });
    const { data } = await axios.put(
      `${URL}/done/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: salesCallType.MARK_SALES_CALL_AS_DONE,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: salesCallType.MARK_SALES_CALL_AS_DONE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
