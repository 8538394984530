import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Paper,
    Grid,
    Button,
    Backdrop,
    Chip,
    Icon,
    IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import clsx from "clsx";
import SearchComponent from "../../SearchComponent";
import { TablePaginationActions } from "../../TablePaginationActions";
import CreateVisitorRequestModal from "./modals/CreateVisitorRequestModal";
import DeleteVisitorRequestModal from "./modals/DeleteVisitorRequestModal";
import { getMyVisitorAppointments } from "../../../actions/visitorsActions";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../atoms/Feedback";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        marginTop: 30,
        marginBottom: 30,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    chip: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    marginBottom: {
        marginBottom: 30,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    createBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: "left"
    },
}));

const VisitorRequests = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [getId, setGetId] = useState("");

    const [openCreate, setOpenCreate] = useState(false);
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { myvisitors, loading, error } = useSelector((state) => state.visitors);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getMyVisitorAppointments());
    }, [dispatch]);

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };

    const handleDeleteOpen = (id) => {
        setGetId(id);
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };

    let filteredRows = () =>
        myvisitors === undefined
            ? []
            : myvisitors &&
            myvisitors.filter((row) => {
                if (search !== "") {
                    return (
                        (row.employee &&
                            row.employee.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                        (row.visitorName &&
                            row.visitorName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                        (row.visitDate &&
                            row.visitDate.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                    );
                } else {
                    return row;
                }
            });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenCreate = () => {
        setOpenCreate(true);
    };
    const handleCloseCreate = () => {
        setOpenCreate(false);
    };
    const navigateById = (path, id) => {
        history.push(`${path}/${id}`);
    };
    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            (Array(1000).length || filteredRows().length) - page * rowsPerPage
        );

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <CreateVisitorRequestModal {...{ openCreate, handleCloseCreate }} />
                <DeleteVisitorRequestModal {...{ openDelete, handleCloseDelete, id: getId }} />

                <Typography variant="overline" gutterBottom className={classes.title}>
                    Visitor Appointments
                </Typography>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={3}
                    className={classes.marginBottom}
                >
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.createBtn}
                            onClick={handleOpenCreate}
                            style={{ alignContent: "left" }}
                        >
                            Create
                        </Button>
                    </Grid>
                    <Grid item>
                        <SearchComponent
                            updateSearch={updateSearch}
                            placeholder="Search..."
                            search={search}
                            ResetSearch={ResetSearch}
                        />
                    </Grid>
                </Grid>

                <Paper>
                    <TableContainer>
                        <Table className={classes.table} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Employee</TableCell>
                                    <TableCell>Visitor Name</TableCell>
                                    <TableCell>Visitor Email</TableCell>
                                    <TableCell>Visitor Phone</TableCell>
                                    <TableCell>Purpose</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>View</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredRows().slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    : filteredRows()
                                ).map((row, i) => {
                                    return (
                                        <TableRow hover key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {row.employee && row.employee}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitorName && row.visitorName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitorEmail && row.visitorEmail}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitorPhone && row.visitorPhone}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitPurpose && row.visitPurpose}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitDate && row.visitDate}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitTime && row.visitTime}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Chip
                                                    style={{ padding: 10 }}
                                                    label={row.status}
                                                    variant="outlined"
                                                    className={clsx(
                                                        row.status === "draft"
                                                            ? classes.statusOrange
                                                            : row.status === "submitted"
                                                                ? classes.statusGreen
                                                                : row.status === "approved" || row.status === "checked_in" || row.status === "checked_out"
                                                                    ? classes.statusDarkGreen
                                                                    : classes.statusRed,
                                                        classes.chip
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <IconButton
                                                    onClick={() =>
                                                        navigateById("/visitor/view", row._id)
                                                    }
                                                >
                                                    <Icon className="fas fa-eye" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <IconButton
                                                    variant="extended"
                                                    color="secondary"
                                                    disabled={row.status !== "draft"}
                                                    onClick={() => handleDeleteOpen(row._id)}
                                                >
                                                    <Icon className="fas fa-trash" color="secondary" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                {filteredRows().length === 0 && (
                                    <TableRow hover>
                                        <TableCell
                                            colSpan={10}
                                            align="center"
                                            style={{ color: "#616161" }}
                                            component="th"
                                            scope="row"
                                        >
                                            No Records To Display
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                        colSpan={3}
                        count={filteredRows().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </MuiPickersUtilsProvider >
    );
};

export default VisitorRequests;