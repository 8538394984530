import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TablePaginationActions } from "../../TablePaginationActions";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function PurchaseTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filteredRows = () =>
    [].filter((row) => {
      if (filter !== "") {
        return row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      } else {
        return row;
      }
    });

  const handleSelectFilter = (e, val) => {
    setFilter(val.substr(0, 20));
    handleCloseFilter();
  };

  const dataLength = Array(1000).length || filteredRows().length;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

  return (
    <Paper>
      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <IconButton
            style={{ marginRight: 10, marginTop: 10 }}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseFilter}
            TransitionComponent={Fade}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={(e) => handleSelectFilter(e, "")}>All</MenuItem>
            <MenuItem onClick={(e) => handleSelectFilter(e, "Pending")}>
              Pending
            </MenuItem>
            <MenuItem onClick={(e) => handleSelectFilter(e, "Approved")}>
              Approved
            </MenuItem>
            <MenuItem onClick={(e) => handleSelectFilter(e, "Declined")}>
              Declined
            </MenuItem>
            <MenuItem onClick={(e) => handleSelectFilter(e, "Draft")}>
              Draft
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <TableContainer>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Document No.</TableCell>
              <TableCell>Requestor</TableCell>
              <TableCell>Div./SubDiv.</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={100} />
              </TableRow>
            )}
            {filteredRows().length === 0 && (
              <TableRow hover>
                <TableCell
                  colSpan={10}
                  align="center"
                  style={{ color: "#616161" }}
                  component="th"
                  scope="row"
                >
                  No Records To Display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
        colSpan={3}
        count={filteredRows().length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
}
