import {
  CREATE_JOB_TITLE,
  GET_JOB_TITLES,
  DELETE_JOB_TITLE,
  GET_JOB_TITLE_BY_ID,
  UPDATE_JOB_TITLE,
  JOB_TITLE_ERROR,
} from "../types/";

import axios from "axios";

const URL = `/api/v1/admin/title`;
const token = localStorage.getItem("token:key");

export const createTitle = ({ name, division }) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token:key");
    const res = await axios.post(
      URL,
      {
        name,
        division,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: CREATE_JOB_TITLE,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return error.response.data || error.message;
  }
};

export const getJobTitles = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token:key");
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_JOB_TITLES,
      payload: res.data,
      error: null,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getJobTitleById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/title/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_JOB_TITLE_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateJobTitle = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/title/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_JOB_TITLE,
      payload: res.data.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: JOB_TITLE_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteJobTitle = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token:key");
    await axios.delete(`/api/v1/admin/title/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: DELETE_JOB_TITLE,
    });
  } catch (error) {
    return error.response.data || error.message;
  }
};
