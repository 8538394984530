import {
  VENDOR_REGISTER,
  UPDATE_VENDOR_INFO,
  ERROR,
  GET_VENDOR_INFO_BY_TOKEN,
  GET_VENDOR_PROFILE,
  UPLOAD_VENDOR_PHOTO,
  UPDATE_VENDOR_PROFILE,
  UPDATE_VENDOR_PASSWORD,
  UPLOAD_VENDOR_DOCUMENT,
  GET_ALL_VENDORS_REQUEST,
  GET_ALL_VENDORS_FAIL,
  GET_ALL_VENDORS,
  GET_VENDOR_BY_ID,
  APPROVE_VENDOR,
  REJECT_VENDOR,
  BLACKLIST_VENDOR,
  CREATE_VENDOR,
  VENDOR_ERROR,
  CREATE_PRODUCT,
  GET_ALL_PRODUCTS,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT_BY_ID,
  DELETE_PRODUCT_BY_ID,
  DELETE_VENDOR_BY_ID,
  GET_PRODUCTS_CATEGORY,
  GET_SERVICES_CATEGORY,
  REACTIVATE_VENDOR,
} from "../types";

import axios from "axios";

const token = localStorage.getItem("token:key");

export const registerVendor = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/vendor/signup`;
    const res = await axios.post(URL, data);
    dispatch({
      type: VENDOR_REGISTER,
      payload: res.data.message,
    });

    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
      return (await error.response.data) || (await error.message);
    }
  }
};

export const getVendorInfoByToken = (token) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/vendor/token/${token}`;
    const res = await axios.get(URL);
    dispatch({
      type: GET_VENDOR_INFO_BY_TOKEN,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const resendVerificationEmail = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/completeReg/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_VENDOR_INFO,
      payload: res.data.message,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const updateVendorInfo = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/vendor/completeReg/${data.token}`;
    const res = await axios.patch(URL, data);
    dispatch({
      type: UPDATE_VENDOR_INFO,
      payload: res.data.message,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};


export const updateVendorData = (body) => async (dispatch) => {
  try {
      const URL = `/api/v1/auth/vendor/update`;
      const res = await axios.patch(`${URL}/${body.id}`, body);
      // console.log(res)
      dispatch({
          type: UPDATE_VENDOR_INFO,
          payload: res.data,
      });
      return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};


export const uploadVendorDocument = (body) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/vendor`;
    const { data } = await axios.post(`${URL}/upload`, body);
    dispatch({
        type: UPLOAD_VENDOR_DOCUMENT,
        payload: data,
    });
    return data.url;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const getVendorProfile = () => async (dispatch) => {
  try {
    if (token !== undefined) {
      const res = await axios.get("/api/v1/auth/vendor/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: GET_VENDOR_PROFILE,
        payload: res.data,
      });
      return await res.data;
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        error: error.message,
      });
    }
  }
};

export const uploadVendorPhoto = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/vendor/profilePic`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPLOAD_VENDOR_PHOTO,
      payload: res.data,
    });
    // return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const updateVendorProfile = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/vendor/updateProfile`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_VENDOR_PROFILE,
      payload: res.data.message,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const updateVendorPassword = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/vendor/updatePassword`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_VENDOR_PASSWORD,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const reactivateVendor = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/reactivate/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REACTIVATE_VENDOR,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const getAllVendors = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_VENDORS_REQUEST,
    });
    const URL = `/api/v1/quality/vendor`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_VENDORS,
      payload: res.data,
    });

    return await res.data;
  } catch (error) {
    console.log(error.response)
    
    dispatch({
      type: GET_ALL_VENDORS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};


export const getApprovedVendors = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_VENDORS_REQUEST,
    });
    const URL = `/api/v1/quality/aproved/vendor`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_VENDORS,
      payload: res.data,
    });

    return await res.data;
  } catch (error) {
    dispatch({
      type: GET_ALL_VENDORS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getVendorById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_VENDOR_BY_ID,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const updateVendor = (body) => async (dispatch) => {
  try {
      const URL = `/api/v1/quality/vendor`;
      const res = await axios.patch(`${URL}/${body.id}`, body, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      // console.log(res)
      dispatch({
          type: UPDATE_VENDOR_PROFILE,
          payload: res.data,
      });
      return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};


export const uploadDocument = (body) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor`;
    const { data } = await axios.post(`${URL}/upload`, body, {
          headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
          type: UPLOAD_VENDOR_DOCUMENT,
          payload: data,
      });
      return data.url;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const deleteVendorById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/delete/${id}`;
    const res = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_VENDOR_BY_ID,
      payload: res.data.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const approveVendor = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/approve/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_VENDOR,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const rejectVendor = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/decline/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REJECT_VENDOR,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const blacklistVendor = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/blacklist/${id}`;
    const res = await axios.put(
      URL,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: BLACKLIST_VENDOR,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const setupVendor = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/setup`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_VENDOR,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const createVendor = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/vendor/register`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_VENDOR,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
  }
};

export const createProduct = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/product`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_PRODUCT,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const getProductCategories = () => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/category/product`;
    const res = await axios.get(URL);
    dispatch({
      type: GET_PRODUCTS_CATEGORY,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const getServiceCategories = () => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/category/service`;
    const res = await axios.get(URL);
    dispatch({
      type: GET_SERVICES_CATEGORY,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const getAllProducts = () => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/product`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const getProductById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/product/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_PRODUCT_BY_ID,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const updateProductById = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/product/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_PRODUCT_BY_ID,
      payload: res.data.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};

export const deleteProductById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/quality/product/${id}`;
    const res = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_PRODUCT_BY_ID,
      payload: res.data.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.request.data,
      });
    } else {
      dispatch({
        type: VENDOR_ERROR,
        payload: error.message,
      });
    }
  }
};
