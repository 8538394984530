import {
  GET_ALL_MONTHLY_BUDGETS,
  CREATE_MONTHLY_BUDGET,
  CREATE_MONTHLY_BUDGET_LINE,
  MONTHLY_BUDGETS_ERROR,
  MONTHLY_BUDGET_LINE_ERROR,
  GET_MONTHLY_BUDGETS,
  GET_ALL_MONTHLY_BUDGET_LINES,
  GET_ALL_MONTHLY_BUDGET_LINE_BY_BUDGET_ID,
  UPDATE_MONTHLY_BUDGET_LINE,
  DELETE_MONTHLY_BUDGET_LINE,
  GET_MONTHLY_BUDGET_BY_ID,
  GET_MONTHLY_BUDGET_ITEMS_BY_SUBHEAD,
  GET_MONTHLY_BUDGET_ITEMS_BY_DIVISION,
  GET_ONE_MONTHLY_BUDGET_ITEM,
  GET_MONTHLY_BUDGET_LINE_BY_ID,
  SUBMIT_MONTHLY_BUDGET,
  CONFIRM_MONTHLY_BUDGET,
  DECLINE_MONTHLY_BUDGET,
  APPROVE_MONTHLY_BUDGET,
  REJECT_MONTHLY_BUDGET,
  POSTPONE_MONTHLY_BUDGET,
  APPROVE_ALL_MONTHLY_BUDGET,
  REVIEW_ALL_MONTHLY_BUDGET,
  REQUEST_MONTHLY_BUDGET_CLARIFICATION,
  REQUEST_MONTHLY_BUDGET_MODIFICATION,
} from "../../types";

const initState = {
  monthBudgets: [],
  monthlyBudget: {},
  monthlyBudgetLines: [],
  monthlyBudgetLine: {},
  items: [],
  divItems: [],
  oneItem: {},
  error: null,
  budgetLineError: null,
};

const monthlyReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_MONTHLY_BUDGETS:
      return {
        ...state,
        monthlyBudgets: action.payload,
      };
    case CREATE_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: action.payload,
      };
    case SUBMIT_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: action.payload,
      };
    case GET_MONTHLY_BUDGET_BY_ID:
      return {
        ...state,
        monthlyBudget: action.payload,
      };
    case GET_ALL_MONTHLY_BUDGETS:
      return {
        ...state,
        monthlyBudgets: action.payload,
      };
    case GET_ALL_MONTHLY_BUDGET_LINES:
      return {
        ...state,
        monthlyBudgetLines: action.payload,
      };
    case GET_ALL_MONTHLY_BUDGET_LINE_BY_BUDGET_ID:
      return {
        ...state,
        monthlyBudgetLines: action.payload.data,
      };
    case GET_MONTHLY_BUDGET_ITEMS_BY_SUBHEAD:
      return {
        ...state,
        items: action.payload.data,
      };
      case GET_MONTHLY_BUDGET_ITEMS_BY_DIVISION:
        return {
          ...state,
          divItems: action.payload.data,
      };
      case GET_ONE_MONTHLY_BUDGET_ITEM:
        return {
          ...state,
          oneItem: action.payload.data,
      };
    case GET_MONTHLY_BUDGET_LINE_BY_ID:
      return {
        ...state,
        monthlyBudgetLine: action.payload,
      };
    case CREATE_MONTHLY_BUDGET_LINE:
      return {
        ...state,
        monthlyBudgetLine: action.payload,
      };
    case UPDATE_MONTHLY_BUDGET_LINE:
      return {
        ...state,
        monthlyBudgetLine: action.payload,
      };
    case DELETE_MONTHLY_BUDGET_LINE:
      return {
        ...state,
        monthlyBudgetLines: state.monthlyBudgetLines.filter(
          (monthly) => monthly._id !== action.payload
        ),
      };
    case CONFIRM_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: action.payload,
      };
    case APPROVE_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: action.payload,
      };
    case DECLINE_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: action.payload,
      };
    case REJECT_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: action.payload,
      };
    case POSTPONE_MONTHLY_BUDGET:
      return {
        ...state,
      };
    case REVIEW_ALL_MONTHLY_BUDGET:
      return {
        ...state,
      };
    case APPROVE_ALL_MONTHLY_BUDGET:
      return {
        ...state,
        monthlyBudget: action.payload,
      };
    case REQUEST_MONTHLY_BUDGET_CLARIFICATION:
      return {
        ...state,
      };
    case REQUEST_MONTHLY_BUDGET_MODIFICATION:
      return {
        ...state,
      };
    case MONTHLY_BUDGETS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case MONTHLY_BUDGET_LINE_ERROR:
      return {
        ...state,
        budgetLineError: action.error,
      };

    default:
      return state;
  }
};

export default monthlyReducer;
