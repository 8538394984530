import * as types from "../../types/inventory";

const initState = {
    locations: [],
    location: {},
    locationItems: [],
    error: null,
    success: false,
    loading: false,
};

const locationReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_LOCATION:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ALL_LOCATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_LOCATIONS:
            return {
                ...state,
                loading: false,
                success: true,
                locations: action.payload.data,
            };
        case types.GET_ALL_LOCATIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_LOCATION:
            return {
                ...state,
                loading: false,
                success: true,
                location: action.payload.data,
            };
        case types.GET_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_LOCATION_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_LOCATION_ITEMS:
            return {
                ...state,
                loading: false,
                success: true,
                locationItems: action.payload.data,
            };
        case types.GET_LOCATION_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_LOCATION:
            return {
                ...state,
                loading: false,
                location: state.locations.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DELETE_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_LOCATION:
            return {
                ...state,
                loading: false,
                success: false,
                locations: state.locations.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.RESET_LOCATION:
            return {
                ...state,
                error: null,
                success: false,
                loading: false,
            };


        default:
            return state;
    }
};

export default locationReducer;
