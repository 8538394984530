import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PPMGuard = ({ children, ...rest }) => {
  const token = localStorage.getItem("token:key");

  const { profile } = useSelector((state) => state.user);

  let isPeoplesGuard = profile
    ? (profile &&
        profile.groups &&
        profile.groups.some(
          (grp) =>
            grp.name === "admin" ||
            (grp.name === "user" && grp.module === "ppm")
        )) ||
      (profile &&
        profile.division &&
        profile.division.name === "People Management")
    : false;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && isPeoplesGuard ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PPMGuard;
