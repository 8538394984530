import React, { useState, useEffect } from "react";
import { Document, Page, Text, StyleSheet, PDFViewer, } from "@react-pdf/renderer";
import { Paper, Typography, makeStyles, Grid, Button, darken, Backdrop, } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../atoms/Feedback";
import { getRequisition } from "../../../../actions/inventoryActions";

import PageOne from './Doc/PageOne';

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        width: 200,
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

const styles = StyleSheet.create({
    header: {
        fontSize: 10,
        marginBottom: 10,
        textAlign: 'center',
        color: 'grey',
        marginTop: 20,
        borderBottomWidth: 1,
        borderBottomColor: "#888",
        borderLeftStyle: "solid",
        textTransform: "capitalize"

    },
    page: {
        backgroundColor: "#fff",
        color: "black",
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        lineHeight: 1.5,
    },
    viewer: {
        width: "100%",
        height: window.innerHeight,
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const RequisitionDocument = ({ requite }) => {
    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <Text style={styles.header} fixed>
                        {requite && requite.dateNeeded ? requite.dateNeeded : null}
                    </Text>
                    <PageOne requite={requite} />
                </Page>
            </Document>
        </PDFViewer>
    );
}

const RequisitionPDF = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, requisition } = useSelector((state) => state.requisition);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getRequisition(id));
    }, [dispatch, id]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            <Typography variant="overline" className={classes.title}>
                Preview Requisition
            </Typography>

            <Paper style={{ padding: 20, marginTop: 15, marginBottom: 15 }}>
                <Typography variant="overline" className={classes.title2}>
                    Please wait a little while for the PDF document to load.
                </Typography>
            </Paper>

            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/inventory/requisition")}
                    >
                        Back
                    </Button>

                </Grid>
            </Grid>

            <Grid item style={{ marginTop: 20 }}>
                <RequisitionDocument requite={requisition} />
            </Grid>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default RequisitionPDF;