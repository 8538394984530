import axios from "axios";
import {
  CREATE_CWSR,
  CREATE_CWSR_REQUEST,
  CREATE_CWSR_FAIL,
  UPDATE_CWSR,
  UPDATE_CWSR_FAIL,
  UPDATE_CWSR_REQUEST,
  DELETE_CWSR,
  DELETE_CWSR_REQUEST,
  DELETE_CWSR_FAIL,
  SUBMIT_CWSR,
  SUBMIT_CWSR_REQUEST,
  SUBMIT_CWSR_FAIL,
  APPROVE_CWSR,
  APPROVE_CWSR_REQUEST,
  APPROVE_CWSR_FAIL,
  REJECT_CWSR,
  REJECT_CWSR_FAIL,
  REJECT_CWSR_REQUEST,
  REVIEW_CWSR,
  REVIEW_CWSR_FAIL,
  REVIEW_CWSR_REQUEST,

  DECLINE_CWSR,
  DECLINE_CWSR_FAIL,
  DECLINE_CWSR_REQUEST,

  ACCEPT_CWSR,
  ACCEPT_CWSR_FAIL,
  ACCEPT_CWSR_REQUEST,

  MODIFY_CWSR,
  MODIFY_CWSR_FAIL,
  MODIFY_CWSR_REQUEST,

  GET_ALL_CWSR,
  GET_ALL_CWSR_FAIL,
  GET_ALL_CWSR_REQUEST,

  GET_ALL_ALL_CWSR,
  GET_ALL_ALL_CWSR_FAIL,
  GET_ALL_ALL_CWSR_REQUEST,

  GET_CWSR_BY_ID,
  GET_CWSR_BY_ID_FAIL,
  GET_CWSR_BY_ID_REQUEST,
  UPLOAD_CWSR_DOCUMENT,
  UPLOAD_CWSR_DOCUMENT_FAIL,
  UPLOAD_CWSR_DOCUMENT_REQUEST,
} from "../types";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/sales/cwsr`;

export const createCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_CWSR_REQUEST,
    });
    const { data } = await axios.post(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_CWSR,
      payload: data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: CREATE_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const updateCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CWSR_REQUEST,
    });
    const { data } = await axios.put(`${URL}/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_CWSR,
      payload: data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: UPDATE_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const deleteCwsr = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_CWSR_REQUEST,
    });
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_CWSR,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const submitCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_CWSR_REQUEST,
    });
    const { data } = await axios.put(`${URL}/submit/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SUBMIT_CWSR,
      payload: data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: SUBMIT_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const approveCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_CWSR_REQUEST,
    });
    const { data } = await axios.put(`${URL}/approve/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_CWSR,
      payload: data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: APPROVE_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const rejectCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_CWSR_REQUEST,
    });
    const { data } = await axios.put(`${URL}/reject/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REJECT_CWSR,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REJECT_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

// BD Head reviews the CWSR
export const reviewCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: REVIEW_CWSR_REQUEST,
    });
    const { data } = await axios.put(`${URL}/review/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REVIEW_CWSR,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: REVIEW_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

// BD Head declines the CWSR
export const declineCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: DECLINE_CWSR_REQUEST,
    });
    const { data } = await axios.put(`${URL}/decline/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DECLINE_CWSR,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: DECLINE_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

// Commercial Head accepts the CWSR
export const acceptCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ACCEPT_CWSR_REQUEST,
    });
    const { data } = await axios.put(`${URL}/accept/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: ACCEPT_CWSR,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: ACCEPT_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

// Commercial Head Requests modification of the CWSR
export const toModifyCwsr = (body) => async (dispatch) => {
  try {
    dispatch({
      type: MODIFY_CWSR_REQUEST,
    });
    const { data } = await axios.put(`${URL}/requestmodify/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: MODIFY_CWSR,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: MODIFY_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const getAllAllCwsrs = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_ALL_CWSR_REQUEST,
    });
    const { data } = await axios.get(`${URL}/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_ALL_CWSR,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ALL_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const getAllCwsrs = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CWSR_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_CWSR,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CWSR_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const getCwsrById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CWSR_BY_ID_REQUEST,
    });
    const { data } = await axios.get(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_CWSR_BY_ID,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CWSR_BY_ID_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const uploadDoc = (doc) => async (dispatch) => {
  try {
    dispatch({
      type: UPLOAD_CWSR_DOCUMENT_REQUEST,
    });
    const { data } = await axios.post(`${URL}/upload`, doc, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPLOAD_CWSR_DOCUMENT,
      payload: data,
    });
    return data.doc;
  } catch (error) {
    dispatch({
      type: UPLOAD_CWSR_DOCUMENT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};
