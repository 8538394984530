import axios from "axios";
import * as leadTypes from "../types/leadsType";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/crm/lead`;

export const createLead = (body) => async (dispatch) => {
  try {
    dispatch({ type: leadTypes.CREATE_LEAD_REQUEST });

    const { data } = await axios.post(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: leadTypes.CREATE_LEAD,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: leadTypes.CREATE_LEAD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getLeads = () => async (dispatch) => {
  try {
    dispatch({
      type: leadTypes.GET_LEADS_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: leadTypes.GET_LEADS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: leadTypes.GET_LEADS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getLead = (id) => async (dispatch) => {
  try {
    dispatch({
      type: leadTypes.GET_LEAD_REQUEST,
    });

    const { data } = await axios.get(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: leadTypes.GET_LEAD,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: leadTypes.GET_LEAD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateLead = (body) => async (dispatch) => {
  try {
    dispatch({
      type: leadTypes.UPDATE_LEAD_REQUEST,
    });
    const { data } = await axios.put(`${URL}/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: leadTypes.UPDATE_LEAD,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: leadTypes.UPDATE_LEAD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateLeadStage = (body) => async (dispatch) => {
  try {
    dispatch({
      type: leadTypes.UPDATE_LEAD_STAGE_REQUEST,
    });
    const { data } = await axios.put(`${URL}/stage/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: leadTypes.UPDATE_LEAD_STAGE,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: leadTypes.UPDATE_LEAD_STAGE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteLead = (id) => async (dispatch) => {
  try {
    dispatch({
      type: leadTypes.DELETE_LEAD_REQUEST,
    });
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: leadTypes.DELETE_LEAD,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: leadTypes.DELETE_LEAD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
