import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Paper,
    Grid,
    IconButton,
    makeStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { TablePaginationActions } from "../../../TablePaginationActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
    },
    btn: {
        marginBottom: 15,
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
        marginRight: 10,
        fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        // padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
        padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    table: {
        minWidth: 500,
    },
    divider: {
        marginBottom: 30,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    grid: {
        marginTop: 15,
    },
    textField2: {
        marginTop: 30,
        marginBottom: 30,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    textFieldSmall: {
        width: 100,
    },
}));

export const ViewContentsModal = ({ contents, openContents, handleCloseContents }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, Array(10).length - page * rowsPerPage);

    return (
        <div>
            <Dialog
                open={openContents}
                onClose={handleCloseContents}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Module / Topic Contents
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseContents}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Paper variant="outlined" square className={classes.paper} style={{paddingBottom:0}}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-label="custom pagination table bordered"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S/N</TableCell>
                                        <TableCell>Content</TableCell>
                                        <TableCell>Delete</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                {contents && contents.map((row, i) => {
                                    return (
                                        <TableRow hover key={i}>
                                            <TableCell component="th" scope="row">
                                                {i + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.content && row.content}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <IconButton
                                                    color="secondary"
                                                    // onClick={() => handleOpenDelete(row._id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                    })}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                            colSpan={3}
                            count={contents && contents.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Paper>
                </DialogContent>
            </Dialog>
        </div>
    );
};
