import * as types from "../../types/lms";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/lms`;

//////////////////////////////////////////////
/////////////////// Courses //////////////////
//////////////////////////////////////////////
export const createCourse = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_COURSE_REQUEST,
        });
        const { data } = await axios.post(`${URL}/course`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_COURSE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_COURSE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCourses = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_COURSES_REQUEST,
        });
        const { data } = await axios.get(`${URL}/course`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_COURSES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_COURSES_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCourse = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_COURSE_REQUEST,
        });
        const { data } = await axios.get(`${URL}/course/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_COURSE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_COURSE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateCourse = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_COURSE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/course/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_COURSE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_COURSE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteCourse = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_COURSE_REQUEST,
        });
        await axios.delete(`${URL}/course/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_COURSE,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_COURSE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

//////////////////////////////////////////////
///////////////// Lessons ////////////////////
//////////////////////////////////////////////
export const createLesson = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_LESSON_REQUEST,
        });
        const { data } = await axios.post(`${URL}/lesson`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });        
        dispatch({
            type: types.CREATE_LESSON,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_LESSON_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const uploadLessonImage = (doc) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPLOAD_LESSON_IMAGE_REQUEST,
        });
        const { data } = await axios.post(`${URL}/lesson/upload`, doc, {
            headers: {
            Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPLOAD_LESSON_IMAGE,
            payload: data,
        });
        return data.doc;
    } catch (error) {
        dispatch({
            type: types.UPLOAD_LESSON_IMAGE_FAIL,
            payload:
            error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
};
  
export const getCourseLessons = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_COURSE_LESSONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/lessons/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_COURSE_LESSONS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_COURSE_LESSONS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getLesson = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_LESSON_REQUEST,
        });
        const { data } = await axios.get(`${URL}/lesson/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_LESSON,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_LESSON_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateLesson = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_LESSON_REQUEST,
        });
        const { data } = await axios.put(`${URL}/lesson/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_LESSON,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_LESSON_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteLesson = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_LESSON_REQUEST,
        });
        await axios.delete(`${URL}/lesson/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_LESSON,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_LESSON_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};



//////////////////////////////////////////////
///////////////// Modules ////////////////////
//////////////////////////////////////////////
export const createModule = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_MODULE_REQUEST,
        });
        const { data } = await axios.post(`${URL}/module`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });        
        dispatch({
            type: types.CREATE_MODULE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_MODULE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const createQuiz = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_QUIZ_REQUEST,
        });
        const { data } = await axios.post(`${URL}/quiz`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });        
        dispatch({
            type: types.CREATE_QUIZ,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_QUIZ_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const createRecord = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_RECORD_REQUEST,
        });
        const { data } = await axios.post(`${URL}/record`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });        
        dispatch({
            type: types.CREATE_RECORD,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_RECORD_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const getLessonRecord = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_LESSON_RECORD_REQUEST,
        });
        const { data } = await axios.get(`${URL}/record/lesson/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_LESSON_RECORD,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_LESSON_RECORD_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
