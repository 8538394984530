import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ProtectedGuard = ({ children, profile, ...rest }) => {
  const token = localStorage.getItem("token:key");

  let isAdmin = profile
    ? profile &&
      profile.groups &&
      profile.groups.some((grp) => grp.name === "admin")
    : false;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

export default connect(mapStateToProps, {})(ProtectedGuard);
