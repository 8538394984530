import * as types from "../../../types/priceListType";

const initState = {
  equipments: [],
  equipment: null,
  loading: false,
  error: null,
  success: false,
};

const equipmentReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CREATE_EQUIPMENT_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_EQUIPMENT_PRICE_LIST:
      return {
        ...state,
        loading: false,
        success: true,
        equipment: action.payload,
      };
    case types.CREATE_EQUIPMENT_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.GET_ALL_EQUIPMENT_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_EQUIPMENT_PRICE_LIST:
      return {
        ...state,
        loading: false,
        equipments: action.payload,
      };
    case types.GET_ALL_EQUIPMENT_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.GET_EQUIPMENT_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_EQUIPMENT_PRICE_LIST:
      return {
        ...state,
        loading: false,
        equipment: action.payload.data,
      };
    case types.GET_EQUIPMENT_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.RESET_EQUIPMENT_PRICE_LIST:
      return {
        equipment: null,
        loading: false,
        error: null,
        success: false,
      };

    case types.UPDATE_EQUIPMENT_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_EQUIPMENT_PRICE_LIST:
      return {
        ...state,
        loading: false,
        equipments: state.equipments.map((item) =>
          item._id === action.payload.data._id
            ? (item = action.payload.data)
            : item
        ),
      };
    case types.UPDATE_EQUIPMENT_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.DELETE_EQUIPMENT_PRICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_EQUIPMENT_PRICE_LIST:
      return {
        ...state,
        loading: false,
        equipments: state.equipments.filter((item) => item._id !== action.payload),
      };
    case types.DELETE_EQUIPMENT_PRICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default equipmentReducer;
