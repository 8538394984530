import React, { useState, useEffect } from "react";
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../atoms/Feedback";
import { getCustomers } from "../../../../actions/customerActions";
import {
  createContactPerson,
  getContactPersons,
} from "../../../../actions/contactPersonActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginBottom: 10,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  total: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  warningInfo: {
    color: "#f5f5f5",
    background: "#f44336",
    padding: 20,
    marginBottom: 20,
    marginRight: 10,
    "& > *": {
      fontWeight: 900,
    },
  },
  form: {
    marginBottom: 20,
  },
  grid: {
    marginBottom: 20,
  },
  input: {
    border: "1px solid #bdbdbd",
    padding: theme.spacing(2),
    borderRadius: 3,
  },

  btnWrapper: {
    textAlign: "center",
  },

  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const INIT_STATE = {
  name: "",
  designation: "",
  phone: "",
  email: "",
  relatedCustomer: "",
};

const CreateContactModal = ({ openCreate, handleCreateClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const [state, setState] = useState(INIT_STATE);

  const { success, loading, error } = useSelector(
    (state) => state.contactPerson
  );
  const { customers } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    let abortController = new AbortController();

    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
      dispatch({
        type: "RESET_CONTACT_PERSON",
      });
    };
  }, [error, dispatch]);

  useEffect(() => {
    let abortController = new AbortController();

    if (success) {
      dispatch(getContactPersons());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "Contact Person Created Successfully.",
        success: true,
      }));
    }
    return () => {
      abortController.abort();
      dispatch({
        type: "RESET_CONTACT_PERSON",
      });
    };
  }, [success, dispatch]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(createContactPerson(state));

    setTimeout(() => {
      handleCreateClose();
    }, 1500);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () =>
    !state.name ||
    !state.designation ||
    !state.email ||
    !state.phone ||
    !state.relatedCustomer;

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreate}
        onClose={handleCreateClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle className={classes.title}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Contact
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCreateClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name"
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={state.name || ""}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Designation"
                  fullWidth
                  variant="outlined"
                  name="designation"
                  value={state.designation || ""}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Phone"
                  fullWidth
                  variant="outlined"
                  name="phone"
                  value={state.phone || ""}
                  onChange={handleChange}
                  type="tel"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Email"
                  fullWidth
                  variant="outlined"
                  name="email"
                  value={state.email || ""}
                  onChange={handleChange}
                  type="email"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="relatedCustomer">Related Customer</InputLabel>
                  <Select
                    name="relatedCustomer"
                    value={state.relatedCustomer}
                    onChange={handleChange}
                    labelId="relatedCustomer"
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {customers &&
                      customers.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>
            <Grid container justify="center">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                className={classes.saveBtn}
                disabled={loading || validCheck()}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateContactModal;
