import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_REQUEST,
} from "../types";

const initState = {
  notifications: [],
  error: null,
  loading: false,
};

const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        loading: false,
        notifications: action.payload.data,
      };
    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        loading: false,
        notifications: state.notifications.filter(
          (item) => item._id !== action.payload
        ),
      };
    case DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
