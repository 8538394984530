import * as types from "../../types/people";

const initState = {
    crfs: [],
    crfs_approved: [],
    crf: null,
    loading: false,
    error: null,
    success: false,
};

const changeMgtReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_CRF_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_CRF:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_CRF_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };    
        case types.GET_CRFS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_CRFS:
            return {
                ...state,
                loading: false,
                crfs: action.payload.data,
                success: true,
            };
        case types.GET_CRFS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case types.GET_APPROVED_CRFS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_APPROVED_CRFS:
            return {
                ...state,
                loading: false,
                success: true,
                crfs_approved: action.payload.data,
            };
        case types.GET_APPROVED_CRFS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_CRF_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_CRF:
            return {
                ...state,
                loading: false,
                crf: action.payload.data,
                success: true,
            };
        case types.GET_CRF_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };


        
        case types.UPDATE_CRF_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_CRF:
            return {
                ...state,
                loading: false,
                crf: state.crfs.map((item) =>
                    item._id === action.payload.data.id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_CRF_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case types.DELETE_CRF_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_CRF:
            return {
                ...state,
                loading: false,
                crfs: state.crfs.filter(
                    (item) => item._id !== action.payload
                ),
                success: false,
            };
        case types.DELETE_CRF_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        


        default:
            return state;
    }
};

export default changeMgtReducer;
