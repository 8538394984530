import * as types from "../../types/inventory";

const initState = {
    invItems: [],
    invCates: [],
    invItem: {},
    invCate: {},
    invCateItems: [],
    error: null,
    success: false,
    loading: false,
};

const inventoryItemReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_INVENTORY_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_INVENTORY_ITEM:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_INVENTORY_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ALL_INVENTORY_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_INVENTORY_ITEMS:
            return {
                ...state,
                loading: false,
                success: true,
                invItems: action.payload.data,
            };
        case types.GET_ALL_INVENTORY_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.CREATE_INVENTORY_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_INVENTORY_CATEGORY:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_INVENTORY_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ALL_INVENTORY_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_INVENTORY_CATEGORIES:
            return {
                ...state,
                loading: false,
                success: true,
                invCates: action.payload.data,
            };
        case types.GET_ALL_INVENTORY_CATEGORIES_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_INVENTORY_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_INVENTORY_CATEGORY:
            return {
                ...state,
                loading: false,
                success: true,
                invCate: action.payload.data,
            };
        case types.GET_INVENTORY_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_INVENTORY_CATEGORY_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_INVENTORY_CATEGORY_ITEMS:
            return {
                ...state,
                loading: false,
                success: true,
                invCateItems: action.payload.data,
            };
        case types.GET_INVENTORY_CATEGORY_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.UPDATE_INVENTORY_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_INVENTORY_CATEGORY:
            return {
                ...state,
                loading: false,
                invCate: state.invCates.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_INVENTORY_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DELETE_INVENTORY_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_INVENTORY_CATEGORY:
            return {
                ...state,
                loading: false,
                success: false,
                invCates: state.invCates.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_INVENTORY_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.GET_INVENTORY_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_INVENTORY_ITEM:
            return {
                ...state,
                loading: false,
                success: true,
                invItem: action.payload.data,
            };
        case types.GET_INVENTORY_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_INVENTORY_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_INVENTORY_ITEM:
            return {
                ...state,
                loading: false,
                invItem: state.invItems.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_INVENTORY_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DELETE_INVENTORY_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_INVENTORY_ITEM:
            return {
                ...state,
                loading: false,
                success: false,
                invItems: state.invItems.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_INVENTORY_ITEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.RESET_ITEM:
            return {
                ...state,
                error: null,
                success: false,
                loading: false,
            };

        default:
            return state;
    }
};

export default inventoryItemReducer;
