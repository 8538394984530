module.exports = {
    CREATE_COURSE: "CREATE_COURSE",
    CREATE_COURSE_REQUEST: "CREATE_COURSE_REQUEST",
    CREATE_COURSE_FAIL: "CREATE_COURSE_FAIL",

    UPDATE_COURSE: "UPDATE_COURSE",
    UPDATE_COURSE_REQUEST: "UPDATE_COURSE_REQUEST",
    UPDATE_COURSE_FAIL: "UPDATE_COURSE_FAIL",

    DELETE_COURSE: "DELETE_COURSE",
    DELETE_COURSE_REQUEST: "DELETE_COURSE_REQUEST",
    DELETE_COURSE_FAIL: "DELETE_COURSE_FAIL",

    GET_COURSES: "GET_COURSES",
    GET_COURSES_REQUEST: "GET_COURSES_REQUEST",
    GET_COURSES_FAIL: "GET_COURSES_FAIL",

    GET_COURSE_LESSONS: "GET_COURSE_LESSONS",
    GET_COURSE_LESSONS_REQUEST: "GET_COURSE_LESSONS_REQUEST",
    GET_COURSE_LESSONS_FAIL: "GET_COURSE_LESSONS_FAIL",

    GET_COURSE: "GET_COURSE",
    GET_COURSE_REQUEST: "GET_COURSE_REQUEST",
    GET_COURSE_FAIL: "GET_COURSE_FAIL",

    RESET_COURSE: "RESET_COURSE",


    CREATE_LESSON: "CREATE_LESSON",
    CREATE_LESSON_REQUEST: "CREATE_LESSON_REQUEST",
    CREATE_LESSON_FAIL: "CREATE_LESSON_FAIL",

    UPDATE_LESSON: "UPDATE_LESSON",
    UPDATE_LESSON_REQUEST: "UPDATE_LESSON_REQUEST",
    UPDATE_LESSON_FAIL: "UPDATE_LESSON_FAIL",

    UPLOAD_LESSON_IMAGE: "UPLOAD_LESSON_IMAGE",
    UPLOAD_LESSON_IMAGE_REQUEST: "UPLOAD_LESSON_IMAGE_REQUEST",
    UPLOAD_LESSON_IMAGE_FAIL: "UPLOAD_LESSON_IMAGE_FAIL",

    DELETE_LESSON: "DELETE_LESSON",
    DELETE_LESSON_REQUEST: "DELETE_LESSON_REQUEST",
    DELETE_LESSON_FAIL: "DELETE_LESSON_FAIL",

    GET_LESSONS: "GET_LESSONS",
    GET_LESSONS_REQUEST: "GET_LESSONS_REQUEST",
    GET_LESSONS_FAIL: "GET_LESSONS_FAIL",

    GET_LESSON: "GET_LESSON",
    GET_LESSON_REQUEST: "GET_LESSON_REQUEST",
    GET_LESSON_FAIL: "GET_LESSON_FAIL",

    RESET_LESSON: "RESET_LESSON",

    CREATE_MODULE: "CREATE_MODULE",
    CREATE_MODULE_REQUEST: "CREATE_MODULE_REQUEST",
    CREATE_MODULE_FAIL: "CREATE_MODULE_FAIL",

    CREATE_QUIZ: "CREATE_QUIZ",
    CREATE_QUIZ_REQUEST: "CREATE_QUIZ_REQUEST",
    CREATE_QUIZ_FAIL: "CREATE_QUIZ_FAIL",

    CREATE_RECORD: "CREATE_RECORD",
    CREATE_RECORD_REQUEST: "CREATE_RECORD_REQUEST",
    CREATE_RECORD_FAIL: "CREATE_RECORD_FAIL",

    GET_LESSON_RECORD: "GET_LESSON_RECORD",
    GET_LESSON_RECORD_REQUEST: "GET_LESSON_RECORD_REQUEST",
    GET_LESSON_RECORD_FAIL: "GET_LESSON_RECORD_FAIL",


};
