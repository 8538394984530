import * as types from "../types/visitorsType";

import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/visitors`;

export const createVisitorAppointment = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.post(URL, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_VISITOR_APPOINTMENT,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitVisitorAppointment = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_VISITOR_APPOINTMENT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getVisitorAppointments = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_VISITOR_APPOINTMENTS_REQUEST,
        });
        const { data } = await axios.get(URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_VISITOR_APPOINTMENTS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_VISITOR_APPOINTMENTS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getMyVisitorAppointments = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_MY_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.get(`${URL}/myvisitors`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_MY_VISITOR_APPOINTMENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_MY_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getTodaysVisitorAppointments = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_TODAY_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.get(`${URL}/today`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_TODAY_VISITOR_APPOINTMENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_TODAY_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getVisitorAppointment = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.get(`${URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_VISITOR_APPOINTMENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateVisitorAppointment = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_VISITOR_APPOINTMENT,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_VISITOR_APPOINTMENT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteVisitorAppointment = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_VISITOR_APPOINTMENT_REQUEST,
        });
        await axios.delete(`${URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_VISITOR_APPOINTMENT,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_VISITOR_APPOINTMENT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const checkinVisitorAppointment = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CHECKIN_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/checkin/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CHECKIN_VISITOR_APPOINTMENT,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CHECKIN_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const checkinByCodeVisitorAppointment = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CHECKIN_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/checkinbycode/${body.code}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CHECKIN_VISITOR_APPOINTMENT,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CHECKIN_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const checkoutVisitorAppointment = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CHECKOUT_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/checkout/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CHECKOUT_VISITOR_APPOINTMENT,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CHECKOUT_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const checkoutByCodeVisitorAppointment = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CHECKOUT_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/checkoutbycode/${body.code}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CHECKOUT_VISITOR_APPOINTMENT,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CHECKOUT_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getCustomerVisitorAppointment = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_VISITOR_APPOINTMENT_REQUEST,
        });
        const { data } = await axios.get(`${URL}/customer/${id}`);
        dispatch({
            type: types.GET_VISITOR_APPOINTMENT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_VISITOR_APPOINTMENT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const createCustomerFeedback = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_CUSTOMER_FEEDBACK_REQUEST,
        });
        const { data } = await axios.put(`${URL}/feedback/${body.id}`, body);
        dispatch({
            type: types.CREATE_CUSTOMER_FEEDBACK,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_CUSTOMER_FEEDBACK_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
