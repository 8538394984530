import React, { useState } from "react";
import {
    makeStyles,
    Paper,
    Grid,
    Button,
    Backdrop,
    ButtonGroup,
    Chip,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { formatAmount } from "../../../../utils";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { TablePaginationActions } from "../../../TablePaginationActions";
import SearchComponent from "../../../SearchComponent";
import MobilizationModal from "../modals/MasterListModals/MobilizationModal";
import EditMobilizationModal from "../modals/MasterListModals/EditMobilizationModal";
import DeleteMobilizationModal from "../modals/MasterListModals/DeleteMobilizationModal";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        marginTop: 30,
        marginBottom: 30,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    chip: {
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: "0.65rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    marginBottom: {
        marginBottom: 30,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    createBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

const MobilizationPriceList = ({ mobs, loading }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [getId, setGetId] = useState("");
    const [search, setSearch] = useState("");
    const { profile } = useSelector((state) => state.user);

    // const updateSearch = (e) => {
    //     setSearch(e.target.value.substr(0, 20));
    // };

    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpenEdit = (id) => {
        setGetId(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };
    const handleOpenDelete = (id) => {
        setGetId(id);
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const isCanDelete = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "reviewer" && grp.module === "sales"
        )
        : false;

    let filteredRows = () =>
        mobs === undefined
            ? []
            : mobs?.filter((row) => {
                if (search !== "") {
                    return (
                        row?.country.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        row?.total.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        row?.classification.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        row?.code.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        row?.subTotal.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        (row.division && row.division?.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                    );
                } else {
                    return row;
                }
            });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            (Array(1000).length || filteredRows().length) - page * rowsPerPage
        );

    return (
        <div>
            <MobilizationModal {...{ open, handleClose }} />
            <EditMobilizationModal {...{ openEdit, handleCloseEdit, id: getId }} />
            <DeleteMobilizationModal {...{ openDelete, handleCloseDelete, id: getId }} />

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={3}
                className={classes.marginBottom}
            >
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ marginRight: 5 }}
                        onClick={handleOpen}
                        className={classes.createBtn}
                    >
                        Create
                    </Button>
                </Grid>
                <Grid item>
                    <SearchComponent
                        // updateSearch={updateSearch}
                        placeholder="Search..."
                        search={search}
                        ResetSearch={ResetSearch}
                    />
                </Grid>
            </Grid>
            <Paper>
                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Country</TableCell>
                                <TableCell>Division</TableCell>
                                <TableCell>Classification</TableCell>
                                <TableCell>SubTotal</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows().slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                : filteredRows()
                            ).map((row, i) => {
                                return (
                                    <TableRow hover key={row?._id}>
                                        <TableCell component="th" scope="row">
                                            {row?.country}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.division?.name || <Chip label="Loading" />}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.classification}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.subTotal
                                                ? formatAmount(row.subTotal, "en-NG")
                                                : formatAmount(0, "en-NG")}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.total
                                                ? formatAmount(row.total, "en-NG")
                                                : formatAmount(0, "en-NG")}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.code}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <ButtonGroup variant="contained">
                                                <Button
                                                    color="primary"
                                                    onClick={() => handleOpenEdit(row?._id)}
                                                >
                                                    <EditIcon />
                                                </Button>
                                                {isCanDelete ? (<Button
                                                    color="secondary"
                                                    onClick={() => handleOpenDelete(row?._id)}
                                                >
                                                    <DeleteIcon />
                                                </Button>) : null}
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            {filteredRows().length === 0 && (
                                <TableRow hover>
                                    <TableCell
                                        colSpan={10}
                                        align="center"
                                        style={{ color: "#616161" }}
                                        component="th"
                                        scope="row"
                                    >
                                        No Records To Display
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default MobilizationPriceList;
