import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  img: {
    width: 500,
    height: 400,
    border: "1px solid #ccc",
    borderRadius: 10,
    objectFit: "contain",
    objectPosition: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  small: {
    fontSize: 11,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  link: {
    display: "block",
    margin: "auto",
  },
}));

const AttachmentRequirementModal = ({
  openAttachment,
  handleCloseAttachment,
  docs = [],
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openAttachment}
        onClose={handleCloseAttachment}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Attachment(s)
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseAttachment}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <List>
            {docs &&
              docs.map((doc, i) => (
                <ListItem key={i}>
                  <object
                    // data={
                    //   doc || (doc && doc.includes(".pdf"))
                    //     ? doc.replace(".pdf", ".png")
                    //     : doc
                    // }
                    data={doc}
                    // type="application/pdf"
                    className={classes.img}
                  >
                    {/* <img src={doc} className={classes.embed} alt={`doc-${i}`} /> */}

                    <Typography component="small" className={classes.small}>
                      Your Browser doesn't have a PDF Plugin. Instead you can{" "}
                      <Typography
                        component="a"
                        // href={
                        //   doc || (doc && doc.includes(".pdf"))
                        //     ? doc.replace(".pdf", ".png")
                        //     : doc
                        // }
                        href={doc}
                        target="_blank"
                        rel="noopener"
                        className={classes.small}
                        download
                      >
                        Click here to download the PDF file.
                      </Typography>
                    </Typography>
                  </object>
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AttachmentRequirementModal;
