import {
  CREATE_CASH_ADVANCE,
  GET_ALL_CASH_ADVANCES,
  CASH_ADVANCE_ERROR,
  GET_CASH_ADVANCE_BY_ID,
  UPDATE_CASH_ADVANCE,
  SUBMIT_CASH_ADVANCE,
  GET_ALL_CASH_ADVANCES_AWAITING_FINANCE_PAY_OR_CEO_APPROVAL,
  RETIRE_CASH_ADVANCE,
  UPLOAD_DOCUMENT,
  PAY_CASH_ADVANCE,
  GET_OUTSTANDING_TOTAL,

  REVIEW_CASH_ADVANCE,
  DECLINE_CASH_ADVANCE,
  HOD_CONFIRM_CASH_RETIREMENT,
  HOD_DECLINE_CASH_RETIREMENT,
  FIN_ACCEPT_CASH_RETIREMENT,
  FIN_REJECT_CASH_RETIREMENT,

} from "../../types";

const initState = {
  cashAdvances: [],
  cashAdvance: {},
  cashAdvanceError: null,
  allCashAdvances: [],
  url: "",
  outStandingTotal: 0,
};

const cashAdvanceReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_CASH_ADVANCE:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case GET_ALL_CASH_ADVANCES:
      return {
        ...state,
        cashAdvances: action.payload.data,
      };
    case GET_CASH_ADVANCE_BY_ID:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case GET_ALL_CASH_ADVANCES_AWAITING_FINANCE_PAY_OR_CEO_APPROVAL:
      return {
        ...state,
        allCashAdvances: action.payload.data,
      };
    case GET_OUTSTANDING_TOTAL:
      return {
        ...state,
        outStandingTotal: action.payload.data,
      };
    case UPDATE_CASH_ADVANCE:
      // return {
      //   ...state,
      //   cashAdvances: state.cashAdvances.map((adv) =>
      //     adv._id === action.payload.data._id
      //       ? (adv = action.payload.data)
      //       : adv
      //   ),
      // };
      return {
        ...state,
        cashAdvance: action.payload,
      };
    case SUBMIT_CASH_ADVANCE:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case RETIRE_CASH_ADVANCE:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case PAY_CASH_ADVANCE:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case UPLOAD_DOCUMENT:
      return {
        ...state,
        url: action.payload.doc,
      };
    case CASH_ADVANCE_ERROR:
      return {
        ...state,
        cashAdvanceError: action.error,
      };

    case REVIEW_CASH_ADVANCE:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case DECLINE_CASH_ADVANCE:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };


    case HOD_CONFIRM_CASH_RETIREMENT:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case HOD_DECLINE_CASH_RETIREMENT:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case FIN_ACCEPT_CASH_RETIREMENT:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };
    case FIN_REJECT_CASH_RETIREMENT:
      return {
        ...state,
        cashAdvance: action.payload.data,
      };

    default:
      return state;
  }
};

export default cashAdvanceReducer;
