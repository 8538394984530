import React from "react";

import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Button,
  DialogTitle,
  IconButton,
  DialogContentText,
  DialogActions,
  ButtonGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";
import {
  deleteCurrency,
  getCurrencies,
} from "../../../../actions/currencyActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  saveBtn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const DeleteCurrencyModal = ({
  id,
  openDelete,
  handleCloseDelete,
  deleteCurrency,
  getCurrencies,
  error,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleDelete = () => {
    setLoading((prev) => !prev);
    deleteCurrency(id)
      .then(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          success: true,
        });
        dispatch(getCurrencies());
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          success: false,
        });
      })
      .finally(() => {
        setLoading(false);

        handleCloseDelete();
      });
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || `Currency deleted successfully.`}
        />
      )}

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Delete Currency
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDelete}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="overline" className={classes.title3}>
              Are you sure you want to delete this currency?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseDelete} className={classes.saveBtn}>
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              color="secondary"
              variant="contained"
              className={classes.saveBtn}
              disabled={loading}
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.currency.error,
});

const mapDispatchToProps = {
  deleteCurrency,
  getCurrencies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCurrencyModal);
