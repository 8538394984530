import {
  GET_ALL_BUDGET_SUB_HEADS,
  GET_ALL_BUDGET_SUB_HEADS_FAIL,
  GET_ALL_BUDGET_SUB_HEADS_REQUEST,
  CREATE_BUDGET_SUB_HEAD,
  CREATE_BUDGET_SUB_HEAD_FAIL,
  CREATE_BUDGET_SUB_HEAD_REQUEST,
  DELETE_BUDGET_SUB_HEAD,
  GET_BUDGET_SUB_HEAD_BY_ID,
  GET_BUDGET_SUB_HEAD_BY_HEAD,
} from "../../types";

const initState = {
  subheads: [],
  subhead: {},
  loading: false,
  error: null,
};

const budgetSubheadReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_BUDGET_SUB_HEADS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_BUDGET_SUB_HEADS:
      return {
        ...state,
        loading: false,
        subheads: action.payload,
      };
    case GET_ALL_BUDGET_SUB_HEADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BUDGET_SUB_HEAD_BY_HEAD:
      return {
        ...state,
        subheads: action.payload,
      };
    case CREATE_BUDGET_SUB_HEAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_BUDGET_SUB_HEAD:
      return {
        ...state,
        loading: false,
        subheads: [action.payload, ...state.subheads],
      };
    case CREATE_BUDGET_SUB_HEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_BUDGET_SUB_HEAD:
      return {
        ...state,
        subheads: state.subheads.filter(
          (sub) => sub._id !== action.payload._id
        ),
      };
    case GET_BUDGET_SUB_HEAD_BY_ID:
      return {
        ...state,
        subhead: action.payload,
      };

    default:
      return state;
  }
};

export default budgetSubheadReducer;
