import axios from "axios";
import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_REQUEST,
} from "../types";

const token = localStorage.getItem("token:key");

const URL = `/api/v1/user/notification`;

export const getNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_NOTIFICATION_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_NOTIFICATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteNotification = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_NOTIFICATION_REQUEST,
    });
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_NOTIFICATION,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DELETE_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
