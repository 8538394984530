import React, { useState } from "react";
import {
  TextField,
  Paper,
  makeStyles,
  Typography,
  Grid,
  Divider,
  IconButton,
  Button,
  Backdrop,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";

import DeleteIcon from "@material-ui/icons/Delete";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { connect, useDispatch } from "react-redux";
import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";
import { useHistory, useParams } from "react-router-dom";
import {
  getAnnualBudgetLineById,
  updateAnnualBudgetLine,
  getAnnualBudgets,
} from "../../../actions/annualAction";
import { formatAmount, formatCurrency } from "../../../utils";
// import moment from "moment";
import { getAllBudgetHeads } from "../../../actions/budgetHeadsActions";
import {
  getAllBudgetSubheads,
  getBudgetSubheadByHead,
} from "../../../actions/budgetSubheadAction";
import {
  getAllBudgetItems,
  getBudgetItemBySubheadAndDivision,
} from "../../../actions/budgetItemActions";
import Feedback from "../../atoms/Feedback";
import { getCurrencies } from "../../../actions/currencyActions";
import { currencyConvert } from "../../../utils/currencyconvert";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  btn: {
    marginBottom: 15,
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  table: {
    minWidth: 500,
  },
  divider: {
    marginBottom: 30,
    marginTop: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  grid: {
    marginTop: 15,
  },
  textField2: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  textFieldSmall: {
    width: 100,
  },
  form: {
    marginBottom: 30,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const INIT_STATE = {
  employeeName: "",
  head: "",
  subHead: "",
  item: "",
  description: "",
  periods: [],
  year: "",
  subTotal: 0,
  month: "",
  amount: 0,
  rate: 1,
};

const EditBudgetLineForm = ({
  getAnnualBudgetLineById,
  annualBudgetLine,
  getAllBudgetHeads,
  getBudgetSubheadByHead,
  getBudgetItemBySubheadAndDivision,
  getAnnualBudgets,
  updateAnnualBudgetLine,
  heads,
  subheads,
  items,
  profile,
  getCurrencies,
  currencies,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(null);

  const [state, setState] = useState(INIT_STATE);
  const [periods, setPeriods] = useState([]);
  // const [subTotal, setSubTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const history = useHistory();
  const { id } = useParams();

  React.useEffect(() => {
    setLoading((prev) => !prev);
    getCurrencies().then(() => {
      setLoading(false);
    });
  }, [getCurrencies]);

  React.useEffect(() => {
    getAllBudgetHeads();
  }, [getAllBudgetHeads]);

  const annualBudgetLineCallback = React.useCallback(() => {
    setLoad((prev) => !prev);
    getAnnualBudgetLineById(id).then(() => {
      setLoad(false);
    });
  }, [id, getAnnualBudgetLineById]);

  React.useEffect(() => {
    annualBudgetLineCallback();
  }, [annualBudgetLineCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: annualBudgetLine && annualBudgetLine.employee,
      item:
        annualBudgetLine && annualBudgetLine.item && annualBudgetLine.item._id,
      head:
        annualBudgetLine && annualBudgetLine.head && annualBudgetLine.head._id,
      subHead:
        annualBudgetLine &&
        annualBudgetLine.subHead &&
        annualBudgetLine.subHead._id,

      description: annualBudgetLine && annualBudgetLine.description,
      subTotal:
        (annualBudgetLine && formatAmount(annualBudgetLine.subTotal)) ||
        formatAmount(0),
      year: annualBudgetLine && annualBudgetLine.year,
      periods: annualBudgetLine && annualBudgetLine.period,
    }));
  }, [annualBudgetLine]);

  const getBudgetSubheadCallback = React.useCallback(async () => {
    if (state.head !== undefined) {
      await getBudgetSubheadByHead(state.head);
    }
  }, [state.head, getBudgetSubheadByHead]);

  React.useEffect(() => {
    setOpenBackdrop((prev) => !prev);

    getBudgetSubheadCallback().then(() => {
      setOpenBackdrop(false);
    });
  }, [getBudgetSubheadCallback]);

  const itemCallback = React.useCallback(async () => {
    const data = {
      subhead: state.subHead && state.subHead,
      // subhead: state.subHead,
      division: profile && profile.division && profile.division._id,
    };

    if (state.subHead && state.subHead !== undefined) {
      await getBudgetItemBySubheadAndDivision(data);
    }
  }, [state.subHead, profile, getBudgetItemBySubheadAndDivision]);

  React.useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    itemCallback().then(() => {
      setOpenBackdrop(false);
    });
  }, [itemCallback]);

  // React.useEffect(() => {
  //   setSelectedDate(
  //     annualBudgetLine && new Date(Date.now(annualBudgetLine.year))
  //   );
  // }, [annualBudgetLine]);

  React.useEffect(() => {
    setPeriods(annualBudgetLine && annualBudgetLine.period);
  }, [annualBudgetLine]);

  // React.useEffect(() => {
  //   let subTotal =
  //     periods && periods.reduce((acc, cur) => acc + +cur.amount, 0);
  //   subTotal = formatAmount(subTotal || 0);
  //   setSubTotal(subTotal);
  // }, [periods]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  // const handleChangeDate = (date) => {
  //   setSelectedDate(moment(date).format("YYYY"));
  // };

  const filteredRows = () =>
    periods === undefined
      ? []
      : periods.filter((row) => {
          if (search !== "") {
            return (
              row.amount
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.month.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddPeriods = () => {
    const { month, amount, rate } = state;
    const data = {
      month,
      amount: currencyConvert(+amount, +rate),
      rate: 1,
    };
    setState((prev) => ({
      ...prev,
      month: "",
      amount: 0,
      rate: 1,
    }));

    setPeriods((prev) => [...prev, data]);
  };

  const handleRemovePeriod = (i) => {
    setPeriods((prev) => prev.filter((_, index) => index !== i));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Array(10).length - page * rowsPerPage);

  console.log(periods);

  const handleEditBudgetLine = () => {
    setLoading((prev) => !prev);

    let subTotal = periods.reduce((acc, cur) => {
      return acc + currencyConvert(+cur.amount, +cur.rate);
    }, 0);

    let data = {};
    data.head = state.head;
    data.subHead = state.subHead;
    data.item = state.item;
    data.description = state.description;
    // data.year = +moment(selectedDate).format("YYYY");
    data.period = periods;
    data.employee = state.employeeName;
    data.budgetId = annualBudgetLine && annualBudgetLine.budgetId;
    data.division = annualBudgetLine && annualBudgetLine.division;
    data.createdBy = annualBudgetLine && annualBudgetLine.createdBy;
    data.subTotal = subTotal;
    data.id = id;

    updateAnnualBudgetLine(data)
      .then((doc) => {
        if (!doc.success) {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `BudgetLine Item failed to update`,
            success: false,
          });
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `BudgetLine Item updated successfully`,
            success: true,
          });
          dispatch(getAnnualBudgets());
          dispatch(getAnnualBudgetLineById(id));
        }
      })
      .catch(() => {
        console.log("Failed to Update");
        // setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   message: `BudgetLine Item failed to update`,
        //   success: false,
        // });
      })
      .finally(() => {
        setTimeout(() => {
          history.goBack();
        }, 5000);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Button
        onClick={() => history.goBack()}
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        className={classes.btn}
      >
        Back
      </Button>
      <Typography variant="overline" className={classes.title}>
        Edit BudgetLine / Form
      </Typography>

      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel id="budget-head">Budget Head</InputLabel>
            <Select
              labelId="budget-head"
              id="head"
              name="head"
              inputProps={{
                id: "head",
                name: "head",
              }}
              value={state.head}
              onChange={handleChange}
            >
              <MenuItem disabled value="">
                --- Select Budget Head ---
              </MenuItem>
              {heads &&
                heads.map((head) => (
                  <MenuItem key={head._id} value={head._id}>
                    {head.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel id="budget-subhead">SubHead</InputLabel>
            <Select
              labelId="budget-subhead"
              id="subHead"
              name="subHead"
              inputProps={{
                id: "subHead",
                name: "subHead",
              }}
              value={state.subHead || ""}
              onChange={handleChange}
            >
              <MenuItem value={``} disabled>
                <em>None</em>
              </MenuItem>
              {subheads &&
                subheads.map((subhead) => (
                  <MenuItem key={subhead._id} value={subhead._id}>
                    {subhead.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel id="budgetItem">Budget Item</InputLabel>
            <Select
              labelId="budgetItem"
              id="item"
              name="item"
              inputProps={{
                id: "item",
                name: "item",
              }}
              value={state.item || ""}
              onChange={handleChange}
              disabled={openBackdrop}
            >
              <MenuItem disabled divider>
                <em>None</em>
              </MenuItem>

              {items &&
                items.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Description"
            fullWidth
            variant="outlined"
            name="description"
            value={state.description || ""}
            // className={classes.textField}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            required
            // className={classes.textField}
          >
            <InputLabel id="head-label-id">Month</InputLabel>
            <Select
              labelId="head-label-id"
              id="month"
              label="Month"
              inputProps={{
                id: "month",
                name: "month",
              }}
              onChange={handleChange}
              value={state.month || ""}
              name="month"
            >
              <MenuItem value="" disabled>
                --- Select Month ---
              </MenuItem>
              <MenuItem value="January">January</MenuItem>
              <MenuItem value="February">February</MenuItem>
              <MenuItem value="March">March</MenuItem>
              <MenuItem value="April">April</MenuItem>
              <MenuItem value="May">May</MenuItem>
              <MenuItem value="June">June</MenuItem>
              <MenuItem value="July">July</MenuItem>
              <MenuItem value="August">August</MenuItem>
              <MenuItem value="September">September</MenuItem>
              <MenuItem value="October">October</MenuItem>
              <MenuItem value="November">November</MenuItem>
              <MenuItem value="December">December</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            required
            // className={classes.textField}
          >
            <InputLabel id="head-label-id">Currency</InputLabel>
            <Select
              labelId="head-label-id"
              id="rate"
              label="Rate"
              inputProps={{
                id: "rate",
                name: "rate",
              }}
              value={state.rate || 1}
              onChange={handleChange}
              name="rate"
            >
              <MenuItem value="" disabled>
                --- Select Currency ---
              </MenuItem>
              {currencies &&
                currencies.map((currency) => (
                  <MenuItem key={currency._id} value={currency.rate}>
                    {currency.code}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="amount"
            label="Amount"
            name="amount"
            type="number"
            fullWidth
            variant="outlined"
            value={state.amount}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            id="amount"
            label="To Naira"
            name="amount"
            type="number"
            fullWidth
            variant="outlined"
            value={currencyConvert(state.amount, state.rate) || 0}
            disabled
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleAddPeriods}
            disabled={!state.amount || !state.month}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Divider className={classes.divider} light />

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 30 }}
      >
        <Grid item></Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Month(s)</TableCell>
                <TableCell>Amount</TableCell>

                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={i}>
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.month}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.amount
                        ? formatCurrency(row.amount, "en-NG", "NGN")
                        : formatCurrency(0, "en-NG", "NGN")}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <IconButton
                        color="secondary"
                        onClick={() => handleRemovePeriod(i)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <TextField
            label="SubTotal"
            variant="outlined"
            disabled
            className={classes.textField2}
            value={state.subTotal}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">&#8358;</InputAdornment>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                </>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            onClick={handleEditBudgetLine}
            className={classes.saveBtn}
            disabled={loading}
            endIcon={
              loading ? <CircularProgress size={20} color="primary" /> : null
            }
          >
            {loading ? "Loading..." : "Update"}
          </Button>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  annualBudgetLine: state.annualBudget.annualBudgetLine.data,
  heads: state.budgetHeads.heads.data,
  subheads: state.budgetSubheads.subheads.data,
  items: state.budgetItems.items.data,
  profile: state.user.profile,
  currencies: state.currency.currencies,
});

const mapDispatchToProps = {
  getAnnualBudgetLineById,
  getAnnualBudgets,
  getAllBudgetHeads,
  getAllBudgetSubheads,
  getAllBudgetItems,
  getBudgetItemBySubheadAndDivision,
  getBudgetSubheadByHead,
  updateAnnualBudgetLine,
  getCurrencies,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBudgetLineForm);
