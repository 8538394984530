import {
  GET_ALL_BUDGET_SUB_HEADS,
  GET_ALL_BUDGET_SUB_HEADS_FAIL,
  GET_ALL_BUDGET_SUB_HEADS_REQUEST,
  ERROR,
  CREATE_BUDGET_SUB_HEAD,
  CREATE_BUDGET_SUB_HEAD_FAIL,
  CREATE_BUDGET_SUB_HEAD_REQUEST,
  DELETE_BUDGET_SUB_HEAD,
  GET_BUDGET_SUB_HEAD_BY_ID,
  GET_BUDGET_SUB_HEAD_BY_HEAD,
  UPDATE_BUDGET_SUB_HEAD,
} from "../types";
import axios from "axios";

const URL = `/api/v1/budget/subHead`;
const token = localStorage.getItem("token:key");

export const getAllBudgetSubheads = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BUDGET_SUB_HEADS_REQUEST,
    });
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_BUDGET_SUB_HEADS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BUDGET_SUB_HEADS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const createBudgetSubhead = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_BUDGET_SUB_HEAD_REQUEST,
    });
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_BUDGET_SUB_HEAD,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_BUDGET_SUB_HEAD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getBudgetSubHeadById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/subHead/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_BUDGET_SUB_HEAD_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const getBudgetSubheadByHead = (head) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/${head}/subHead`;

    const res = await axios.get(URL, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({
      type: GET_BUDGET_SUB_HEAD_BY_HEAD,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const updateBudgetSubhead = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/subHead/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_BUDGET_SUB_HEAD,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteBudgetSubhead = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/subHead/${id}`;
    const res = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_BUDGET_SUB_HEAD,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};
