import * as types from "../../../types/priceListType";

const initState = {
    materials: [],
    material: null,
    loading: false,
    error: null,
    success: false,
};

const materialReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_MATERIAL_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_MATERIAL_PRICE_LIST:
            return {
                ...state,
                loading: false,
                success: true,
                material: action.payload,
            };
        case types.CREATE_MATERIAL_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.GET_ALL_MATERIAL_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_MATERIAL_PRICE_LIST:
            return {
                ...state,
                loading: false,
                materials: action.payload,
            };
        case types.GET_ALL_MATERIAL_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.GET_MATERIAL_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_MATERIAL_PRICE_LIST:
            return {
                ...state,
                loading: false,
                material: action.payload.data,
            };
        case types.GET_MATERIAL_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.RESET_MATERIAL_PRICE_LIST:
            return {
                material: null,
                loading: false,
                error: null,
                success: false,
            };

        case types.UPDATE_MATERIAL_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_MATERIAL_PRICE_LIST:
            return {
                ...state,
                loading: false,
                materials: state.materials.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_MATERIAL_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DELETE_MATERIAL_PRICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_MATERIAL_PRICE_LIST:
            return {
                ...state,
                loading: false,
                materials: state.materials.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_MATERIAL_PRICE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default materialReducer;
