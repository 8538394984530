module.exports = {    
    // Purchase Requisition
    CREATE_PURCHASE_REQUISITION: "CREATE_PURCHASE_REQUISITION",
    CREATE_PURCHASE_REQUISITION_REQUEST: "CREATE_PURCHASE_REQUISITION_REQUEST",
    CREATE_PURCHASE_REQUISITION_FAIL: "CREATE_PURCHASE_REQUISITION_FAIL",

    GET_ALL_PURCHASE_REQUISITIONS: "GET_ALL_PURCHASE_REQUISITIONS",
    GET_ALL_PURCHASE_REQUISITIONS_REQUEST: "GET_ALL_PURCHASE_REQUISITIONS_REQUEST",
    GET_ALL_PURCHASE_REQUISITIONS_FAIL: "GET_ALL_PURCHASE_REQUISITIONS_FAIL",

    GET_ACCEPTED_PURCHASE_REQUISITIONS: "GET_ACCEPTED_PURCHASE_REQUISITIONS",
    GET_ACCEPTED_PURCHASE_REQUISITIONS_REQUEST: "GET_ACCEPTED_PURCHASE_REQUISITIONS_REQUEST",
    GET_ACCEPTED_PURCHASE_REQUISITIONS_FAIL: "GET_ACCEPTED_PURCHASE_REQUISITIONS_FAIL",

    GET_PURCHASE_REQUISITION: "GET_PURCHASE_REQUISITION",
    GET_PURCHASE_REQUISITION_REQUEST: "GET_PURCHASE_REQUISITION_REQUEST",
    GET_PURCHASE_REQUISITION_FAIL: "GET_PURCHASE_REQUISITION_FAIL",

    UPDATE_PURCHASE_REQUISITION: "UPDATE_PURCHASE_REQUISITION",
    UPDATE_PURCHASE_REQUISITION_REQUEST: "UPDATE_PURCHASE_REQUISITION_REQUEST",
    UPDATE_PURCHASE_REQUISITION_FAIL: "UPDATE_PURCHASE_REQUISITION_FAIL",

    DELETE_PURCHASE_REQUISITION: "DELETE_PURCHASE_REQUISITION",
    DELETE_PURCHASE_REQUISITION_REQUEST: "DELETE_PURCHASE_REQUISITION_REQUEST",
    DELETE_PURCHASE_REQUISITION_FAIL: "DELETE_PURCHASE_REQUISITION_FAIL",

    SUBMIT_PURCHASE_REQUISITION: "SUBMIT_PURCHASE_REQUISITION",
    SUBMIT_PURCHASE_REQUISITION_REQUEST: "SUBMIT_PURCHASE_REQUISITION_REQUEST",
    SUBMIT_PURCHASE_REQUISITION_FAIL: "SUBMIT_PURCHASE_REQUISITION_FAIL",

    APPROVE_PURCHASE_REQUISITION: "APPROVE_PURCHASE_REQUISITION",
    APPROVE_PURCHASE_REQUISITION_REQUEST: "APPROVE_PURCHASE_REQUISITION_REQUEST",
    APPROVE_PURCHASE_REQUISITION_FAIL: "APPROVE_PURCHASE_REQUISITION_FAIL",

    REJECT_PURCHASE_REQUISITION: "REJECT_PURCHASE_REQUISITION",
    REJECT_PURCHASE_REQUISITION_REQUEST: "REJECT_PURCHASE_REQUISITION_REQUEST",
    REJECT_PURCHASE_REQUISITION_FAIL: "REJECT_PURCHASE_REQUISITION_FAIL",

    ACCEPT_PURCHASE_REQUISITION: "ACCEPT_PURCHASE_REQUISITION",
    ACCEPT_PURCHASE_REQUISITION_REQUEST: "ACCEPT_PURCHASE_REQUISITION_REQUEST",
    ACCEPT_PURCHASE_REQUISITION_FAIL: "ACCEPT_PURCHASE_REQUISITION_FAIL",

    DECLINE_PURCHASE_REQUISITION: "DECLINE_PURCHASE_REQUISITION",
    DECLINE_PURCHASE_REQUISITION_REQUEST: "DECLINE_PURCHASE_REQUISITION_REQUEST",
    DECLINE_PURCHASE_REQUISITION_FAIL: "DECLINE_PURCHASE_REQUISITION_FAIL",

    REVIEW_PURCHASE_REQUISITION: "REVIEW_PURCHASE_REQUISITION",
    REVIEW_PURCHASE_REQUISITION_REQUEST: "REVIEW_PURCHASE_REQUISITION_REQUEST",
    REVIEW_PURCHASE_REQUISITION_FAIL: "REVIEW_PURCHASE_REQUISITION_FAIL",

    RESET_PURCHASE_REQUISITION: "RESET_PURCHASE_REQUISITION",


    // Request for Quote
    UPDATE_REQUEST_FOR_QUOTE: "UPDATE_REQUEST_FOR_QUOTE",
    UPDATE_REQUEST_FOR_QUOTE_REQUEST: "UPDATE_REQUEST_FOR_QUOTE_REQUEST",
    UPDATE_REQUEST_FOR_QUOTE_FAIL: "UPDATE_REQUEST_FOR_QUOTE_FAIL",

    SUBMIT_REQUEST_FOR_QUOTE: "SUBMIT_REQUEST_FOR_QUOTE",
    SUBMIT_REQUEST_FOR_QUOTE_REQUEST: "SUBMIT_REQUEST_FOR_QUOTE_REQUEST",
    SUBMIT_REQUEST_FOR_QUOTE_FAIL: "SUBMIT_REQUEST_FOR_QUOTE_FAIL",

    ACCEPT_REQUEST_FOR_QUOTE: "ACCEPT_REQUEST_FOR_QUOTE",
    ACCEPT_REQUEST_FOR_QUOTE_REQUEST: "ACCEPT_REQUEST_FOR_QUOTE_REQUEST",
    ACCEPT_REQUEST_FOR_QUOTE_FAIL: "ACCEPT_REQUEST_FOR_QUOTE_FAIL",

    DECLINE_REQUEST_FOR_QUOTE: "DECLINE_REQUEST_FOR_QUOTE",
    DECLINE_REQUEST_FOR_QUOTE_REQUEST: "DECLINE_REQUEST_FOR_QUOTE_REQUEST",
    DECLINE_REQUEST_FOR_QUOTE_FAIL: "DECLINE_REQUEST_FOR_QUOTE_FAIL",

    DELETE_REQUEST_FOR_QUOTE: "DELETE_REQUEST_FOR_QUOTE",
    DELETE_REQUEST_FOR_QUOTE_REQUEST: "DELETE_REQUEST_FOR_QUOTE_REQUEST",
    DELETE_REQUEST_FOR_QUOTE_FAIL: "DELETE_REQUEST_FOR_QUOTE_FAIL",

    GET_ALL_REQUEST_FOR_QUOTES: "GET_ALL_REQUEST_FOR_QUOTES",
    GET_ALL_REQUEST_FOR_QUOTES_REQUEST: "GET_ALL_REQUEST_FOR_QUOTES_REQUEST",
    GET_ALL_REQUEST_FOR_QUOTES_FAIL: "GET_ALL_REQUEST_FOR_QUOTES_FAIL",

    GET_ONE_REQUEST_FOR_QUOTE: "GET_ONE_REQUEST_FOR_QUOTE",
    GET_ONE_REQUEST_FOR_QUOTE_REQUEST: "GET_ONE_REQUEST_FOR_QUOTE_REQUEST",
    GET_ONE_REQUEST_FOR_QUOTE_FAIL: "GET_ONE_REQUEST_FOR_QUOTE_FAIL",

    GET_ACCEPTED_REQUEST_FOR_QUOTES_REQUEST: "GET_ACCEPTED_REQUEST_FOR_QUOTES_REQUEST",
    GET_ACCEPTED_REQUEST_FOR_QUOTES: "GET_ACCEPTED_REQUEST_FOR_QUOTES",
    GET_ACCEPTED_REQUEST_FOR_QUOTES_FAIL: "GET_ACCEPTED_REQUEST_FOR_QUOTES_FAIL",

    RESET_REQUEST_FOR_QUOTE: "RESET_REQUEST_FOR_QUOTE",

    // open market
    GET_ALL_OPEN_MARKETS: "GET_ALL_OPEN_MARKETS",
    GET_ALL_OPEN_MARKETS_REQUEST: "GET_ALL_OPEN_MARKETS_REQUEST",
    GET_ALL_OPEN_MARKETS_FAIL: "GET_ALL_OPEN_MARKETS_FAIL",

    GET_ONE_OPEN_MARKET: "GET_ONE_OPEN_MARKET",
    GET_ONE_OPEN_MARKET_REQUEST: "GET_ONE_OPEN_MARKET_REQUEST",
    GET_ONE_OPEN_MARKET_FAIL: "GET_ONE_OPEN_MARKET_FAIL",

    UPDATE_OPEN_MARKET: "UPDATE_OPEN_MARKET",
    UPDATE_OPEN_MARKET_REQUEST: "UPDATE_OPEN_MARKET_REQUEST",
    UPDATE_OPEN_MARKET_FAIL: "UPDATE_OPEN_MARKET_FAIL",

    DELETE_OPEN_MARKET: "DELETE_OPEN_MARKET",
    DELETE_OPEN_MARKET_REQUEST: "DELETE_OPEN_MARKET_REQUEST",
    DELETE_OPEN_MARKET_FAIL: "DELETE_OPEN_MARKET_FAIL",

    SUBMIT_OPEN_MARKET: "SUBMIT_OPEN_MARKET",
    SUBMIT_OPEN_MARKET_REQUEST: "SUBMIT_OPEN_MARKET_REQUEST",
    SUBMIT_OPEN_MARKET_FAIL: "SUBMIT_OPEN_MARKET_FAIL",

    APPROVE_OPEN_MARKET: "APPROVE_OPEN_MARKET",
    APPROVE_OPEN_MARKET_REQUEST: "APPROVE_OPEN_MARKET_REQUEST",
    APPROVE_OPEN_MARKET_FAIL: "APPROVE_OPEN_MARKET_FAIL",

    REJECT_OPEN_MARKET: "REJECT_OPEN_MARKET",
    REJECT_OPEN_MARKET_REQUEST: "REJECT_OPEN_MARKET_REQUEST",
    REJECT_OPEN_MARKET_FAIL: "REJECT_OPEN_MARKET_FAIL",

    RESET_OPEN_MARKET: "RESET_OPEN_MARKET",

    // Purchase Order
    CREATE_PURCHASE_ORDER: "CREATE_PURCHASE_ORDER",
    CREATE_PURCHASE_ORDER_REQUEST: "CREATE_PURCHASE_ORDER_REQUEST",
    CREATE_PURCHASE_ORDER_FAIL: "CREATE_PURCHASE_ORDER_FAIL",

    GET_ALL_PURCHASE_ORDERS: "GET_ALL_PURCHASE_ORDERS",
    GET_ALL_PURCHASE_ORDERS_REQUEST: "GET_ALL_PURCHASE_ORDERS_REQUEST",
    GET_ALL_PURCHASE_ORDERS_FAIL: "GET_ALL_PURCHASE_ORDERS_FAIL",

    GET_VENDOR_PURCHASE_ORDERS: "GET_VENDOR_PURCHASE_ORDERS",
    GET_VENDOR_PURCHASE_ORDERS_REQUEST: "GET_VENDOR_PURCHASE_ORDERS_REQUEST",
    GET_VENDOR_PURCHASE_ORDERS_FAIL: "GET_VENDOR_PURCHASE_ORDERS_FAIL",

    GET_PURCHASE_ORDER: "GET_PURCHASE_ORDER",
    GET_PURCHASE_ORDER_REQUEST: "GET_PURCHASE_ORDER_REQUEST",
    GET_PURCHASE_ORDER_FAIL: "GET_PURCHASE_ORDER_FAIL",

    UPDATE_PURCHASE_ORDER: "UPDATE_PURCHASE_ORDER",
    UPDATE_PURCHASE_ORDER_REQUEST: "UPDATE_PURCHASE_ORDER_REQUEST",
    UPDATE_PURCHASE_ORDER_FAIL: "UPDATE_PURCHASE_ORDER_FAIL",

    DELETE_PURCHASE_ORDER: "DELETE_PURCHASE_ORDER",
    DELETE_PURCHASE_ORDER_REQUEST: "DELETE_PURCHASE_ORDER_REQUEST",
    DELETE_PURCHASE_ORDER_FAIL: "DELETE_PURCHASE_ORDER_FAIL",

    SUBMIT_PURCHASE_ORDER: "SUBMIT_PURCHASE_ORDER",
    SUBMIT_PURCHASE_ORDER_REQUEST: "SUBMIT_PURCHASE_ORDER_REQUEST",
    SUBMIT_PURCHASE_ORDER_FAIL: "SUBMIT_PURCHASE_ORDER_FAIL",

    APPROVE_PURCHASE_ORDER: "APPROVE_PURCHASE_ORDER",
    APPROVE_PURCHASE_ORDER_REQUEST: "APPROVE_PURCHASE_ORDER_REQUEST",
    APPROVE_PURCHASE_ORDER_FAIL: "APPROVE_PURCHASE_ORDER_FAIL",

    REJECT_PURCHASE_ORDER: "REJECT_PURCHASE_ORDER",
    REJECT_PURCHASE_ORDER_REQUEST: "REJECT_PURCHASE_ORDER_REQUEST",
    REJECT_PURCHASE_ORDER_FAIL: "REJECT_PURCHASE_ORDER_FAIL",

    ACCEPT_PURCHASE_ORDER: "ACCEPT_PURCHASE_ORDER",
    ACCEPT_PURCHASE_ORDER_REQUEST: "ACCEPT_PURCHASE_ORDER_REQUEST",
    ACCEPT_PURCHASE_ORDER_FAIL: "ACCEPT_PURCHASE_ORDER_FAIL",

    DECLINE_PURCHASE_ORDER: "DECLINE_PURCHASE_ORDER",
    DECLINE_PURCHASE_ORDER_REQUEST: "DECLINE_PURCHASE_ORDER_REQUEST",
    DECLINE_PURCHASE_ORDER_FAIL: "DECLINE_PURCHASE_ORDER_FAIL",

    REVIEW_PURCHASE_ORDER: "REVIEW_PURCHASE_ORDER",
    REVIEW_PURCHASE_ORDER_REQUEST: "REVIEW_PURCHASE_ORDER_REQUEST",
    REVIEW_PURCHASE_ORDER_FAIL: "REVIEW_PURCHASE_ORDER_FAIL",

    PAY_PURCHASE_ORDER: "PAY_PURCHASE_ORDER",
    PAY_PURCHASE_ORDER_REQUEST: "PAY_PURCHASE_ORDER_REQUEST",
    PAY_PURCHASE_ORDER_FAIL: "PAY_PURCHASE_ORDER_FAIL",

    RESET_PURCHASE_ORDER: "RESET_PURCHASE_ORDER",

};
