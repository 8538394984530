import React from "react";
import { connect } from "react-redux";
import {
  Paper,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Backdrop,
  Button,
  Icon,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import FilterListIcon from "@material-ui/icons/FilterList";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { TablePaginationActions } from "../../TablePaginationActions";
import { getAllSubModules } from "../../../actions/moduleAction";
import SearchComponent from "../../SearchComponent";
import { useParams } from "react-router-dom";
import EditSubModuleModal from "./modals/EditSubModuleModal";
import DeleteSubModuleModal from "./modals/DeleteSubModuleModal";
import CreateSubModuleModal from "./modals/CreateSubModuleModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    // marginBottom: 20,
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
}));

const SubModuleTable = ({ getAllSubModules, submodules }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [getId, setGetId] = React.useState("");
  const [openCreate, setOpenCreate] = React.useState(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { id } = useParams();

  const getAllSubModulesCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);

    getAllSubModules(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [getAllSubModules, id]);

  React.useEffect(() => {
    getAllSubModulesCallback();
  }, [getAllSubModulesCallback]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  let filteredRows = () =>
    submodules === undefined
      ? []
      : submodules &&
        submodules.map((row) => {
          if (search !== "") {
            return (
              row.name
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.code
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEdit = (id) => {
    setGetId(id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCreateOpen = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  return (
    <div>
      <CreateSubModuleModal {...{ openCreate, handleCloseCreate, id }} />
      <EditSubModuleModal {...{ openEdit, handleCloseEdit, getId }} />
      <DeleteSubModuleModal {...{ openDelete, handleCloseDelete, id: getId }} />

      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            onClick={handleCreateOpen}
            className={classes.btn}
            variant="contained"
            color="primary"
            size="large"
            endIcon={<Icon className="fas fa-folder-plus" />}
          >
            Add SubModule
          </Button>
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>
      <Typography variant="overline" className={classes.title2}>
        SubModules
      </Typography>
      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.code}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEdit(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDelete(row._id)}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  submodules: state.module.submodules,
});

const mapDispatchToProps = {
  getAllSubModules,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubModuleTable);
