import {
    CREATE_QUOTATION,
    CREATE_QUOTATION_FAIL,
    CREATE_QUOTATION_REQUEST,
    GET_QUOTATIONS,
    GET_QUOTATIONS_REQUEST,
    GET_QUOTATIONS_FAIL,
    GET_QUOTATION,
    GET_QUOTATION_FAIL,
    GET_QUOTATION_REQUEST,
    UPDATE_QUOTATION,
    UPDATE_QUOTATION_FAIL,
    UPDATE_QUOTATION_REQUEST,
    DELETE_QUOTATION,
    DELETE_QUOTATION_REQUEST,
    DELETE_QUOTATION_FAIL,
    APPROVE_QUOTATION,
    APPROVE_QUOTATION_REQUEST,
    APPROVE_QUOTATION_FAIL,
    DECLINE_QUOTATION,
    DECLINE_QUOTATION_REQUEST,
    DECLINE_QUOTATION_FAIL,
    REJECT_QUOTATION,
    REJECT_QUOTATION_REQUEST,
    REJECT_QUOTATION_FAIL,
    REVIEW_QUOTATION,
    REVIEW_QUOTATION_REQUEST,
    REVIEW_QUOTATION_FAIL,
    UPLOAD_QUOTATION_DOCUMENT,
    UPLOAD_QUOTATION_DOCUMENT_REQUEST,
    UPLOAD_QUOTATION_DOCUMENT_FAIL,
    SEND_QUOTATION,
    SEND_QUOTATION_REQUEST,
    SEND_QUOTATION_FAIL,
    RESET_QUOTATION,
    GET_CUSTOMER_QUOTATION_REQUEST,
    GET_CUSTOMER_QUOTATION,
    GET_CUSTOMER_QUOTATION_ERROR,
    MARK_CUSTOMER_QUOTATION_REQUEST,
    MARK_CUSTOMER_QUOTATION,
    MARK_CUSTOMER_QUOTATION_ERROR,

} from "../../types/quotationType";

const initState = {
    quotations: [],
    loading: false,
    error: null,
    quotation: null,
    customerquotation: null,
    success: false,
    doc: null,
};

const quotationReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case CREATE_QUOTATION:
            return {
                ...state,
                loading: false,
                success: true,
                // quotation: action.payload.data,
            };
        case CREATE_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,

                error: action.payload,
            };
        case GET_QUOTATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case GET_QUOTATIONS:
            return {
                ...state,
                loading: false,
                quotations: action.payload.data,
                success: false,
            };
        case GET_QUOTATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case GET_QUOTATION:
            return {
                ...state,
                loading: false,
                quotation: action.payload.data,
                success: false,
            };
        case GET_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };

        case GET_CUSTOMER_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case GET_CUSTOMER_QUOTATION:
            return {
                ...state,
                loading: false,
                customerquotation: action.payload,
                success: false,
            };
        case GET_CUSTOMER_QUOTATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };

        case MARK_CUSTOMER_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MARK_CUSTOMER_QUOTATION:
            return {
                ...state,
                loading: false,
            };
        case MARK_CUSTOMER_QUOTATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case UPDATE_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case UPDATE_QUOTATION:
            return {
                ...state,
                loading: false,
                quotation: state.quotations.map((item) =>
                    item._id === action.payload.data.id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case UPDATE_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case DELETE_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case DELETE_QUOTATION:
            return {
                ...state,
                loading: false,
                quotations: state.quotations.filter(
                    (item) => item._id !== action.payload
                ),
                success: false,
            };
        case DELETE_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case APPROVE_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case APPROVE_QUOTATION:
            return {
                ...state,
                loading: false,
                quotation: action.payload.data,
            };
        case APPROVE_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case REJECT_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REJECT_QUOTATION:
            return {
                ...state,
                loading: false,
                quotation: action.payload.data,
            };
        case REJECT_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DECLINE_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DECLINE_QUOTATION:
            return {
                ...state,
                loading: false,
                quotation: action.payload.data,
            };
        case DECLINE_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case REVIEW_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REVIEW_QUOTATION:
            return {
                ...state,
                loading: false,
                quotation: action.payload.data,
            };
        case REVIEW_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case UPLOAD_QUOTATION_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPLOAD_QUOTATION_DOCUMENT:
            return {
                ...state,
                loading: false,
                doc: action.payload.doc,
            };
        case UPLOAD_QUOTATION_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SEND_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SEND_QUOTATION:
            return {
                ...state,
                loading: false,
                quotation: action.payload.data,
            };
        case SEND_QUOTATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_QUOTATION: {
            return {
                ...state,
                loading: false,
                quotation: null,
                error: null,
                success: false,
            };
        }

        default:
            return state;
    }
};

export default quotationReducer;
