import React from "react";

import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";
import {
  getCostCode,
  updateCostCode,
  getCostCodes,
} from "../../../../actions/costCodeAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  name: "",
  code: "",
  type: "",
};

const EditCostCenterModal = ({
  openEdit,
  handleCloseEdit,
  id,
  getCostCodes,
  getCostCode,
  updateCostCode,
  costcode,
  error,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = React.useState(INIT_STATE);

  const [loading, setLoading] = React.useState(false);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const costCodeCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    getCostCode(id).then(() => {
      setLoading(false);
    });
  }, [getCostCode, id]);

  React.useEffect(() => {
    costCodeCallback();
  }, [costCodeCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      name: costcode && costcode.name,
      code: costcode && costcode.code,
      type: costcode && costcode.type,
    }));
  }, [costcode]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      ...state,
      id,
    };
    updateCostCode(data)
      .then(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Cost Code created successfully.`,
          success: true,
        });
        dispatch(getCostCodes());
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          success: false,
          message: null,
        });
      })
      .finally(() => {
        setLoading(false);
        handleCloseEdit();
      });
  };
  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || `Cost Code created successfully.`}
        />
      )}

      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Update Center
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseEdit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={state.name || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Code"
                  name="code"
                  value={state.code || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="typeId">Type</InputLabel>
                  <Select
                    id="type"
                    labelId="typeId"
                    name="type"
                    inputProps={{
                      id: "type",
                      name: "type",
                    }}
                    value={state.type || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="---SELECT TYPE---" disabled>
                      <em>Select Type</em>
                    </MenuItem>
                    <MenuItem value="project">Project</MenuItem>
                    <MenuItem value="division">Division/Sub-division</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  disabled={loading}
                  className={classes.saveBtn}
                >
                  {loading ? "Loading..." : "Update"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  costcode: state.costcode.costcode && state.costcode.costcode.data,
  error: state.costcode.error,
});

const mapDispatchToProps = {
  getCostCodes,
  getCostCode,
  updateCostCode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCostCenterModal);
