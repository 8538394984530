import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  TextField,
  makeStyles,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  declineBudget,
  getAnnualBudgetById,
  getAnnualBudgets,
} from "../../../../actions/annualAction";
import Feedback from "../../../atoms/Feedback";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textField: {
    marginBottom: 20,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeclineBudgetModal = ({
  openDecline,
  handleCloseDecline,
  id,
  declineBudget,
  getAnnualBudgets,
  getAnnualBudgetById,
  error,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [reason, setReason] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const handleDecline = () => {
    setLoading((prev) => !prev);
    const data = {
      comment: reason,
      id,
      path: "budget/annual/budgetline",
    };
    declineBudget(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `You have successfully declined this budget, an email has been sent to the initiator for modification.`,
          success: true,
        });
        dispatch(getAnnualBudgets);
        dispatch(getAnnualBudgetById(id));
      })
      .catch(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `An error has occurred, please contact admin.`,
          success: false,
        });
      })
      .finally(() => {
        handleCloseDecline();
        setTimeout(() => {
          history.push("/budget/annual");
        }, 2000);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message ||
            "You have successfully declined this budget, an email has been sent to the initiator for modification."
          }
        />
      )}
      <Dialog
        open={openDecline}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDecline}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {"Decline budget?"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDecline}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText id="alert-dialog-slide-description" color="error"> */}
          <Typography variant="overline" className={classes.title3}>
            Note: By Clicking Decline, it means you're unsatisfied with this
            budget.
          </Typography>
          {/* </DialogContentText> */}
          <TextField
            label="Reason"
            className={classes.textField}
            variant="outlined"
            fullWidth
            value={reason}
            onChange={handleChange}
            helperText="State your reason for Declining."
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseDecline}
              color="secondary"
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDecline}
              color="primary"
              disabled={!reason || loading}
              className={classes.saveBtn}
              variant="contained"
            >
              {loading ? "Loading..." : "Decline"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.annualBudget.error,
});

const mapDispatchToProps = {
  declineBudget,
  getAnnualBudgetById,
  getAnnualBudgets,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclineBudgetModal);
