export const GET_TOTAL_ANNUAL_BUDGET = "GET_TOTAL_ANNUAL_BUDGET";
export const GET_TOTAL_ANNUAL_BUDGET_REQUEST =
  "GET_TOTAL_ANNUAL_BUDGET_REQUEST";
export const GET_TOTAL_ANNUAL_BUDGET_FAIL = "GET_TOTAL_ANNUAL_BUDGET_FAIL";
export const GET_TOTAL_MONTHLY_BUDGET = "GET_TOTAL_MONTHLY_BUDGET";
export const GET_TOTAL_MONTHLY_BUDGET_REQUEST =
  "GET_TOTAL_MONTHLY_BUDGET_REQUEST";
export const GET_TOTAL_MONTHLY_BUDGET_FAIL = "GET_TOTAL_MONTHLY_BUDGET_FAIL";
export const GET_TOTAL_SUPPLEMENTARY_BUDGET = "GET_TOTAL_SUPPLEMENTARY_BUDGET";
export const GET_TOTAL_SUPPLEMENTARY_BUDGET_REQUEST =
  "GET_TOTAL_SUPPLEMENTARY_BUDGET_REQUEST";
export const GET_TOTAL_SUPPLEMENTARY_BUDGET_FAIL =
  "GET_TOTAL_SUPPLEMENTARY_BUDGET_FAIL";
