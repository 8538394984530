import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  Divider,
  darken,
  Backdrop,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import {
  getCashRecoveryById,
  peoplesRecoverCashRecovery,
} from "../../../actions/cashRecoveryAction";
import Feedback from "../../atoms/Feedback";
import CompleteRecoveryModal from "./modals/CompleteRecoveryModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  submitBtn: {
    marginLeft: 10,
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    // padding: theme.spacing(2),
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  danger: {
    color: "#ef5350",
    fontSize: 13,
    marginTop: -25,
    display: "block",
  },
  actions: {
    background: "#388e3c",
    color: "#fff",
    marginLeft: 10,
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  actions2: {
    background: theme.palette.warning.main,
    color: "#fff",
    marginLeft: 10,
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    "&:hover": {
      background: darken(theme.palette.warning.main, 0.15),
      // color: "#fff",
    },
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const INIT_STATE = {
  employeeName: "",
  employeeId: "",
  division: "",
  month: "",
  year: "",
  purpose: "",
  jobTitle: "",

  status: "",
  source: "",
  total: 0,
  currency: "",
  overdueDate: "",
  documentNo: "",
  recoveryType: "",
  exemptionReason: "",
};

const ViewRecoveryLog = ({
  profile,
  getCashRecoveryById,
  cashRecovery,
  peoplesRecoverCashRecovery,
  error,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [state, setState] = useState(INIT_STATE);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openFinalRecover, setOpenFinalRecover] = useState(false);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const getCashRecoveryByIdCallback = useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getCashRecoveryById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [getCashRecoveryById, id]);

  useEffect(() => {
    getCashRecoveryByIdCallback();
  }, [getCashRecoveryByIdCallback]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: cashRecovery && cashRecovery.employee,
      employeeId: cashRecovery && cashRecovery.employeeId,
      division:
        cashRecovery && cashRecovery.division && cashRecovery.division.name,
      month: cashRecovery && cashRecovery.month,
      year: cashRecovery && cashRecovery.year,
      purpose: cashRecovery && cashRecovery.purpose,
      jobTitle: cashRecovery && cashRecovery.jobTitle,

      status: cashRecovery && cashRecovery.status,
      total: cashRecovery && cashRecovery.totalOverdue,
      currency: cashRecovery && cashRecovery.currency,
      overdueDate: cashRecovery && cashRecovery.overdueDate,
      source: cashRecovery && cashRecovery.source,
      documentNo: cashRecovery && cashRecovery.documentNo,
      recoveryType: cashRecovery && cashRecovery.recoveryType,
      exemptionReason: cashRecovery && cashRecovery.exemptionReason,
    }));
  }, [cashRecovery]);

  const handleRecoverCash = () => {
    setOpenBackdrop((prev) => !prev);
    peoplesRecoverCashRecovery(id)
      .then(() => {
        setOpenBackdrop(false);
        setFeed((prev) => ({
          ...prev,
          loading: false,
          open: !prev.open,
          message: `You have successfully Recovered this Cash.`,
          success: true,
        }));

        dispatch(getCashRecoveryById(id));
      })
      .catch(() => {
        setOpenBackdrop(false);

        setFeed({
          loading: false,
          open: !feed.open,
          // message: error && error.error,
          // success: false,
        });
      })
      .finally(() => {
        setTimeout(() => {
          history.push("/recovery/log");
        }, 1500);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleOpenFinalRecover = () => {
    setOpenFinalRecover(true);
  };

  const handleCloseFinalRecover = () => {
    setOpenFinalRecover(false);
  };

  return (
    <div>
      {error && error.success === false ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message || `You have successfully Exempted this recovery.`
          }
        />
      )}
      <CompleteRecoveryModal
        {...{ openFinalRecover, handleCloseFinalRecover, id }}
      />
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIosIcon />}
            className={classes.backBtn}
          >
            Back
          </Button>

          {state.status !== "recovered" ? (
            <Button
              variant="contained"
              className={classes.actions}
              // size="large"
              onClick={handleRecoverCash}
              disabled={openBackdrop}
            >
              {openBackdrop ? "Loading..." : "Acknowledge"}
            </Button>
          ) : null}

          {state.status === "recovered" ? (
            <Button
              variant="contained"
              className={classes.actions2}
              // size="large"
              onClick={handleOpenFinalRecover}
              disabled={openBackdrop}
            >
              {openBackdrop ? "Loading..." : "Acknowledge"}
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              state.status === "draft"
                ? classes.statusYellow
                : state.status === "exempted" || state.status === "to recover"
                ? classes.statusOrange
                : state.status === "recovered"
                ? classes.statusGreen
                : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop
              ? "Loading..."
              : state.status && state.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ margin: "30px 0" }}
      >
        <Grid item>
          <Typography className={classes.title} variant="overline">
            View Cash Recovery Log
          </Typography>
        </Grid>
        <Grid item>
          <Paper variant="outlined" className={clsx(classes.status)}>
            <Typography variant="overline" className={classes.statusTitle}>
              Document No.
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop ? "Loading..." : state.documentNo}
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            label="Employee Name"
            variant="outlined"
            fullWidth
            value={state.employeeName || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="EID"
            variant="outlined"
            fullWidth
            value={state.employeeId || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Division/Subdivison"
            variant="outlined"
            fullWidth
            value={state.division || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Month"
            variant="outlined"
            fullWidth
            value={state.month || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Year"
            variant="outlined"
            fullWidth
            value={state.year || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Purpose"
            variant="outlined"
            fullWidth
            value={state.purpose || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Job Title"
            variant="outlined"
            fullWidth
            value={state.jobTitle || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Source"
            variant="outlined"
            fullWidth
            value={state.source || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Currency"
            variant="outlined"
            fullWidth
            value={state.currency || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Total Overdue"
            variant="outlined"
            fullWidth
            value={state.total || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Recovery Type"
            variant="outlined"
            fullWidth
            value={state.recoveryType || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {state.exemptionReason && (
            <TextField
              label="Reason"
              variant="outlined"
              fullWidth
              value={state.exemptionReason || ""}
              disabled
            />
          )}
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cashRecovery: state.cashRecovery.cashRecovery,
  profile: state.user.profile,
  error: state.cashRecovery.cashRecoveryLogError,
});

const mapDispatchToProps = {
  getCashRecoveryById,
  peoplesRecoverCashRecovery,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecoveryLog);
