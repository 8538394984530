import React, { useState, useEffect } from "react";
import {
    Paper,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    InputAdornment,
    Divider,
    MenuItem,
    Button,
    IconButton,
    Dialog,
    Tooltip,
    Backdrop,
    AppBar,
    Toolbar,
    Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { countries } from "../../../../../utils";
import { getSymbol } from "../../../../../utils/currencyconvert";

import { useDispatch, useSelector } from "react-redux";
import { getDivisionsByService } from "../../../../../actions/divisionActions";
import { getCurrencies } from "../../../../../actions/currencyActions";
import {
    getMobilizationPriceListById, updateMobilizationPriceList, getAllMobilizationPriceList
} from "../../../../../actions/masterListActions/mobilizationActions";
import Feedback from "../../../../atoms/Feedback";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: "bold",
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 2,
    },
    container: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 300,
    },
}));

const initialState = {
    country: null,
    division: "",
    classification: "",
    code: "",
    airFare: null,
    quota: null,
    STR: null,
    TWP: null,
    accommodation: null,
    transport: null,
    subTotal: null,
    markup: null,
    total: null,
    currency: null,
};

const EditMobilizationModal = ({ openEdit, handleCloseEdit, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [country, setCountry] = useState({});
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const [state, setState] = useState(initialState);
    const { divisions } = useSelector((state) => state.division);
    const { loading, error, mob } = useSelector((state) => state.mob);
    const { currencies } = useSelector((state) => state.currency);

    useEffect(() => {
        dispatch(getCurrencies());
        dispatch(getMobilizationPriceListById(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            country: (mob && mob.country) || "",
            division: (mob && mob.division) || "",
            classification: (mob && mob.classification) || "",
            code: (mob && mob.code) || "",
            airFare: (mob && mob.airFare) || "",
            quota: (mob && mob.quota) || "",
            STR: (mob && mob.STR) || "",
            TWP: (mob && mob.TWP) || "",
            accommodation: (mob && mob.accommodation) || "",
            transport: (mob && mob.transport) || "",
            subTotal: (mob && mob.subTotal) || "",
            markup: (mob && mob.markup) || "",
            total: (mob && mob.total) || "",
            currency: (mob && mob.currency) || "",
        }));
    }, [mob]);

    useEffect(() => {
        dispatch(getDivisionsByService());
    }, [dispatch]);

    useEffect(() => {
        const subTotal =
            +state.airFare +
            +state.quota +
            +state.STR +
            +state.TWP +
            +state.accommodation +
            +state.transport;
        setState((prev) => ({
            ...prev,
            subTotal,
        }));
    }, [
        state.airFare,
        state.quota,
        state.STR,
        state.TWP,
        state.accommodation,
        state.transport,
    ]);

    useEffect(() => {
        if (state.classification === "Expatriate") {
            setState((prev) => ({
                ...prev, currency: "USD",
            }));
        } else if (state.classification === "National") {
            setState((prev) => ({
                ...prev, currency: "NGN",
            }));
        }
    }, [state.classification]);

    useEffect(() => {
        const initialCharge = (+state.markup / 100) * +state.subTotal;
        const total = initialCharge + +state.subTotal;
        setState((prev) => ({
            ...prev,
            total,
        }));
    }, [state.markup, state.subTotal]);

    const handleCountry = (e, country) => {
        setCountry(country);
        if (country?.label) {
            setState((prev) => ({
                ...prev,
                country: country.label
            }));
        }
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            id,
        };
        dispatch(updateMobilizationPriceList(data));
        setTimeout(() => {
            handleCloseEdit();
        }, 1500);

        setTimeout(() => {
            dispatch(getAllMobilizationPriceList());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Mobilization Price List Updated Successfully.",
                success: true,
            }));
        }, 2000);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Dialog
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                fullScreen
                maxWidth="md"
            >
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <AppBar
                    className={classes.appBar}
                    variant="elevation"
                    position="fixed"
                    color="inherit"
                    elevation={1}
                >
                    <Toolbar>
                        <Tooltip title="close" arrow>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseEdit}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <div className={classes.container}>
                    <Typography className={classes.title} variant="overline">
                        Edit Mobilization Price List
                    </Typography>
                    <div>
                        <form
                            component={Paper}
                            className={classes.form}
                            onSubmit={handleEditSubmit}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <Autocomplete
                                        fullWidth
                                        id="combo-box-demo"
                                        options={countries}
                                        name="country"
                                        value={country || null}
                                        onChange={handleCountry}
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(option) => (
                                            <React.Fragment>{option.label}</React.Fragment>
                                        )}
                                        autoComplete
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                variant="outlined"
                                                required
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "disabled",
                                                    name: "country",
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Division</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "division",
                                                name: "division",
                                            }}
                                            value={state.division}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Division</MenuItem>
                                            {divisions.map((division) => (
                                                <MenuItem key={division._id} value={division._id}>
                                                    {division.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Code"
                                        name="code"
                                        variant="outlined"
                                        value={state.code}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Currency</InputLabel>
                                        <Select
                                        inputProps={{
                                            id: "currency",
                                            name: "currency",
                                        }}
                                        value={state.currency}
                                        onChange={handleChange}
                                        >
                                        <MenuItem disabled>Select Currency</MenuItem>
                                        {currencies.map((currency) => (
                                            <MenuItem key={currency._id} value={currency.code}>
                                            {currency.code}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Classification</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "classification",
                                                name: "classification",
                                            }}
                                            value={state.classification}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Classification</MenuItem>
                                            <MenuItem value="National">National</MenuItem>
                                            <MenuItem value="Expatriate">Expatriate</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Air fare"
                                        name="airFare"
                                        type="number"
                                        variant="outlined"
                                        value={state.airFare}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Quota"
                                        name="quota"
                                        type="number"
                                        variant="outlined"
                                        value={state.quota}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="STR"
                                        name="STR"
                                        type="number"
                                        variant="outlined"
                                        value={state.STR}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="TWP"
                                        name="TWP"
                                        variant="outlined"
                                        value={state.TWP}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Accommodation"
                                        name="accommodation"
                                        type="number"
                                        variant="outlined"
                                        value={state.accommodation}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Transport"
                                        name="transport"
                                        type="number"
                                        variant="outlined"
                                        value={state.transport}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Markup (%)"
                                        name="markup"
                                        type="number"
                                        variant="outlined"
                                        value={state.markup}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Sub Total"
                                        name="subTotal"
                                        value={state.subTotal || 0}
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label={`Total Charge (${getSymbol(
                                            state.currency || "NGN",
                                            0
                                        )})`}
                                        name="total"
                                        variant="outlined"
                                        value={state.total}
                                        type="number"
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            startAdornment: (
                                                <>
                                                    <InputAdornment position="start">
                                                        {getSymbol(state.currency || "NGN", 0)}
                                                    </InputAdornment>
                                                    <Divider
                                                        className={classes.divider2}
                                                        orientation="vertical"
                                                    />
                                                </>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <Button
                                        onClick={handleEditSubmit}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading}
                                        endIcon={loading ? <CircularProgress size={20} /> : null}
                                    >
                                        {loading ? "updating" : "Save Changes"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};

export default EditMobilizationModal;
