import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
  ButtonGroup,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { updateLeadStage } from "../../../../actions/leadsAction";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const UpdateLeadStageModal = ({ open, handleClose, value, id, loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [justification, setJustification] = useState("");

  const handleProgress = () => {
    let data = {
        stage: value?.toString(),
        id,
        justification,
        date: new Date(),
    };
    dispatch(updateLeadStage(data));
    setJustification("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="overline" className={classes.title}>
                Justification for Update?
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          label={`Justification for lead stage update.`}
          required
          multiline
          rows={4}
          placeholder="Type something..."
          fullWidth
          variant="outlined"
          value={justification}
          onChange={(e) => {
            setJustification(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button
            onClick={handleClose}
            color="secondary"
            className={classes.saveBtn}
            variant="contained"
          >
            Close
          </Button>
          <Button
            onClick={handleProgress}
            color="primary"
            disabled={loading || !justification}
            className={classes.saveBtn}
            variant="contained"
          >
            {loading ? <CircularProgress size={20} /> : "Update"}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLeadStageModal;
