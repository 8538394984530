module.exports = {
    CREATE_VISITOR_APPOINTMENT: "CREATE_VISITOR_APPOINTMENT",
    CREATE_VISITOR_APPOINTMENT_REQUEST: "CREATE_VISITOR_APPOINTMENT_REQUEST",
    CREATE_VISITOR_APPOINTMENT_FAIL: "CREATE_VISITOR_APPOINTMENT_FAIL",

    UPDATE_VISITOR_APPOINTMENT: "UPDATE_VISITOR_APPOINTMENT",
    UPDATE_VISITOR_APPOINTMENT_REQUEST: "UPDATE_VISITOR_APPOINTMENT_REQUEST",
    UPDATE_VISITOR_APPOINTMENT_FAIL: "UPDATE_VISITOR_APPOINTMENT_FAIL",

    DELETE_VISITOR_APPOINTMENT: "DELETE_VISITOR_APPOINTMENT",
    DELETE_VISITOR_APPOINTMENT_REQUEST: "DELETE_VISITOR_APPOINTMENT_REQUEST",
    DELETE_VISITOR_APPOINTMENT_FAIL: "DELETE_VISITOR_APPOINTMENT_FAIL",

    SUBMIT_VISITOR_APPOINTMENT: "SUBMIT_VISITOR_APPOINTMENT",
    SUBMIT_VISITOR_APPOINTMENT_REQUEST: "SUBMIT_VISITOR_APPOINTMENT_REQUEST",
    SUBMIT_VISITOR_APPOINTMENT_FAIL: "SUBMIT_VISITOR_APPOINTMENT_FAIL",

    GET_VISITOR_APPOINTMENTS: "GET_VISITOR_APPOINTMENTS",
    GET_VISITOR_APPOINTMENTS_REQUEST: "GET_VISITOR_APPOINTMENTS_REQUEST",
    GET_VISITOR_APPOINTMENTS_FAIL: "GET_VISITOR_APPOINTMENTS_FAIL",

    GET_VISITOR_APPOINTMENT: "GET_VISITOR_APPOINTMENT",
    GET_VISITOR_APPOINTMENT_REQUEST: "GET_VISITOR_APPOINTMENT_REQUEST",
    GET_VISITOR_APPOINTMENT_FAIL: "GET_VISITOR_APPOINTMENT_FAIL",

    GET_MY_VISITOR_APPOINTMENT: "GET_MY_VISITOR_APPOINTMENT",
    GET_MY_VISITOR_APPOINTMENT_REQUEST: "GET_MY_VISITOR_APPOINTMENT_REQUEST",
    GET_MY_VISITOR_APPOINTMENT_FAIL: "GET_MY_VISITOR_APPOINTMENT_FAIL",

    GET_TODAY_VISITOR_APPOINTMENT: "GET_TODAY_VISITOR_APPOINTMENT",
    GET_TODAY_VISITOR_APPOINTMENT_REQUEST: "GET_TODAY_VISITOR_APPOINTMENT_REQUEST",
    GET_TODAY_VISITOR_APPOINTMENT_FAIL: "GET_TODAY_VISITOR_APPOINTMENT_FAIL",

    DECLINE_VISITOR_APPOINTMENT: "DECLINE_VISITOR_APPOINTMENT",
    DECLINE_VISITOR_APPOINTMENT_REQUEST: "DECLINE_VISITOR_APPOINTMENT_REQUEST",
    DECLINE_VISITOR_APPOINTMENT_FAIL: "DECLINE_VISITOR_APPOINTMENT_FAIL",

    APPROVE_VISITOR_APPOINTMENT: "APPROVE_VISITOR_APPOINTMENT",
    APPROVE_VISITOR_APPOINTMENT_REQUEST: "APPROVE_VISITOR_APPOINTMENT_REQUEST",
    APPROVE_VISITOR_APPOINTMENT_FAIL: "APPROVE_VISITOR_APPOINTMENT_FAIL",

    CHECKIN_VISITOR_APPOINTMENT: "CHECKIN_VISITOR_APPOINTMENT",
    CHECKIN_VISITOR_APPOINTMENT_REQUEST: "CHECKIN_VISITOR_APPOINTMENT_REQUEST",
    CHECKIN_VISITOR_APPOINTMENT_FAIL: "CHECKIN_VISITOR_APPOINTMENT_FAIL",

    CHECKOUT_VISITOR_APPOINTMENT: "CHECKOUT_VISITOR_APPOINTMENT",
    CHECKOUT_VISITOR_APPOINTMENT_REQUEST: "CHECKOUT_VISITOR_APPOINTMENT_REQUEST",
    CHECKOUT_VISITOR_APPOINTMENT_FAIL: "CHECKOUT_VISITOR_APPOINTMENT_FAIL",

    RESET_VISITOR_APPOINTMENT: "RESET_VISITOR_APPOINTMENT",

    CREATE_CUSTOMER_FEEDBACK: "CREATE_CUSTOMER_FEEDBACK",
    CREATE_CUSTOMER_FEEDBACK_REQUEST: "CREATE_CUSTOMER_FEEDBACK_REQUEST",
    CREATE_CUSTOMER_FEEDBACK_FAIL: "CREATE_CUSTOMER_FEEDBACK_FAIL",
};
