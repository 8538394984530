import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
    TextField,
    Button,
    IconButton,
    Backdrop,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    darken,
    ButtonGroup,
    Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch, connect } from "react-redux";
import {
    DatePicker,
    MuiPickersUtilsProvider,
    TimePicker,
} from "@material-ui/pickers";
import clsx from "clsx";
import MomentUtils from "@date-io/moment";
import Calendar from "react-calendar";
import Feedback from "../../../atoms/Feedback";
import {
    uploadSalesCallDocument,
    createSalesCall,
    getAllSalesCalls,
} from "../../../../actions/salesCallActions";
import {
    getLeads,
} from "../../../../actions/leadsAction";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },
    btn: {
        margin: "30px 0",
        width: 300,
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down("sm")]: {
            width: 200,
            // minWidth: "100%",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 10000,
        color: "#fff",
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },
    greenBg: {
        background: "#4caf50",
        color: "#fff",
        "&:hover": {
            background: darken("#4caf50", 0.2),
        },
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 15,
        borderColor: "#000",
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    flex: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
    calendar: {
        border: "1px solid #cacaca",
        // boxShadow: theme.shadows[2],
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        background: theme.palette.type === "light" ? "#fff" : "#424242",
    },
    mb2: {
        // marginBottom: 5,
    },
    mb20: {
        marginBottom: 20,
    },
    labelTwo: {
        fontSize: 13,
        display: "inline-block",
        marginTop: 5,
        color: theme.palette.type === "light" ? "#333" : "#fff",
        marginBottom: 5,
    },
    btnSuspect: {
        "&:hover": {
            background: darken("#ff9800", 0.2),
        },
    },
    btnSuspectActive: {
        background: "#ff9800",
    },
    btnProspect: {
        "&:hover": {
            background: darken("#fbc02d", 0.2),
        },
    },
    btnProspectActive: {
        background: "#fbc02d",
    },
    btnOpportunity: {
        "&:hover": {
            background: darken("#ffd600", 0.2),
        },
    },
    btnOpportunityActive: {
        background: "#ffd600",
    },
    btnWon: {
        "&:hover": {
            background: darken("#4caf50", 0.2),
        },
    },
    btnWonActive: {
        background: "#4caf50",
    },
    btnLost: {
        "&:hover": {
            background: darken("#ef5350", 0.2),
        },
    },
    btnLostActive: {
        background: "#ef5350",
    },
}));

const INIT_STATE = {
    activityType: "",
    summary: "",
    nextActivity: "",
    details: "",
    status: "Pending",
    lead: "",
};

function CreateSalesCallFromSalesCallModal({ open, handleClose, uploadSalesCallDocument }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = useState(INIT_STATE);
    const [date, setDate] = useState(new Date());
    const [activityDate, setActivityDate] = useState(new Date());
    const [progress, setProgress] = useState(0);
    const [justification, setJustification] = useState("");
    const [isLead, setIsLead] = useState(false);
    const [theLead, setTheLead] = useState(null);
    const [time, setTime] = useState(new Date());
    const [file, setFile] = useState(undefined);
    const [documents, setDocuments] = useState([]);
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const { loading, error, success, url } = useSelector(
        (state) => state.salesCall
    );
    const { leads } = useSelector((state) => state.leads);

    useEffect(() => {
        let progress = "";
        switch (theLead?.leadType) {
            case "Suspect":
                progress = 25;
                break;
            case "Prospect":
                progress = 50;
                break;
            case "Opportunity":
                progress = 75;
                break;
            case "Won":
                progress = 100;
                break;
            case "Lost":
                progress = 0;
                break;
            default:
                break;
        }
        setProgress(progress);
    }, [theLead]);

    useEffect(() => {
        let abortController = new AbortController();

        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
        return () => {
            abortController.abort();
            dispatch({
                type: "RESET_SALES_CALL",
            });
        };
    }, [error, dispatch]);

    useEffect(() => {
        dispatch(getLeads);
    }, [dispatch]);

    useEffect(() => {
        let abortController = new AbortController();

        if (success) {
            setTheLead(null);
            setState(INIT_STATE);
            setDate(new Date());
            setTime(new Date());
            setActivityDate(new Date());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Sales Call Created Successfully.",
                success: true,
            }));
        }
        return () => {
            abortController.abort();
            dispatch({
                type: "RESET_SALES_CALL",
            });
        };
    }, [success, dispatch]);

    useEffect(() => {
        let abortController = new AbortController();

        if (url) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Document Uploaded Successfully.",
                success: true,
            }));
        }
        return () => {
            abortController.abort();
            dispatch({
                type: "RESET_SALES_CALL",
            });
        };
    }, [success, dispatch, url]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangeDate = (date) => {
        setDate(date);
    };

    const handleChangeTime = (time) => {
        setTime(time);
    };

    const handleChangeActivityDate = (date) => {
        setActivityDate(date);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleUploadDocument = (e) => {
        setFile(e.target.files);
    };

    useEffect(() => {
        const upload = async () => {
            if (file) {
                for (let i = 0; i < file.length; i++) {
                    const formData = new FormData();

                    formData.append("doc", file[i]);

                    let doc = await new Promise((resolve, reject) => {
                        resolve(uploadSalesCallDocument(formData));
                    });

                    if (doc) {
                        setDocuments((prev) => prev.concat(doc));
                    }
                }
            }
        };
        upload();
    }, [file, uploadSalesCallDocument]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            id: state.lead,
            date: date._d || new Date(),
            time: time._d || new Date(),
            nextActivityDate: activityDate,
            justification: justification !== "" ? justification : undefined,
            progress: progress?.toString(),
            documents,
            ...state,
        };
        dispatch(createSalesCall(data));

        setTimeout(() => {
            handleClose();
            dispatch(getAllSalesCalls())
        }, 3000);
    };

    const handleProgress = (value) => {
        setIsLead(!!value);
        setProgress(value);
    };

    const isValid = () =>
        !date ||
        !state.activityType ||
        !state.summary ||
        !state.details ||
        !time ||
        !activityDate ||
        isLead === true ||
        !state.nextActivity;
    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="overline" className={classes.title}>
                                    Sales Call
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit}>
                            <>
                                <Typography
                                    variant="caption"
                                    component="small"
                                    display="block"
                                    color="textSecondary"
                                    style={{
                                        textTransform: "uppercase",
                                        letterSpacing: 2,
                                        fontSize: 10,
                                    }}
                                >
                                    Lead Stages
                                </Typography>
                                <ButtonGroup
                                    disabled={state?.status?.toLowerCase() === "done"}
                                    className={classes.mb20}
                                >
                                    <Tooltip arrow placement="top" title="Lost">
                                        <Button
                                            onClick={() => handleProgress(0)}
                                            className={clsx(
                                                {
                                                    [classes.btnLostActive]: progress === 0,
                                                },
                                                classes.btnLost
                                            )}
                                        >
                                            0%
                                        </Button>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title="Suspect">
                                        <Button
                                            onClick={() => handleProgress(25)}
                                            className={clsx(
                                                {
                                                    [classes.btnSuspectActive]: progress === 25,
                                                },
                                                classes.btnSuspect
                                            )}
                                        >
                                            25%
                                        </Button>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title="Prospect">
                                        <Button
                                            onClick={() => handleProgress(50)}
                                            className={clsx(
                                                {
                                                    [classes.btnProspectActive]: progress === 50,
                                                },
                                                classes.btnProspect
                                            )}
                                        >
                                            50%
                                        </Button>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title="Opportunity">
                                        <Button
                                            onClick={() => handleProgress(75)}
                                            className={clsx(
                                                {
                                                    [classes.btnOpportunityActive]: progress === 75,
                                                },
                                                classes.btnOpportunity
                                            )}
                                        >
                                            75%
                                        </Button>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title="Won">
                                        <Button
                                            onClick={() => handleProgress(100)}
                                            className={clsx(
                                                {
                                                    [classes.btnWonActive]: progress === 100,
                                                },
                                                classes.btnWon
                                            )}
                                        >
                                            100%
                                        </Button>
                                    </Tooltip>
                                </ButtonGroup>
                            </>
                            <Grid
                                container
                                spacing={3}
                                justify="center"
                                alignItems="flex-start"
                            >
                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth required variant="outlined">
                                        <InputLabel id="item">
                                            Select Leads
                                        </InputLabel>
                                        <Select
                                            labelId="lead"
                                            label="Select Lead"
                                            name="lead"
                                            required
                                            value={state.lead || ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select</MenuItem>
                                            {leads &&
                                                leads.map((lead) => (
                                                    <MenuItem key={lead._id} value={lead._id}>
                                                        CUSTOMER: {lead.customer.name}, DOCCUMENT NO: {lead.documentNo}, STAGE: {lead.leadType}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {isLead && (
                                        <TextField
                                            name="justification"
                                            onChange={(e) => {
                                                setJustification(e.target.value);
                                            }}
                                            value={justification}
                                            label="Justification"
                                            fullWidth
                                            variant="outlined"
                                            className={classes.mb20}
                                            placeholder="Type something..."
                                            required={isLead}
                                        />
                                    )}
                                    <DatePicker
                                        autoOk
                                        format="DD/MM/yyyy"
                                        views={["year", "month", "date"]}
                                        label="Activity Date"
                                        fullWidth
                                        inputVariant="outlined"
                                        value={date}
                                        onChange={handleChangeDate}
                                        required
                                    />
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        required
                                        className={classes.textField}
                                    >
                                        <InputLabel>Activity Type</InputLabel>
                                        <Select
                                            name="activityType"
                                            value={state.activityType}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>
                                                <em>---Select Activity Type</em>
                                            </MenuItem>
                                            <MenuItem value="Phone Call">Phone Call</MenuItem>
                                            <MenuItem value="Meeting">Meeting</MenuItem>
                                            <MenuItem value="Outbound Call">Outbound Call</MenuItem>
                                            <MenuItem value="Inbound Call">Inbound Call</MenuItem>
                                            <MenuItem value="Presentation">Presentation</MenuItem>
                                            <MenuItem value="Email">Email</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        name="summary"
                                        value={state.summary}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        label="Summary"
                                        placeholder="Type something..."
                                        required
                                        className={classes.textField}
                                    />
                                    <FormControl fullWidth variant="outlined">
                                        <input
                                            type="file"
                                            variant="outlined"
                                            accept="image/*,.pdf,.ppt,.pptx,.doc,.docx"
                                            multiple
                                            id="file"
                                            className={clsx(classes.input)}
                                            onChange={handleUploadDocument}
                                        />
                                        <FormHelperText className={classes.mb2}>
                                            Upload Documents (Powerpoint, Word, Pngs, Jpgs)
                                        </FormHelperText>
                                    </FormControl>
                                    <TextField
                                        name="details"
                                        value={state.details}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        label="Details"
                                        placeholder="Type something..."
                                        rows={4}
                                        multiline
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="nextActivity"
                                        value={state.nextActivity}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        label="Next Activity/Appointment Date"
                                        placeholder="Type something..."
                                        required
                                    />
                                    <Typography className={clsx(classes.mb2, classes.labelTwo)}>
                                        Next Activity/Appointment Date *
                                    </Typography>
                                    <Calendar
                                        value={activityDate}
                                        onChange={handleChangeActivityDate}
                                        className={classes.calendar}
                                    />
                                    <TimePicker
                                        autoOk
                                        fullWidth
                                        inputVariant="outlined"
                                        label="Activity Time"
                                        value={time}
                                        onChange={handleChangeTime}
                                        required
                                        className={classes.textField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify="center" alignItems="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    className={classes.btn}
                                    disabled={isValid() || loading}
                                    endIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="primary" />
                                        ) : null
                                    }
                                >
                                    {loading ? "Loading..." : "Create"}
                                </Button>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default connect(null, { uploadSalesCallDocument })(CreateSalesCallFromSalesCallModal);
