import * as types from "../../types/inventory";

const initState = {
    receipts: [],
    receipt: {},
    error: null,
    success: false,
    loading: false,
};

const receiptReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_INVENTORY_RECEIPTS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_INVENTORY_RECEIPTS:
            return {
                ...state,
                loading: false,
                success: true,
                receipts: action.payload.data,
            };
        case types.GET_ALL_INVENTORY_RECEIPTS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                success: true,
                receipt: action.payload.data,
            };
        case types.GET_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                success: true,
                receipt: state.receipts.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                success: false,
                receipts: state.receipts.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                receipt: action.payload.data,
            };
        case types.SUBMIT_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                receipt: action.payload.data,
            };
        case types.ACCEPT_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                receipt: action.payload.data,
            };
        case types.REJECT_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RECEIVE_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.RECEIVE_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                receipt: action.payload.data,
            };
        case types.RECEIVE_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case types.APPROVE_INVENTORY_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_INVENTORY_RECEIPT:
            return {
                ...state,
                loading: false,
                receipt: action.payload.data,
            };
        case types.APPROVE_INVENTORY_RECEIPT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_RECEIPT:
            return {
                receipt: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default receiptReducer;
