import {
  GET_ALL_BUDGET_ITEMS,
  GET_ALL_BUDGET_ITEMS_REQUEST,
  GET_ALL_BUDGET_ITEMS_FAIL,
  CREATE_BUDGET_ITEM,
  GET_BUDGET_ITEM_BY_ID,
  UPDATE_BUDGET_ITEM,
  DELETE_BUDGET_ITEM,
  GET_BUDGET_ITEM_BY_SUBHEAD_AND_DIVISION,
  BUDGET_ITEM_ERROR,
} from "../../types";

const initState = {
  items: [],
  item: {},
  error: null,
  loading: false,
};

const itemReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_BUDGET_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_BUDGET_ITEMS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    case GET_ALL_BUDGET_ITEMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_BUDGET_ITEM:
      return {
        ...state,
        items: [action.payload, ...state.items],
      };
    case GET_BUDGET_ITEM_BY_ID:
      return {
        ...state,
        item: action.payload,
      };
    case GET_BUDGET_ITEM_BY_SUBHEAD_AND_DIVISION:
      return {
        ...state,
        items: action.payload,
      };
    case UPDATE_BUDGET_ITEM:
      return {
        ...state,
        items: state.items.map((item) =>
          item._id === action.payload.data._id
            ? (item = action.payload.data)
            : item
        ),
      };
    case DELETE_BUDGET_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload.id),
      };
    case BUDGET_ITEM_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default itemReducer;
