import {
  CREATE_CWSR,
  CREATE_CWSR_REQUEST,
  CREATE_CWSR_FAIL,
  UPDATE_CWSR,
  UPDATE_CWSR_FAIL,
  UPDATE_CWSR_REQUEST,
  DELETE_CWSR,
  DELETE_CWSR_REQUEST,
  DELETE_CWSR_FAIL,
  SUBMIT_CWSR,
  SUBMIT_CWSR_REQUEST,
  SUBMIT_CWSR_FAIL,
  APPROVE_CWSR,
  APPROVE_CWSR_REQUEST,
  APPROVE_CWSR_FAIL,
  REJECT_CWSR,
  REJECT_CWSR_FAIL,
  REJECT_CWSR_REQUEST,
  REVIEW_CWSR,
  REVIEW_CWSR_FAIL,
  REVIEW_CWSR_REQUEST,

  ACCEPT_CWSR,
  ACCEPT_CWSR_FAIL,
  ACCEPT_CWSR_REQUEST,

  DECLINE_CWSR,
  DECLINE_CWSR_FAIL,
  DECLINE_CWSR_REQUEST,

  MODIFY_CWSR,
  MODIFY_CWSR_FAIL,
  MODIFY_CWSR_REQUEST,

  GET_ALL_CWSR,
  GET_ALL_CWSR_FAIL,
  GET_ALL_CWSR_REQUEST,
  
  GET_ALL_ALL_CWSR,
  GET_ALL_ALL_CWSR_FAIL,
  GET_ALL_ALL_CWSR_REQUEST,

  GET_CWSR_BY_ID,
  GET_CWSR_BY_ID_FAIL,
  GET_CWSR_BY_ID_REQUEST,
  UPLOAD_CWSR_DOCUMENT,
  UPLOAD_CWSR_DOCUMENT_FAIL,
  UPLOAD_CWSR_DOCUMENT_REQUEST,
  RESET_CWSR_DOCUMENT,
} from "../../types";

const initState = {
  cwsrs: [],
  allcwsrs: [],
  cwsr: null,
  loading: false,
  doc: null,
};

const cwsrReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CWSR:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case CREATE_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CWSR:
      return {
        ...state,
        loading: false,
        cwsrs: state.cwsrs.map((item) =>
          item._id === action.payload.data._id
            ? (item = action.payload.data)
            : item
        ),
      };
    case UPDATE_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CWSR:
      return {
        ...state,
        loading: false,
        cwsrs: state.cwsrs.filter((item) => item._id !== action.payload),
      };
    case DELETE_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUBMIT_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_CWSR:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case SUBMIT_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case APPROVE_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case APPROVE_CWSR:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case APPROVE_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REJECT_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REJECT_CWSR:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case REJECT_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REVIEW_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REVIEW_CWSR:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case REVIEW_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ACCEPT_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACCEPT_CWSR:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case ACCEPT_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DECLINE_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DECLINE_CWSR:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case DECLINE_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MODIFY_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MODIFY_CWSR:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case MODIFY_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ALL_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CWSR:
      return {
        ...state,
        loading: false,
        cwsrs: action.payload.data,
      };
    case GET_ALL_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ALL_ALL_CWSR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ALL_CWSR:
      return {
        ...state,
        loading: false,
        allcwsrs: action.payload.data,
      };
    case GET_ALL_ALL_CWSR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CWSR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CWSR_BY_ID:
      return {
        ...state,
        loading: false,
        cwsr: action.payload.data,
      };
    case GET_CWSR_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_CWSR_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_CWSR_DOCUMENT:
      return {
        ...state,
        loading: false,
        doc: action.payload.doc,
      };
    case UPLOAD_CWSR_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_CWSR_DOCUMENT:
      return {
        ...state,
        loading: false,
        doc: null,
      };

    default:
      return state;
  }
};

export default cwsrReducer;
