import {
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_FAIL,
  CREATE_CUSTOMER_REQUEST,
  GET_CUSTOMERS,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMER,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_FAIL,
} from "../../types";

const initState = {
  customers: [],
  loading: false,
  error: null,
  customer: null,
  success: false,
};

const customerReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CREATE_CUSTOMER:
      return {
        ...state,
        loading: false,
        success: true,
        // customer: action.payload.data,
      };
    case CREATE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,

        error: action.payload,
      };
    case GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: action.payload.data,
        success: false,
      };
    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

    case GET_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        loading: false,
        customer: action.payload.data,
        success: false,
      };
    case GET_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        loading: false,
        customers: state.customers.map((item) =>
          item._id === action.payload.data._id
            ? (item = action.payload.data)
            : item
        ),
      };
    case UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        loading: false,
        customers: state.customers.filter(
          (item) => item._id !== action.payload
        ),
        success: false,
      };
    case DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case "RESET_CUSTOMER": {
      return {
        ...state,
        loading: false,
        customer: null,
        error: null,
        success: false,
      };
    }

    default:
      return state;
  }
};

export default customerReducer;
