export const CREATE_PERSONNEL_PRICE_LIST = "CREATE_PERSONNEL_PRICE_LIST";
export const CREATE_PERSONNEL_PRICE_LIST_REQUEST = "CREATE_PERSONNEL_PRICE_LIST_REQUEST";
export const CREATE_PERSONNEL_PRICE_LIST_FAIL = "CREATE_PERSONNEL_PRICE_LIST_FAIL";

export const UPDATE_PERSONNEL_PRICE_LIST = "UPDATE_PERSONNEL_PRICE_LIST";
export const UPDATE_PERSONNEL_PRICE_LIST_REQUEST = "UPDATE_PERSONNEL_PRICE_LIST_REQUEST";
export const UPDATE_PERSONNEL_PRICE_LIST_FAIL = "UPDATE_PERSONNEL_PRICE_LIST_FAIL";

export const DELETE_PERSONNEL_PRICE_LIST = "DELETE_PERSONNEL_PRICE_LIST";
export const DELETE_PERSONNEL_PRICE_LIST_REQUEST = "DELETE_PERSONNEL_PRICE_LIST_REQUEST";
export const DELETE_PERSONNEL_PRICE_LIST_FAIL = "DELETE_PERSONNEL_PRICE_LIST_FAIL";

export const GET_ALL_PERSONNEL_PRICE_LIST = "GET_ALL_PERSONNEL_PRICE_LIST";
export const GET_ALL_PERSONNEL_PRICE_LIST_REQUEST = "GET_ALL_PERSONNEL_PRICE_LIST_REQUEST";
export const GET_ALL_PERSONNEL_PRICE_LIST_FAIL = "GET_ALL_PERSONNEL_PRICE_LIST_FAIL";

export const GET_PERSONNEL_PRICE_LIST = "GET_PERSONNEL_PRICE_LIST";
export const GET_PERSONNEL_PRICE_LIST_REQUEST =
  "GET_PERSONNEL_PRICE_LIST_REQUEST";
export const GET_PERSONNEL_PRICE_LIST_FAIL = "GET_PERSONNEL_PRICE_LIST_FAIL";
export const RESET_PERSONNEL_PRICE_LIST = "RESET_PERSONNEL_PRICE_LIST";

// EQUIPMENT
export const CREATE_EQUIPMENT_PRICE_LIST = "CREATE_EQUIPMENT_PRICE_LIST";
export const CREATE_EQUIPMENT_PRICE_LIST_REQUEST =
  "CREATE_EQUIPMENT_PRICE_LIST_REQUEST";
export const CREATE_EQUIPMENT_PRICE_LIST_FAIL =
  "CREATE_EQUIPMENT_PRICE_LIST_FAIL";

export const UPDATE_EQUIPMENT_PRICE_LIST = "UPDATE_EQUIPMENT_PRICE_LIST";
export const UPDATE_EQUIPMENT_PRICE_LIST_REQUEST =
  "UPDATE_EQUIPMENT_PRICE_LIST_REQUEST";
export const UPDATE_EQUIPMENT_PRICE_LIST_FAIL =
  "UPDATE_EQUIPMENT_PRICE_LIST_FAIL";

export const DELETE_EQUIPMENT_PRICE_LIST = "DELETE_EQUIPMENT_PRICE_LIST";
export const DELETE_EQUIPMENT_PRICE_LIST_REQUEST =
  "DELETE_EQUIPMENT_PRICE_LIST_REQUEST";
export const DELETE_EQUIPMENT_PRICE_LIST_FAIL =
  "DELETE_EQUIPMENT_PRICE_LIST_FAIL";

export const GET_ALL_EQUIPMENT_PRICE_LIST = "GET_ALL_EQUIPMENT_PRICE_LIST";
export const GET_ALL_EQUIPMENT_PRICE_LIST_REQUEST =
  "GET_ALL_EQUIPMENT_PRICE_LIST_REQUEST";
export const GET_ALL_EQUIPMENT_PRICE_LIST_FAIL =
  "GET_ALL_EQUIPMENT_PRICE_LIST_FAIL";

export const GET_EQUIPMENT_PRICE_LIST = "GET_EQUIPMENT_PRICE_LIST";
export const GET_EQUIPMENT_PRICE_LIST_REQUEST =
  "GET_EQUIPMENT_PRICE_LIST_REQUEST";
export const GET_EQUIPMENT_PRICE_LIST_FAIL = "GET_EQUIPMENT_PRICE_LIST_FAIL";
export const RESET_EQUIPMENT_PRICE_LIST = "RESET_EQUIPMENT_PRICE_LIST";

// MOBILIZATION
export const CREATE_MOBILIZATION_PRICE_LIST = "CREATE_MOBILIZATION_PRICE_LIST";
export const CREATE_MOBILIZATION_PRICE_LIST_REQUEST = "CREATE_MOBILIZATION_PRICE_LIST_REQUEST";
export const CREATE_MOBILIZATION_PRICE_LIST_FAIL = "CREATE_MOBILIZATION_PRICE_LIST_FAIL";

export const UPDATE_MOBILIZATION_PRICE_LIST = "UPDATE_MOBILIZATION_PRICE_LIST";
export const UPDATE_MOBILIZATION_PRICE_LIST_REQUEST = "UPDATE_MOBILIZATION_PRICE_LIST_REQUEST";
export const UPDATE_MOBILIZATION_PRICE_LIST_FAIL = "UPDATE_MOBILIZATION_PRICE_LIST_FAIL";

export const DELETE_MOBILIZATION_PRICE_LIST = "DELETE_MOBILIZATION_PRICE_LIST";
export const DELETE_MOBILIZATION_PRICE_LIST_REQUEST = "DELETE_MOBILIZATION_PRICE_LIST_REQUEST";
export const DELETE_MOBILIZATION_PRICE_LIST_FAIL = "DELETE_MOBILIZATION_PRICE_LIST_FAIL";

export const GET_ALL_MOBILIZATION_PRICE_LIST = "GET_ALL_MOBILIZATION_PRICE_LIST";
export const GET_ALL_MOBILIZATION_PRICE_LIST_REQUEST = "GET_ALL_MOBILIZATION_PRICE_LIST_REQUEST";
export const GET_ALL_MOBILIZATION_PRICE_LIST_FAIL = "GET_ALL_MOBILIZATION_PRICE_LIST_FAIL";

export const GET_MOBILIZATION_PRICE_LIST = "GET_MOBILIZATION_PRICE_LIST";
export const GET_MOBILIZATION_PRICE_LIST_REQUEST = "GET_MOBILIZATION_PRICE_LIST_REQUEST";
export const GET_MOBILIZATION_PRICE_LIST_FAIL = "GET_MOBILIZATION_PRICE_LIST_FAIL";
export const RESET_MOBILIZATION_PRICE_LIST = "RESET_MOBILIZATION_PRICE_LIST";

// MATERIAL
export const CREATE_MATERIAL_PRICE_LIST = "CREATE_MATERIAL_PRICE_LIST";
export const CREATE_MATERIAL_PRICE_LIST_REQUEST = "CREATE_MATERIAL_PRICE_LIST_REQUEST";
export const CREATE_MATERIAL_PRICE_LIST_FAIL = "CREATE_MATERIAL_PRICE_LIST_FAIL";

export const UPDATE_MATERIAL_PRICE_LIST = "UPDATE_MATERIAL_PRICE_LIST";
export const UPDATE_MATERIAL_PRICE_LIST_REQUEST = "UPDATE_MATERIAL_PRICE_LIST_REQUEST";
export const UPDATE_MATERIAL_PRICE_LIST_FAIL = "UPDATE_MATERIAL_PRICE_LIST_FAIL";

export const DELETE_MATERIAL_PRICE_LIST = "DELETE_MATERIAL_PRICE_LIST";
export const DELETE_MATERIAL_PRICE_LIST_REQUEST = "DELETE_MATERIAL_PRICE_LIST_REQUEST";
export const DELETE_MATERIAL_PRICE_LIST_FAIL = "DELETE_MATERIAL_PRICE_LIST_FAIL";

export const GET_ALL_MATERIAL_PRICE_LIST = "GET_ALL_MATERIAL_PRICE_LIST";
export const GET_ALL_MATERIAL_PRICE_LIST_REQUEST = "GET_ALL_MATERIAL_PRICE_LIST_REQUEST";
export const GET_ALL_MATERIAL_PRICE_LIST_FAIL = "GET_ALL_MATERIAL_PRICE_LIST_FAIL";

export const GET_MATERIAL_PRICE_LIST = "GET_MATERIAL_PRICE_LIST";
export const GET_MATERIAL_PRICE_LIST_REQUEST = "GET_MATERIAL_PRICE_LIST_REQUEST";
export const GET_MATERIAL_PRICE_LIST_FAIL = "GET_MATERIAL_PRICE_LIST_FAIL";
export const RESET_MATERIAL_PRICE_LIST = "RESET_MATERIAL_PRICE_LIST";
