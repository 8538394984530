import {
  GET_ALL_MODULES,
  CREATE_MODULES,
  UPDATE_MODULES,
  DELETE_MODULE,
  GET_MODULE,
  MODULE_ERROR,
  CREATE_SUBMODULES,
  UPDATE_SUBMODULE,
  GET_ALL_SUBMODULES,
  GET_ALL_MODULES_AND_SUBMODULES,
  GET_SUBMODULE,
  DELETE_SUBMODULE,
  SUBMODULE_ERROR,
} from "../types";

const initState = {
  allmodules: [],
  modules: [],
  module: {},
  submodules: [],
  submodule: {},
  submoduleError: null,
  error: null,
};

const modulesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_MODULES:
      return {
        ...state,
        modules: action.payload.data,
      };
    case GET_ALL_SUBMODULES:
      return {
        ...state,
        submodules: action.payload.data,
      };
    case GET_ALL_MODULES_AND_SUBMODULES:
      return {
        ...state,
        allmodules: action.payload.data,
      };
    case GET_MODULE:
      return {
        ...state,
        module: action.payload.data,
      };
    case GET_SUBMODULE:
      return {
        ...state,
        submodule: action.payload.data,
      };
    case CREATE_MODULES:
      return {
        ...state,
        module: action.payload,
      };
    case CREATE_SUBMODULES:
      return {
        ...state,
        submodule: action.payload,
      };
    case UPDATE_MODULES:
      return {
        ...state,
        modules: state.modules.map((mod) =>
          mod._id === action.payload.data._id
            ? (mod = action.payload.data)
            : mod
        ),
      };
    case UPDATE_SUBMODULE:
      return {
        ...state,
        submodules: state.submodules.map((submod) =>
          submod._id === action.payload.data._id
            ? (submod = action.payload.data)
            : submod
        ),
      };
    case DELETE_MODULE:
      return {
        ...state,
        modules: state.modules.filter((mod) => mod._id !== action.payload),
      };
    case DELETE_SUBMODULE:
      return {
        ...state,
        submodules: state.submodules.filter(
          (submod) => submod._id !== action.payload
        ),
      };
    case MODULE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SUBMODULE_ERROR:
      return {
        ...state,
        submoduleError: action.error,
      };

    default:
      return state;
  }
};

export default modulesReducer;
