import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    IconButton,
    Icon,
    Badge,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import {
    updateGRN,
    submitGRN,
    getGRN,
    getAllGRNS,
} from "../../../../actions/inventoryActions";
import { formatAmount, amountToWords } from "../../../../utils";
import InvoiceViewModal from "../../../pages/quality/modals/InvoiceModal";


const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    vendorName: "",
    vendorEmail: "",
    purchaseOrderNo: "",
    date: "",
    totalCost:"",
    items: [{
        description: "",
        quantityNeeded: "",
        cost: "",
        total: "",
    }],
    invoices:[{
        description: "",
        invoice: "",
    }],
};

const EditGRN = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [theUrl, setTheUrl] = useState("");
    const [openDoc, setOpenDoc] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, grn } = useSelector((state) => state.grn);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllGRNS());
        dispatch(getGRN(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            vendorName: (grn && grn.vendorName) || "",
            vendorEmail: (grn && grn.vendorEmail) || "",
            purchaseOrderNo: (grn && grn.purchaseOrderNo) || "",
            employee: (grn && grn.employee) || "",
            division: (grn && grn.division) || "",
            date: (grn && moment(grn.date).format("l")) || "",
            items: (grn && grn.items) || [],
            invoices: (grn && grn.invoices) || [],
            status: (grn && grn.status) || "",
            totalCost: (grn && grn.totalCost) || "",
            documentNo: (grn && grn.documentNo) || "",

            createdBy: (grn && grn.createdBy) || "",
            createdAt: (grn && grn.createdAt) || "",

            submittedBy: (grn && grn.submittedBy) || "",
            submittedAt: (grn && grn.submittedAt) || "",

            approvedBy: (grn && grn.approvedBy) || "",
            approvedAt: (grn && grn.approvedAt) || "",
            approveComment: (grn && grn.approveComment) || "",
            
            rejectedBy: (grn && grn.rejectedBy) || "",
            rejectedAt: (grn && grn.rejectedAt) || "",
            rejectComment: (grn && grn.rejectComment) || "",

            acceptedBy: (grn && grn.acceptedBy) || "",
            acceptedAt: (grn && grn.acceptedAt) || "",
            acceptComment: (grn && grn.acceptComment) || "",

            declinedBy: (grn && grn.declinedBy) || "",
            declinedAt: (grn && grn.declinedAt) || "",
            declineComment: (grn && grn.declineComment) || "",
        }));
    }, [grn]);
    

    const totalSum = React.useMemo(() => {
        if(state.items.length > 0){
            return state.items.reduce((sum, item) => sum + item.total, 0);
        }
      }, [state.items]);


    useEffect(()=> {
        setState((prev) => ({
            ...prev,
            totalCost : totalSum,
        }));
    },[totalSum]);

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            path: "inventory/grn/view",
            id,
        };

        await dispatch(submitGRN(data));
        await dispatch(getAllGRNS);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Goods Received Note Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/inventory/grn");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            id
        }

        await dispatch(updateGRN(data));
        await dispatch(getGRN(id));
        setTimeout(async () => {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Goods Received Note Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleOpenDoc = (url) => {
        setTheUrl(null);
        if(url){
            setTheUrl(url);
            setOpenDoc(true);
        }
    };
    
    const handleCloseDoc = () => {
        setOpenDoc(false);
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft")) : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <InvoiceViewModal {...{ openDoc, handleCloseDoc, docs: theUrl }} />

                <Typography variant="overline" className={classes.title}>
                    Update Goods Received Note
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/inventory/grn")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Paper variant="outlined" className={classes.status}>
                            <Typography variant="overline" className={classes.statusTitle}>
                                Document No.
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            {loading ? "Loading..." : state.documentNo}
                        </Paper>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ marginBottom: 20 }}
                >
                    <Hidden smDown>
                        <Grid item></Grid>
                    </Hidden>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : state.status === "accepted"
                                            ? classes.statusGreen
                                            : (state.status === "granted" || state.status === "completed")
                                                ? classes.statusDarkGreen
                                                : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmitHandler}>
                    <div>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    disabled
                                    label="Employee Name"
                                    name="employee"
                                    value={state.employee?.userName || ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    disabled
                                    label="Date Created"
                                    name="employee"
                                    value={state.date || ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    disabled
                                    label="Division/Subdivision"
                                    name="employee"
                                    value={state.division?.name || ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Vendor Name"
                                    name="vendorName"
                                    value={state.vendorName || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Vendor Email"
                                    required
                                    name="vendorEmail"
                                    value={state.vendorEmail || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Purchase Order Number"
                                    required
                                    name="purchaseOrderNo"
                                    value={state.purchaseOrderNo || ""}
                                    disabled
                                />
                            </Grid>
                        </Grid>

                        <Typography variant="overline" className={classes.title2}>
                            Attached Invoices
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {(state.invoices && state.invoices.length > 0) ? state.invoices.map((sinvoice, i) => (
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.grid}
                                    key={i}
                                >
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Description of Invoice"
                                            name="description"
                                            value={sinvoice.description || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <IconButton
                                            disabled={sinvoice.invoice === null}
                                            onClick={() => handleOpenDoc(sinvoice.invoice)}
                                        >
                                            <Badge
                                                color="secondary"
                                                badgeContent={sinvoice.invoice ? 1 : null}
                                            >
                                                <AttachFileIcon />
                                            </Badge>
                                        </IconButton>
                                    </Grid>
                            </Grid>
                            )) : <>
                                <Typography variant="overline" className={classes.title2}>
                                    No Attached Invoice
                                </Typography>
                            </>}
                        </Paper>

                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Items
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.items.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Description"
                                                type="text"
                                                name="description"
                                                value={item.description}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity Ordered"
                                                type="text"
                                                name="quantityOrdered"
                                                value={item.quantityOrdered || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity Delivered"
                                                placeholder="Quantity Delivered"
                                                type="text"
                                                name="quantityDelivered"
                                                value={item.quantityDelivered || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                label="Unit Cost"
                                                fullWidth
                                                required
                                                type="number"
                                                name="cost"
                                                value={item.cost || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                label="Total Cost"
                                                fullWidth
                                                required
                                                type="number"
                                                name="total"
                                                value={`${item.total}`  || ""}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Paper>

                            <Grid container spacing={2} className={classes.grid}>
                                <Grid item xs={12} md={4} />
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Amount in Words"
                                        name="amountInWords"
                                        value={`${amountToWords(state.totalCost || 0)}` || "N/A"}
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        label="Total Cost"
                                        name="totalCost"
                                        value={`${formatAmount(state.totalCost, "en-NG") || 0}` || ""}
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </>

                        <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                            style={{ marginBottom: 5 }}
                        >
                            <Grid item>
                                <Typography variant="overline" className={classes.title2}>
                                    Audit Trail
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Icon className="fas fa-clipboard-list" color="disabled" />
                            </Grid>
                        </Grid>
                        <Paper variant="outlined" square className={classes.paper}>
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Created By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.createdBy && state.createdBy?.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.createdAt}</span>
                                    </Paper>
                                </Grid>
                                
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Prepared By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.submittedAt}</span>
                                    </Paper>
                                </Grid>
                                {(state.rejectedAt && state.rejectedBy) && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Asset Manager Reject</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.rejectedAt}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.auditTitle}>Comment</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.rejectComment}</span>
                                            </Paper>
                                        </Grid>
                                    </>
                                )}
                                {(state.declinedAt && state.declinedBy) && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Finance Manager Decline</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.declinedAt}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.auditTitle}>Comment</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.declineComment}</span>
                                            </Paper>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>

                        {isCanEdit ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleSave}
                                        >
                                            {loading ? "Loading..." : "Save Changes"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            onClick={handleSubmitHandler}
                                            disabled={loading}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}
                    </div>
                </form>
            </MuiPickersUtilsProvider>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditGRN;
