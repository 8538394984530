import * as leadTypes from "../../types/leadsType";

const initialState = {
  error: null,
  leads: [],
  lead: null,
  success: false,
  loading: false,
};

const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case leadTypes.CREATE_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadTypes.CREATE_LEAD:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case leadTypes.CREATE_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case leadTypes.GET_LEADS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadTypes.GET_LEADS:
      return {
        ...state,
        loading: false,
        leads: action.payload,
      };
    case leadTypes.GET_LEADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case leadTypes.GET_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadTypes.GET_LEAD:
      return {
        ...state,
        loading: false,
        lead: action.payload,
      };
    case leadTypes.GET_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case leadTypes.UPDATE_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadTypes.UPDATE_LEAD:
      return {
        ...state,
        loading: false,
        leads: state.leads.map((item) =>
          item._id === action.payload._id ? (item = action.payload) : item
        ),
        success: true,
      };
    case leadTypes.UPDATE_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case leadTypes.UPDATE_LEAD_STAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadTypes.UPDATE_LEAD_STAGE:
      return {
        ...state,
        loading: false,
        leads: state.leads.map((item) =>
          item._id === action.payload._id ? (item = action.payload) : item
        ),
        success: true,
      };
    case leadTypes.UPDATE_LEAD_STAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case leadTypes.DELETE_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case leadTypes.DELETE_LEAD:
      return {
        ...state,
        loading: false,
        leads: state.leads.filter((item) => item._id !== action.payload),
      };
    case leadTypes.DELETE_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case leadTypes.RESET_LEADS:
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default leadReducer;
