import React from "react";

import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import {
  getCurrencyLists,
  createCurrency,
  getCurrencies,
} from "../../../../actions/currencyActions";
import Feedback from "../../../atoms/Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  code: "",
  rate: "",
};

const CreateCurrencyModal = ({
  openCreate,
  handleCloseCreate,
  getCurrencyLists,
  getCurrencies,
  createCurrency,
  currencyLists,
  error,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = React.useState(INIT_STATE);
  const [name, setName] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [allCurrency, setAllCurrency] = React.useState([]);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  React.useEffect(() => {
    setLoading((prev) => !prev);
    getCurrencyLists().then(() => {
      setLoading(false);
    });
  }, [getCurrencyLists]);

  React.useEffect(() => {
    setAllCurrency(currencyLists && currencyLists);
  }, [currencyLists]);

  const defaultProps = {
    options: allCurrency,
    getOptionLabel: (option) => option.name,
    renderOption: (option) => <React.Fragment>{option.name}</React.Fragment>,
  };

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const codeCallback = React.useCallback(() => {
    let code =
      currencyLists &&
      currencyLists
        .filter((cur) => cur.name === (name && name.name) && cur.code)
        .map((curr) => curr.code)[0];
    setState((prev) => ({
      ...prev,
      code,
    }));
  }, [currencyLists, name]);

  React.useEffect(() => {
    codeCallback();
  }, [codeCallback]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);

    let data = {
      ...state,
      name: name && name.name,
    };

    createCurrency(data)
      .then(() => {
        setLoading(false);
        setState(INIT_STATE);
        setName(null);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Currency Rate created successfully.`,
          success: true,
        });

        setTimeout(() => {
          dispatch(getCurrencies);
        }, 3000);
      })
      .catch(() => {
        setLoading(false);
        setState(INIT_STATE);
        setName(null);
        setFeed({
          loading: false,
          open: !feed.open,
          success: false,
        });
      })
      .finally(() => {
        setTimeout(() => {
          handleCloseCreate();
        }, 3000);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () => name && name;

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreate}
        onClose={handleCloseCreate}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Rate
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseCreate}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  {...defaultProps}
                  name="name"
                  value={name}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{option.name}</span>
                    </React.Fragment>
                  )}
                  disabled={allCurrency === undefined}
                  onChange={(event, newValue) => {
                    setName(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Currency Name"
                      variant="outlined"
                      value={name}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Code"
                  name="code"
                  value={state.code || ""}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Rate"
                  name="rate"
                  value={state.rate}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  disabled={!isValid() || loading}
                  className={classes.saveBtn}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currencyLists: state.currency?.currencyLists?.data,
  error: state.currency?.error,
});

const mapDispatchToProps = {
  getCurrencyLists,
  getCurrencies,
  createCurrency,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCurrencyModal);
