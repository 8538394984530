import * as types from "../types/offices";

const initState = {
    offices: [],
    office: {},
    loading: false,
    error: null,
    success: null,
};

const officeReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_OFFICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_OFFICE:
            return {
                ...state,
                loading: false,
                offices: action.payload,
            };
        case types.CREATE_OFFICE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_OFFICES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_OFFICES:
            return {
                ...state,
                loading: false,
                success: true,
                offices: action.payload.data,
            };
        case types.GET_ALL_OFFICES_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_OFFICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_OFFICE:
            return {
                ...state,
                loading: false,
                success: true,
                office: action.payload.data,
            };
        case types.GET_OFFICE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_OFFICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_OFFICE:
            return {
                ...state,
                loading: false,
                success: true,
                office: state.offices.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_OFFICE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_OFFICE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_OFFICE:
            return {
                ...state,
                loading: false,
                success: false,
                offices: state.offices.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_OFFICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.RESET_OFFICE:
            return {
                office: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default officeReducer;
