import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    Fab,
    DialogContent,
    Backdrop,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";

import Feedback from "../../../../atoms/Feedback";
import { getProfile } from "../../../../../actions/userActions";
import {
    createGatePass,
    getAllGatePasses
} from "../../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: "#444",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    purpose: "",
    projectName: "",
    movementSpecification: "",
    dateCreated: "",
    items: [{
        assetDescription: "",
        assetSerialNo: "",
        previousLocation: "",
        newLocation: "",
        quantity: 1,
        condition: "Good",
        toBeReturned: "Yes",
    }],
};

const CreateGatePassModal = ({ openCreate, handleCloseCreate, createGatePass }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [dateCreated] = useState(new Date());
    const [state, setState] = useState(INIT_STATE);

    const { profile } = useSelector((state) => state.user);
    const { loading, error } = useSelector((state) => state.gatepass);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile.userName,
            division: profile && profile.division && profile.division._id,
            dateCreated: moment(dateCreated).toDate(),
        }));
    }, [profile, dateCreated]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = () => {
        const data = {
            assetDescription: "",
            assetSerialNo: "",
            previousLocation: "",
            newLocation: "",
            quantity: 1,
            condition: "Good",
            toBeReturned: "Yes",
        };
        setState((prev) => ({
            ...prev,
            items: prev.items.concat([data]),
        }));
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleChangeItemsArray = (i) => (e) => {
        e.persist();
        const newItemsArray = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            items: newItemsArray,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(createGatePass(data));
        });

        if (res) {
            dispatch(getAllGatePasses());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "GatePass Request Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/inventory/gatepass/edit/${res.data._id}`);
                handleCloseCreate();
            }, 2000);
        } else {
            dispatch(getAllGatePasses());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "GatePass Request Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.employee ||
        !state.division ||
        !state.purpose ||
        !state.projectName ||
        !state.movementSpecification ||
        !state.dateCreated ||
        (state.items.length === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Request Gate Pass
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Employee Name"
                                            disabled
                                            name="employee"
                                            value={state.employee || ""}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Date"
                                            fullWidth
                                            disabled
                                            required
                                            name="dateCreated"
                                            inputVariant="outlined"
                                            value={dateCreated}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Project Name"
                                            name="projectName"
                                            value={state.projectName || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Purpose"
                                            name="purpose"
                                            value={state.purpose || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            component="fieldset"
                                        >
                                            <FormLabel>Movement Specification?</FormLabel>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    value="Outward"
                                                    control={<Radio />}
                                                    label="Outward"
                                                    name="movementSpecification"
                                                    onChange={handleChange}
                                                />
                                                <FormControlLabel
                                                    value="Transit"
                                                    control={<Radio />}
                                                    label="Transit"
                                                    name="movementSpecification"
                                                    onChange={handleChange}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* To be filled by the Requestor */}
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Choose Asset
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper}>
                                        {state.items.map((item, i) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                                key={i}
                                            >
                                                <Grid item xs={12} md={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        label="Asset Description"
                                                        type="text"
                                                        name="assetDescription"
                                                        value={item.assetDescription}
                                                        onChange={handleChangeItemsArray(i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        label="Asset Serial No"
                                                        type="text"
                                                        name="assetSerialNo"
                                                        value={item.assetSerialNo}
                                                        onChange={handleChangeItemsArray(i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        label="Previous Location"
                                                        type="text"
                                                        name="previousLocation"
                                                        value={item.previousLocation}
                                                        onChange={handleChangeItemsArray(i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        label="New Location"
                                                        type="text"
                                                        name="newLocation"
                                                        value={item.newLocation}
                                                        onChange={handleChangeItemsArray(i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        label="Quantity"
                                                        type="text"
                                                        name="quantity"
                                                        value={item.quantity}
                                                        onChange={handleChangeItemsArray(i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <InputLabel id="condition">
                                                            Condition
                                                        </InputLabel>
                                                        <Select
                                                            labelId="condition"
                                                            required
                                                            value={item.condition || ""}
                                                            name="condition"
                                                            onChange={handleChangeItemsArray(i)}
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            <MenuItem value="Good">Good</MenuItem>
                                                            <MenuItem value="Faulty">Faulty</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <InputLabel id="toBeReturned">
                                                            To Be Returned?
                                                        </InputLabel>
                                                        <Select
                                                            required
                                                            labelId="toBeReturned"
                                                            value={item.toBeReturned || ""}
                                                            name="toBeReturned"
                                                            onChange={handleChangeItemsArray(i)}
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            <MenuItem value="Yes">Yes</MenuItem>
                                                            <MenuItem value="No">No</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <Fab
                                                        color="secondary"
                                                        onClick={() => handleRemoveItemsArray(i)}
                                                    >
                                                        <CloseIcon />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        ))}

                                        <Button
                                            variant="outlined"
                                            color="default"
                                            onClick={() =>
                                                handleAddItemsArray(state.items.length + 1)
                                            }
                                            endIcon={<AddIcon fontSize="large" />}
                                        >
                                            Insert Item
                                        </Button>
                                    </Paper>
                                </>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createGatePass })(CreateGatePassModal);