import axios from "axios";
import {
  CREATE_SERVICE_TYPE,
  CREATE_SERVICE_TYPE_FAIL,
  CREATE_SERVICE_TYPE_REQUEST,
  UPDATE_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE_FAIL,
  UPDATE_SERVICE_TYPE_REQUEST,
  GET_SERVICE_TYPES,
  GET_SERVICE_TYPES_REQUEST,
  GET_SERVICE_TYPES_FAIL,
  GET_SERVICE_TYPE,
  GET_SERVICE_TYPE_FAIL,
  GET_SERVICE_TYPE_REQUEST,
  DELETE_SERVICE_TYPE,
  DELETE_SERVICE_TYPE_FAIL,
  DELETE_SERVICE_TYPE_REQUEST,
  GET_SERVICE_TYPE_BY_DIVISION,
  GET_SERVICE_TYPE_BY_DIVISION_FAIL,
  GET_SERVICE_TYPE_BY_DIVISION_REQUEST,
} from "../types";

const token = localStorage.getItem("token:key");

const URL = `/api/v1/sales/service`;

export const createServiceType = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SERVICE_TYPE_REQUEST,
    });
    const { data } = await axios.post(URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_SERVICE_TYPE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SERVICE_TYPE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateServiceType = (body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SERVICE_TYPE_REQUEST,
    });
    const { data } = await axios.put(`${URL}/${body.id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_SERVICE_TYPE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_SERVICE_TYPE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getServiceTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SERVICE_TYPES_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_SERVICE_TYPES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SERVICE_TYPES_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getServiceTypeById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SERVICE_TYPE_REQUEST,
    });
    const { data } = await axios.get(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_SERVICE_TYPE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SERVICE_TYPE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteServiceType = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SERVICE_TYPE_REQUEST,
    });
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_SERVICE_TYPE,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SERVICE_TYPE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getServiceTypesByDivision = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SERVICE_TYPE_BY_DIVISION_REQUEST,
    });
    const { data } = await axios.get(`${URL}/division/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_SERVICE_TYPE_BY_DIVISION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SERVICE_TYPE_BY_DIVISION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
