import {
  CREATE_CONTACT_PERSON,
  CREATE_CONTACT_PERSON_FAIL,
  CREATE_CONTACT_PERSON_REQUEST,
  UPDATE_CONTACT_PERSON,
  UPDATE_CONTACT_PERSON_FAIL,
  UPDATE_CONTACT_PERSON_REQUEST,
  GET_CONTACT_PERSON,
  GET_CONTACT_PERSON_REQUEST,
  GET_CONTACT_PERSON_FAIL,
  GET_CONTACT_PERSON_BY_CUSTOMER,
  GET_CONTACT_PERSON_BY_CUSTOMER_REQUEST,
  GET_CONTACT_PERSON_BY_CUSTOMER_FAIL,
  GET_CONTACT_PERSONS,
  GET_CONTACT_PERSONS_REQUEST,
  GET_CONTACT_PERSONS_FAIL,
  DELETE_CONTACT_PERSON,
  DELETE_CONTACT_PERSON_REQUEST,
  DELETE_CONTACT_PERSON_FAIL,
} from "../../types";

const initState = {
  contactPersons: [],
  contactPerson: null,
  loading: false,
  error: null,
  success: false,
};

const contactPersonReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_CONTACT_PERSON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CONTACT_PERSON:
      return {
        ...state,
        loading: false,
        // contactPerson: action.payload.data,
        success: true,
      };
    case CREATE_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_CONTACT_PERSON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CONTACT_PERSON:
      return {
        ...state,
        loading: false,
        contactPersons: state.contactPersons.map((item) =>
          item._id === action.payload.data._id
            ? (item = action.payload.data)
            : item
        ),
      };
    case UPDATE_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CONTACT_PERSONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTACT_PERSONS:
      return {
        ...state,
        loading: false,
        contactPersons: action.payload.data,
      };
    case GET_CONTACT_PERSONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CONTACT_PERSON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTACT_PERSON:
      return {
        ...state,
        loading: false,
        contactPerson: action.payload.data,
      };
    case GET_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CONTACT_PERSON_BY_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CONTACT_PERSON_BY_CUSTOMER:
      return {
        ...state,
        loading: false,
        contactPersons: action.payload.data,
      };
    case GET_CONTACT_PERSON_BY_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CONTACT_PERSON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CONTACT_PERSON:
      return {
        ...state,
        loading: false,
        contactPersons: state.contactPersons.filter(
          (item) => item._id !== action.payload
        ),
      };
    case DELETE_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "RESET_CONTACT_PERSON": {
      return {
        ...state,
        loading: false,
        success: false,
        error: null,
        contactPerson: null,
      };
    }

    default:
      return state;
  }
};

export default contactPersonReducer;
