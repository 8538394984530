import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  Typography,
  makeStyles,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";
import {
  getAnnualBudgetById,
  declineSuppAnnualBudgetLine,
} from "../../../../actions/annualAction";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const SupplementaryBudgetLineDeclineModal = ({
        openSuppDecline,
        handleCloseSuppDecline,
        iid,
        id,

        declineSuppAnnualBudgetLine,
        getAnnualBudgetById,
        error,
    }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [comment, setComment] = React.useState("");
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleChange = (e) => {
      e.persist();
      setComment(e.target.value);
  };
  
    const handleDecline = () => {
        setLoading((prev) => !prev);
        const data = {
          id: iid, comment
        }
        return new Promise((resolve, reject) => {
            resolve(declineSuppAnnualBudgetLine(data));
        })
        .then((doc) => {
            if (doc.success) {
                setLoading(false);
                setFeed({
                    loading: false,
                    open: !feed.open,
                    message: `You have successfully declined this supplementary budget line.`,
                    success: true,
                });
                dispatch(getAnnualBudgetById(id));
                handleCloseSuppDecline();
            }
        })
        .catch((err) => {
            setLoading(false);

            handleCloseSuppDecline();
        })
        .finally(() => {
            handleCloseSuppDecline();

            setTimeout(() => {
            history.push(`/budget/annual/view/${id}`);
            }, 2000);
        });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message || "You have successfully declined this budget."
          }
        />
      )}
      <Dialog
        open={openSuppDecline}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseSuppDecline}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {" "}
                {"Decline Supplementary Budget Line?"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseSuppDecline}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="overline" className={classes.title3}>
              By Clicking 'Decline', it means you're NOT satisfied with this Supplementary Budget Line. Please add a comment describing why you want to decline it.
            </Typography>
          </DialogContentText>
          <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <TextField
                  id="comment"
                  name="comment"
                  label="Comment"
                  value={comment}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseSuppDecline}
              color="secondary"
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDecline}
              variant="contained"
              color="primary"
              disabled={loading || !comment}
              className={classes.saveBtn}
            >
              {loading ? "Loading..." : "Decline Line"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.annualBudget.error,
});

const mapDispatchToProps = {
    declineSuppAnnualBudgetLine,
    getAnnualBudgetById,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplementaryBudgetLineDeclineModal);
