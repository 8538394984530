import * as types from "../../types/quality";

const initState = {
    workcompletions: [],
    workcompletions_accepted: [],
    workcompletion: {},
    error: null,
    success: false,
    loading: false,
};

const workcompletionReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_WORK_COMPLETIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_WORK_COMPLETIONS:
            return {
                ...state,
                loading: false,
                success: true,
                workcompletions: action.payload.data,
            };
        case types.GET_ALL_WORK_COMPLETIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ACCEPTED_WORK_COMPLETIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ACCEPTED_WORK_COMPLETIONS:
            return {
                ...state,
                loading: false,
                success: true,
                workcompletions_accepted: action.payload.data,
            };
        case types.GET_ACCEPTED_WORK_COMPLETIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                success: true,
                workcompletion: action.payload.data,
            };
        case types.GET_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                success: true,
                workcompletion: state.workcompletions.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                success: false,
                workcompletions: state.workcompletions.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                workcompletion: action.payload.data,
            };
        case types.SUBMIT_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.INSPECT_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.INSPECT_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                workcompletion: action.payload.data,
            };
        case types.INSPECT_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.APPROVE_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                workcompletion: action.payload.data,
            };
        case types.APPROVE_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DISAPPROVE_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DISAPPROVE_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                workcompletion: action.payload.data,
            };
        case types.DISAPPROVE_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                workcompletion: action.payload.data,
            };
        case types.REJECT_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                workcompletion: action.payload.data,
            };
        case types.ACCEPT_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_WORK_COMPLETION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_WORK_COMPLETION:
            return {
                ...state,
                loading: false,
                workcompletion: action.payload.data,
            };
        case types.DECLINE_WORK_COMPLETION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_WORK_COMPLETION:
            return {
                workcompletion: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default workcompletionReducer;
