import * as salesCallType from "../../types/salesCallType";

const initialState = {
  salesCalls: [],
  salesCall: null,
  success: false,
  loading: false,
  error: null,
  url: null,
};

const salesCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case salesCallType.GET_ALL_SALES_CALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case salesCallType.GET_ALL_SALES_CALL:
      return {
        ...state,
        loading: false,
        salesCalls: action.payload,
      };
    case salesCallType.GET_ALL_SALES_CALL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case salesCallType.GET_SALES_CALL_BY_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case salesCallType.GET_SALES_CALL_BY_LEAD:
      return {
        ...state,
        loading: false,
        salesCalls: action.payload,
      };
    case salesCallType.GET_SALES_CALL_BY_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case salesCallType.GET_SALES_CALL_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case salesCallType.GET_SALES_CALL_BY_ID:
      return {
        ...state,
        loading: false,
        salesCall: action.payload,
      };
    case salesCallType.GET_SALES_CALL_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case salesCallType.CREATE_SALES_CALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case salesCallType.CREATE_SALES_CALL:
      return {
        ...state,
        loading: false,
        salesCall: action.payload,
        success: true,
      };
    case salesCallType.CREATE_SALES_CALL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case salesCallType.MARK_SALES_CALL_AS_DONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case salesCallType.MARK_SALES_CALL_AS_DONE:
      return {
        ...state,
        loading: false,
        salesCall: action.payload,
      };
    case salesCallType.MARK_SALES_CALL_AS_DONE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case salesCallType.UPDATE_SALES_CALL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case salesCallType.UPDATE_SALES_CALL:
      return {
        ...state,
        loading: false,
        salesCalls: state.salesCalls.map((item) =>
          item._id === action.payload._id ? item === action.payload : item
        ),
        success: true,
      };
    case salesCallType.UPDATE_SALES_CALL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case salesCallType.UPLOAD_SALES_CALL_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case salesCallType.UPLOAD_SALES_CALL_DOCUMENT:
      return {
        ...state,
        loading: false,
        url: action.payload,
      };
    case salesCallType.UPLOAD_SALES_CALL_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case salesCallType.DELETE_SALES_CALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case salesCallType.DELETE_SALES_CALL:
      return {
        ...state,
        loading: false,
        salesCalls: state.salesCalls.filter(
          (item) => item._id !== action.payload
        ),
      };
    case salesCallType.DELETE_SALES_CALL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case salesCallType.RESET_SALES_CALL:
      return {
        ...state,
        success: false,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default salesCallReducer;
