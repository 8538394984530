import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const CUEGuard = ({ children, profile, ...rest }) => {
    const token = localStorage.getItem("token:key");

    let CUEGuard = profile ? profile.division && (profile.division.name === "Customer Experience") : false;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token && CUEGuard ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: {
                                from: location,
                            },
                        }}
                    />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    profile: state.user.profile,
});

export default connect(mapStateToProps, {})(CUEGuard);
