import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    section: {
        margin: 10,
        paddingHorizontal: 20,
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    text: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
    },
    boldText: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: 800,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
    },
    italicText: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: 800,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
        fontStyle: "italic"
    },
    caps: {
        fontWeight: 800,
        fontSize: 14,
        textTransform: "uppercase",
    },
    title: {
        marginBottom: 15,
    },
    title2: {
        marginBottom: 50,
    },
    textCenter: {
        textAlign: "center",
        fontSize: 12,
        lineHeight: 1.5
    }
});

export const PageThree = ({ quote }) => {
    return (
        <>
            <View break>
                <View style={styles.section}>
                    <Text style={styles.boldText}><strong>Notes to Pricing:</strong></Text>
                    {quote && quote.noteToPricing ? (
                        <>
                            <Text style={styles.text}>{quote && quote.noteToPricing ? quote.noteToPricing : null}</Text>
                        </>
                    ) : null}

                </View>
            </View>
        </>
    )
}

export default PageThree;
