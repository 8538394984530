import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
    ButtonGroup,
    TextField,
    makeStyles,
    Typography,
    Grid,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";
import {
    declineHodCashRetirement,
    getCashAdvanceById,
} from "../../../../actions/cashAdvanceAction";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    textField: {
        marginBottom: 20,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeclineCashRetirement = ({
    openHodDecline,
    handleCloseHodDecline,
    id,
    declineHodCashRetirement,
    getCashAdvanceById,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [reason, setReason] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleChange = (e) => {
        setReason(e.target.value);
    };

    const handleHodDecline = () => {
        setLoading((prev) => !prev);
        const data = {
            comment: reason,
            id,
            path: "cashadvance/view",
        };
        declineHodCashRetirement(data)
            .then((doc) => {
                if (!doc.success) {
                    setLoading(false);
                    setFeed({
                        loading: false,
                        open: !feed.open,
                        message: `An error has occurred, please contact admin. Failed to decline cash retirement.`,
                        success: false,
                    });
                } else {
                    setLoading(false);
                    setFeed({
                        loading: false,
                        open: !feed.open,
                        message: `You have successfully declined this cash retirement, an email has been sent to the initiator for modification.`,
                        success: true,
                    });
                    dispatch(getCashAdvanceById(id));
                    handleCloseHodDecline();
                }
            })
            .catch(() => {
                console.error(`An error has occurred. Contact admin`);
            })
            .finally(() => {
                handleCloseHodDecline();
            });
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <Dialog
                open={openHodDecline}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseHodDecline}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                {"Decline Cash Retirement?"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseHodDecline}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="overline" className={classes.title3}>
                        Note: By Clicking Decline, it means you are not okay with this Cash Retirement.
                    </Typography>
                    <TextField
                        label="Comment"
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        value={reason}
                        onChange={handleChange}
                        helperText="State your reason for Decline."
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button
                            onClick={handleCloseHodDecline}
                            color="secondary"
                            className={classes.saveBtn}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleHodDecline}
                            color="primary"
                            disabled={!reason || loading}
                            className={classes.saveBtn}
                            variant="contained"
                            endIcon={
                                loading ? <CircularProgress color="primary" size={20} /> : null
                            }
                        >
                            {loading ? "Loading..." : "Decline"}
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapDispatchToProps = {
    declineHodCashRetirement,
    getCashAdvanceById,
};

export default connect(null, mapDispatchToProps)(DeclineCashRetirement);
