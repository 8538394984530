import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Backdrop,
  IconButton,
  useTheme,
  FormControlLabel,
  Chip,
  Checkbox,
  Menu,
  MenuItem,
  Icon,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from 'clsx';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Fade from "@material-ui/core/Fade";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchComponent from "../../SearchComponent";

import { connect, useDispatch } from "react-redux";
import { deleteUser, getUsers } from "../../../actions/userActions";
import EditUserModal from "./modals/EditUserModal";
import CreateUserModal from "./modals/CreateUserModal";

import GroupsModal from "./modals/GroupsModal";
import Enable2FAModal from "./modals/Enable2FAModal";
import Disable2FAModal from "./modals/Disable2FAModal";
import PasswordLinkModal from "./modals/PasswordLinkModal";
import DeleteUserModal from "./modals/DeleteUserModal";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  actionBtn: {
    // background: green[500],
    background: "#00695f",
    color: "#fff",
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    "&:hover": {
      background: "#014e47",
    },
  },
  marginBottom: {
    marginBottom: 30,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
}));

const UsersList = ({ getUsers, users, deleteUser }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEnable, setOpenEnable] = useState(false);
  const [openDisable, setOpenDisable] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [allEmails, setAllEmails] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [filter, setFilter] = useState("");

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openGroups, setOpenGroups] = useState(false);
  const [getId, setGetId] = useState("");

  const [search, setSearch] = useState("");
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleSelectAllIds = (e) => {
    if (e.target.checked) {
      const ids = users && users.map((item) => item._id);
      const emails = users && users.map((item) => item.email);
      setUserIds(ids);
      setAllEmails(emails);
      return;
    }
    setUserIds([]);
    setAllEmails([]);
  };

  const handleResetUserIdsAndEmails = () => {
    setUserIds([]);
    setAllEmails([]);
  };

  const handleSelectIdAndEmail = (e, id, email) => {
    e.persist();

    setUserIds((prev) =>
      e.target.checked === false && prev.includes(id)
        ? prev.filter((acc) => acc !== id)
        : [...prev, id]
    );

    setAllEmails((prev) =>
      e.target.checked === false && prev.includes(email)
        ? prev.filter((acc) => acc !== email)
        : [...prev, email]
    );
  };

  // const handleTwoFactorCallback = React.useCallback(() => {
  //   setLoadTwoFa((prev) => !prev);
  //   const data = {
  //     isAuth: twoFactor,
  //     getId,
  //   };
  //   console.log(data);
  //   updateTwoFactor(data)
  //     .then(() => {
  //       setLoadTwoFa(false);

  //       dispatch(getUserById(getId));
  //     })
  //     .catch(() => console.error("Failed to update"));
  // }, [updateTwoFactor, twoFactor, dispatch, getUserById, getId]);

  // React.useEffect(() => {
  //   if (isFirstRun.current) {
  //     isFirstRun.current = false;
  //     return;
  //   }
  //   handleTwoFactorCallback();
  // }, [handleTwoFactorCallback]);

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleEditOpen = (id) => {
    setGetId(id);

    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getUsers().then(() => {
      setOpenBackdrop(false);
    });
  }, [getUsers]);

  const handleCreateOpen = () => {
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteUser = () => {
    setLoading((prev) => !prev);
    deleteUser(getId)
      .then((doc) => {
        if (!doc) {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `An error has occurred, please contact admin.`,
            success: false,
          });
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `User successfully deleted.`,
            success: true,
          });
          dispatch(getUsers());
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          // setLoading(false);
          // setFeed({
          //   loading: false,
          //   open: !feed.open,
          //   message: `An error has occurred, please contact admin.`,
          //   success: false,
          // });
        }
      })
      .finally(() => {
        handleCloseDelete();
      });
  };

  // const handleToggleTwoFA = (e) => {
  //   setTwoFactor(e.target.checked);
  // };

    // console.log(users);

  const filteredRows = () =>
    users === undefined
      ? []
      : users.filter((row) => {
        if (search !== "") {
          return (
            row.firstName.toLowerCase().indexOf(search.toLowerCase()) !==
            -1 ||
            row.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            row.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            (row.jobTitle &&
              row.jobTitle?.name
                ?.toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            row.employeeId.toLowerCase().indexOf(search.toLowerCase()) !==
            -1 ||
            (row.division &&
              row.division.name
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1)
          );
        } else if (filter !== "") {
          return row.active.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        } else {
          return row;
        }
      });

  const dataLength = Array(1000).length || filteredRows().length;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

  const isSelected = (id) => userIds.length > 0 && userIds.indexOf(id) !== -1;

  const handleOpenDropdown = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleOpenGroups = () => {
    setOpenGroups(true);
    handleCloseDropdown();
  };

  const handleCloseGroups = () => {
    setOpenGroups(false);
  };

  const handleOpenEnable = () => {
    setOpenEnable(true);
    handleCloseDropdown();
  };

  const handleCloseEnable = () => {
    setOpenEnable(false);
  };

  const handleOpenDisable = () => {
    setOpenDisable(true);
    handleCloseDropdown();
  };

  const handleCloseDisable = () => {
    setOpenDisable(false);
  };

  const handleOpenPasswordReset = () => {
    setOpenPassword(true);
    handleCloseDropdown();
  };

  const handleClosePasswordReset = () => {
    setOpenPassword(false);
  };

  const handleSelectFilter = (e, val) => {
    setFilter(val.substr(0, 20));
    handleCloseFilter();
  };
  const handleOpenFilter = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setFilterAnchorEl(null);
  };

  return (
    <div>
      <CreateUserModal
        openCreate={openCreate}
        handleCreateClose={handleCreateClose}
      />
      <GroupsModal {...{ openGroups, handleCloseGroups, userIds }} />
      <Enable2FAModal
        {...{
          openEnable,
          handleCloseEnable,
          userIds,
          handleResetUserIdsAndEmails,
        }}
      />
      <Disable2FAModal
        {...{
          openDisable,
          handleCloseDisable,
          userIds,
          handleResetUserIdsAndEmails,
        }}
      />
      <PasswordLinkModal
        {...{
          openPassword,
          handleClosePasswordReset,
          allEmails,
          handleResetUserIdsAndEmails,
        }}
      />
      <Typography variant="overline" className={classes.title}>
        Users List
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={handleCreateOpen}
            className={classes.createBtn}
          >
            Create
          </Button>

          {userIds.length > 0 && (
            <>
              <Button
                className={classes.actionBtn}
                color="default"
                variant="contained"
                onClick={handleOpenDropdown}
                endIcon={
                  Boolean(anchorEl) ? (
                    <Icon className="fas fa-chevron-up" />
                  ) : (
                    <Icon className="fas fa-chevron-down" />
                  )
                }
              >
                Actions
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseDropdown}
              >
                <MenuItem onClick={handleOpenEnable}>Activate 2FA</MenuItem>
                <MenuItem onClick={handleOpenDisable}>Deactivate 2FA</MenuItem>
                <MenuItem onClick={handleOpenPasswordReset}>
                  Password Link
                </MenuItem>
                <MenuItem onClick={handleOpenGroups}>Groups</MenuItem>
              </Menu>
            </>
          )}
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
                <IconButton
                    style={{ marginRight: 10, marginTop: 10 }}
                    onClick={handleOpenFilter}
                >
                    <FilterListIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={filterAnchorEl}
                    keepMounted
                    open={Boolean(filterAnchorEl)}
                    onClose={handleCloseFilter}
                    TransitionComponent={Fade}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                        All
                    </MenuItem>
                    <MenuItem onClick={(e) => handleSelectFilter(e, "true")}>
                        Active
                    </MenuItem>
                    <MenuItem onClick={(e) => handleSelectFilter(e, "false")}>
                        Inactive
                    </MenuItem>
                </Menu>
            </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleSelectAllIds}
                        checked={userIds.length > 0}
                        indeterminate={
                          userIds.length > 0 &&
                          userIds.length < filteredRows().length
                        }
                      />
                    }
                  />
                </TableCell>
                <TableCell>FirstName</TableCell>
                <TableCell>LastName</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Division</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>EmployeeId</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : filteredRows()
              ).map((row) => {
                const isItemSelected = isSelected(row._id);

                return (
                  <TableRow hover key={row._id} selected={isItemSelected}>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleSelectIdAndEmail(e, row._id, row.email)
                            }
                            // checked={userIds.includes(row._id)}
                            checked={isItemSelected}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.firstName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.lastName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.division && row.division.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.jobTitle?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.employeeId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        style={{ padding: 10 }}
                        label={row.active ? "Active" : "Disabled"}
                        variant="outlined"
                        className={clsx(
                          row.active ? classes.statusDarkGreen : classes.statusRed,
                          classes.chip
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditOpen(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDelete(row._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={20}
                    align="center"
                    style={{ color: "#616161" }}
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Delete Dialog */}
      <DeleteUserModal
        {...{ openDelete, handleCloseDelete, loading, handleDeleteUser }}
      />

      {/* Edit User Dialog */}
      <EditUserModal
        openEdit={openEdit}
        handleCloseEdit={handleCloseEdit}
        id={getId}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.user.users,
});

const mapDispatchToProps = {
  getUsers,
  deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
