import * as types from "../../types/purchasing";

const initState = {
    opms: [],
    opm: {},
    error: null,
    success: false,
    loading: false,
};

const openMarketReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_ALL_OPEN_MARKETS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_OPEN_MARKETS:
            return {
                ...state,
                loading: false,
                success: true,
                opms: action.payload.data,
            };
        case types.GET_ALL_OPEN_MARKETS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ONE_OPEN_MARKET_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ONE_OPEN_MARKET:
            return {
                ...state,
                loading: false,
                success: true,
                opm: action.payload.data,
            };
        case types.GET_ONE_OPEN_MARKET_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_OPEN_MARKET_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_OPEN_MARKET:
            return {
                ...state,
                loading: false,
                success: true,
                opm: state.opms.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_OPEN_MARKET_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_OPEN_MARKET_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_OPEN_MARKET:
            return {
                ...state,
                loading: false,
                success: false,
                opms: state.opms.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_OPEN_MARKET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_OPEN_MARKET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_OPEN_MARKET:
            return {
                ...state,
                loading: false,
                opm: action.payload.data,
            };
        case types.SUBMIT_OPEN_MARKET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
    

        default:
            return state;
    }
};

export default openMarketReducer;
