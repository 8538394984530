import {
  VENDOR_REGISTER,
  UPDATE_VENDOR_INFO,
  GET_VENDOR_INFO_BY_TOKEN,
  GET_VENDOR_PROFILE,
  UPLOAD_VENDOR_PHOTO,
  UPDATE_VENDOR_PROFILE,
  UPDATE_VENDOR_PASSWORD,
  GET_ALL_VENDORS,
  GET_ALL_VENDORS_REQUEST,
  GET_ALL_VENDORS_FAIL,
  GET_VENDOR_BY_ID,
  APPROVE_VENDOR,
  REJECT_VENDOR,
  BLACKLIST_VENDOR,
  CREATE_VENDOR,
  VENDOR_ERROR,
  CREATE_PRODUCT,
  GET_ALL_PRODUCTS,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT_BY_ID,
  DELETE_PRODUCT_BY_ID,
  GET_PRODUCTS_CATEGORY,
  GET_SERVICES_CATEGORY,
  REACTIVATE_VENDOR,
} from "../types";

const initState = {
  vendors: [],
  vendor: {},
  message: "",
  error: null,
  product: {},
  products: [],
  services: [],
  authError: null,
  loading: false,
};

const vendorsReducer = (state = initState, action) => {
  switch (action.type) {
    case VENDOR_REGISTER:
      return {
        ...state,
        message: action.payload,
      };
    case CREATE_VENDOR:
      return {
        ...state,
        vendors: [action.payload.data, ...state.vendors],
      };
    case UPDATE_VENDOR_INFO:
      return {
        ...state,
        message: action.payload,
      };
    case REACTIVATE_VENDOR:
      return {
        ...state,
        vendor: action.payload,
      };
    case GET_ALL_VENDORS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_VENDORS:
      return {
        ...state,
        loading: false,
        vendors: action.payload.data,
      };
    case GET_ALL_VENDORS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_VENDOR_INFO_BY_TOKEN:
      return {
        ...state,
        vendor: action.payload,
      };
    case GET_VENDOR_PROFILE:
      return {
        ...state,
        vendor: action.payload.data,
      };
    case GET_VENDOR_BY_ID:
      return {
        ...state,
        vendor: action.payload.data,
      };
    case UPLOAD_VENDOR_PHOTO:
      return {
        ...state,
      };
    case UPDATE_VENDOR_PROFILE:
      return {
        ...state,
        message: action.payload,
      };
    case UPDATE_VENDOR_PASSWORD:
      return {
        ...state,
      };
    case APPROVE_VENDOR:
      return {
        ...state,
        vendor: action.payload,
      };
    case REJECT_VENDOR:
      return {
        ...state,
        vendor: action.payload,
      };
    case BLACKLIST_VENDOR:
      return {
        ...state,
        vendor: action.payload,
      };
    case VENDOR_ERROR:
      return {
        ...state,
        authError: action.payload,
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        products: [action.payload.data, ...state.products],
      };
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload.data,
      };
    case GET_PRODUCTS_CATEGORY:
      return {
        ...state,
        products: action.payload.data,
      };
    case GET_SERVICES_CATEGORY:
      return {
        ...state,
        services: action.payload.data,
      };
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        product: action.payload.data,
      };
    case UPDATE_PRODUCT_BY_ID:
      return {
        ...state,
        products: state.products.map((prod) =>
          prod._id === action.payload._id ? (prod = action.payload) : prod
        ),
      };
    case DELETE_PRODUCT_BY_ID:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default vendorsReducer;
