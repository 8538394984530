import * as types from "../types/offices";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/inventory/office`;

//////////////////////////////////////////////
///////////////// Offices ////////////////////
//////////////////////////////////////////////
export const createOffice = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_OFFICE_REQUEST,
        });
        const { data } = await axios.post(`${URL}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_OFFICE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CREATE_OFFICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getOffices = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_OFFICES_REQUEST,
        });
        const { data } = await axios.get(`${URL}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_OFFICES,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_ALL_OFFICES_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getOffice = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_OFFICE_REQUEST,
        });
        const { data } = await axios.get(`${URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_OFFICE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_OFFICE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateOffice = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_OFFICE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_OFFICE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_OFFICE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteOffice = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_OFFICE_REQUEST,
        });
        await axios.delete(`${URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_OFFICE,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_OFFICE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
