import React from "react";
import { connect } from "react-redux";
import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Chip,
  makeStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import FilterListIcon from "@material-ui/icons/FilterList";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { TablePaginationActions } from "../../TablePaginationActions";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    marginBottom: 20,
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    // fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
}));

const MonthlyBudgetTable = ({
  filteredRows,

  page,
  rowsPerPage,

  emptyRows,
  handleChangePage,
  handleChangeRowsPerPage,
  theme,
  formatCurrency,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="overline" className={classes.title}>
        BudgetLines
      </Typography>
      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Expense Head</TableCell>
                <TableCell>Expense SubHead</TableCell>
                <TableCell>Budget Category</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow
                    hover
                    key={row._id}
                    style={{
                      background:
                        row.status === "postponed" ? "#eeeeee" : "none",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.head && row.head.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.subHead && row.subHead.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.item && row.item.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        className={clsx(
                          row.status === "draft"
                            ? classes.statusOrange
                            : row.status === "deferred"
                            ? classes.statusRed
                            : row.status === "approved"
                            ? classes.statusGreen
                            : row.status === "completed",
                          classes.chip
                        )}
                        variant="outlined"
                        label={row.status}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color:
                          row.amount <= 200000
                            ? theme.palette.warning.main
                            : theme.palette.success.main,
                      }}
                    >
                      {row.amount
                        ? formatCurrency(row.amount, "en-NG", "NGN")
                        : formatCurrency(0, "en-NG", "NGN")}
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyBudgetTable);
