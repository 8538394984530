import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import {
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    DialogTitle,
    IconButton,
    Tooltip,
    Fab,
    Icon,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../atoms/Feedback";
import { getCourse, getCourseLessons, createLesson, uploadLessonImage } from "../../../../actions/ppmActions/lmsActions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
    },

    centered: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 20,
    },
    btn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    createBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        [theme.breakpoints.down("sm")]: {
        width: "100%",
        },
    },
    saveBtn2: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        // [theme.breakpoints.down("sm")]: {
        //   width: "100%",
        // },
    },
    input: {
        display: "none",
    },
}));

const INIT_STATE = {
    course:"",
    title: "",
    description:"",
    code: "",
    image: "",
};

const CreateLessonModa = ({ openCreate, handleCloseCreate, id, getCourse, getCourseLessons, createLesson, error }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [imgUpload, setImgUpload] = useState(false);

    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleFile = (e) => {
        e.persist();
        const { files } = e.target;
        setFile(files[0]);
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        if (file && file !== null) {
            setState((prev) => ({
                ...prev,
                course: id,
            }));
        }
    }, [file, id]);

    const uploadCallback = useCallback(async () => {
        if(file !=="" && !state.image){
            setImgUpload((prev) => !prev);
            let formData = new FormData();
            formData.append("doc", file);        
            let doc = await new Promise((resolve, reject) => {
                resolve(dispatch(uploadLessonImage(formData)));
            });

            if(doc){
                setFeed({
                    loading: false,
                    open: !feed.open,
                    message: `Image uploaded successfully.`,
                    success: true,
                });
                setState((prev) => ({
                    ...prev,
                    image: doc,
                }));
            }
        }
    }, [dispatch, file, feed, state]);

    useEffect(() => {
        if(file){
            uploadCallback()
        }
    }, [file, uploadCallback]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading((prev) => !prev);
        const data = { ...state };
        if(data.title && data.description && data.code && data.course && data.image){ 
            createLesson(data)
                .then((res) => {
                    setLoading(false);
                    setState(INIT_STATE);

                    setFeed({
                        loading: false,
                        open: !feed.open,
                        // message: `Lesson Created Successfully`,
                        success: true,
                    });
                    dispatch(getCourseLessons(id));
                    setTimeout(() => {
                        history.push(`/lms/lesson/${res._id}`);
                        handleCloseCreate();
                    }, 2000);
                })
                .catch(() => {
                    setLoading(false);

                    setFeed({
                        loading: false,
                        open: !feed.open,
                        // message: `Lesson Created Successfully`,
                        success: false,
                    });
                })
                .finally(() => {
                    handleCloseCreate();
                });
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isValid = () => !state.title || !state.description || !state.code || !state.image || !state.course;

    return (
        <div>
            {error && error.error ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={(error && error.error) || feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message || "Lesson Created Successfully."}
                />
            )}
            <Dialog
                open={openCreate}
                onClose={handleCloseCreate}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                // fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Create Lesson
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseCreate}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    label="Title"
                                    name="title"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={state.title || ""}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Code"
                                    name="code"
                                    value={state.code || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    multiline
                                    rows={3}
                                    label="Description"
                                    name="description"
                                    value={state.description || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <label style={{fontWeight:'bold'}}>Add Badge Image:</label> <br/>
                                <label htmlFor="document">
                                    <Tooltip arrow title="Select File Attachment">
                                    <Fab color="primary" component="span">
                                        <Icon className="fas fa-paperclip" />
                                    </Fab>
                                    </Tooltip>
                                </label>

                                <input
                                    id="document"
                                    name="document"
                                    type="file"
                                    accept="image/*,.pdf"
                                    className={classes.input}
                                    onChange={handleFile}
                                />

                                <Button
                                    variant="outlined"
                                    color="default"
                                    disabled
                                    style={{width:'80%', marginLeft: 15}}
                                >
                                    {(!state.image) ? "Upload Badge" : (imgUpload ? "Uploading..." : state.image)}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                fullWidth
                                size="large"
                                disabled={loading || isValid()}
                                className={classes.saveBtn}
                            >
                                {loading ? "Loading..." : "Submit"}
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    error: state.lms.error,
});

const mapDispatchToProps = { getCourse, getCourseLessons, createLesson };

export default connect( mapStateToProps, mapDispatchToProps )(CreateLessonModa);
