import * as types from "../../types/quality";

const initState = {
    receivings: [],
    receivings_accepted: [],
    receiving: {},
    error: null,
    success: false,
    loading: false,
};

const receivingReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_RECEIVING_INSPECTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_RECEIVING_INSPECTIONS:
            return {
                ...state,
                loading: false,
                success: true,
                receivings: action.payload.data,
            };
        case types.GET_ALL_RECEIVING_INSPECTIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_ACCEPTED_RECEIVING_INSPECTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ACCEPTED_RECEIVING_INSPECTIONS:
            return {
                ...state,
                loading: false,
                success: true,
                receivings_accepted: action.payload.data,
            };
        case types.GET_ACCEPTED_RECEIVING_INSPECTIONS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                success: true,
                receiving: action.payload.data,
            };
        case types.GET_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                success: true,
                receiving: state.receivings.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                success: false,
                receivings: state.receivings.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                receiving: action.payload.data,
            };
        case types.SUBMIT_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.INSPECT_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.INSPECT_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                receiving: action.payload.data,
            };
        case types.INSPECT_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.APPROVE_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                receiving: action.payload.data,
            };
        case types.APPROVE_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DISAPPROVE_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DISAPPROVE_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                receiving: action.payload.data,
            };
        case types.DISAPPROVE_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                receiving: action.payload.data,
            };
        case types.REJECT_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                receiving: action.payload.data,
            };
        case types.ACCEPT_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_RECEIVING_INSPECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_RECEIVING_INSPECTION:
            return {
                ...state,
                loading: false,
                receiving: action.payload.data,
            };
        case types.DECLINE_RECEIVING_INSPECTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_RECEIVING_INSPECTION:
            return {
                receiving: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default receivingReducer;
