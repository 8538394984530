module.exports = {    
    // Order Database
    CREATE_ORDER_DATABASE: "CREATE_ORDER_DATABASE",
    CREATE_ORDER_DATABASE_REQUEST: "CREATE_ORDER_DATABASE_REQUEST",
    CREATE_ORDER_DATABASE_FAIL: "CREATE_ORDER_DATABASE_FAIL",

    GET_ALL_ORDER_DATABASES: "GET_ALL_ORDER_DATABASES",
    GET_ALL_ORDER_DATABASES_REQUEST: "GET_ALL_ORDER_DATABASES_REQUEST",
    GET_ALL_ORDER_DATABASES_FAIL: "GET_ALL_ORDER_DATABASES_FAIL",

    GET_CUSTOMER_ORDERS: "GET_CUSTOMER_ORDERS",
    GET_CUSTOMER_ORDERS_REQUEST: "GET_CUSTOMER_ORDERS_REQUEST",
    GET_CUSTOMER_ORDERS_FAIL: "GET_CUSTOMER_ORDERS_FAIL",

    GET_ORDER_DATABASE: "GET_ORDER_DATABASE",
    GET_ORDER_DATABASE_REQUEST: "GET_ORDER_DATABASE_REQUEST",
    GET_ORDER_DATABASE_FAIL: "GET_ORDER_DATABASE_FAIL",

    UPDATE_ORDER_DATABASE: "UPDATE_ORDER_DATABASE",
    UPDATE_ORDER_DATABASE_REQUEST: "UPDATE_ORDER_DATABASE_REQUEST",
    UPDATE_ORDER_DATABASE_FAIL: "UPDATE_ORDER_DATABASE_FAIL",

    DELETE_ORDER_DATABASE: "DELETE_ORDER_DATABASE",
    DELETE_ORDER_DATABASE_REQUEST: "DELETE_ORDER_DATABASE_REQUEST",
    DELETE_ORDER_DATABASE_FAIL: "DELETE_ORDER_DATABASE_FAIL",

    SUBMIT_ORDER_DATABASE: "SUBMIT_ORDER_DATABASE",
    SUBMIT_ORDER_DATABASE_REQUEST: "SUBMIT_ORDER_DATABASE_REQUEST",
    SUBMIT_ORDER_DATABASE_FAIL: "SUBMIT_ORDER_DATABASE_FAIL",

    APPROVE_ORDER_DATABASE: "APPROVE_ORDER_DATABASE",
    APPROVE_ORDER_DATABASE_REQUEST: "APPROVE_ORDER_DATABASE_REQUEST",
    APPROVE_ORDER_DATABASE_FAIL: "APPROVE_ORDER_DATABASE_FAIL",

    REJECT_ORDER_DATABASE: "REJECT_ORDER_DATABASE",
    REJECT_ORDER_DATABASE_REQUEST: "REJECT_ORDER_DATABASE_REQUEST",
    REJECT_ORDER_DATABASE_FAIL: "REJECT_ORDER_DATABASE_FAIL",

    ACCEPT_ORDER_DATABASE: "ACCEPT_ORDER_DATABASE",
    ACCEPT_ORDER_DATABASE_REQUEST: "ACCEPT_ORDER_DATABASE_REQUEST",
    ACCEPT_ORDER_DATABASE_FAIL: "ACCEPT_ORDER_DATABASE_FAIL",

    DECLINE_ORDER_DATABASE: "DECLINE_ORDER_DATABASE",
    DECLINE_ORDER_DATABASE_REQUEST: "DECLINE_ORDER_DATABASE_REQUEST",
    DECLINE_ORDER_DATABASE_FAIL: "DECLINE_ORDER_DATABASE_FAIL",

    REVIEW_ORDER_DATABASE: "REVIEW_ORDER_DATABASE",
    REVIEW_ORDER_DATABASE_REQUEST: "REVIEW_ORDER_DATABASE_REQUEST",
    REVIEW_ORDER_DATABASE_FAIL: "REVIEW_ORDER_DATABASE_FAIL",

    RESET_ORDER_DATABASE: "RESET_ORDER_DATABASE",

};
