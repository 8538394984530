import {
  CREATE_REIMBURSEMENT,
  GET_ALL_REIMBURSEMENT,
  UPLOAD_DOC,
  REIMBURSEMENT_ERROR,
  GET_REIMBURSEMENT_BY_ID,
  UPDATE_REIMBURSEMENT,
  SUBMIT_REIMBURSEMENT,
  APPROVE_REIMBURSEMENT,
  REJECT_REIMBURSEMENT,
  REVIEW_REIMBURSEMENT,
  DECLINE_REIMBURSEMENT,
  FINANCE_PAY_REIMBURSEMENT,
  EMPLOYEE_PAY_REIMBURSEMENT,
} from "../types/";
import axios from "axios";

const token = localStorage.getItem("token:key");

export const getAllReimbursements = () => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/all/reimbursement`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const getReimbursementById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_REIMBURSEMENT_BY_ID,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const createReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const submitReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/submit/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SUBMIT_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const reviewReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/review/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REVIEW_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const declineReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/decline/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DECLINE_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const approveReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/approve/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const rejectReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/reject/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REJECT_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const financePayReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/pay/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FINANCE_PAY_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const employeePayReimbursement = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/employeePay/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: EMPLOYEE_PAY_REIMBURSEMENT,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};

export const uploadDoc = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/advance/reimbursement/upload`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPLOAD_DOC,
      payload: res.data,
    });
    return await res.data.doc;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: REIMBURSEMENT_ERROR,
        error: error.message,
      });
    }
  }
};
