import axios from "axios";
import { GET_COUNT, GET_COUNT_FAIL, GET_COUNT_REQUEST } from "../types/count";

const URL = `/api/v1/user/count`;
const token = localStorage.getItem("token:key");

export const getCount = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNT_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_COUNT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
