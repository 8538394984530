import {
  GET_ALL_CURRENCIES,
  GET_CURRENCY,
  GET_CURRENCY_LISTS,
  UPDATE_CURRENCY,
  CREATE_CURRENCY,
  DELETE_CURRENCY,
  CURRENCY_ERROR,
} from "../types/index";

const initState = {
  currency: {},
  currencyLists: [],
  currencies: [],
  error: null,
};

const currencyReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_CURRENCIES:
      return {
        ...state,
        currencies: action.payload.data,
      };
    case GET_CURRENCY_LISTS:
      return {
        ...state,
        currencyLists: action.payload,
      };
    case GET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case CREATE_CURRENCY:
      return {
        ...state,
        // currencies: [action.payload, ...state.currencies],
      };
    case UPDATE_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case DELETE_CURRENCY:
      return {
        ...state,
        currencies: state.currencies.filter(
          (currency) => currency._id !== action.payload
        ),
      };

    case CURRENCY_ERROR:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

export default currencyReducer;
