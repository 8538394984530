import {
  CREATE_CASH_RECOVERY,
  DELETE_CASH_RECOVERY,
  GET_ALL_CASH_RECOVERY,
  GET_CASH_RECOVERY_BY_ID,
  PPM_RECOVER_CASH,
  FINANCE_ALLOW_CASH_RECOVERY,
  FINANCE_EXEMPT_CASH_RECOVERY,
  CASH_RECOVERY_ERROR,
  CASH_RECOVERY_LOG_ERROR,
  GET_ALL_CASH_RECOVERY_LOG,
  FINANCE_COMPLETE_RECOVERY,
  FINANCE_COMPLETE_RECOVERY_FAIL,
  FINANCE_COMPLETE_RECOVERY_REQUEST,
  PPM_COMPLETE_RECOVERY,
  PPM_COMPLETE_RECOVERY_FAIL,
  PPM_COMPLETE_RECOVERY_REQUEST,
} from "../../types/";

const initState = {
  cashRecovery: {},
  cashRecoveries: [],
  cashRecoveryLog: {},
  cashRecoveryLogs: [],
  cashRecoveryError: null,
  cashRecoveryLogError: null,
  loading: false,
  error: null,
};

const cashRecoveryReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_CASH_RECOVERY:
      return {
        ...state,
        cashRecovery: action.payload.data,
      };
    case GET_ALL_CASH_RECOVERY:
      return {
        ...state,
        cashRecoveries: action.payload.data,
      };
    case GET_ALL_CASH_RECOVERY_LOG:
      return {
        ...state,
        cashRecoveryLogs: action.payload.data,
      };

    case GET_CASH_RECOVERY_BY_ID:
      return {
        ...state,
        cashRecovery: action.payload.data,
      };

    case FINANCE_ALLOW_CASH_RECOVERY:
      return {
        ...state,
        cashRecovery: action.payload.data,
      };
    case FINANCE_EXEMPT_CASH_RECOVERY:
      return {
        ...state,
        cashRecovery: action.payload.data,
      };
    case PPM_RECOVER_CASH:
      return {
        ...state,
        cashRecoveryLog: action.payload.data,
      };

    case DELETE_CASH_RECOVERY:
      return {
        ...state,
        cashRecoveries: state.cashRecoveries.filter(
          (item) => item._id !== action.payload
        ),
      };

    case CASH_RECOVERY_ERROR:
      return {
        ...state,
        cashRecoveryError: action.payload,
      };
    case CASH_RECOVERY_LOG_ERROR:
      return {
        ...state,
        cashRecoveryLogError: action.payload,
      };
    case FINANCE_COMPLETE_RECOVERY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FINANCE_COMPLETE_RECOVERY:
      return {
        ...state,
        loading: false,
        cashRecovery: action.payload.data,
      };
    case FINANCE_COMPLETE_RECOVERY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PPM_COMPLETE_RECOVERY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PPM_COMPLETE_RECOVERY:
      return {
        ...state,
        loading: false,
        cashRecovery: action.payload.data,
      };
    case PPM_COMPLETE_RECOVERY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default cashRecoveryReducer;
