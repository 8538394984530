import React, { useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Typography,
    Grid,
    Button,
    Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { getCourses } from "../../../actions/ppmActions/lmsActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 18,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
        marginBottom: 30,
    },
    description: {
        display: "block",
        textTransform:'none',
        lineHeight:2,
        fontSize: 14,
        fontFamily: "Rubik",
        fontWeight:'400',
        marginBottom: 20,
    },
    grid:{
        padding: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    bgColor:{
        backgroundColor: theme.palette.type === "light"
            ? theme.palette.background.paper
            : "#fafafa",
        padding: 20,
        border:'1px solid #ccc',
        textAlign: 'left',
        marginTop: 10,
        transition: 'ease-in 0.2s all',
        boxShadow: '0 0 10px #ccc',
        width: '100%',
        color:
            theme.palette.type === "dark"
                ? theme.palette.primary.dark
                : theme.palette.common.white,
        "&:hover": {
            backgroundColor: "#655757",
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        },
    }
}));

const Courses = ({ getCourses, courses }) => {
    const classes = useStyles();
    const history = useHistory();
    const [openBackdrop, setOpenBackdrop] = useState(false);

    React.useEffect(() => {
        setOpenBackdrop((prev) => !prev);
        getCourses().then(() => {
            setOpenBackdrop(false);
        });
    }, [getCourses]);

    const navigateById = (path, id) => {
        history.push(`${path}/${id}`);
    };

    return (
        <div>
            <Typography variant="overline" className={classes.title}>
                Courses and Lessons
            </Typography>

            <Grid container spacing={2} className={classes.grid}>
                <Typography variant="overline" className={classes.description}>
                It's an exciting time in RSEdge, as the Council of Elders has highlighted some interesting courses and lessons for villagers to embark on and gain insight on various areas of interest. Complete the lessons below carefully, and share your progress with your community!!"
                </Typography>   
            </Grid>

            <div className="services">
                <Grid container spacing={2}>
                    {courses && courses.map((row, i) => {
                        return (
                            <Grid item xs={12} md={6} key={i}>
                                <div className="service">
                                    <h4>{row.name && row.name}</h4>
                                    <p>{row.description && row.description}</p>
                                    <Button 
                                        className="btn"
                                        onClick={() => navigateById("/course", row._id)}
                                    >   
                                        Learn More
                                    </Button>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>

            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

const mapStateToProps = (state) => ({
    courses: state.lms.courses,
});

const mapDispatchToProps = {
    getCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
