import {
  GET_ALL_MONTHLY_BUDGETS,
  CREATE_MONTHLY_BUDGET,
  CREATE_MONTHLY_BUDGET_LINE,
  MONTHLY_BUDGETS_ERROR,
  MONTHLY_BUDGET_LINE_ERROR,
  GET_MONTHLY_BUDGETS,
  GET_ALL_MONTHLY_BUDGET_LINES,
  GET_ALL_MONTHLY_BUDGET_LINE_BY_BUDGET_ID,
  UPDATE_MONTHLY_BUDGET_LINE,
  DELETE_MONTHLY_BUDGET_LINE,
  GET_MONTHLY_BUDGET_BY_ID,
  GET_MONTHLY_BUDGET_ITEMS_BY_SUBHEAD,
  GET_MONTHLY_BUDGET_ITEMS_BY_DIVISION,
  GET_ONE_MONTHLY_BUDGET_ITEM,
  GET_MONTHLY_BUDGET_LINE_BY_ID,
  SUBMIT_MONTHLY_BUDGET,
  CONFIRM_MONTHLY_BUDGET,
  DECLINE_MONTHLY_BUDGET,
  APPROVE_MONTHLY_BUDGET,
  REJECT_MONTHLY_BUDGET,
  POSTPONE_MONTHLY_BUDGET,
  APPROVE_ALL_MONTHLY_BUDGET,
  REVIEW_ALL_MONTHLY_BUDGET,
  REQUEST_MONTHLY_BUDGET_CLARIFICATION,
  REQUEST_MONTHLY_BUDGET_MODIFICATION,
  CLARIFY_MONTHLY_BUDGET,
  MODIFY_MONTHLY_BUDGET,
  SUBMIT_REJECTED_MONTHLY_BUDGET,
} from "../types";

import axios from "axios";

const token = localStorage.getItem("token:key");

export const getMonthlyBudgets = () => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_MONTHLY_BUDGETS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const getMonthlyBudgetById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_MONTHLY_BUDGET_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const getMonthlyBudgetLineById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/budgetLine/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_MONTHLY_BUDGET_LINE_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllMonthlyBudgets = () => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/all`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_MONTHLY_BUDGETS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllMonthlyBudgetLines = () => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/line`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_MONTHLY_BUDGET_LINES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllMonthlyBudgetItems = (data) => async (dispatch) => {
  try {
    let { year, month, serviceDivision } = data; let URL;
    URL = `/api/v1/budget/month-extra/${year}/${month}/item`;
    if (serviceDivision) {
      URL = `/api/v1/budget/month-extra/${year}/${month}/${serviceDivision}/item`;
    }
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_MONTHLY_BUDGET_ITEMS_BY_SUBHEAD,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const getDivisionMonthlyBudgetItems = (data) => async (dispatch) => {
  try {
    let { year, month, serviceDivision } = data; 
    let URL = `/api/v1/budget/month-extra/${year}/${month}/${serviceDivision}/item`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_MONTHLY_BUDGET_ITEMS_BY_DIVISION,
      payload: res.data,
    });

    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const getOneMonthlyBudgetItem = (data) => async (dispatch) => {
  
  try {
    let { budgetItem } = data; 
    let URL = `/api/v1/budget/month-extra/item/${budgetItem}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ONE_MONTHLY_BUDGET_ITEM,
      payload: res.data,
    });

    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const submitMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/submit/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SUBMIT_MONTHLY_BUDGET,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const createMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_MONTHLY_BUDGET,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const createMonthlyBudgetLine = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/line/${data.budgetId}`;

    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_MONTHLY_BUDGET_LINE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateMonthlyBudgetLine = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/line/${data.id}`;

    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_MONTHLY_BUDGET_LINE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllMonthlyBudgetLineByBudgetId = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/line/budgetId/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_MONTHLY_BUDGET_LINE_BY_BUDGET_ID,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteMonthlyBudgetLine = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/line/${id}`;
    await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_MONTHLY_BUDGET_LINE,
      payload: id,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const confirmMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/confirm/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CONFIRM_MONTHLY_BUDGET,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const approveMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/approve/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_MONTHLY_BUDGET,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const declineMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/decline/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DECLINE_MONTHLY_BUDGET,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const rejectMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/reject/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REJECT_MONTHLY_BUDGET,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const postponeMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/budgetLine/postpone`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: POSTPONE_MONTHLY_BUDGET,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const reviewAllMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/review/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REVIEW_ALL_MONTHLY_BUDGET,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const requestMonthlyBudgetClarification = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/requestclarify/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REQUEST_MONTHLY_BUDGET_CLARIFICATION,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const requestMonthlyBudgetModification = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/requestmodify/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REQUEST_MONTHLY_BUDGET_MODIFICATION,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const approveAllMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/finalapprove/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_ALL_MONTHLY_BUDGET,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const clarifyMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/clarification/submit/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CLARIFY_MONTHLY_BUDGET,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const modifyMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/modification/submit/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: MODIFY_MONTHLY_BUDGET,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    dispatch({
      type: MONTHLY_BUDGETS_ERROR,
      error:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
            ? error.request.data.error
            : error.message,
    });
  }
};

export const submitRejectedMonthlyBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/month-extra/rejected/submit/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SUBMIT_REJECTED_MONTHLY_BUDGET,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MONTHLY_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};
