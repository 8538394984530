import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
    section: {
        margin: 10,
        paddingHorizontal: 20,
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    caps: {
        fontWeight: 800,
        fontSize: 14,
        textTransform: "uppercase",
    },
    text: {
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 25,
    },
    title: {
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 5,
    },
    title2: {
        marginBottom: 50,
    },
    viewer: {
        width: "100%", //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
    textCenterBig: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1.5,
        marginBottom: 15,
    },
    textCenter: {
        textAlign: "center",
        fontSize: 13,
    },
    textRight: {
        textAlign: "right",
        fontSize: 13,
    },
    textLeft: {
        textAlign: "left",
        fontSize: 13,
    },
    table: {
        padding: 0,
        margin: 0,
        marginBottom: 30,
    },
    row: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #999',
        padding: 0,
        margin: 0,
    },
    th: {
        fontWeight: "bold",
        fontSize: 10,
        padding: 4,
        margin: 0,
        backgroundColor: "#444",
        color: "#fff"
    },
    foot: {
        backgroundColor: "#afafaf",
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #999',
        padding: 0,
        margin: 0,
    },
    td: {
        fontSize: 10,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        color: "#444",
        border: "1px solid #999",
        borderTopWidth: 0,
    },
    desc: {
        width: "20%",
    },
    desc2: {
        width: "30%",
    },
    desc3: {
        width: "80%",
    },
    desc4: {
        width: "40%",
    },
    desc5: {
        width: "50%",
        textAlign: "right",
    },
    sn: {
        width: "10%",
    },
});

const TableHead = () => {
    return (
        <>
            <View style={[styles.row]}>
                <Text style={[styles.th, styles.desc4]}>Item</Text>
                <Text style={[styles.th, styles.sn]}>Qty Req.</Text>
                <Text style={[styles.th, styles.sn]}>Qty Iss.</Text>
                <Text style={[styles.th, styles.desc]}>Condition</Text>
                <Text style={[styles.th, styles.desc]}>To be Returned?</Text>
            </View>
        </>
    )
}

export const PageOne = ({ requite }) => {

    return (
        <>
            <View>
                <View style={styles.section}>
                    <Text style={styles.title}>REQUESTED BY: {requite && requite.employee ? requite.employee : null}</Text>
                    <Text style={styles.title}>DATE NEEDED: {requite && requite.dateNeeded ?
                        moment(requite.dateNeeded).format("MMMM Do YYYY") : null}</Text>
                    <Text style={styles.title}>REQUISITION NO: {requite && requite.documentNo ? requite.documentNo : null}</Text>
                    <Text style={styles.title}>MAIN CATEGORY: {requite && requite.mainCategory ? requite.mainCategory : null}</Text>
                    <Text style={styles.title}>STORE: {requite && requite.store ? requite.store?.name : null}</Text>
                    <Text style={styles.title}>PURPOSE: {requite && requite.purpose ? requite.purpose : null}</Text>
                </View>
                <View style={styles.section}>

                    {/* Items requested */}
                    {requite && requite.items && requite.items.length > 0 ? (
                        <>
                            <Text style={styles.textLeft}>ITEMS REQUESTED:</Text>
                            <View style={styles.table}>
                                <TableHead />
                                {(requite.items).map((item, i) => (
                                    <View style={[styles.row]} key={i}>
                                        <Text style={[styles.td, styles.desc4]}>
                                            {(item.item && item.item) || "NA"}
                                        </Text>
                                        <Text style={[styles.td, styles.sn]}>
                                            {(item.quantityRequested && item.quantityRequested) || "NA"}
                                        </Text>
                                        <Text style={[styles.td, styles.sn]}>
                                            {(item.quantityIssued && item.quantityIssued) || "NA"}
                                        </Text>
                                        <Text style={[styles.td, styles.desc]}>
                                            {(item.condition && item.condition) || "NA"}
                                        </Text>
                                        <Text style={[styles.td, styles.desc]}>
                                            {(item.toBeReturned && item.toBeReturned) || "NA"}
                                        </Text>
                                    </View>
                                ))}
                            </View>
                        </>
                    ) : null}
                </View>
            </View>
        </>
    )
}

export default PageOne;
