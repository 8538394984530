import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { connect } from "react-redux";
import EditAnnualBudgetLineTable from "./EditAnnualBudgetLineTable";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
}));

const EditBudgetLine = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Button
        onClick={() => history.goBack()}
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
      >
        Back
      </Button>
      <Typography variant="overline" className={classes.title}>
        Edit BudgetLine / Datatable
      </Typography>

      <EditAnnualBudgetLineTable />
    </div>
  );
};

export default connect()(EditBudgetLine);
