import {
  CREATE_SERVICE_TYPE,
  CREATE_SERVICE_TYPE_FAIL,
  CREATE_SERVICE_TYPE_REQUEST,
  UPDATE_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE_FAIL,
  UPDATE_SERVICE_TYPE_REQUEST,
  GET_SERVICE_TYPES,
  GET_SERVICE_TYPES_REQUEST,
  GET_SERVICE_TYPES_FAIL,
  GET_SERVICE_TYPE,
  GET_SERVICE_TYPE_FAIL,
  GET_SERVICE_TYPE_REQUEST,
  GET_SERVICE_TYPE_BY_DIVISION,
  GET_SERVICE_TYPE_BY_DIVISION_FAIL,
  GET_SERVICE_TYPE_BY_DIVISION_REQUEST,
  DELETE_SERVICE_TYPE,
  DELETE_SERVICE_TYPE_FAIL,
  DELETE_SERVICE_TYPE_REQUEST,
} from "../../types";

const initState = {
  services: [],
  service: null,
  error: null,
  loading: false,
};

const serviceTypeReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SERVICE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_TYPES:
      return {
        ...state,
        loading: false,
        services: action.payload.data,
      };
    case GET_SERVICE_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_TYPE:
      return {
        ...state,
        loading: false,
        service: action.payload.data,
      };
    case GET_SERVICE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SERVICE_TYPE_BY_DIVISION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_TYPE_BY_DIVISION:
      return {
        ...state,
        loading: false,
        services: action.payload.data,
      };
    case GET_SERVICE_TYPE_BY_DIVISION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SERVICE_TYPE:
      return {
        ...state,
        loading: false,
        service: action.payload.data,
      };
    case CREATE_SERVICE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SERVICE_TYPE:
      return {
        ...state,
        loading: false,
        services: state.services.map((item) =>
          item._id === action.payload.data._id
            ? (item = action.payload.data)
            : item
        ),
      };
    case UPDATE_SERVICE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SERVICE_TYPE:
      return {
        ...state,
        loading: false,
        services: state.services.filter((item) => item._id !== action.payload),
      };
    case DELETE_SERVICE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default serviceTypeReducer;
