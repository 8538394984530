import * as types from "../../types/lms";

const initState = {
    courses: [],
    courseLessons: [],
    records: [],
    course: null,
    lesson: null,
    loading: false,
    error: null,
    success: false,
};

const lmsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_COURSE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_COURSE:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };    
        case types.GET_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_COURSES:
            return {
                ...state,
                loading: false,
                courses: action.payload.data,
                success: true,
            };
        case types.GET_COURSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case types.GET_COURSE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_COURSE:
            return {
                ...state,
                loading: false,
                course: action.payload.data,
                success: true,
            };
        case types.GET_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };

        
        case types.UPDATE_COURSE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_COURSE:
            return {
                ...state,
                loading: false,
                course: state.courses.map((item) =>
                    item._id === action.payload.data.id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case types.DELETE_COURSE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_COURSE:
            return {
                ...state,
                loading: false,
                courses: state.courses.filter(
                    (item) => item._id !== action.payload
                ),
                success: false,
            };
        case types.DELETE_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        

        case types.GET_COURSE_LESSONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_COURSE_LESSONS:
            return {
                ...state,
                loading: false,
                courseLessons: action.payload.data,
                success: true,
            };
        case types.GET_COURSE_LESSONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_COURSE: {
            return {
                ...state,
                loading: false,
                course: null,
                error: null,
                success: false,
            };
        }


        case types.CREATE_LESSON_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_LESSON:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_LESSON_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        
        case types.GET_LESSON_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_LESSON:
            return {
                ...state,
                loading: false,
                lesson: action.payload.data,
                success: true,
            };
        case types.GET_LESSON_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        
        case types.UPDATE_LESSON_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_LESSON:
            return {
                ...state,
                loading: false,
                lesson: action.payload.data,
            };
        case types.UPDATE_LESSON_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };

        case types.DELETE_LESSON_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_LESSON:
            return {
                ...state,
                loading: false,
                lesson: null,
                success: false,
            };
        case types.DELETE_LESSON_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };


        case types.CREATE_RECORD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_RECORD:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_RECORD_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_LESSON_RECORD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_LESSON_RECORD:
            return {
                ...state,
                loading: false,
                records: action.payload.data,
                success: true,
            };
        case types.GET_LESSON_RECORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default lmsReducer;
