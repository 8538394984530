import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Feedback from "../../../atoms/Feedback";
import {
  getProductById,
  updateProductById,
  getAllProducts,
} from "../../../../actions/vendorsActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  name: "",
  category: "",
};

const EditProductModal = ({
  openEdit,
  handleEditClose,
  id,
  getProductById,
  product,
  updateProductById,
  getAllProducts,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);

  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const productCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    getProductById(id).then(() => {
      setLoading(false);
    });
  }, [getProductById, id]);

  React.useEffect(() => {
    productCallback();
  }, [productCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      name: product.name,
      category: product.category,
    }));
  }, [product]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      ...state,
      id,
    };
    updateProductById(data)
      .then(() => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          message: `Product/Service updated Successfully`,
          success: true,
        });
        dispatch(getAllProducts);
        handleEditClose();
      })
      .catch(() => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          message: "An Unexpected Error has occured. Kindly contact Admin.",
          success: false,
        });
      })
      .finally(() => {
        handleEditClose();
      });
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEdit}
        onClose={handleEditClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="overline"
                gutterBottom
                className={classes.title}
              >
                Edit Product/Service
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleEditClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={loading ? "Loading..." : state.name || ""}
                  onChange={handleChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="type-label-id">Category</InputLabel>
                  <Select
                    labelId="type-label-id"
                    id="category"
                    inputProps={{
                      id: "category",
                      name: "category",
                    }}
                    label="category"
                    name="category"
                    value={loading ? "Loading..." : state.category || ""}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem disabled>---Select Type----</MenuItem>
                    <MenuItem value="product">Product</MenuItem>
                    <MenuItem value="service">Service</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  className={classes.saveBtn}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Update"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.vendors.product,
});

const mapDispatchToProps = {
  getProductById,
  updateProductById,
  getAllProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProductModal);
