import * as types from "../../types/inventory";

const initState = {
    counts: [],
    count: {},
    error: null,
    success: false,
    loading: false,
};

const inventoryCountReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case types.GET_ALL_INVENTORYCOUNTS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_ALL_INVENTORYCOUNTS:
            return {
                ...state,
                loading: false,
                success: true,
                counts: action.payload.data,
            };
        case types.GET_ALL_INVENTORYCOUNTS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                success: true,
                count: action.payload.data,
            };
        case types.GET_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.UPDATE_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                success: true,
                count: state.counts.map((item) =>
                    item._id === action.payload.data._id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.DELETE_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                success: false,
                counts: state.counts.filter((item) => item._id !== action.payload),
            };
        case types.DELETE_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SUBMIT_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SUBMIT_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                count: action.payload.data,
            };
        case types.SUBMIT_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ACCEPT_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ACCEPT_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                count: action.payload.data,
            };
        case types.ACCEPT_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.DECLINE_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DECLINE_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                count: action.payload.data,
            };
        case types.DECLINE_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.APPROVE_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.APPROVE_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                count: action.payload.data,
            };
        case types.APPROVE_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.REJECT_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.REJECT_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                count: action.payload.data,
            };
        case types.REJECT_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ISSUE_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ISSUE_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                count: action.payload.data,
            };
        case types.ISSUE_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RECEIVE_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.RECEIVE_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                count: action.payload.data,
            };
        case types.RECEIVE_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RETURN_INVENTORYCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.RETURN_INVENTORYCOUNT:
            return {
                ...state,
                loading: false,
                count: action.payload.data,
            };
        case types.RETURN_INVENTORYCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.RESET_INVENTORYCOUNT:
            return {
                count: null,
                loading: false,
                error: null,
                success: false,
            };

        default:
            return state;
    }
};

export default inventoryCountReducer;
