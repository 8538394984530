import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  IconButton,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 2.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  fade: {
    color: "#afacac",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    paddingBottom: 30,
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginBottom: 10,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  total: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  warningInfo: {
    color: "#f5f5f5",
    background: "#f44336",
    padding: 20,
    marginBottom: 20,
    marginRight: 10,
    "& > *": {
      fontWeight: 900,
    },
  },
  form: {
    marginBottom: 20,
  },
  grid: {
    marginBottom: 20,
  },
  input: {
    border: "1px solid #bdbdbd",
    padding: theme.spacing(2),
    borderRadius: 3,
  },

  btnWrapper: {
    textAlign: "center",
  },

  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  auditContainer: {
    width: 800,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  auditTitle: {
    padding: 20,
    // background: theme.palette.grey[600],
    color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
    fontFamily: "Rubik",
    fontSize: 12,
    width: 500,
    textTransform: "uppercase",
    // textTransform: "capitalize",
    letterSpacing: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  audit: {
    padding: 20,
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 12,
    width: 500,
    // textTransform: "uppercase",
    textTransform: "capitalize",
    letterSpacing: 1.5,
    // fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  grey: {
    color: theme.palette.grey[400],
  },
  warn: {
    color: theme.palette.warning.main,
    fontWeight: 400,
    textTransform: "uppercase",
    letterSpacing: 2.5,
  },
}));

const ModifiedDataModal = ({
  openModify,
  handleCloseModify,
  modifiedData = [],
}) => {
  const classes = useStyles();

  const doc =
    modifiedData &&
    modifiedData.map((item) =>
      Object.entries(item).map(([key, value]) =>
        value instanceof Object
          ? `${[key && key]}: <span>Check List Items For Updated Fields</span>`
          : `${[key && key]}: ${value}`
      )
    );

  const camelCaseFormatter = (str) => {
    return str.replace(/[A-Z]/, ` ${str.match(/[A-Z]/)[0]}`);
  };

  let newDoc = [];
  newDoc = doc && doc.map((item) => item);
  let arr = newDoc && newDoc.flat();

  return (
    <div>
      <Dialog
        open={openModify}
        onClose={handleCloseModify}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Modified Fields
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseModify}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <>
            <Paper
              className={classes.auditContainer}
              component="div"
              elevation={1}
              variant="outlined"
              square
            >
              <span className={classes.auditTitle}>
                <b>Modified Field</b>
              </span>
              <Divider orientation="vertical" flexItem />
              <span className={classes.auditTitle}>
                <b>Modified Value</b>
              </span>
            </Paper>
            {arr &&
              arr.map((item, i) => {
                let data = item.split(":");
                return (
                  <Paper
                    className={classes.auditContainer}
                    component="div"
                    elevation={1}
                    variant="outlined"
                    square
                    key={i}
                  >
                    <span className={classes.auditTitle}>
                      {camelCaseFormatter(data[0])}
                    </span>
                    <Divider orientation="vertical" flexItem />
                    <span className={classes.audit}>
                      {data[1] && data[1].includes("span") ? (
                        <span
                          className={classes.warn}
                          dangerouslySetInnerHTML={{ __html: data[1] }}
                        />
                      ) : (
                        data[1]
                      )}
                    </span>
                  </Paper>
                );
              })}
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModifiedDataModal;
