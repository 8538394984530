import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
    TextField,
    Button,
    IconButton,
    Backdrop,
    CircularProgress,
    Divider,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import {
    createInventoryItem,
    getLocations,
    getAllStores,
    getAllInventoryCategories
} from "../../../../../actions/inventoryActions";
import Feedback from "../../../../atoms/Feedback";
import { useHistory } from "react-router-dom";
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },
    btn: {
        margin: "30px 0",
        width: 300,
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down("sm")]: {
            width: 200,
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 10000,
        color: "#fff",
    },
}));

const INIT_STATE = {
    store: "",
    category: "",
    location: "",
    periodEnding: "",
    scope: "",
};

const StartCountModal = ({ openCreate, handleCloseCreate }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { locations } = useSelector((state) => state.location);
    const { allstores } = useSelector((state) => state.stores);
    const [state, setState] = useState(INIT_STATE);
    const [periodEnding, setPeriodEnding] = useState(moment(new Date()).format('YYYY/MM/DD'));
    const { error, loading, invCates } = useSelector((state) => state.inventoryItem);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        let abortController = new AbortController();

        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
        return () => {
            abortController.abort();
            dispatch({
                type: "RESET_ITEM",
            });
        };
    }, [error, dispatch]);

    useEffect(() => {
        dispatch(getLocations());
        dispatch(getAllStores());
        dispatch(getAllInventoryCategories());
    }, [dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            periodEnding,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(dispatch(createInventoryItem(data)));
        });
        if (res) {
            handleCloseCreate();
            setState(INIT_STATE);
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Inventory Count Started Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/inventory/count/view/${res.data._id}`);
            }, 2000);
        }
    };

    const handleChangePeriodEnding = (date) => {
        setPeriodEnding(moment(date).format('YYYY/MM/DD'));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isValid = () =>
        !state.store ||
        !state.category ||
        !periodEnding ||
        !state.scope ||
        !state.location;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="overline" className={classes.title}>
                                    Start Inventory Count
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleCloseCreate}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3} justify="center" alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Inventory Category</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "category",
                                                name: "category",
                                            }}
                                            value={state.category}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Category</MenuItem>
                                            {invCates.map((category) => (
                                                <MenuItem key={category._id} value={category._id}>
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Store</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "store",
                                                name: "store",
                                            }}
                                            value={state.store}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Store</MenuItem>
                                            {allstores.map((store) => (
                                                <MenuItem key={store._id} value={store._id}>
                                                    {store.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="location"
                                        options={locations}
                                        value={state.location}
                                        onChange={(event, newValue) => {
                                            setState((prev) => ({
                                                ...prev,
                                                location: newValue,
                                            }));
                                        }}
                                        getOptionLabel={(option) => option.name || ""}
                                        getOptionSelected={(option, value) => option.userName === value.name}
                                        renderOption={(option) => (
                                            <React.Fragment>{option.name}</React.Fragment>
                                        )}
                                        style={{ marginTop: -10 }}
                                        autoComplete
                                        autoHighlight
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Location Name"
                                                margin="normal"
                                                fullWidth
                                                required
                                                variant="outlined"
                                                value={state.location.name || ""}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "disabled",
                                                    name: "location",
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        className={classes.marginBottom}
                                        autoOk
                                        format="DD/MM/yyyy"
                                        views={["year", "month", "date"]}
                                        label="Period Ending"
                                        fullWidth
                                        inputVariant="outlined"
                                        value={periodEnding}
                                        onChange={handleChangePeriodEnding}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    <Divider
                                                        className={classes.divider2}
                                                        orientation="vertical"
                                                    />
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disabled={periodEnding === null}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setPeriodEnding(null);
                                                            }}
                                                        >
                                                            {periodEnding ? (
                                                                <CloseIcon />
                                                            ) : (
                                                                <FilterListIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                </>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <TextField
                                            label="Inventory Count Scope"
                                            name="scope"
                                            type="text"
                                            variant="outlined"
                                            value={state.scope || ""}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                            multiline
                                            rows={2}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" alignItems="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    className={classes.btn}
                                    disabled={isValid() || loading}
                                    endIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="primary" />
                                        ) : null
                                    }
                                >
                                    {loading ? "Loading..." : "Create"}
                                </Button>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default StartCountModal;