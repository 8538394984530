import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  darken,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Divider,
  TablePagination,
  lighten,
  Chip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import clsx from "clsx";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { TablePaginationActions } from "../../../TablePaginationActions";
import { getSalesCallByLead } from "../../../../actions/salesCallActions";
import { momentFormat } from "../../../../utils/momentFormat";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    color: theme.palette.type === "light" ? "#504f4f9c" : "white",
    marginBottom: 15,
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  btn: {
    margin: "30px 0",
    width: 300,
    letterSpacing: 2.5,
    padding: theme.spacing(2),
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      width: 200,
      // minWidth: "100%",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: "#fff",
  },
  input: {
    border: "1px solid #bdbdbd",
    padding: theme.spacing(2),
    borderRadius: 3,
  },
  greenBg: {
    background: "#4caf50",
    color: "#fff",
    "&:hover": {
      background: darken("#4caf50", 0.2),
    },
  },
  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 15,
    borderColor: "#000",
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  small: {
    fontSize: 10,
    letterSpacing: 1.5,
    display: "block",
  },

  marginVertical: {
    margin: "30px 0",
  },
  chip: {
    fontSize: 9,
    height: 20,
    padding: "8px 8px",
  },
  chipYellow: {
    background: "#fbc02d",
  },
  chipeGreen: {
    background: "green",
  },
  chipBlue: {
    background: "blue",
  },
  paper: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-stretch",
    overflow: "hidden",
  },
  paperInner: {
    padding: theme.spacing(3),
    flex: 1,
  },
  paperInner2: {
    padding: theme.spacing(3),
    background: lighten("#fbc02d", 0.5),
    alignContent: "center",
    textAlign: "center",
    color: theme.palette.type === "light" ? "black" : "black",
  },
  papperInner2Even: {
    background: theme.palette.primary.main,
    color: "white",
  },
  marginAuto: {
    margin: "0% auto",
    textAlign: "center",
  },
  avatar: {
    margin: "3px auto",
  },
  statusOrange: {
    borderColor: darken("#ff9800", 0.1),
  },
  statusDone: {
    borderColor: "#4caf50",
  },
}));

function LeadHistoryModal({ openHistory, handleCloseHistory }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pageTwo, setPageTwo] = React.useState(0);
  const [rowsPerPageTwo, setRowsPerPageTwo] = React.useState(10);
  const { id } = useParams();

  const { lead } = useSelector((state) => state.leads);
  const { salesCalls } = useSelector((state) => state.salesCall);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePageTwo = (event, newPage) => {
    setPageTwo(newPage);
  };

  const handleChangeRowsPerPageTwo = (event) => {
    setRowsPerPageTwo(parseInt(event.target.value, 10));
    setPageTwo(0);
  };

  useEffect(() => {
    dispatch(getSalesCallByLead(id));
  }, [dispatch, id]);

  const navigate = (path) => {
    history.push(path);
  };

  let filteredRows = () => salesCalls || [];
  let filteredRows2 = () => lead?.history || [];

  const split = (date) => {
    let str = momentFormat(moment, date);
    let arr = str.split(",");
    let item1 = arr[0];
    let item2 = arr[1];

    return {
      item1,
      item2,
    };
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );
  const emptyRowsTwo =
    rowsPerPage -
    Math.min(
      rowsPerPageTwo,
      (Array(1000).length || filteredRows2().length) - pageTwo * rowsPerPageTwo
    );

  return (
    <div>
      <Dialog
        open={openHistory}
        onClose={handleCloseHistory}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Lead History
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseHistory}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography className={classes.title2} variant="overline">
            Sales Calls <PhoneInTalkIcon />
          </Typography>

          <Paper>
            <TableContainer>
              <Table
                className={classes.table}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Activity</TableCell>
                    <TableCell>Summary</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows().map((row, i) => (
                    <TableRow hover key={i}>
                      <TableCell component="th" scope="row">
                        <Chip label={momentFormat(moment, row?.date)} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row?.activityType}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row?.summary}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          label={row?.status}
                          variant="outlined"
                          className={clsx({
                            [classes.statusOrange]:
                              row?.status?.toLowerCase() === "pending",
                            [classes.statusDone]:
                              row?.status?.toLowerCase() === "done",
                          })}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          variant="contained"
                          onClick={() => navigate(`/crm/salescall/${row._id}`)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {filteredRows().length === 0 && (
                    <TableRow hover>
                      <TableCell
                        colSpan={10}
                        align="center"
                        style={{ color: "#616161" }}
                        component="th"
                        scope="row"
                      >
                        No Records To Display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={filteredRows().length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>

          <Divider className={classes.marginVertical} />

          <Typography
            variant="overline"
            gutterBottom
            className={classes.title2}
          >
            History <AssignmentIcon />
          </Typography>

          {lead?.history?.length > 0 ? (
            <Paper>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Initiator</TableCell>
                      <TableCell>Activity</TableCell>
                      <TableCell>Justification</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows2().map((row) => (
                      <TableRow hover key={row._id}>
                        <TableCell component="th" scope="row">
                          <Chip label={row?.name} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.activity}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.justification ? row?.justification : "Nil"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Chip
                            label={split(row?.date).item1}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Chip
                            label={split(row?.date).item2}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    ))}

                    {emptyRowsTwo > 0 && (
                      <TableRow style={{ height: 53 * emptyRowsTwo }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                    {filteredRows2().length === 0 && (
                      <TableRow hover>
                        <TableCell
                          colSpan={10}
                          align="center"
                          style={{ color: "#616161" }}
                          component="th"
                          scope="row"
                        >
                          No Records To Display
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={filteredRows2().length}
                rowsPerPage={rowsPerPageTwo}
                page={pageTwo}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePageTwo}
                onChangeRowsPerPage={handleChangeRowsPerPageTwo}
                ActionsComponent={TablePaginationActions}
              />
            </Paper>
          ) : (
            <Typography
              gutterBottom
              variant="overline"
              style={{ textAlign: "center", display: "block" }}
            >
              ---------------------------- No History
              ----------------------------
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LeadHistoryModal;
