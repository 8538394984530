import React, { useState } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Paper,
  Tabs,
  Tab,
  AppBar,
  TextField,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
} from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import Dialog from "@material-ui/core/Dialog";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { useDispatch, connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Feedback from "../../../atoms/Feedback";
import { getCurrencies } from "../../../../actions/currencyActions";
import { standards } from "../../../../utils/standards";
import {
  getProductCategories,
  getServiceCategories,
} from "../../../../actions/vendorsActions";
import { countries } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  title2: {
    marginLeft: theme.spacing(2),
    flex: 1,
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  tab: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  gridForm: {
    padding: theme.spacing(3),
    backgroundImage:
      "radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255))",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  gridCarousel: {
    display: "block",
    background: "#263238",
    width: "100%",
    height: "100vh",
    position: "fixed",
    right: 0,
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(4, 3, 9, 0.25)),url(/img/oil-1.jpg);",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    boxShadow: "0px 5px 10px #888888",
  },
  paper: {
    padding: theme.spacing(6),
    // marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  carousel1: {
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(3, 2, 9, 0.25)),url(/img/oil-1.jpg);",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    // minHeight: "100vh",
    height: "100%",
    overflow: "scroll",

    // objectFit: "cover",
  },
  btnGroup: {
    marginTop: 40,
  },
  footerBottom: {
    marginTop: "2.5rem",
    textAlign: "center",
  },
  btn2: {
    marginTop: 20,
  },
  footerText: {
    color: "#bdbdbd",
  },
  ref: {
    fontWeight: 600,
    color: "#9e9e9e",
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // willChange: "opacity",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(3, 2, 9, 0.25))",
  },
  label: {
    fontSize: 14,
    marginBottom: 10,
  },
  tabs: {
    fontSize: 12,
    display: "block",
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const INIT_STATE = {
  companyName: "",
  officeAddress: "",
  registrationNumber: "",
  email: "",
  city: "",
  state: "",

  companyTelephone: "",
  website: "",
  contactPerson: "",
  contactDesignation: "",
  contactTelephone: "",
  contactEmail: "",
  businessType: "",
  yearEstablished: "",
  numberOfEmployee: "",
  businessCategory: "",
  relatedProduct: "",
  relatedService: "",

  BQMS: "",
  BQMSDocumentNo: "",
  standardCompliance: "",
  standard: "",
  otherStandard: "",
  QHSPolicy: "",
  policyDocumentName: "",
  QHSRisks: "",
  riskDocumentName: "",
  QHSEPolicyAderence: "",
  QHSTraining: "",
  safetyRep: "",

  tin: "",
  vat: "",
  accountName: "",
  accountNumber: "",
  bank: "",
  sortCode: "",
  branch: "",
  currency: "",
  bankContactPhone: "",
  workRefCompanyName: "",
  workRefCompanyAddress: "",
  workRefContactPerson: "",
  workRefContactDesignation: "",
  workRefContactEmail: "",
  workRefContactPhone: "",

  individualRefName: "",
  individualRefAddress: "",
  individualRefEmail: "",
  individualRefPhone: "",
};

const EditVendorModal = ({
  openEdit,
  handleCloseEdit,
  vendor,
  getVendorProfile,
  updateVendorProfile,
  getCurrencies,
  getProductCategories,
  getServiceCategories,
  currencies,
  products,
  services,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabPage, setTabPage] = useState(0);
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  React.useEffect(() => {
    setLoading((prev) => !prev);
    getCurrencies().then(() => {
      setLoading(false);
    });
  }, [getCurrencies]);

  React.useEffect(() => {
    let country = countries
      .map((country) => country)
      .filter((item) => item.label === (vendor && vendor.country));

    setState((prev) => ({
      ...prev,
      companyName: (vendor && vendor.companyName) || "",
      officeAddress: (vendor && vendor.officeAddress) || "",
      email: (vendor && vendor.email) || "",
      registrationNumber: (vendor && vendor.registrationNumber) || "",

      city: (vendor && vendor.city) || "",
      state: (vendor && vendor.state) || "",

      companyTelephone: (vendor && vendor.companyTelephone) || "",
      website: (vendor && vendor.website) || "",
      contactPerson: (vendor && vendor.contactPerson) || "",
      contactDesignation: (vendor && vendor.contactDesignation) || "",
      contactTelephone: (vendor && vendor.contactTelephone) || "",
      contactEmail: (vendor && vendor.contactEmail) || "",
      businessType: (vendor && vendor.businessType) || "",
      yearEstablished: (vendor && vendor.yearEstablished) || "",
      numberOfEmployee: (vendor && vendor.numberOfEmployee) || "",
      businessCategory: (vendor && vendor.businessCategory) || "",

      relatedProduct: (vendor && vendor.relatedProduct) || "",
      relatedService: (vendor && vendor.relatedService) || "",

      BQMS: vendor && vendor.BQMS,
      BQMSDocumentNo: (vendor && vendor.BQMSDocumentNo) || "",
      standardCompliance: vendor && vendor.standardCompliance,
      standard: (vendor && vendor.standard) || "",
      otherStandard: (vendor && vendor.otherStandard) || "",
      QHSPolicy: vendor && vendor.QHSPolicy,
      policyDocumentName: (vendor && vendor.policyDocumentName) || "",
      QHSRisks: vendor && vendor.QHSRisks,
      riskDocumentName: (vendor && vendor.riskDocumentName) || "",
      QHSEPolicyAderence: vendor && vendor.QHSEPolicyAderence,
      QHSTraining: vendor && vendor.QHSTraining,
      safetyRep: vendor && vendor.safetyRep,

      tin: (vendor && vendor.tin) || "",
      vat: (vendor && vendor.vat) || "",
      accountName: (vendor && vendor.accountName) || "",
      accountNumber: (vendor && vendor.accountNumber) || "",
      bank: (vendor && vendor.bank) || "",
      sortCode: (vendor && vendor.sortCode) || "",
      branch: (vendor && vendor.branch) || "",
      bankContactPhone: (vendor && vendor.bankContactPhone) || "",
      currency: (vendor && vendor.currency) || "",
      workRefCompanyName: (vendor && vendor.workRefCompanyName) || "",
      workRefCompanyAddress: (vendor && vendor.workRefCompanyAddress) || "",
      workRefContactPerson: (vendor && vendor.workRefContactPerson) || "",
      workRefContactDesignation:
        (vendor && vendor.workRefContactDesignation) || "",
      workRefContactEmail: (vendor && vendor.workRefContactEmail) || "",
      workRefContactPhone: (vendor && vendor.workRefContactPhone) || "",

      individualRefName: (vendor && vendor.individualRefName) || "",
      individualRefAddress: (vendor && vendor.individualRefAddress) || "",
      individualRefEmail: (vendor && vendor.individualRefEmail) || "",
      individualRefPhone: (vendor && vendor.individualRefPhone) || "",
    }));
    setCountry((country && country[0]) || null);
  }, [vendor]);

  React.useEffect(() => {
    getProductCategories();
  }, [getProductCategories]);

  React.useEffect(() => {
    getServiceCategories();
  }, [getServiceCategories]);

  const handleTabChange = (e, newValue) => {
    setTabPage(newValue);
  };

  const handleNextTab = () => {
    setTabPage((prev) => prev + 1);
  };

  const handlePrevTab = () => {
    setTabPage((prev) => prev - 1);
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCountry = (e, country) => {
    setCountry(country);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);

    const data = {
      ...state,
      country: country && country.label,
    };

    updateVendorProfile(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: "Profile Updated Successfully",
          success: true,
        });
        handleCloseEdit();
        dispatch(getVendorProfile());
      })
      .catch(() => {
        setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   // message: "Failed to update profile",
        //   success: false,
        // });
        handleCloseEdit();
      });
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

      <Dialog
        fullScreen
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          variant="elevation"
          position="fixed"
          color="default"
        >
          <Toolbar>
            <Tooltip title="close" arrow>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseEdit}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <Typography className={classes.title} variant="overline" gutterBottom>
            Update Profile
          </Typography>

          <AppBar position="static" color="transparent">
            <Tabs
              value={tabPage}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              // centered
            >
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    General Info.
                  </Typography>
                }
                wrapped
                {...a11yProps(0)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Business Info
                  </Typography>
                }
                wrapped
                {...a11yProps(1)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    QHSE Info.
                  </Typography>
                }
                wrapped
                {...a11yProps(2)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Bank Details
                  </Typography>
                }
                wrapped
                {...a11yProps(3)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Work Ref.
                  </Typography>
                }
                wrapped
                {...a11yProps(4)}
                className={classes.tabs}
                fullWidth
              />
            </Tabs>
          </AppBar>

          <form onSubmit={handleSubmit}>
            <Paper className={classes.paper} variant="outlined">
              <TabPanel value={tabPage} index={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="email"
                      type="email"
                      name="email"
                      label="Email"
                      onChange={handleChange}
                      value={state.email || ""}
                      fullWidth
                      placeholder="Email"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="companyName"
                      name="companyName"
                      label="Company Name"
                      onChange={handleChange}
                      value={state.companyName || ""}
                      fullWidth
                      placeholder="Company Name"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      variant="outlined"
                      id="officeAddress"
                      name="officeAddress"
                      label="Office Address"
                      onChange={handleChange}
                      value={state.officeAddress || ""}
                      fullWidth
                      placeholder="Office Address"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="city"
                      name="city"
                      label="City"
                      value={state.city || ""}
                      onChange={handleChange}
                      fullWidth
                      placeholder="City"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="state"
                      name="state"
                      label="State"
                      value={state.state || ""}
                      onChange={handleChange}
                      fullWidth
                      placeholder="State"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      fullWidth
                      id="combo-box-demo"
                      options={countries}
                      value={country}
                      name="country"
                      onChange={handleCountry}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => (
                        <React.Fragment>{option.label}</React.Fragment>
                      )}
                      autoComplete
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "disabled",
                            name: "country",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="companyTelephone"
                      name="companyTelephone"
                      label="Company Telephone"
                      fullWidth
                      value={state.companyTelephone || ""}
                      onChange={handleChange}
                      placeholder="Company Telephone"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="website"
                      name="website"
                      label="Website"
                      fullWidth
                      // required
                      value={state.website || ""}
                      onChange={handleChange}
                      placeholder="Website"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="contactPerson"
                      name="contactPerson"
                      label="Contact Person"
                      fullWidth
                      onChange={handleChange}
                      value={state.contactPerson || ""}
                      placeholder="Contact Person"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="contactDesignation"
                      name="contactDesignation"
                      label="Designation"
                      fullWidth
                      placeholder="Designation"
                      value={state.contactDesignation || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="contactTelephone"
                      name="contactTelephone"
                      label="Contact Telephone"
                      fullWidth
                      placeholder="Contact Telephone"
                      value={state.contactTelephone || ""}
                      onChange={handleChange}
                      type="tel"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="email"
                      id="contactEmail"
                      name="contactEmail"
                      label="Contact Email"
                      fullWidth
                      placeholder="Contact Email"
                      value={state.contactEmail || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabPage} index={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-controlled-open-select-label">
                        Business Type
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="businessType"
                        name="businessType"
                        inputProps={{
                          id: "businessType",
                          name: "businessType",
                        }}
                        value={state.businessType || ""}
                        onChange={handleChange}
                      >
                        <MenuItem value="" disabled>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Corporate Limited">
                          Corporate Limited
                        </MenuItem>
                        <MenuItem value="Partnership">Partnership</MenuItem>
                        <MenuItem value="Sole Proprietorship">
                          Sole Proprietorship
                        </MenuItem>
                        {/* <MenuItem value="Others">Others</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="yearEstablished"
                      name="yearEstablished"
                      label="Year Established"
                      fullWidth
                      placeholder="Year Established"
                      value={state.yearEstablished || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="numberOfEmployee"
                      name="numberOfEmployee"
                      label="No. of Employee"
                      fullWidth
                      placeholder="No. of Employee"
                      value={state.numberOfEmployee || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="registrationNumber"
                      name="registrationNumber"
                      label="Registration/CAC Number"
                      value={state.registrationNumber || ""}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Registration Number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="business_category">
                        Business Category
                      </InputLabel>
                      <Select
                        labelId="business_category"
                        id="businessCategory"
                        name="businessCategory"
                        inputProps={{
                          id: "businessCategory",
                          name: "businessCategory",
                        }}
                        value={state.businessCategory || ""}
                        onChange={handleChange}
                      >
                        <MenuItem value="" disabled>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="products">Products</MenuItem>
                        <MenuItem value="services">Services</MenuItem>
                        <MenuItem value="productsAndServices">
                          Products & Services
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {state.businessCategory === "products" ||
                  state.businessCategory === "productsAndServices" ? (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="related_product_label">
                          Related Product
                        </InputLabel>
                        <Select
                          labelId="related_product_label"
                          id="relatedProduct"
                          name="relatedProduct"
                          inputProps={{
                            id: "relatedProduct",
                            name: "relatedProduct",
                          }}
                          value={state.relatedProduct || ""}
                          onChange={handleChange}
                        >
                          <MenuItem value="" disabled>
                            <em>None</em>
                          </MenuItem>
                          {products &&
                            products.map((product) => (
                              <MenuItem key={product._id} value={product.name}>
                                {product.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}

                  {state.businessCategory === "services" ||
                  state.businessCategory === "productsAndServices" ? (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="related_service_label">
                          Related Service
                        </InputLabel>
                        <Select
                          labelId="related_service_label"
                          id="relatedService"
                          name="relatedService"
                          inputProps={{
                            id: "relatedService",
                            name: "relatedService",
                          }}
                          value={state.relatedService || ""}
                          onChange={handleChange}
                        >
                          {services &&
                            services.map((service) => (
                              <MenuItem key={service._id} value={service.name}>
                                {service.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="tin"
                      name="tin"
                      label="Tax Identification No."
                      fullWidth
                      placeholder="Tax Identification No."
                      value={state.tin || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="vat"
                      name="vat"
                      label="VAT Registration No."
                      fullWidth
                      placeholder="VAT Registration No."
                      value={state.vat || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabPage} index={2}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      component="fieldset"
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Does your company have any
                        Management System?
                      </FormLabel>

                      <Select
                        labelId="BQMS"
                        id="BQMS"
                        name="BQMS"
                        inputProps={{
                          id: "BQMS",
                          name: "BQMS",
                        }}
                        value={state.BQMS}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {state.BQMS && (
                      <>
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Enter Your Business
                          Quality Management Document Number
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          name="BQMSDocumentNo"
                          fullWidth
                          value={state.BQMSDocumentNo}
                          onChange={handleChange}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {state.BQMS && <Divider light />}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    {state.BQMS && (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        component="fieldset"
                      >
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> Can you confirm that your systems
                          comply with the requirements?
                        </FormLabel>

                        <Select
                          labelId="standardCompliance"
                          id="standardCompliance"
                          name="standardCompliance"
                          inputProps={{
                            id: "standardCompliance",
                            name: "standardCompliance",
                          }}
                          value={state.standardCompliance}
                          onChange={handleChange}
                        >
                          <MenuItem value={false} disabled>
                            <em>----Select Option----</em>
                          </MenuItem>

                          <MenuItem value={false}>No</MenuItem>
                          <MenuItem value={true}>Yes</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {state.standardCompliance && (
                      <>
                        <FormControl fullWidth variant="outlined">
                          <FormLabel
                            component="legend"
                            className={classes.label}
                          >
                            <strong>Q.</strong> If Yes? Please state the
                            standard
                            {""}
                            {""}
                          </FormLabel>

                          <Select
                            id="standard"
                            name="standard"
                            inputProps={{
                              id: "standard",
                              name: "standard",
                            }}
                            value={state.standard}
                            onChange={handleChange}
                          >
                            <MenuItem value={false} disabled>
                              <em>----Select Option----</em>
                            </MenuItem>

                            {standards.map((standard, i) => (
                              <MenuItem key={i} value={standard}>
                                {standard}
                              </MenuItem>
                            ))}
                            <MenuItem value="others">Others</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Grid>
                  {state.standard === "others" && state.standardCompliance && (
                    <Grid item xs={12} md={12}>
                      <FormLabel component="legend" className={classes.label}>
                        Please indicate the other standard your company conforms
                        to
                      </FormLabel>
                      <TextField
                        variant="outlined"
                        name="otherStandard"
                        fullWidth
                        value={state.otherStandard}
                        onChange={handleChange}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="overline"
                      gutterBottom
                      className={classes.ref}
                    >
                      Health, Safety & Environment Requirements
                    </Typography>
                    <Divider />
                  </Grid>
                  {/* </Grid> */}

                  <Grid item xs={12} md={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Does your company have a Quality,
                        Health & Safety Policy?
                      </FormLabel>

                      <Select
                        labelId="QHSPolicy"
                        id="QHSPolicy"
                        name="QHSPolicy"
                        inputProps={{
                          id: "QHSPolicy",
                          name: "QHSPolicy",
                        }}
                        value={state.QHSPolicy}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {state.QHSPolicy && (
                      <>
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Please indicate Document
                          Name
                          {""}
                          {""}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          name="policyDocumentName"
                          fullWidth
                          value={state.policyDocumentName}
                          onChange={handleChange}
                        />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Does your company have Systems to
                        Identify Significant Quality, Health & Safety Risks &
                        How They're Controlled?
                      </FormLabel>

                      <Select
                        labelId="QHSRisks"
                        id="QHSRisks"
                        name="QHSRisks"
                        inputProps={{
                          id: "QHSRisks",
                          name: "QHSRisks",
                        }}
                        value={state.QHSRisks}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {state.QHSRisks && (
                      <>
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Please indicate Document
                          Name
                          {""}
                          {""}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          name="riskDocumentName"
                          fullWidth
                          value={state.riskDocumentName}
                          onChange={handleChange}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Please Confirm that you'll adhere to
                        both RusselSmith's QHSE Policies and Where applicable,
                        comply with any project specific requirements requested
                        by Our Site Management to ensure the Safety of everyone
                        on Site is Maintained?
                      </FormLabel>

                      <Select
                        labelId="QHSEPolicyAderence"
                        id="QHSEPolicyAderence"
                        name="QHSEPolicyAderence"
                        inputProps={{
                          id: "QHSEPolicyAderence",
                          name: "QHSEPolicyAderence",
                        }}
                        value={state.QHSEPolicyAderence}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Does your company encourage and
                        provide proactive health and safety training?
                      </FormLabel>

                      <Select
                        labelId="QHSTraining"
                        id="QHSTraining"
                        name="QHSTraining"
                        inputProps={{
                          id: "QHSTraining",
                          name: "QHSTraining",
                        }}
                        value={state.QHSTraining}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Do you have appointed safety
                        representatives within your work force?
                      </FormLabel>

                      <Select
                        labelId="safetyRep"
                        id="safetyRep"
                        name="safetyRep"
                        inputProps={{
                          id: "safetyRep",
                          name: "safetyRep",
                        }}
                        value={state.safetyRep}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabPage} index={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="accountName"
                      name="accountName"
                      label="Account Name"
                      fullWidth
                      placeholder="Account Name"
                      value={state.accountName || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="accountNumber"
                      name="accountNumber"
                      label="Account Number"
                      fullWidth
                      placeholder="Account Number"
                      value={state.accountNumber || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="bank"
                      name="bank"
                      label="Bank"
                      fullWidth
                      placeholder="Bank"
                      value={state.bank || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="sortCode"
                      name="sortCode"
                      label="Sort/Swift Code"
                      fullWidth
                      placeholder="Sort/Swift Code"
                      value={state.sortCode || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      component="fieldset"
                    >
                      <InputLabel>Select Currency</InputLabel>

                      <Select
                        labelId="currency"
                        id="currency"
                        name="currency"
                        inputProps={{
                          id: "currency",
                          name: "currency",
                        }}
                        value={state.currency}
                        onChange={handleChange}
                      >
                        <MenuItem disabled>
                          <em>----Select Currency----</em>
                        </MenuItem>
                        {currencies &&
                          currencies.map((currency) => (
                            <MenuItem key={currency._id} value={currency.code}>
                              {currency.code}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="branch"
                      name="branch"
                      label="Branch"
                      fullWidth
                      placeholder="Branch"
                      value={state.branch || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="bankContactPhone"
                      name="bankContactPhone"
                      label="Contact Phone"
                      fullWidth
                      placeholder="Contact Phone"
                      value={state.bankContactPhone || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabPage} index={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="workRefCompanyName"
                      name="workRefCompanyName"
                      label="Company Name"
                      fullWidth
                      placeholder="Company Name"
                      value={state.workRefCompanyName || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="workRefCompanyAddress"
                      name="workRefCompanyAddress"
                      label="Company Address"
                      fullWidth
                      placeholder="Company Address"
                      value={state.workRefCompanyAddress || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="workRefContactPerson"
                      name="workRefContactPerson"
                      label="Contact Person"
                      fullWidth
                      placeholder="Contact Person"
                      value={state.workRefContactPerson || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="workRefContactDesignation"
                      name="workRefContactDesignation"
                      label="Contact Designation"
                      fullWidth
                      placeholder="Contact Designation"
                      value={state.workRefContactDesignation || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="email"
                      id="workRefContactEmail"
                      name="workRefContactEmail"
                      label="Contact Email"
                      fullWidth
                      placeholder="Contact Email"
                      value={state.workRefContactEmail || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="workRefContactPhone"
                      name="workRefContactPhone"
                      label="Contact Phone"
                      fullWidth
                      placeholder="Contact Phone"
                      value={state.workRefContactPhone || ""}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography variant="overline" className={classes.ref}>
                      Individual Reference
                    </Typography>

                    <Divider light />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="individualRefName"
                      name="individualRefName"
                      label="Name"
                      fullWidth
                      placeholder="Name"
                      value={state.individualRefName || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="individualRefAddress"
                      name="individualRefAddress"
                      label="Address"
                      fullWidth
                      placeholder="Address"
                      value={state.individualRefAddress || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      type="email"
                      id="individualRefEmail"
                      name="individualRefEmail"
                      label="Email"
                      fullWidth
                      placeholder="Email"
                      value={state.individualRefEmail || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="individualRefPhone"
                      name="individualRefPhone"
                      label="Phone"
                      fullWidth
                      placeholder="Phone"
                      value={state.individualRefPhone || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    className={classes.saveBtn}
                    disabled={loading}
                    size="large"
                  >
                    {loading ? "Loading..." : "Update"}
                  </Button>
                </Grid>
              </TabPanel>

              <ButtonGroup>
                <Button
                  onClick={handlePrevTab}
                  color="secondary"
                  disabled={tabPage === 0}
                  startIcon={<ArrowBackIosIcon />}
                  variant="contained"
                  className={classes.saveBtn}
                >
                  Prev
                </Button>
                <Button
                  onClick={handleNextTab}
                  color="primary"
                  disabled={tabPage === 4}
                  endIcon={<ArrowForwardIosIcon />}
                  variant="contained"
                  className={classes.saveBtn}
                >
                  Next
                </Button>
              </ButtonGroup>
            </Paper>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.vendors.products,
  services: state.vendors.services,
  currencies: state.currency.currencies,
});

const mapDispatchToProps = {
  getCurrencies,
  getProductCategories,
  getServiceCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVendorModal);
