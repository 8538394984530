import {
  GET_ALL_MODULES,
  CREATE_MODULES,
  UPDATE_MODULES,
  DELETE_MODULE,
  GET_MODULE,
  MODULE_ERROR,
  CREATE_SUBMODULES,
  UPDATE_SUBMODULE,
  GET_ALL_SUBMODULES,
  GET_ALL_MODULES_AND_SUBMODULES,
  GET_SUBMODULE,
  DELETE_SUBMODULE,
  SUBMODULE_ERROR,
} from "../types";
import axios from "axios";

const token = localStorage.getItem("token:key");

export const getAllModules = () => async (dispatch) => {
  try {
    const URL = `/api/v1/module`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_MODULES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllModulesAndSubmodules = () => async (dispatch) => {
  try {
    const URL = `/api/v1/module/subModule/concat`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_MODULES_AND_SUBMODULES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllSubModules = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/module/subModule/parent/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_SUBMODULES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getModule = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/module/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_MODULE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const getSubModule = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/module/subModule/data/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_SUBMODULE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const createModule = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/module`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_MODULES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const createSubModule = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/module/subModule/${data.parentId}`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_SUBMODULES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateModule = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/module/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_MODULES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateSubModule = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/module/subModule/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_SUBMODULE,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteModule = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/module/${id}`;
    await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_MODULE,
      payload: id,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: MODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: MODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: MODULE_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteSubModule = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/module/subModule/${id}`;
    await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_SUBMODULE,
      payload: id,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: SUBMODULE_ERROR,
        error: error.message,
      });
    }
  }
};
