import {
  GET_ALL_ACCESS_RIGHTS,
  CREATE_ACCESS_RIGHT,
  DELETE_SINGLE_ACCESS_RIGHT,
} from "../types/";

const INIT_STATE = {
  accessRights: [],
  accessRight: {},
};

const accessRightsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ACCESS_RIGHTS:
      return {
        ...state,
        accessRights: action.payload,
      };
    case CREATE_ACCESS_RIGHT:
      return {
        ...state,
        accessRight: action.payload,
      };
    case DELETE_SINGLE_ACCESS_RIGHT:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default accessRightsReducer;
