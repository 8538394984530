import * as types from "../types/inventory";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/inventory`;

//////////////////////////////////////////////
///////////// Inventory Count ////////////////
//////////////////////////////////////////////
export const createInventoryCount = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_INVENTORYCOUNT_REQUEST,
        });
        const { data } = await axios.post(`${URL}/receipt`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_INVENTORYCOUNT,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_INVENTORYCOUNT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getInventoryCounts = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_INVENTORYCOUNTS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/receipt`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_INVENTORYCOUNTS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_INVENTORYCOUNTS_FAIL,
            payload: error
        });
    }
};

export const getInventoryCount = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_INVENTORYCOUNT_REQUEST,
        });
        const { data } = await axios.get(`${URL}/receipt/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_INVENTORYCOUNT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_INVENTORYCOUNT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateInventoryCount = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_INVENTORYCOUNT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_INVENTORYCOUNT,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_INVENTORYCOUNT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteInventoryCount = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_INVENTORYCOUNT_REQUEST,
        });
        await axios.delete(`${URL}/receipt/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_INVENTORYCOUNT,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_INVENTORYCOUNT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitInventoryCount = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_INVENTORYCOUNT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_INVENTORYCOUNT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_INVENTORYCOUNT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptInventoryCount = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_INVENTORYCOUNT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_INVENTORYCOUNT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_INVENTORYCOUNT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineInventoryCount = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_INVENTORYCOUNT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_INVENTORYCOUNT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_INVENTORYCOUNT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveInventoryCount = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_INVENTORYCOUNT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_INVENTORYCOUNT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_INVENTORYCOUNT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectInventoryCount = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_INVENTORYCOUNT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_INVENTORYCOUNT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_INVENTORYCOUNT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
