import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../../atoms/Feedback";
import { getInventoryCategory, updateInventoryCategory, getAllInventoryCategories, } from "../../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    centered: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
    },
}));

const INIT_STATE = {
    name: "",
    code: "",
};

const EditCategoryModal = ({ openEdit, handleCloseEdit, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = React.useState(INIT_STATE);
    const { loading, error, invCate } = useSelector((state) => state.inventoryItem);

    React.useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    React.useEffect(() => {
        dispatch(getInventoryCategory(id));
    }, [dispatch, id]);

    React.useEffect(() => {
        setState((prev) => ({
            ...prev,
            name: (invCate && invCate.name) || "",
            code: (invCate && invCate.code) || "",
        }));
    }, [invCate]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            id,
        };
        await dispatch(updateInventoryCategory(data));
        setTimeout(() => {
            handleCloseEdit();
            dispatch(getAllInventoryCategories());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Inventory Categories updated Successfully.",
                success: true,
            }));
        }, 2000);
    };

    const isValid = () =>
        !state.name ||
        !state.code;

    return (
        <div>
            {error && error.error ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message || (error && error.error)}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message || `Inventory Category Updated successfully.`}
                />
            )}

            <Dialog
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Update Inventory Category
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseEdit}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleEditSubmit}>
                        <Grid container spacing={3} justify="center" alignItems="center">
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Name of Category"
                                    name="name"
                                    variant="outlined"
                                    value={state.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Category Code"
                                    name="code"
                                    variant="outlined"
                                    value={state.code}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    disabled={isValid() || loading}
                                    className={classes.saveBtn}
                                >
                                    {loading ? "Loading..." : "Update"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EditCategoryModal;
