import * as types from "../../types/people";

const initState = {
    forces: [],
    force: null,
    loading: false,
    error: null,
    success: false,
};

const changeFieldReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CREATE_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.CREATE_FIELD:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case types.CREATE_FIELD_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };    
        case types.GET_FIELDS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_FIELDS:
            return {
                ...state,
                loading: false,
                forces: action.payload.data,
                success: true,
            };
        case types.GET_FIELDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case types.GET_APPROVED_FIELDS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_APPROVED_FIELDS:
            return {
                ...state,
                loading: false,
                success: true,
                forces_approved: action.payload.data,
            };
        case types.GET_APPROVED_FIELDS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case types.GET_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.GET_FIELD:
            return {
                ...state,
                loading: false,
                force: action.payload.data,
                success: true,
            };
        case types.GET_FIELD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        
        case types.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.UPDATE_FIELD:
            return {
                ...state,
                loading: false,
                force: state.forces.map((item) =>
                    item._id === action.payload.data.id
                        ? (item = action.payload.data)
                        : item
                ),
            };
        case types.UPDATE_FIELD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        case types.DELETE_FIELD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case types.DELETE_FIELD:
            return {
                ...state,
                loading: false,
                forces: state.forces.filter(
                    (item) => item._id !== action.payload
                ),
                success: false,
            };
        case types.DELETE_FIELD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };

        default:
            return state;
    }
};

export default changeFieldReducer;
