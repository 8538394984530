module.exports = {
    CREATE_MOBNOTICE: "CREATE_MOBNOTICE",
    CREATE_MOBNOTICE_REQUEST: "CREATE_MOBNOTICE_REQUEST",
    CREATE_MOBNOTICE_FAIL: "CREATE_MOBNOTICE_FAIL",

    UPDATE_MOBNOTICE: "UPDATE_MOBNOTICE",
    UPDATE_MOBNOTICE_REQUEST: "UPDATE_MOBNOTICE_REQUEST",
    UPDATE_MOBNOTICE_FAIL: "UPDATE_MOBNOTICE_FAIL",

    DELETE_MOBNOTICE: "DELETE_MOBNOTICE",
    DELETE_MOBNOTICE_REQUEST: "DELETE_MOBNOTICE_REQUEST",
    DELETE_MOBNOTICE_FAIL: "DELETE_MOBNOTICE_FAIL",

    SUBMIT_MOBNOTICE: "SUBMIT_MOBNOTICE",
    SUBMIT_MOBNOTICE_REQUEST: "SUBMIT_MOBNOTICE_REQUEST",
    SUBMIT_MOBNOTICE_FAIL: "SUBMIT_MOBNOTICE_FAIL",

    GET_MOBNOTICES: "GET_MOBNOTICES",
    GET_MOBNOTICES_REQUEST: "GET_MOBNOTICES_REQUEST",
    GET_MOBNOTICES_FAIL: "GET_MOBNOTICES_FAIL",

    GET_MOBNOTICE: "GET_MOBNOTICE",
    GET_MOBNOTICE_REQUEST: "GET_MOBNOTICE_REQUEST",
    GET_MOBNOTICE_FAIL: "GET_MOBNOTICE_FAIL",

    REVIEW_MOBNOTICE: "REVIEW_MOBNOTICE",
    REVIEW_MOBNOTICE_REQUEST: "REVIEW_MOBNOTICE_REQUEST",
    REVIEW_MOBNOTICE_FAIL: "REVIEW_MOBNOTICE_FAIL",

    ACCEPT_MOBNOTICE: "ACCEPT_MOBNOTICE",
    ACCEPT_MOBNOTICE_REQUEST: "ACCEPT_MOBNOTICE_REQUEST",
    ACCEPT_MOBNOTICE_FAIL: "ACCEPT_MOBNOTICE_FAIL",

    DECLINE_MOBNOTICE: "DECLINE_MOBNOTICE",
    DECLINE_MOBNOTICE_REQUEST: "DECLINE_MOBNOTICE_REQUEST",
    DECLINE_MOBNOTICE_FAIL: "DECLINE_MOBNOTICE_FAIL",

    APPROVE_MOBNOTICE: "APPROVE_MOBNOTICE",
    APPROVE_MOBNOTICE_REQUEST: "APPROVE_MOBNOTICE_REQUEST",
    APPROVE_MOBNOTICE_FAIL: "APPROVE_MOBNOTICE_FAIL",

    REJECT_MOBNOTICE: "REJECT_MOBNOTICE",
    REJECT_MOBNOTICE_REQUEST: "REJECT_MOBNOTICE_REQUEST",
    REJECT_MOBNOTICE_FAIL: "REJECT_MOBNOTICE_FAIL",

    SEND_MOBNOTICE: "SEND_MOBNOTICE",
    SEND_MOBNOTICE_REQUEST: "SEND_MOBNOTICE_REQUEST",
    SEND_MOBNOTICE_FAIL: "SEND_MOBNOTICE_FAIL",

    CONFIRM_MOBNOTICE: "CONFIRM_MOBNOTICE",
    CONFIRM_MOBNOTICE_REQUEST: "CONFIRM_MOBNOTICE_REQUEST",
    CONFIRM_MOBNOTICE_FAIL: "CONFIRM_MOBNOTICE_FAIL",

    DEMOB_MOBNOTICE: "DEMOB_MOBNOTICE",
    DEMOB_MOBNOTICE_REQUEST: "DEMOB_MOBNOTICE_REQUEST",
    DEMOB_MOBNOTICE_FAIL: "DEMOB_MOBNOTICE_FAIL",

    GET_CUSTOMER_MOBNOTICE_REQUEST: "GET_CUSTOMER_MOBNOTICE_REQUEST",
    GET_CUSTOMER_MOBNOTICE: "GET_CUSTOMER_MOBNOTICE",
    GET_CUSTOMER_MOBNOTICE_ERROR: "GET_CUSTOMER_MOBNOTICE_ERROR",

    MARK_CUSTOMER_MOBNOTICE_REQUEST: "MARK_CUSTOMER_MOBNOTICE_REQUEST",
    MARK_CUSTOMER_MOBNOTICE: "MARK_CUSTOMER_MOBNOTICE",
    MARK_CUSTOMER_MOBNOTICE_ERROR: "MARK_CUSTOMER_MOBNOTICE_ERROR",

    RESET_MOBNOTICE: "RESET_MOBNOTICE",




    CREATE_EQUIPMENT_MANIFEST: "CREATE_EQUIPMENT_MANIFEST",
    CREATE_EQUIPMENT_MANIFEST_REQUEST: "CREATE_EQUIPMENT_MANIFEST_REQUEST",
    CREATE_EQUIPMENT_MANIFEST_FAIL: "CREATE_EQUIPMENT_MANIFEST_FAIL",

    UPDATE_EQUIPMENT_MANIFEST: "UPDATE_EQUIPMENT_MANIFEST",
    UPDATE_EQUIPMENT_MANIFEST_REQUEST: "UPDATE_EQUIPMENT_MANIFEST_REQUEST",
    UPDATE_EQUIPMENT_MANIFEST_FAIL: "UPDATE_EQUIPMENT_MANIFEST_FAIL",

    DELETE_EQUIPMENT_MANIFEST: "DELETE_EQUIPMENT_MANIFEST",
    DELETE_EQUIPMENT_MANIFEST_REQUEST: "DELETE_EQUIPMENT_MANIFEST_REQUEST",
    DELETE_EQUIPMENT_MANIFEST_FAIL: "DELETE_EQUIPMENT_MANIFEST_FAIL",

    SUBMIT_EQUIPMENT_MANIFEST: "SUBMIT_EQUIPMENT_MANIFEST",
    SUBMIT_EQUIPMENT_MANIFEST_REQUEST: "SUBMIT_EQUIPMENT_MANIFEST_REQUEST",
    SUBMIT_EQUIPMENT_MANIFEST_FAIL: "SUBMIT_EQUIPMENT_MANIFEST_FAIL",

    GET_EQUIPMENT_MANIFESTS: "GET_EQUIPMENT_MANIFESTS",
    GET_EQUIPMENT_MANIFESTS_REQUEST: "GET_EQUIPMENT_MANIFESTS_REQUEST",
    GET_EQUIPMENT_MANIFESTS_FAIL: "GET_EQUIPMENT_MANIFESTS_FAIL",

    GET_EQUIPMENT_MANIFEST: "GET_EQUIPMENT_MANIFEST",
    GET_EQUIPMENT_MANIFEST_REQUEST: "GET_EQUIPMENT_MANIFEST_REQUEST",
    GET_EQUIPMENT_MANIFEST_FAIL: "GET_EQUIPMENT_MANIFEST_FAIL",

    QHSE_EQUIPMENT_MANIFEST: "QHSE_EQUIPMENT_MANIFEST",
    QHSE_EQUIPMENT_MANIFEST_REQUEST: "QHSE_EQUIPMENT_MANIFEST_REQUEST",
    QHSE_EQUIPMENT_MANIFEST_FAIL: "QHSE_EQUIPMENT_MANIFEST_FAIL",

    SDV_EQUIPMENT_MANIFEST: "SDV_EQUIPMENT_MANIFEST",
    SDV_EQUIPMENT_MANIFEST_REQUEST: "SDV_EQUIPMENT_MANIFEST_REQUEST",
    SDV_EQUIPMENT_MANIFEST_FAIL: "SDV_EQUIPMENT_MANIFEST_FAIL",

    ISSUER_EQUIPMENT_MANIFEST: "ISSUER_EQUIPMENT_MANIFEST",
    ISSUER_EQUIPMENT_MANIFEST_REQUEST: "ISSUER_EQUIPMENT_MANIFEST_REQUEST",
    ISSUER_EQUIPMENT_MANIFEST_FAIL: "ISSUER_EQUIPMENT_MANIFEST_FAIL",

    RETURN_EQUIPMENT_MANIFEST: "RETURN_EQUIPMENT_MANIFEST",
    RETURN_EQUIPMENT_MANIFEST_REQUEST: "RETURN_EQUIPMENT_MANIFEST_REQUEST",
    RETURN_EQUIPMENT_MANIFEST_FAIL: "RETURN_EQUIPMENT_MANIFEST_FAIL",

    RESET_EQUIPMENT_MANIFEST: "RESET_EQUIPMENT_MANIFEST",


};
