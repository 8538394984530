import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Icon,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  CircularProgress,
  Backdrop,
  Chip,
  darken,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import VisibilityIcon from "@material-ui/icons/Visibility";

import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import SearchComponent from "../../SearchComponent";
import { TablePaginationActions } from "../../TablePaginationActions";
import CreateLeadsModal from "./modals/CreateLeadsModal";
import { getLeads } from "../../../actions/leadsAction";
import Feedback from "../../atoms/Feedback";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },

  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: darken("#ff9800", 0.1),
  },
  statusLemon: {
    borderColor: "#ffd600",
  },
}));

function Leads() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { loading, error, leads } = useSelector((state) => state.leads);
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    let abortController = new AbortController();

    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
      dispatch({
        type: "RESET_CONTACT_PERSON",
      });
    };
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(getLeads());
  }, [dispatch]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };
  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleSelectFilter = (e, val) => {
    setFilter(val.substr(0, 20));
    handleCloseFilter();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = (path) => {
    history.push(path);
  };

  let filteredRows = () =>
    leads === undefined
      ? []
      : leads.filter((row) => {
        if (search !== "") {
          return (
            row.documentNo
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            (row.date &&
              row.date.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            (row.week &&
              row.week.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            row.customer?.name
              ?.toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            row.contactPerson?.name
              ?.toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            row.salesPerson.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            row.leadType.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        } else {
          return row;
        }
      });

  let filtered = filteredRows();

  filteredRows = () =>
    filtered.filter((row) => {
      if (filter !== "") {
        return (
          row?.leadType?.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        );
      } else {
        return row;
      }
    });

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <CreateLeadsModal {...{ open, handleClose }} />
      <Typography variant="overline" gutterBottom className={classes.title}>
        Leads
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            className={classes.createBtn}
            endIcon={<Icon className="fas fa-plus" />}
            onClick={handleOpenModal}
          >
            Create
          </Button>
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton
              style={{ marginRight: 10, marginTop: 10 }}
              onClick={handleOpenFilter}
            >
              <FilterListIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseFilter}
              TransitionComponent={Fade}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                All
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Suspect")}>
                Suspect
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Prospect")}>
                Prospect
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Opportunity")}>
                Opportunity
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Won")}>
                Won
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Lost")}>
                Lost
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell>Contact Person</TableCell>
                <TableCell>Sales Person</TableCell>
                <TableCell>Document No.</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Week</TableCell>
                <TableCell>Last Contact Date</TableCell>
                <TableCell>Next Contact Date</TableCell>
                <TableCell>Lead Stage</TableCell>

                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={i}>
                    <TableCell component="th" scope="row">
                      {row.customer?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.contactPerson?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.salesPerson}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip label={row.documentNo} variant="outlined" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.date}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.week}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip label={row.nextContactDate ? moment(row.lastContactDate).format("l") : "NIL"} variant="outlined" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip label={row.nextContactDate ? moment(row.nextContactDate).format("l") : "NIL"} variant="outlined" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        label={row.leadType}
                        variant="outlined"
                        className={clsx({
                          [classes.statusOrange]:
                            row.leadType?.toLowerCase() === "suspect",
                          [classes.statusYellow]:
                            row.leadType?.toLowerCase() === "prospect",
                          [classes.statusLemon]:
                            row.leadType?.toLowerCase() === "opportunity",
                          [classes.statusDarkGreen]:
                            row.leadType?.toLowerCase() === "won",
                          [classes.statusRed]:
                            row.leadType?.toLowerCase() === "lost",
                        })}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        // color="primary"
                        onClick={() => navigate(`/crm/leads/${row._id}`)}
                      // disabled={
                      //   (row.leadType?.toLowerCase() !== "won" ||
                      //     row.leadType?.toLowerCase() !== "lost") &&
                      //   profile?.id !== row.createdBy?._id
                      // }
                      >
                        {row.leadType?.toLowerCase() === "won" ||
                          row.leadType?.toLowerCase() === "lost" ||
                          profile?.id !== row.createdBy?._id ? (
                          <VisibilityIcon />
                        ) : (
                          <EditIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Leads;
