import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  makeStyles,
  darken,
  Grid,
  Backdrop,
  CircularProgress,
  Paper,
  Divider,
  TextField,
  ButtonGroup,
  Popover,
  Fab,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import clsx from "clsx";
import {
  getSalesCallById,
  markSalesCallAsDone,
} from "../../../actions/salesCallActions";
import { useHistory, useParams } from "react-router";
import Progress from "../../Utils/Progress";
import Feedback from "../../atoms/Feedback";
import UpdateStageModal from "./modals/UpdateStageModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    marginTop: 20,
  },
  title4: {
    display: "block",
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  paper3: {
    padding: 20,
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  divider3: {
    marginTop: 10,
    marginBottom: 10,
  },
  submitBtn: {
    marginLeft: 10,
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,

    // padding: theme.spacing(2),
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  greenBg: {
    background: "#4caf50",
    color: "#fff",
    "&:hover": {
      background: darken("#4caf50", 0.2),
    },
  },
  greenBg2: {
    background: "#4caf50",
    color: "#fff",
    "&:disabled:hover": {
      background: darken("#4caf50", 0.2),
    },
  },
  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginLeft: 15,
    borderColor: "#000",
  },
  statusDone: {
    borderColor: "#4caf50",
  },

  danger: {
    color: "#ef5350",
    fontSize: 13,
    // marginTop: -25,
    display: "block",
  },
  info: {
    fontWeight: 500,
    fontSize: 13,
  },
  textGreen: {
    color: "#388e3c",
    fontSize: 15,
  },
  docs: {
    padding: theme.spacing(3),
    overflow: "hidden",
  },
  link: {},
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: darken("#ff9800", 0.1),
  },
  statusLemon: {
    borderColor: "#ffd600",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  btnSuspect: {
    "&:hover": {
      background: darken("#ff9800", 0.2),
    },
  },
  btnSuspectActive: {
    background: "#ff9800",
  },
  btnProspect: {
    "&:hover": {
      background: darken("#fbc02d", 0.2),
    },
  },
  btnProspectActive: {
    background: "#fbc02d",
  },
  btnOpportunity: {
    "&:hover": {
      background: darken("#ffd600", 0.2),
    },
  },
  btnOpportunityActive: {
    background: "#ffd600",
  },
  btnWon: {
    "&:hover": {
      background: darken("#4caf50", 0.2),
    },
  },
  btnWonActive: {
    background: "#4caf50",
  },
  btnLost: {
    "&:hover": {
      background: darken("#ef5350", 0.2),
    },
  },
  btnLostActive: {
    background: "#ef5350",
  },
  small: {
    display: "block",
    fontSize: 10,
    letterSpacing: 1.5,
  },
  mt20: {
    marginTop: theme.spacing(2),
  },
  mVertical: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  calendar: {
    border: "0px solid #ccc",
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    marginLeft: 10,
    background: theme.palette.type === "light" ? "#fff" : "#424242",
    "& > *": {
      // color: theme.palette.type === "light" ? "#303030" : "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  ml20: {
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  popover: {
    padding: theme.spacing(2),
  },
  greyLight: {
    color: "#333",
  },
  chip: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: darken("#ff9800", 0.1),
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  popoverType: {
    marginTop: 10,
    padding: "2px 5px",
    fontSize: 13,
    color: theme.palette.type === "light" ? "#000" : "#fff",
  },
  upper: {
    textTransform: "uppercase",
  },
  mb20: {
    marginBottom: 20,
  },
  gridResponsive: {
    width: 600,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const INIT_STATE = {
  activityType: "",
  summary: "",
  nextActivity: "",
  details: "",
  nextActivityDate: new Date(),
  date: new Date(),
  time: "",
  documents: [],
  status: "",
};

function ViewSalesCall() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useState(INIT_STATE);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [progress, setProgress] = useState(25);
  const [value, setValue] = useState(null);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { salesCall, loading, success } = useSelector(
    (state) => state.salesCall
  );
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getSalesCallById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (salesCall?.progress) {
      setProgress(+salesCall?.progress);
    }
  }, [salesCall]);

  useEffect(() => {
    if (success) {
      dispatch(getSalesCallById(id));

      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "Sales Call Updated Successfully.",
        success: true,
      }));
    }
    return () => {
      dispatch({
        type: "RESET_SALES_CALL",
      });
    };
  }, [success, id, dispatch]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      activityType: salesCall?.activityType,
      summary: salesCall?.summary,
      nextActivity: salesCall?.nextActivity,
      details: salesCall?.details,
      nextActivityDate: salesCall?.nextActivityDate
        ? new Date(salesCall?.nextActivityDate)
        : prev.nextActivityDate,
      date: salesCall?.date ? new Date(salesCall?.date) : prev.date,
      documents: salesCall?.documents || [],
      status: salesCall?.status,
      time: salesCall?.time,
    }));
  }, [salesCall]);

  const handleMarkAsDone = () => {
    dispatch(markSalesCallAsDone(id));
    setTimeout(() => {
      dispatch(getSalesCallById(id));
    }, 2000);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popId = open ? "simple-popover" : undefined;

  const formatDate = (date) => {
    return moment(date).format("MMMM Do YYYY");
  };

  const getTime = (time) => {
    if (time) {
      return moment(time).format("h:mm:ss a");
    } else {
      return null;
    }
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleOpenUpdate = (value) => {
    setValue(value);
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  return (
    <div>
      <UpdateStageModal
        {...{
          open: openUpdate,
          handleClose: handleCloseUpdate,
          value,
          id,
          loading,
        }}
      />
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <>
          <Typography gutterBottom variant="overline" className={classes.title}>
            View Sales Call
          </Typography>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.goBack()}
                startIcon={<ArrowBackIosIcon />}
                className={classes.createBtn}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Paper
                variant="outlined"
                className={clsx(
                  {
                    [classes.statusOrange]:
                      salesCall?.documentNo?.toLowerCase() === "pending",
                    [classes.statusDarkGreen]:
                      salesCall?.documentNo?.toLowerCase() === "done",
                  },
                  classes.status
                )}
              >
                <Typography variant="overline" className={classes.statusTitle}>
                  Doc. No.
                </Typography>
                <Divider className={classes.divider2} orientation="vertical" />
                {loading ? "Loading..." : salesCall?.documentNo?.toUpperCase()}
              </Paper>
            </Grid>
          </Grid>

          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.mt20}
            spacing={3}
          >
            <Grid item>
              {state?.status?.toLowerCase() !== "done" ? (
                <ButtonGroup
                  disabled={profile?._id !== salesCall?.createdBy?._id}
                >
                  <Button
                    className={classes.greenBg}
                    onClick={handleMarkAsDone}
                  >
                    {loading ? "Loading..." : "Mark Done"}
                  </Button>
                  <Button className={classes.greenBg2} disabled>
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <CheckIcon />
                    )}
                  </Button>
                </ButtonGroup>
              ) : null}
            </Grid>
            <Grid item>
              <Paper
                variant="outlined"
                className={clsx(
                  {
                    [classes.statusDone]:
                      state?.status?.toLowerCase() === "done",
                  },
                  classes.status
                )}
              >
                <Typography variant="overline" className={classes.statusTitle}>
                  Status
                </Typography>
                <Divider className={classes.divider2} orientation="vertical" />
                {loading ? "Loading..." : state?.status?.toUpperCase()}
              </Paper>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems={matches ? "flex-start" : "center"}
            justify="space-between"
            className={clsx(classes.mVertical)}
            direction={matches ? "column-reverse" : "row"}
          >
            <Typography
              variant="overline"
              display="block"
              className={classes.title4}
            >
              Details
            </Typography>
            <div>
              <Typography
                variant="caption"
                component="small"
                display="block"
                color="textSecondary"
                style={{
                  textTransform: "uppercase",
                  letterSpacing: 2,
                  fontSize: 8,
                }}
              >
                Lead Stages
              </Typography>
              <ButtonGroup
                variant="outlined"
                disabled={
                  state?.status?.toLowerCase() === "done" ||
                  profile?._id !== salesCall?.createdBy?._id
                }
              >
                <Tooltip arrow placement="top" title="Lost">
                  <Button
                    // onClick={() => handleProgress(0)}
                    onClick={() => handleOpenUpdate(0)}
                    className={clsx(
                      {
                        [classes.btnLostActive]: progress === 0,
                      },
                      classes.btnLost
                    )}
                    component="span"
                  >
                    0%
                  </Button>
                </Tooltip>
                <Tooltip arrow placement="top" title="Suspect">
                  <Button
                    // onClick={() => handleProgress(25)}
                    onClick={() => handleOpenUpdate(25)}
                    className={clsx(
                      {
                        [classes.btnSuspectActive]: progress === 25,
                      },
                      classes.btnSuspect
                    )}
                    component="span"
                  >
                    25%
                  </Button>
                </Tooltip>
                <Tooltip arrow placement="top" title="Prospect">
                  <Button
                    // onClick={() => handleProgress(50)}
                    onClick={() => handleOpenUpdate(50)}
                    className={clsx(
                      {
                        [classes.btnProspectActive]: progress === 50,
                      },
                      classes.btnProspect
                    )}
                    component="span"
                  >
                    50%
                  </Button>
                </Tooltip>
                <Tooltip arrow placement="top" title="Opportunity">
                  <Button
                    // onClick={() => handleProgress(75)}
                    onClick={() => handleOpenUpdate(75)}
                    className={clsx(
                      {
                        [classes.btnOpportunityActive]: progress === 75,
                      },
                      classes.btnOpportunity
                    )}
                    component="span"
                  >
                    75%
                  </Button>
                </Tooltip>
                <Tooltip arrow placement="top" title="Won">
                  <Button
                    // onClick={() => handleProgress(100)}
                    onClick={() => handleOpenUpdate(100)}
                    className={clsx(
                      {
                        [classes.btnWonActive]: progress === 100,
                      },
                      classes.btnWon
                    )}
                    component="span"
                  >
                    100%
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <div>
                <Progress value={progress} loading={loading} />
              </div>
            </div>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            className={classes.mt20}
          >
            <Grid
              item
              xs={12}
              md={8}
              component={Paper}
              className={classes.paper}
            >
              <Grid container justify="flex-start" spacing={2}>
                <Grid item md={6} xs={12}>
                  <DatePicker
                    autoOk
                    format="DD/MM/yyyy"
                    views={["year", "month", "date"]}
                    label="Activity Date"
                    fullWidth
                    inputVariant="outlined"
                    value={state.date}
                    disabled
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={state.summary || ""}
                    disabled
                    label="Summary"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={state.details || ""}
                    disabled
                    label="Details"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Calendar
                value={state.nextActivityDate}
                className={classes.calendar}
                onClickDay={(value, e) =>
                  formatDate(value) === formatDate(state.nextActivityDate)
                    ? setAnchorEl(e.currentTarget)
                    : null
                }
              />
              <Popover
                id={popId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div className={classes.popover}>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.mb20}
                  >
                    <Grid item>
                      <Typography
                        variant="overline"
                        component="small"
                        className={clsx(classes.small, classes.flex)}
                        gutterBottom
                      >
                        Next Activity <AccessAlarmIcon />
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Fab
                        size="small"
                        color="secondary"
                        onClick={() => setAnchorEl(false)}
                      >
                        <CloseIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                  <Typography>{state.nextActivity} </Typography>

                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <Paper
                        variant="outlined"
                        className={clsx(
                          classes.status,

                          classes.popoverType
                        )}
                      >
                        <span>Activity Type</span>
                        <Divider
                          className={classes.divider2}
                          orientation="vertical"
                        />
                        <strong>{state.activityType}</strong>
                      </Paper>
                    </Grid>
                    <Grid item>
                      {state.time && (
                        <Paper
                          variant="outlined"
                          className={clsx(
                            classes.status,

                            classes.popoverType
                          )}
                        >
                          <AccessTimeIcon />

                          <Divider
                            className={classes.divider2}
                            orientation="vertical"
                          />
                          <span className={classes.upper}>
                            {getTime(state.time)}
                          </span>
                        </Paper>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Popover>
            </Grid>
          </Grid>
          {salesCall?.documents?.length > 0 && (
            <Paper className={classes.paper}>
              <ul>
                {salesCall?.documents?.map((doc, i) => (
                  <li key={i}>
                    <a href={doc} target="_blank" rel="noopener noreferrer">
                      {doc}
                    </a>
                  </li>
                ))}
              </ul>
            </Paper>
          )}
        </>
      </MuiPickersUtilsProvider>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default ViewSalesCall;
