import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";

import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonGroup,
  Typography,
  makeStyles,
  CircularProgress,
  Grid,
  IconButton,
} from "@material-ui/core";
import Feedback from "../../../atoms/Feedback";

import { useHistory } from "react-router-dom";
import {
  getCashRecoveryById,
  ppmCompleteRecovery,
} from "../../../../actions/cashRecoveryAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const CompleteRecoveryModal = ({
  openFinalRecover,
  handleCloseFinalRecover,
  id,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const { loading, error } = useSelector((state) => state.cashRecovery);

  React.useEffect(() => {
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
  }, [error]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleRecover = async () => {
    let data = {
      id,
    };
    dispatch(ppmCompleteRecovery(data));
    setTimeout(() => {
      dispatch(getCashRecoveryById(id));
      handleCloseFinalRecover();
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "This Cash Recovery Has Been Acknowledged Successfully.",
        success: true,
      }));
    }, 1000);
    setTimeout(() => {
      history.push("/recovery/log");
    }, 2000);
  };
  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

      <Dialog
        open={openFinalRecover}
        keepMounted
        onClose={handleCloseFinalRecover}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {"Acknowledge?"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseFinalRecover}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="overline" className={classes.title3}>
            By Clicking "Acknowledge", It means you acknowledge this cash
            recovery?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseFinalRecover}
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRecover}
              color="primary"
              disabled={loading}
              className={classes.saveBtn}
              variant="contained"
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "Acknowledge"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompleteRecoveryModal;
