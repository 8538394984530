import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    Fab,
    InputAdornment,
    DialogContent,
    Divider,
    Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import Feedback from "../../../../atoms/Feedback";
import { getProfile } from "../../../../../actions/userActions";
import { getCustomers } from "../../../../../actions/customerActions";
import { getContactPersonByCustomer } from "../../../../../actions/contactPersonActions";
import { getAllPersonnelPriceList } from "../../../../../actions/masterListActions/personnelActions";
import { getAllEquipmentPriceList } from "../../../../../actions/masterListActions/equipmentAction";
import { getAllMaterialPriceList } from "../../../../../actions/masterListActions/materialActions";
import { getAllMobilizationPriceList } from "../../../../../actions/masterListActions/mobilizationActions";
import { createQuotation, getQuotations } from "../../../../../actions/quotationActions";
import { getCurrencies } from "../../../../../actions/currencyActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: "#444",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    title: "",
    employee: "",
    division: "",
    jobTitle: "",
    employeeId: "",
    customer: null,
    contactPerson: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonJob: "",
    contactPersonPhone: "",
    personnel: [],
    equipment: [],
    material: [],
    consumable: [],
    mob_demob: [],
    others: [],
    comment: "",
    noteToPricing: "",
    currency: "",
    sendComment: "",
};

const CreateQuotationModal = ({ openCreate, handleCreateClose, createQuotation }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const [dateCreated, setDateCreated] = useState(new Date());
    const [state, setState] = useState(INIT_STATE);

    const { profile } = useSelector((state) => state.user);
    const { currencies } = useSelector((state) => state.currency);
    const { customers } = useSelector((state) => state.customer);
    const { contactPersons } = useSelector((state) => state.contactPerson);
    const { personnels } = useSelector((state) => state.personnel);
    const { equipments } = useSelector((state) => state.equipment);
    const { materials } = useSelector((state) => state.material);
    const { loading, error } = useSelector((state) => state.quotation);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getCurrencies());
        dispatch(getAllEquipmentPriceList());
        dispatch(getAllPersonnelPriceList());
        dispatch(getAllMaterialPriceList());
        dispatch(getAllMobilizationPriceList());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCustomers());
    }, [dispatch]);

    useEffect(() => {
        if (state.customer !== null) {
            dispatch(getContactPersonByCustomer(state.customer._id || ""));
        }
    }, [dispatch, state.customer]);

    useEffect(() => {
        if (state.contactPersonName) {
            contactPersons &&
                contactPersons.map(
                    (item) =>
                        item.name === state.contactPersonName &&
                        setState((prev) => ({
                            ...prev,
                            contactPerson: item && item.id,
                            contactPersonEmail: item && item.email,
                            contactPersonJob: item && item.designation,
                            contactPersonPhone: item && item.phone,
                        }))
                );
        }
    }, [state.contactPersonName, contactPersons]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile.userName,
            division: profile && profile.division && profile.division.name,
            jobTitle: profile && profile.jobTitle,
            employeeId: profile && profile.employeeId,
        }));
    }, [profile]);

    const handleChangeDate = (date) => {
        setDateCreated(date);
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const isServices = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "user" && grp.module === "sales"
        )
        : false;

    const handleChangePersonnelCharges = (i) => (e) => {
        e.persist();
        const newPersonnelCharges = state.personnel.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            personnel: newPersonnelCharges,
        }));
    };

    const handleChangeEquipmentCharges = (i) => (e) => {
        e.persist();
        const newEquipmentCharges = state.equipment.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            equipment: newEquipmentCharges,
        }));
    };

    const handleChangeMaterialCharges = (i) => (e) => {
        e.persist();
        const newMaterialCharges = state.material.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            material: newMaterialCharges,
        }));
    };

    const handleChangeConsumableCharges = (i) => (e) => {
        e.persist();
        const newConsumableCharges = state.consumable.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            consumable: newConsumableCharges,
        }));
    };

    const handleChangeMobDemobCharges = (i) => (e) => {
        e.persist();
        const newMobDemobCharges = state.mob_demob.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            mob_demob: newMobDemobCharges,
        }));
    };

    const handleChangeOtherCharges = (i) => (e) => {
        e.persist();
        const newOtherCharges = state.others.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            others: newOtherCharges,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            division: profile && profile.division,
            currency: state.currency,
            quotationDate: moment(dateCreated).toDate(),
            customer: state.customer && state.customer._id,
            personnel: state.personnel,
            equipment: state.equipment,
            material: state.material,
            consumable: state.consumable,
            mob_demob: state.mob_demob,
            others: state.others,
            comment: state.comment,
            sendComment: state.sendComment,
            noteToPricing: state.noteToPricing,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(createQuotation(data));
        });
        dispatch(getQuotations());
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Quotation Created Successfully.",
            success: true,
        }));
        handleCreateClose();
        console.log(res)
    };

    const handleAddPersonnelCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            duration: "",
            unitRate: null,
            totalRate: null
        };
        setState((prev) => ({
            ...prev,
            personnel: prev.personnel.concat([data]),
        }));
    };

    const handleRemovePersonnelCharges = (i) => {
        setState((prev) => ({
            ...prev,
            personnel: prev.personnel.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddEquipmentCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            duration: "",
            unitRate: null,
            totalRate: null
        };
        setState((prev) => ({
            ...prev,
            equipment: prev.equipment.concat([data]),
        }));
    };

    const handleRemoveEquipmentCharges = (i) => {
        setState((prev) => ({
            ...prev,
            equipment: prev.equipment.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddMaterialCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            duration: "",
            unitRate: "",
            totalRate: ""
        };
        setState((prev) => ({
            ...prev,
            material: prev.material.concat([data]),
        }));
    };

    const handleRemoveMaterialCharges = (i) => {
        setState((prev) => ({
            ...prev,
            material: prev.material.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddConsumableCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            unitRate: null,
            totalRate: null
        };
        setState((prev) => ({
            ...prev,
            consumable: prev.consumable.concat([data]),
        }));
    };

    const handleRemoveConsumableCharges = (i) => {
        setState((prev) => ({
            ...prev,
            consumable: prev.consumable.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddMobDemobCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            kind: "",
            totalAmount: null
        };
        setState((prev) => ({
            ...prev,
            mob_demob: prev.mob_demob.concat([data]),
        }));
    };

    const handleRemoveMobDemobCharges = (i) => {
        setState((prev) => ({
            ...prev,
            mob_demob: prev.mob_demob.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddOtherCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            kind: "",
            currency: "",
            totalAmount: null
        };
        setState((prev) => ({
            ...prev,
            others: prev.others.concat([data]),
        }));
    };

    const handleRemoveOtherCharges = (i) => {
        setState((prev) => ({
            ...prev,
            others: prev.others.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const validCheck = () =>
        !state.title ||
        !dateCreated ||
        !state.division ||
        !state.jobTitle ||
        !state.employee ||
        !state.employeeId ||
        !state.customer ||
        !state.contactPersonName ||
        !state.contactPerson ||
        !state.contactPersonEmail ||
        !state.contactPersonJob ||
        !state.contactPersonPhone ||
        !state.noteToPricing ||
        !state.sendComment ||
        !state.currency;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCreateClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCreateClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Quotation
                                </Typography>

                                <Typography variant="overline" className={classes.title2}>
                                    Basic Information
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}
                                        className={classes.grid}
                                    >
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Prepared By"
                                                name="employee"
                                                value={state.employee || ""}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Division/Subdivision"
                                                name="division"
                                                value={state.division || ""}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel>Currency</InputLabel>
                                                <Select
                                                    inputProps={{
                                                        id: "currency",
                                                        name: "currency",
                                                    }}
                                                    value={state.currency}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem disabled>Select Currency</MenuItem>
                                                    {currencies.map((currency) => (
                                                        <MenuItem key={currency._id} value={currency.code}>
                                                            {currency.code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <DatePicker
                                                className={classes.marginBottom}
                                                autoOk
                                                format="DD/MM/yyyy"
                                                views={["year", "month", "date"]}
                                                label="Date Created"
                                                fullWidth
                                                inputVariant="outlined"
                                                value={dateCreated}
                                                onChange={handleChangeDate}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            <Divider
                                                                className={classes.divider2}
                                                                orientation="vertical"
                                                            />
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    disabled={dateCreated === null}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setDateCreated(null);
                                                                    }}
                                                                >
                                                                    {dateCreated ? (
                                                                        <CloseIcon />
                                                                    ) : (
                                                                        <FilterListIcon />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Typography variant="overline" className={classes.title2}>
                                    Quotation &amp; Customer Information
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={1}
                                        className={classes.grid}
                                    >
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quotation Title"
                                                name="title"
                                                value={state.title || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth required variant="outlined" >
                                                <InputLabel id="customer">
                                                    Customer Name
                                                </InputLabel>
                                                <Select
                                                    labelId="Customer Name"
                                                    name="customer"
                                                    value={state.customer || ""}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem disabled value="">
                                                        Select
                                                    </MenuItem>
                                                    {customers &&
                                                        customers.map((item) => (
                                                            <MenuItem key={item._id} value={item}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth required variant="outlined" >
                                                <InputLabel id="contactPersonName">
                                                    Contact Person
                                                </InputLabel>
                                                <Select
                                                    labelId="contactPersonName"
                                                    name="contactPersonName"
                                                    value={state.contactPersonName || ""}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem disabled value="">
                                                        Select
                                                    </MenuItem>
                                                    {contactPersons &&
                                                        contactPersons.map((item) => (
                                                            <MenuItem key={item._id} value={item.name}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                style={{ marginTop: 10 }}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                type="email"
                                                label="Contact Person Email"
                                                name="contactPersonEmail"
                                                value={state.contactPersonEmail || ""}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                style={{ marginTop: 10 }}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Job Title"
                                                name="contactPersonJob"
                                                value={state.contactPersonJob || ""}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                style={{ marginTop: 10 }}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Phone Number"
                                                type="tel"
                                                name="contactPersonPhone"
                                                value={state.contactPersonPhone || ""}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>

                                {/* To be filled by the Commercials head or designate */}
                                {isServices ? (
                                    <>
                                        <Typography variant="overline" className={classes.title2}>
                                            Personnel Charges
                                        </Typography>

                                        <Paper variant="outlined" square className={classes.paper}>
                                            {state.personnel.map((item, i) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                    key={i}
                                                >
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth required variant="outlined">
                                                            <InputLabel id="description">
                                                                Personnel Description
                                                            </InputLabel>
                                                            <Select
                                                                labelId="description"
                                                                name="description"
                                                                value={item.description || ""}
                                                                onChange={handleChangePersonnelCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select</MenuItem>
                                                                {personnels &&
                                                                    personnels.map((item) => (
                                                                        <MenuItem key={item._id} value={item.description}>
                                                                            {item.description}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Quantity"
                                                            type="number"
                                                            name="quantity"
                                                            value={item.quantity}
                                                            onChange={handleChangePersonnelCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Duration"
                                                            name="duration"
                                                            value={item.duration || ""}
                                                            onChange={handleChangePersonnelCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth variant="outlined" required>
                                                            <InputLabel>Currency</InputLabel>
                                                            <Select
                                                                inputProps={{
                                                                    id: "currency",
                                                                    name: "currency",
                                                                }}
                                                                value={item.currency || ""}
                                                                onChange={handleChangePersonnelCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select Currency</MenuItem>
                                                                {currencies.map((currency) => (
                                                                    <MenuItem key={currency._id} value={currency.code}>
                                                                        {currency.code}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Daily Rate"
                                                            name="unitRate"
                                                            value={item.unitRate || ""}
                                                            onChange={handleChangePersonnelCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Total Rate"
                                                            name="totalRate"
                                                            value={item.totalRate || ""}
                                                            onChange={handleChangePersonnelCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <Fab
                                                            color="secondary"
                                                            onClick={() => handleRemovePersonnelCharges(i)}
                                                        >
                                                            <CloseIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() =>
                                                    handleAddPersonnelCharges(state.personnel.length + 1)
                                                }
                                                endIcon={<AddIcon fontSize="large" />}
                                            >
                                                Insert Item
                                            </Button>
                                        </Paper>

                                        <Typography variant="overline" className={classes.title2}>
                                            Equipment Charges
                                        </Typography>

                                        <Paper variant="outlined" square className={classes.paper}>
                                            {state.equipment.map((item, i) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                    key={i}
                                                >
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth required variant="outlined">
                                                            <InputLabel id="description">
                                                                Equipment Description
                                                            </InputLabel>
                                                            <Select
                                                                labelId="description"
                                                                name="description"
                                                                value={item.description || ""}
                                                                onChange={handleChangeEquipmentCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select</MenuItem>
                                                                {equipments &&
                                                                    equipments.map((item) => (
                                                                        <MenuItem key={item._id} value={item.description}>
                                                                            {item.description}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Quantity"
                                                            type="number"
                                                            name="quantity"
                                                            value={item.quantity}
                                                            onChange={handleChangeEquipmentCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth variant="outlined" required>
                                                            <InputLabel>Currency</InputLabel>
                                                            <Select
                                                                inputProps={{
                                                                    id: "currency",
                                                                    name: "currency",
                                                                }}
                                                                value={item.currency || ""}
                                                                onChange={handleChangeEquipmentCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select Currency</MenuItem>
                                                                {currencies.map((currency) => (
                                                                    <MenuItem key={currency._id} value={currency.code}>
                                                                        {currency.code}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Duration"
                                                            name="duration"
                                                            value={item.duration || ""}
                                                            onChange={handleChangeEquipmentCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Daily Rate"
                                                            name="unitRate"
                                                            value={item.unitRate || ""}
                                                            onChange={handleChangeEquipmentCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Total Rate"
                                                            name="totalRate"
                                                            value={item.totalRate || ""}
                                                            onChange={handleChangeEquipmentCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <Fab
                                                            color="secondary"
                                                            onClick={() => handleRemoveEquipmentCharges(i)}
                                                        >
                                                            <CloseIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() =>
                                                    handleAddEquipmentCharges(state.equipment.length + 1)
                                                }
                                                endIcon={<AddIcon fontSize="large" />}
                                            >
                                                Insert Item
                                            </Button>
                                        </Paper>

                                        <Typography variant="overline" className={classes.title2}>
                                            Material Charges
                                        </Typography>

                                        <Paper variant="outlined" square className={classes.paper}>
                                            {state.material.map((item, i) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                    key={i}
                                                >
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth required variant="outlined">
                                                            <InputLabel id="description">
                                                                Material Description
                                                            </InputLabel>
                                                            <Select
                                                                labelId="description"
                                                                name="description"
                                                                value={item.description || ""}
                                                                onChange={handleChangeMaterialCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select</MenuItem>
                                                                {materials &&
                                                                    materials.map((item) => (
                                                                        <MenuItem key={item._id} value={item.description}>
                                                                            {item.description}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Quantity"
                                                            type="number"
                                                            name="quantity"
                                                            value={item.quantity}
                                                            onChange={handleChangeMaterialCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth variant="outlined" required>
                                                            <InputLabel>Currency</InputLabel>
                                                            <Select
                                                                inputProps={{
                                                                    id: "currency",
                                                                    name: "currency",
                                                                }}
                                                                value={item.currency || ""}
                                                                onChange={handleChangeMaterialCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select Currency</MenuItem>
                                                                {currencies.map((currency) => (
                                                                    <MenuItem key={currency._id} value={currency.code}>
                                                                        {currency.code}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Duration"
                                                            name="duration"
                                                            value={item.duration || ""}
                                                            onChange={handleChangeMaterialCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Daily Rate"
                                                            name="unitRate"
                                                            value={item.unitRate || ""}
                                                            onChange={handleChangeMaterialCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Total Rate"
                                                            name="totalRate"
                                                            value={item.totalRate || ""}
                                                            onChange={handleChangeMaterialCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <Fab
                                                            color="secondary"
                                                            onClick={() => handleRemoveMaterialCharges(i)}
                                                        >
                                                            <CloseIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() =>
                                                    handleAddMaterialCharges(state.material.length + 1)
                                                }
                                                endIcon={<AddIcon fontSize="large" />}
                                            >
                                                Insert Item
                                            </Button>
                                        </Paper>

                                        <Typography variant="overline" className={classes.title2}>
                                            Consumable Charges
                                        </Typography>

                                        <Paper variant="outlined" square className={classes.paper}>
                                            {state.consumable.map((item, i) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                    key={i}
                                                >
                                                    <Grid item xs={12} md={3}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Description"
                                                            type="text"
                                                            name="description"
                                                            value={item.description}
                                                            onChange={handleChangeConsumableCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Quantity"
                                                            type="number"
                                                            name="quantity"
                                                            value={item.quantity}
                                                            onChange={handleChangeConsumableCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth variant="outlined" required>
                                                            <InputLabel>Currency</InputLabel>
                                                            <Select
                                                                inputProps={{
                                                                    id: "currency",
                                                                    name: "currency",
                                                                }}
                                                                value={item.currency || ""}
                                                                onChange={handleChangeConsumableCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select Currency</MenuItem>
                                                                {currencies.map((currency) => (
                                                                    <MenuItem key={currency._id} value={currency.code}>
                                                                        {currency.code}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Unit/Daily Rate"
                                                            name="unitRate"
                                                            value={item.unitRate || ""}
                                                            onChange={handleChangeConsumableCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Total Rate"
                                                            name="totalRate"
                                                            value={item.totalRate || ""}
                                                            onChange={handleChangeConsumableCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <Fab
                                                            color="secondary"
                                                            onClick={() => handleRemoveConsumableCharges(i)}
                                                        >
                                                            <CloseIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() =>
                                                    handleAddConsumableCharges(state.consumable.length + 1)
                                                }
                                                endIcon={<AddIcon fontSize="large" />}
                                            >
                                                Insert Item
                                            </Button>
                                        </Paper>

                                        <Typography variant="overline" className={classes.title2}>
                                            Mob / Demob Charges
                                        </Typography>

                                        <Paper variant="outlined" square className={classes.paper}>
                                            {state.mob_demob.map((item, i) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                    key={i}
                                                >
                                                    <Grid item xs={12} md={3}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Description"
                                                            type="text"
                                                            name="description"
                                                            value={item.description}
                                                            onChange={handleChangeMobDemobCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Quantity"
                                                            type="number"
                                                            name="quantity"
                                                            value={item.quantity}
                                                            onChange={handleChangeMobDemobCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth variant="outlined" required>
                                                            <InputLabel>Currency</InputLabel>
                                                            <Select
                                                                inputProps={{
                                                                    id: "currency",
                                                                    name: "currency",
                                                                }}
                                                                value={item.currency || ""}
                                                                onChange={handleChangeMobDemobCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select Currency</MenuItem>
                                                                {currencies.map((currency) => (
                                                                    <MenuItem key={currency._id} value={currency.code}>
                                                                        {currency.code}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Kind"
                                                            name="kind"
                                                            value={item.kind || ""}
                                                            onChange={handleChangeMobDemobCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Total Amount"
                                                            name="totalAmount"
                                                            value={item.totalAmount || ""}
                                                            onChange={handleChangeMobDemobCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <Fab
                                                            color="secondary"
                                                            onClick={() => handleRemoveMobDemobCharges(i)}
                                                        >
                                                            <CloseIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() =>
                                                    handleAddMobDemobCharges(state.mob_demob.length + 1)
                                                }
                                                endIcon={<AddIcon fontSize="large" />}
                                            >
                                                Insert Item
                                            </Button>
                                        </Paper>

                                        <Typography variant="overline" className={classes.title2}>
                                            Other Charges
                                        </Typography>

                                        <Paper variant="outlined" square className={classes.paper}>
                                            {state.others.map((item, i) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                    key={i}
                                                >
                                                    <Grid item xs={12} md={3}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Description"
                                                            type="text"
                                                            name="description"
                                                            value={item.description}
                                                            onChange={handleChangeOtherCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Quantity"
                                                            type="number"
                                                            name="quantity"
                                                            value={item.quantity}
                                                            onChange={handleChangeOtherCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <FormControl fullWidth variant="outlined" required>
                                                            <InputLabel>Currency</InputLabel>
                                                            <Select
                                                                inputProps={{
                                                                    id: "currency",
                                                                    name: "currency",
                                                                }}
                                                                value={item.currency || ""}
                                                                onChange={handleChangeOtherCharges(i)}
                                                            >
                                                                <MenuItem disabled>Select Currency</MenuItem>
                                                                {currencies.map((currency) => (
                                                                    <MenuItem key={currency._id} value={currency.code}>
                                                                        {currency.code}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Kind"
                                                            name="kind"
                                                            value={item.kind || ""}
                                                            onChange={handleChangeOtherCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            label="Total Amount"
                                                            name="totalAmount"
                                                            value={item.totalAmount || ""}
                                                            onChange={handleChangeOtherCharges(i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={1}>
                                                        <Fab
                                                            color="secondary"
                                                            onClick={() => handleRemoveOtherCharges(i)}
                                                        >
                                                            <CloseIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() =>
                                                    handleAddOtherCharges(state.others.length + 1)
                                                }
                                                endIcon={<AddIcon fontSize="large" />}
                                            >
                                                Insert Item
                                            </Button>
                                        </Paper>
                                    </>
                                ) : null}

                                <Typography variant="overline" className={classes.title2}>
                                    Note To Pricing
                                </Typography>
                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows={3}
                                            label="Note to Pricing"
                                            type="text"
                                            name="noteToPricing"
                                            value={state.noteToPricing || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Paper>

                                <Typography variant="overline" className={classes.title2}>
                                    Cover Letter to Customer
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Cover Letter to Customer"
                                            multiline
                                            required
                                            rows={3}
                                            type="text"
                                            name="sendComment"
                                            value={state.sendComment || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Paper>

                                <Typography variant="overline" className={classes.title2}>
                                    Comment
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Comment"
                                            multiline
                                            rows={3}
                                            type="text"
                                            name="comment"
                                            value={state.comment || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Paper>

                                {isServices ? (
                                    <div className={classes.btnWrapper}>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn}
                                            disabled={loading || validCheck()}
                                            color="primary"
                                            variant="contained"
                                        >
                                            {loading ? "Loading..." : "Save"}
                                        </Button>
                                    </div>
                                ) : null}

                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createQuotation })(CreateQuotationModal);
