import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Backdrop,
  Chip,
  Icon,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import SearchComponent from "../../../SearchComponent";
import { TablePaginationActions } from "../../../TablePaginationActions";
import CreateCWSRModal from "../modals/CreateCWSRModal";
import { getAllCwsrs } from "../../../../actions/cwsrActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Feedback from "../../../atoms/Feedback";
import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const CWSR = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [openCreate, setOpenCreate] = useState(false);

  const [search, setSearch] = useState("");
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { cwsrs, loading, error } = useSelector((state) => state.cwsr);
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
  }, [error]);

  useEffect(() => {
    dispatch(getAllCwsrs());
  }, [dispatch]);

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const formatDate = (date) => {
    return moment(date).format("ll");
  };

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };
  let filteredRows = () =>
    cwsrs === undefined
      ? []
      : cwsrs &&
      cwsrs.filter((row) => {
        if (search !== "") {
          return (
            row.documentNo
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            (row.customer &&
              row.customer.name
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            row.serviceType
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            (row.division &&
              row.division.name
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            row.contactPerson
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            row.contactPersonEmail
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            row.contactPersonPhone
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1
          );
        } else {
          return row;
        }
      });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCreateOpen = () => {
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isServices = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "head" && grp.module === "sales"
    )
    : false;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <CreateCWSRModal {...{ openCreate, handleCreateClose }} />
      <Typography variant="overline" gutterBottom className={classes.title}>
        Customer Work Scope Requirements Log
      </Typography>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 5, marginBottom: 30 }}
            onClick={handleCreateOpen}
            className={classes.createBtn}
          >
            Create
          </Button>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item style={{ width: "100%" }}>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Paper>
        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Document No.</TableCell>
                <TableCell>Division</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Date Received</TableCell>
                <TableCell>Service Type</TableCell>
                <TableCell>Contact Name</TableCell>
                <TableCell>Contact Email</TableCell>
                <TableCell>Contact Tel.</TableCell>

                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      <Chip label={row.documentNo} variant="outlined" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.division && row.division.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.customer && row.customer.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        style={{ padding: 10 }}
                        icon={
                          <Icon
                            className="fas fa-calendar-alt"
                            fontSize="small"
                            color="disabled"
                          />
                        }
                        label={row.dateReceived && formatDate(row.dateReceived)}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.serviceType}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.contactPerson}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.contactPersonEmail}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.contactPersonPhone}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        style={{ padding: 10 }}
                        label={row.status}
                        variant="outlined"
                        className={clsx(
                          row.status === "draft"
                            ? classes.statusYellow
                            : row.status === "submitted" || row.status === "declined"
                              ? classes.statusOrange
                              : row.status === "approved" || row.status === "reviewed"
                                ? classes.statusGreen
                                : row.status === "accepted"
                                  ? classes.statusDarkGreen
                                  : classes.statusRed,
                          classes.chip
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      {row.status === "draft" || row.status === "declined" || row.status === "rejected" || row.status === "tomodify" || row.status === "accepted" ? (
                        <IconButton
                          onClick={() => navigateById("/sales/cwsr", row._id)}
                          disabled={row.status === "submitted" || row.status === "approved" || row.status === "reviewed"}
                          variant="extended"
                        >
                          <Icon className="fas fa-pencil-alt" color="primary" />
                        </IconButton>
                      ) : (row.status === "submitted" || row.status === "reviewed" || row.status === "approved" ? (
                        // BD Head reviews or declines the CWSR
                        // Service Division Head approves or rejects the CWSR
                        // Commercials accepts or request modification of
                        isServices && row.status === "reviewed" && row.serviceMethod === "" ? (
                          <IconButton
                            color="inherit"
                            onClick={() => navigateById("/sales/cwsr/review", row._id)}
                            disabled={row.status === "draft" || row.status === "submitted" || row.status === "approved"}
                            variant="extended"
                          >
                            <Icon className="fas fa-eye" color="action" />
                          </IconButton>
                        ) : (<IconButton
                          color="inherit"
                          onClick={() => navigateById("/sales/cwsr/view", row._id)}
                          disabled={row.status === "draft"}
                          variant="extended"
                        >
                          <Icon className="fas fa-eye" color="action" />
                        </IconButton>)

                      ) : (
                        <IconButton
                          color="inherit"
                          onClick={() => navigateById("/sales/cwsr/view", row._id)}
                          disabled={row.status === "draft"}
                          variant="extended"
                        >
                          <Icon className="fas fa-eye" color="action" />
                        </IconButton>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  );
};

export default CWSR;
