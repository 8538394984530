import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    Fab,
    TextField,
    Grid,
    FormControl,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Icon,
    Button,
    Divider,
    darken,
    Backdrop,
    InputLabel,
    MenuItem,
    Select,
    ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { getCIM, updateCIM, getCIMs, submitCIM } from "../../../../actions/ppmActions/changeMgtActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        marginBottom: 30,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        padddingBottom: 0,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper1: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: darken("#fff", 0.05),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: darken("#fff", 0.15),
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    month: "",
    year: "",
    expectedOutcomes: [],
};

const INIT_OUTCOME = {
    duration: "",
    outcome: "",
    category: "",
    activity: "",
}

const EditChangeImpact = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [outcome, setOutcome] = useState(INIT_OUTCOME);
    const { error, loading, cim } = useSelector((state) => state.changeImpact);
    
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getCIM(id));
        dispatch(getCIMs());
    }, [dispatch, id]);

    const handleOutcomeChange = (e) => {
        e.persist();
        setOutcome((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddOutcomeArray = (item) => {
        if (item.duration !== "" && item.outcome !== "" && item.positive !== "" && item.negative !== "") {
            setState((prev) => ({
                ...prev,
                expectedOutcomes: prev.expectedOutcomes.concat([item]),
            }));
            setOutcome(INIT_OUTCOME);
        }
    };

    const handleRemoveOutcomeArray = (i) => {
        setState((prev) => ({
            ...prev,
            expectedOutcomes: prev.expectedOutcomes.filter(
                (_, index) => index !== i
            ),
        }));
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            changeRequest: (cim && cim.changeRequest) || "",
            expectedOutcomes: (cim && cim.expectedOutcomes) || [],
            requestDate: (cim && cim.requestDate) || "",
            division: (cim && cim.division) || "",
            month: (cim && cim.month) || "",
            year: (cim && cim.year) || "",

            status: (cim && cim.status) || "",
            documentNo: (cim && cim.documentNo) || "",
            
            createdBy: (cim && cim.createdBy) || "",
            createdAt: (cim && cim.createdAt) || "",

            submittedBy: (cim && cim.submittedBy) || "",
            submittedAt: (cim && cim.submittedAt) || "",

            approvedBy: (cim && cim.approvedBy) || "",
            approvedAt: (cim && cim.approvedAt) || "",
            approveComment: (cim && cim.approveComment) || "",
            
            rejectedBy: (cim && cim.rejectedBy) || "",
            rejectedAt: (cim && cim.rejectedAt) || "",
            rejectComment: (cim && cim.rejectComment) || "",

            reviewedBy: (cim && cim.reviewedBy) || "",
            reviewedAt: (cim && cim.reviewedAt) || "",
            reviewComment: (cim && cim.reviewComment) || "",

            declinedBy: (cim && cim.declinedBy) || "",
            declinedAt: (cim && cim.declinedAt) || "",
            declineComment: (cim && cim.declineComment) || "",

        }));
    }, [cim]);

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            expectedOutcomes: state.expectedOutcomes,
            id,
            path: `change/impact/view/${id}`,
        };
        await dispatch(submitCIM(data));
        await dispatch(getCIMs);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Change Impact Form Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/change/impact");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            expectedOutcomes: state.expectedOutcomes,
            id
        }
        await dispatch(updateCIM(data));
        setTimeout(async () => {
            await dispatch(getCIM(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Change Impact Form Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" ||
            state.status === "declined" ||
            state.status === "rejected")
        )
        : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" className={classes.title}>
                    Edit Change Impact Monitoring
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/change/impact")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Paper variant="outlined" className={classes.status}>
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        Document No.
                                    </Typography>
                                    <Divider className={classes.divider2} orientation="vertical" />
                                    {loading ? "Loading..." : state.documentNo}
                                    
                                </Paper>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;
                            <Grid item>
                                <Paper
                                    variant="outlined"
                                    className={clsx(
                                        state.status === "draft"
                                            ? classes.statusYellow
                                            : state.status === "submitted"
                                                ? classes.statusOrange
                                                : state.status === "reviewed"
                                                    ? classes.statusGreen
                                                    : (state.status === "approved" || state.status === "sent")
                                                        ? classes.statusDarkGreen
                                                        : classes.statusRed,
                                        classes.status
                                    )}
                                >
                                
                                <Typography variant="overline" className={classes.statusTitle}>
                                    Status
                                </Typography>
                                <Divider className={classes.divider2} orientation="vertical" />
                                <Typography variant="overline" className={classes.statusTitle}>
                                    {loading ? "Loading..." : state.status}
                                </Typography>

                                </Paper>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </Grid>

                
                <form onSubmit={handleSubmitHandler}>
                    <div>
                        <Typography variant="overline" className={classes.title}>
                            Change Request Details
                        </Typography>

                        <Paper variant="outlined" square spacing={3} className={classes.paper}>
                            <Paper variant="outlined" square className={classes.paper1}>
                                <Grid container spacing={2} className={classes.grid} style={{marginBottom:0}} >
                                    {state.changeRequest !== null ? <>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Request No"
                                                name="changeRequestNo"
                                                value={state.changeRequest?.requestNo || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Initiator"
                                                name="changeInitiator"
                                                value={state.changeRequest?.createdBy?.userName || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Coordinator"
                                                name="changeCoordinator"
                                                value={state.changeRequest?.reviewedBy?.userName || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Change Request Description"
                                                name="changeRequestDescription"
                                                value={state.changeRequest?.requestDescription || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                component="fieldset"
                                                disabled
                                            >
                                                <FormLabel style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                    Priority 
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Critical"
                                                        control={<Radio checked={(state.changeRequest?.priority === "Critical") ? true : false} />}
                                                        label="Critical"
                                                        name="priority"
                                                    />
                                                    <FormControlLabel
                                                        value="High"
                                                        control={<Radio checked={(state.changeRequest?.priority === "High") ? true : false} />}
                                                        label="High"
                                                        name="priority"
                                                    />
                                                    <FormControlLabel
                                                        value="Medium"
                                                        control={<Radio checked={(state.changeRequest?.priority === "Medium") ? true : false} />}
                                                        label="Medium"
                                                        name="priority"
                                                    />
                                                    <FormControlLabel
                                                        value="Low"
                                                        control={<Radio checked={(state.changeRequest?.priority === "Low") ? true : false} />}
                                                        label="Low"
                                                        name="priority"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </> : null }
                                </Grid>
                            </Paper>

                            {/* Timelines Added */}
                            {(state.changeRequest?.timelines && state.changeRequest?.timelines.length > 0) ? <>
                                <Typography variant="overline" className={classes.title2}>
                                    Project Timelines
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper1}>
                                    {(state.changeRequest?.timelines && state.changeRequest?.timelines.length > 0) ? state.changeRequest?.timelines.map((sitem, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            style={{marginBottom: 0}}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={8}>
                                                <label style={{ fontWeight: "bold" }}>Action</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    name="action"
                                                    value={sitem.action || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <label style={{ fontWeight: "bold" }}>Due Date</label>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    name="dueDate"
                                                    value={sitem.dueDate || ""}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    )) : <>
                                        <Typography variant="overline" className={classes.title2}>
                                            No Project Timeline added yet
                                        </Typography>
                                    </>}
                                </Paper>
                            </> : null }
                        </Paper>
                        
                        <Typography variant="overline" className={classes.title}>
                            Add Expected Outcome
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper1}>                            
                            {outcome ? (
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.grid}
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel id="duration">
                                                Duration
                                            </InputLabel>
                                            <Select
                                                required
                                                labelId="duration"
                                                value={(outcome.duration && outcome.duration) || ""}
                                                name="duration"
                                                onChange={handleOutcomeChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="7">7 Days</MenuItem>
                                                <MenuItem value="30">30 Days</MenuItem>
                                                <MenuItem value="90">3 months</MenuItem>
                                                <MenuItem value="180">6 months</MenuItem>
                                                <MenuItem value="365">1 year</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Expected Outcome"
                                            name="outcome"
                                            value={(outcome.outcome && outcome.outcome) || ""}
                                            onChange={handleOutcomeChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            component="fieldset"
                                        >
                                            <FormLabel>Category</FormLabel>
                                            <RadioGroup row onChange={handleOutcomeChange}>
                                                <FormControlLabel
                                                    value="positive"
                                                    control={<Radio checked={(outcome.category === "positive") ? true : false} />}
                                                    label="Positive"
                                                    name="category"
                                                />
                                                <FormControlLabel
                                                    value="negative"
                                                    control={<Radio checked={(outcome.category === "negative") ? true : false} />}
                                                    label="Negative"
                                                    name="category"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            component="fieldset"
                                        >
                                            <FormLabel>Action To Take</FormLabel>
                                            <RadioGroup row onChange={handleOutcomeChange}>
                                                <FormControlLabel
                                                    value="Stop"
                                                    control={<Radio checked={(outcome.activity === "Stop") ? true : false} />}
                                                    label="Stop"
                                                    name="activity"
                                                />
                                                <FormControlLabel
                                                    value="Start"
                                                    control={<Radio checked={(outcome.activity === "Start") ? true : false} />}
                                                    label="Start"
                                                    name="activity"
                                                />
                                                <FormControlLabel
                                                    value="Continue"
                                                    control={<Radio checked={(outcome.activity === "Continue") ? true : false} />}
                                                    label="Continue"
                                                    name="activity"
                                                />
                                                <FormControlLabel
                                                    value="Change"
                                                    control={<Radio checked={(outcome.activity === "Change") ? true : false} />}
                                                    label="Change"
                                                    name="activity"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Fab
                                            color="primary"
                                            style={{ backgroundColor: "#1877F2" }}
                                            onClick={() => handleAddOutcomeArray(outcome)}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Paper>

                        {/* Outcomes Added */}
                        
                        <Typography variant="overline" className={classes.title}>
                            Expected Outcomes
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper1}>
                            {(state.expectedOutcomes && state.expectedOutcomes.length > 0) ? <>
                                {state.expectedOutcomes.map((sitem, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={2}>
                                            <label style={{ fontWeight: "bold" }}>Duration</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="duration"
                                                value={`${sitem.duration + ' days' }` || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label style={{ fontWeight: "bold" }}>Expected Outcome</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="outcome"
                                                value={sitem.outcome || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <label style={{ fontWeight: "bold" }}>Outcome Category</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="category"
                                                value={sitem.category || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <label style={{ fontWeight: "bold" }}>Action to Take</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                name="activity"
                                                value={sitem.activity || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <label>&nbsp;</label>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveOutcomeArray(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                )) }
                            </> : <>
                                <Typography variant="overline" className={classes.title2}>
                                    No Expected Outcome has been added yet
                                </Typography>
                            </> }
                        </Paper>
                            
                        <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                            style={{ marginBottom: 5 }}
                        >
                            <Grid item>
                                <Typography variant="overline" className={classes.title2}>
                                    Audit Trail
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Icon className="fas fa-clipboard-list" color="disabled" />
                            </Grid>
                        </Grid>


                        <Paper variant="outlined" square className={classes.paper}>
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                {state.status === "draft" && (
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Created By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdAt}</span>
                                        </Paper>
                                    </Grid>
                                )}
                                {state.status !== "draft" && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Submitted By</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedAt}</span>
                                            </Paper>
                                        </Grid>
                                        {(state.rejectedAt && state.rejectedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Rejected By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                        {(state.declinedAt && state.declinedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Declined By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declineComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Paper>

                        {isCanEdit ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleSave}
                                        >
                                            {loading ? "Loading..." : "Save Changes"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            onClick={handleSubmitHandler}
                                            disabled={loading}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                    </div>
                </form>

            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditChangeImpact;
