import React, { useState } from "react";
import {
  TextField,
  Paper,
  makeStyles,
  Typography,
  Grid,
  Divider,
  IconButton,
  Button,
  Backdrop,
  InputAdornment,
  darken,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { connect } from "react-redux";
import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";
import { useHistory, useParams } from "react-router-dom";
import { getAnnualBudgetLineById } from "../../../actions/annualAction";
import { formatAmount, formatCurrency } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  btn: {
    marginBottom: 15,
  },
  btn2: {
    marginLeft: 10,
    marginBottom: 15,

    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  table: {
    minWidth: 500,
  },
  divider: {
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  grid: {
    marginTop: 15,
  },
  textField2: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  textFieldSmall: {
    width: 100,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const INIT_STATE = {
  employeeName: "",
  head: "",
  subHead: "",
  item: "",
  description: "",
  costCenter: "",
  periods: [],
  year: "",
  subTotal: 0,
};

const ViewAnnualBudgetLine = ({
  getAnnualBudgetLineById,
  annualBudgetLine,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [state, setState] = useState(INIT_STATE);

  const history = useHistory();
  const { id } = useParams();

  const annualBudgetLineCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getAnnualBudgetLineById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [id, getAnnualBudgetLineById]);

  React.useEffect(() => {
    annualBudgetLineCallback();
  }, [annualBudgetLineCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: annualBudgetLine && annualBudgetLine.employee,
      head: annualBudgetLine && annualBudgetLine.head,
      subHead: annualBudgetLine && annualBudgetLine.subHead,
      item: annualBudgetLine && annualBudgetLine.item,
      costCenter: annualBudgetLine && annualBudgetLine.costCenter,
      description: annualBudgetLine && annualBudgetLine.description,
      subTotal:
        (annualBudgetLine && formatAmount(annualBudgetLine.subTotal)) ||
        formatAmount(0),
      year: annualBudgetLine && annualBudgetLine.year,
      periods: annualBudgetLine && annualBudgetLine.period,
    }));
  }, [annualBudgetLine]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };
  const filteredRows = () =>
    state.periods === null || state.periods === undefined
      ? []
      : state.periods.filter((row) => {
          if (search !== "") {
            return (
              row.amount
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.month.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Array(10).length - page * rowsPerPage);

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="overline" className={classes.title}>
            View BudgetLine / Annual
          </Typography>
          <Button
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            className={classes.backBtn}
          >
            Back
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="flex-start"
        className={classes.grid}
      >
        <Grid item xs={12} md={6}>
          <TextField
            label="Employee Name"
            fullWidth
            variant="outlined"
            className={classes.textField}
            name="employeeName"
            value={state.employeeName || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Expense Head"
            fullWidth
            variant="outlined"
            name="head"
            className={classes.textField}
            value={state.head || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Expense SubHead"
            fullWidth
            variant="outlined"
            name="subHead"
            className={classes.textField}
            value={state.subHead || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Budget Category"
            fullWidth
            variant="outlined"
            name="item"
            className={classes.textField}
            value={state.item || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Description"
            fullWidth
            variant="outlined"
            name="description"
            className={classes.textField}
            value={state.description || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Year"
            fullWidth
            variant="outlined"
            name="year"
            className={classes.textField}
            value={state.year || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Cost Center"
            fullWidth
            variant="outlined"
            name="costCenter"
            className={classes.textField}
            value={state.costCenter || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>

      <Divider className={classes.divider} light />

      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Month(s)</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.month}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.amount
                        ? formatCurrency(row.amount, "en-NG", "NGN")
                        : formatCurrency(0, "en-NG", "NGN")}
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Paper>
      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <TextField
            label="Total"
            variant="outlined"
            disabled
            className={classes.textField2}
            value={state.subTotal}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">&#8358;</InputAdornment>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                </>
              ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  annualBudgetLine: state.annualBudget.annualBudgetLine.data,
});

const mapDispatchToProps = {
  getAnnualBudgetLineById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAnnualBudgetLine);
