export const isCashManager = (profile) => {
  return (
    profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "manager" && grp.module === "cash_advance"
    )
  );
};

export const isCashPayer = (profile) => {
  return (
    profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "payer" && grp.module === "cash_advance"
    )
  );
};

export const isCashApprover = (profile) => {
  return (
    profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "head" && grp.module === "cash_advance"
    )
  );
};

export const isCashApproverCeo = (profile) => {
  return (
    profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "ceo" && grp.module === "cash_advance"
    )
  );
};
